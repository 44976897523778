import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getListReportByPr,
  getListCourseApi,
  getDetailReportApi,
  exportReportApi,
  getListTrainingFacility,
  getDetailLessonApi,
} from '../service';
import {
  getListReportItem,
  getListReportItemFail,
  getListReportItemSuccess,
  exportReport,
  exportReportFail,
  exportReportSuccess,
  getListTF,
  getListTFFail,
  getListTFSuccess,
  getDetail,
  getDetailFail,
  getDetailSuccess,
  getListCourse,
  getListCourseFail,
  getListCourseSuccess,
  setParams,
  getDetailLesson,
  getDetailLessonFail,
  getDetailLessonSuccess,
} from '../redux';
import { NotificationError } from 'components/notifications';
import moment from 'moment';
import { managementBaseSelector } from 'modules/auth/selectors';
import { ManagementBaseType } from 'modules/auth/types';
import { ActionType, UnitType } from 'helper/constants';
import { paramsSelector } from '../selector';
import { IMAGE_URL } from '../constants';
import { getDetailHistoryStart, setTypeModal } from 'modules/monitorHistory/redux';

function* getListReportItemByParams(action: ReturnType<typeof getListReportItem>): any {
  try {
    const res = yield getListReportByPr(action.payload);
    yield put(getListReportItemSuccess(res));
  } catch (e: any) {
    yield put(getListReportItemFail());
    NotificationError(e);
  }
}

function* handleGetDetail(action: ReturnType<typeof getDetail>): any {
  try {
    const res = yield getDetailReportApi(action.payload);
    yield put(
      getDetailSuccess({
        ...res,
        idCourse: action.payload.idCourse,
        idStudent: action.payload.idStudent,
        faceImage: res.faceImage ? `${IMAGE_URL}${res.faceImage}` : '',
      })
    );
  } catch (e: any) {
    yield put(getDetailFail());
    NotificationError(e);
  }
}

function* handleExportListReport(action: ReturnType<typeof exportReport>): any {
  try {
    const res = yield exportReportApi(action.payload);
    const blob = new Blob([res], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = `Bao_cao_qua_trinh_dao_tao_cua_hoc_vien_${moment().format('YYYYMMDD')}`;
    a.target = '_self';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
    yield put(exportReportSuccess());
  } catch (e: any) {
    yield put(exportReportFail());
    NotificationError(e);
  }
}

function* handleGetListCourse(action: ReturnType<typeof getListCourse>): any {
  try {
    const res = yield getListCourseApi(action.payload);
    yield put(getListCourseSuccess(res));
  } catch (e: any) {
    yield put(getListCourseFail());
    NotificationError(e);
  }
}

function* handleGetListTF(): any {
  try {
    const { clientType }: ManagementBaseType = yield select(managementBaseSelector);
    const res = yield getListTrainingFacility({
      clientType: clientType,
    });
    yield put(getListTFSuccess(res));
    if (clientType === UnitType.CSDT) {
      const params = yield select(paramsSelector);
      yield put(setParams({ ...params, clientId: res?.[0]?.id }));
    }
  } catch (e: any) {
    yield put(getListTFFail());
    NotificationError(e);
  }
}

function* handleGetDetailLesson(action: ReturnType<typeof getDetailLesson>): any {
  try {
    const res = yield getDetailLessonApi(action.payload);
    yield put(getDetailLessonSuccess());
    yield put(setTypeModal(ActionType.VIEW));
    yield put(getDetailHistoryStart(res));
  } catch (e: any) {
    yield put(getDetailLessonFail());
    NotificationError(e);
  }
}

export default function* studentReportSaga(): any {
  return all([
    yield takeLatest(getListReportItem, getListReportItemByParams),
    yield takeLatest(exportReport, handleExportListReport),
    yield takeLatest(getListTF, handleGetListTF),
    yield takeLatest(getDetail, handleGetDetail),
    yield takeLatest(getListCourse, handleGetListCourse),
    yield takeLatest(getDetailLesson, handleGetDetailLesson),
  ]);
}
