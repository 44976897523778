import { Row } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 8px);
  overflow-y: scroll;
  z-index: 999;
  .wrapChart {
    border-radius: 8px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 12px !important;
    width: 100%;
    margin-bottom: 12px;
  }
`;

export const TitleChart = styled.p`
  color: #03045e;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0;
`;
export const HeaderChart = styled.div`
  display: flex;
  gap: 16px;
  .ant-btn {
    min-width: 87px;
  }
`;

export const StyledSpanUpdate = styled.div`
  text-align: center;
  display: inline-block;
  span {
    border-radius: 4px;
    background: rgba(0, 77, 148, 0.1);
    color: #0059c9;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 21px */
    padding: 5px 8px;
  }
`;

export const StyledTooltip = styled.div`
  min-width: 80px;
  padding: 5px 0;
  text-align: center;
  p {
    margin: 0;
    color: #03326d;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 22px */

    span {
      color: #03326d;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */
    }
  }
`;

export const StyledWrapGrid = styled(Row)`
  width: 100%;
  margin: 0 !important;
`;

export const StyledWrapSpanUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTime = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #f6ece2;
  width: 130px;
  height: 33px;
  flex-shrink: 0;
  color: #ef7c08;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  svg {
    margin-right: 5px;
  }
`;
