import ManagementAgency from './page';
import { RouterConfig } from 'router/AppRoute';
import paths from 'helper/pathRoutes';

const routes: RouterConfig[] = [
  {
    path: paths.management_agency,
    page: ManagementAgency,
  },
];

export default routes;
