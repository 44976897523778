import { Select, Space } from 'antd';
import { changeLanguage, LanguageType } from 'languages/redux';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { typeLanguageSelector } from '../../../../languages/selectors';
import { IconFlag, WrapperChangeLanguage, WrapperFlagLanguage } from '../styles';
import { ReactComponent as FlagVN } from 'assets/imgs/FlagVN.svg';
import { ReactComponent as FlagEN } from 'assets/imgs/FlagEN.svg';
import { collapsedSelect } from 'components/layouts/selector/leftMenuSelector';

const ChangeLanguage = () => {
  const collapsed = useSelector(collapsedSelect);
  const type = useSelector(typeLanguageSelector);
  const dispatch = useDispatch();
  const handleChange = (value: any) => {
    dispatch(changeLanguage(value));
  };
  return (
    <WrapperChangeLanguage>
      {collapsed ? (
        <WrapperFlagLanguage>
          {type === LanguageType.VI ? (
            <FlagVN onClick={() => handleChange(LanguageType.EN)} />
          ) : (
            <FlagEN onClick={() => handleChange(LanguageType.VI)} />
          )}
        </WrapperFlagLanguage>
      ) : (
        <Select style={{ width: '100%', textAlign: 'center' }} onChange={handleChange} value={type}>
          <Select.Option value={LanguageType.VI} label="China">
            <Space>
              <IconFlag>
                <FlagVN />
              </IconFlag>
              Tiếng Việt
            </Space>
          </Select.Option>
          <Select.Option value={LanguageType.EN} label="USA">
            <Space>
              <IconFlag>
                <FlagEN />
              </IconFlag>
              English
            </Space>
          </Select.Option>
        </Select>
      )}
    </WrapperChangeLanguage>
  );
};

export default ChangeLanguage;
