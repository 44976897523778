import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spin from 'antd/lib/spin';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CModal from 'components/commons/Modal';
import { getLiveStart, setShowModalVideo } from 'modules/mapMonitor/redux';
import {
  loadingVideoSelector,
  playUrlSelector,
  showModalVideoSelector,
  cameraSnVideoSelector,
  wakeupStatusSelector,
} from 'modules/mapMonitor/selector';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { WakeupContainer, WakeupTitle } from './style';

const ModalPlayVideo = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(showModalVideoSelector);
  const loadingVideo = useSelector(loadingVideoSelector);
  const playUrl = useSelector(playUrlSelector);
  const cameraSn = useSelector(cameraSnVideoSelector);
  const wakeupStatus = useSelector(wakeupStatusSelector);
  const handleCancel = () => {
    dispatch(setShowModalVideo(false));
  };

  const handleWakeup = () => {
    dispatch(getLiveStart(cameraSn));
  };

  return (
    <CModal open={showModal} title="Live Stream" closable maskClosable={false} onCancel={handleCancel} footer={null}>
      {wakeupStatus ? (
        <Spin spinning={loadingVideo} tip="Loading" size="large">
          <ReactPlayer width="100%" url={playUrl} playing={true} controls />
        </Spin>
      ) : (
        <WakeupContainer>
          <FontAwesomeIcon icon={faCircleXmark} size="2xl" />
          <WakeupTitle>
            <FormattedMessage id="mapMonitor.cameraOffline" />
          </WakeupTitle>
          <CButton onClick={handleWakeup} sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary}>
            <FormattedMessage id="mapMonitor.wakeup" />
          </CButton>
        </WakeupContainer>
      )}
    </CModal>
  );
};

export default ModalPlayVideo;
