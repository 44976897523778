import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataPayloadCreateDevice, DataPayloadUpdateDevice, ParamsPayloadType, DeviceModelType, VehicleType, DataPayloadAddDevice } from '../type';
import moment, { Moment } from 'moment';

export interface DeviceManagerState {
  params: ParamsPayloadType;
  listDevice: any;
  listWareHouse: any;
  loadingTable: boolean;
  loadingBtnModal: boolean;
  showModal: boolean;
  loadingModal: boolean;
  itemData: any;
  openFilter: boolean;
  typeModal: string;
  qsValue: string;
  deviceModels: DeviceModelType[];
  vehicles: VehicleType[];
  startCountDown: boolean;
  otpDevice: string;
  showOtpSection: boolean;
  timeCountDownOtp: Moment;
  id: string;
  cameraSn: string,
  password: string,
  serialTablet: string
  form: object
}

const initialState: DeviceManagerState = {
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  listDevice: {},
  loadingTable: false,
  loadingBtnModal: false,
  showModal: false,
  loadingModal: false,
  itemData: {},
  openFilter: false,
  typeModal: '',
  qsValue: '',
  deviceModels: [] as any,
  vehicles: [] as any,
  startCountDown: false,
  otpDevice: '',
  showOtpSection: false,
  timeCountDownOtp: moment(),
  id: '',
  cameraSn: '',
  password: '',
  serialTablet: '',
  listWareHouse: {},
  form: {}
};



const deviceManagerSlice = createSlice({
  name: 'deviceManagerSlice',
  initialState,
  reducers: {
    setOpenFilter: (state, action: PayloadAction<boolean>) => {
      state.openFilter = action.payload;
    },
    getListDevice: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.loadingTable = true;
    },
    getListDeviceSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.listDevice = action.payload;
    },
    getListWareHouse: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.loadingTable = true;
    },
    getListWareHouseSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.listWareHouse = action.payload;
    },
    getListDeviceFail: state => {
      state.loadingTable = false;
    },

    setParams: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.params = action.payload;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },

    setShowOtpSection: (state, action: PayloadAction<boolean>) => {
      state.showOtpSection = action.payload;
    },

    createDevice: (state, action: PayloadAction<DataPayloadCreateDevice>) => {
      state.loadingBtnModal = true;
    },

    createDeviceSuccess: (state, action: PayloadAction<DataPayloadCreateDevice>) => {
      state.startCountDown = true;
      state.loadingBtnModal = false;
      state.otpDevice = action.payload?.otp;
      state.showOtpSection = true;
    },

    createDeviceFail: state => {
      state.loadingBtnModal = false;
      state.startCountDown = false;
    },
    handleCreateWareHouseFail: (state, action: PayloadAction<DataPayloadCreateDevice>) => {
      state.loadingBtnModal = false;
    },

    createWareHouse: (state, action: PayloadAction<DataPayloadAddDevice>) => {
      state.loadingBtnModal = true;
    },

    createWareHouseSuccess: (state, action: PayloadAction<DataPayloadCreateDevice>) => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },

    createWareHouseFail: state => {
      state.loadingBtnModal = false;
    },

    setStartCountDown: (state, action: PayloadAction<boolean>) => {
      state.startCountDown = action.payload;
    },

    resetOtp: state => {
      state.otpDevice = '';
    },

    getDetailDevice: (state, action: PayloadAction<string>) => {
      state.loadingBtnModal = true;
    },

    getDetailDeviceSuccess: (state, action: PayloadAction<any>) => {
      state.loadingBtnModal = false;
      state.itemData = action.payload;
      state.showModal = true;
    },

    getDetailDeviceFail: state => {
      state.loadingBtnModal = false;
    },

    setDataItem: (state, action: PayloadAction<any>) => {
      state.itemData = action.payload;
    },
    updateDevice: (state, action: PayloadAction<DataPayloadUpdateDevice>) => {
      state.loadingBtnModal = true;
    },
    updateDeviceSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },
    updateDeviceFail: state => {
      state.loadingBtnModal = false;
    },
    deleteDevice: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteDeviceWareHouse: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteAddDevice: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteDeviceSuccess: state => {
      state.loadingTable = false;
    },
    deleteDeviceFail: state => {
      state.loadingTable = false;
    },
    getListGroupDevice: (state, action: PayloadAction<any>) => { },
    getListGroupDeviceFail: state => { },
    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
    getListAddDeviceParam: state => {
      state.loadingTable = true;
    },
    getListAddDeviceParamSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.id = action.payload.id;
      state.cameraSn = action.payload.cameraSn;
      state.password = action.payload.password;
      state.serialTablet = action.payload.serialTablet;
    },
    getListAddDeviceParamFail: state => {
      state.loadingTable = false;
    },
    getListDeviceParam: state => {
      state.loadingTable = true;
    },
    getListDeviceParamSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.deviceModels = action.payload.deviceModels;
      state.vehicles = action.payload.vehicles;
    },
    getListDeviceParamFail: state => {
      state.loadingTable = false;
    },
    setTimeCountDownOtp: (state, action: PayloadAction<Moment>) => {
      state.timeCountDownOtp = action.payload;
    },
    setVehicles: (state, action: PayloadAction<VehicleType[]>) => {
      state.vehicles = action.payload;
    },
  },
});

export const {
  createDevice,
  createDeviceFail,
  createDeviceSuccess,
  createWareHouseSuccess,
  getListDevice,
  getListDeviceFail,
  getListDeviceSuccess,
  setParams,
  setShowModal,
  setShowOtpSection,
  getDetailDevice,
  getDetailDeviceFail,
  getDetailDeviceSuccess,
  setDataItem,
  updateDevice,
  updateDeviceFail,
  updateDeviceSuccess,
  deleteDevice,
  deleteDeviceFail,
  deleteDeviceSuccess,
  getListGroupDevice,
  getListGroupDeviceFail,
  setOpenFilter,
  setTypeModal,
  setQsValue,
  getListDeviceParam,
  getListDeviceParamSuccess,
  getListDeviceParamFail,
  setStartCountDown,
  resetOtp,
  setTimeCountDownOtp,
  getListWareHouseSuccess,
  getListWareHouse,
  deleteAddDevice,
  setVehicles,
  createWareHouse,
  deleteDeviceWareHouse,
  getListAddDeviceParam,
  handleCreateWareHouseFail,
  getListAddDeviceParamSuccess,
  getListAddDeviceParamFail
} = deviceManagerSlice.actions;

export default deviceManagerSlice.reducer;
