import React, { FC } from 'react';

import generatePicker from 'antd/es/date-picker/generatePicker';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { Form } from 'antd';

const DatePicker = generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = DatePicker;

const CDatePicker: FC<any> = React.memo(({ id, children, onBlur, ...rest }) => {
  const form = Form.useFormInstance();
  const field = id as string;
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    } else {
      form?.validateFields([field]);
    }
  };
  return (
    <DatePicker onBlur={handleBlur} style={{ minHeight: '36px' }} {...rest}>
      {children}
    </DatePicker>
  );
});

export const CRangePicker: FC<any> = React.memo(({ id, children, onBlur, ...rest }) => {
  const form = Form.useFormInstance();
  const field = id as string;
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    } else {
      form?.validateFields([field]);
    }
  };
  return (
    <RangePicker onBlur={handleBlur} style={{ minHeight: '36px' }} {...rest}>
      {children}
    </RangePicker>
  );
});

export default CDatePicker;
