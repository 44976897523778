import { Form } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { WrapperFormEmail } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailConfirm, setShowModalInputEmail } from 'modules/forgotPass/redux';
import Modal from 'components/commons/Modal';
import { showModalInputEmailSelector } from 'modules/forgotPass/selector';
import { TitleOtp, WrapperButton } from 'modules/auth/pages/ModalLogin/styles';
import Input from 'components/commons/Input';
import { RegValidEmail } from 'helper/constants';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';

const ModalInputEmail = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const openModal = useSelector(showModalInputEmailSelector);
  const handleClose = () => {
    dispatch(setShowModalInputEmail(false));
  };

  const onFinish = (values: any) => {
    dispatch(getEmailConfirm({ data: values.email, form: form }));
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Modal centered open={openModal} footer={null} onCancel={handleClose}>
      <TitleOtp>
        <FormattedMessage id="auth.titleForgotPassword" />
      </TitleOtp>
      <br />
      <WrapperFormEmail>
        <Form form={form} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" labelAlign="left">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Email' + intl.formatMessage({ id: 'validator.require' }) },
              {
                validator: (_, value) =>
                  !value || RegValidEmail.test(value)
                    ? Promise.resolve()
                    : Promise.reject(<FormattedMessage id="validator.requireEmail" />),
              },
            ]}
          >
            <Input maxLength={255} className="login-form__input" placeholder={intl.formatMessage({ id: 'auth.placeholderEmail' })} />
          </Form.Item>

          <WrapperButton>
            <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleClose}>
              <FormattedMessage id="common.cancel" />
            </CButton>
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} htmlType="submit">
              <FormattedMessage id="common.continue" />
            </CButton>
          </WrapperButton>
        </Form>
      </WrapperFormEmail>
    </Modal>
  );
};

export default ModalInputEmail;
