import { Col, Form, Row } from 'antd';
import { ActionType, RegValidEmail, RegValidStringEnglish } from 'helper/constants';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { accountManagerSelector, itemDataSelector, listGroupsSelector, listRolesSelector } from '../selector';
import {
  createAccount,
  setDataItem,
  setShowModal,
  setTypeModal,
  updateAccount,
  deleteAccount,
  pushListRoleStatus0,
  pushListGroupStatus0,
} from '../redux';
import { StyledModal } from '../page/styles';
import { useEffectOnce } from 'react-use';
import { isEmpty } from 'helper/utils';
import ModalConfirm from 'components/commons/modalConfirm';
import CInput from 'components/commons/Input';
import CSelect from 'components/commons/Select';
import { StyledTitleModal, TagActive } from 'modules/role/page/style';
import { themes } from 'styles';
import { useRolesByRouter } from 'helper/cusHooks';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { includes } from 'lodash';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { RowButton } from 'components/commons/Template/style';
import CSwitch from 'components/commons/Switch';
import { orderBy } from 'lodash';
interface Iprops {
  type: string;
}

const AddEditViewAccount = (props: Iprops) => {
  const { loadingBtnModal, showModal, typeModal, listRoleStatus0, listGroupStatus0 } = useSelector(accountManagerSelector);
  const listRole = useSelector(listRolesSelector);
  const listGroup = useSelector(listGroupsSelector);
  const { type } = props;
  const dispatch = useDispatch();
  const itemData = useSelector(itemDataSelector);
  const intl = useIntl();
  const listRoles = useRolesByRouter();

  const [requireRole, setRequireRole] = useState<boolean>(typeModal !== ActionType.VIEW);
  const [requireGroup, setRequireGroup] = useState<boolean>(typeModal !== ActionType.VIEW);
  const [form] = Form.useForm();

  const renderTitle = () => {
    switch (type) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };

  const setFields = (field: string, message: string) => {
    form.setFields([
      {
        name: field,
        errors: [message],
      },
    ]);
  };

  const onFinish = (values: any) => {
    const data = {
      ...values,
      username: values?.email,
      roles: values?.roles?.map((item: string) => ({
        roleId: item,
      })),
      groups: values?.groups?.map((item: string) => ({
        id: item,
      })),
      status: values?.status ? 1 : 0,
    };

    if (type === ActionType.ADD) {
      dispatch(
        createAccount({
          ...data,
          setFields: setFields,
        })
      );
      return;
    }
    if (type === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(
            updateAccount({
              ...data,
              userId: itemData?.userId,
            })
          );
        },
      });
      return;
    }
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',

        handleConfirm: () => {
          dispatch(setShowModal(false));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        status: 0,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setShowModal(false));
          },
        });
      } else {
        dispatch(setShowModal(false));
      }
      return;
    }
    dispatch(setShowModal(false));
  };
  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
    setRequireGroup(true);
    setRequireRole(true);
  };

  const handleDeleteRole = () => {
    if (itemData?.userId) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteAccount([itemData?.userId]));
          handleCancel();
        },
      });
    }
  };

  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = form.getFieldValue(field);
      form.setFieldValue(field, value.trim());
      form.validateFields([field]);
    }, 0);
  };

  const handleChangeRole = (values: string[]) => {
    if (values.length) {
      setRequireGroup(false);
      setRequireRole(false);
      form.setFields([
        {
          name: 'groups',
          errors: [],
        },
      ]);
    } else if (!values?.length && !form.getFieldValue('groups')?.length) {
      setRequireRole(true);
      setRequireGroup(true);
    } else if (!values.length && form.getFieldValue('groups')?.length && requireRole) {
      setRequireRole(false);
    }
    if (listRoleStatus0.length === 0) return;
    const arr = [...listRoleStatus0].filter(item => values.includes(item.roleId));
    dispatch(pushListRoleStatus0(arr));
  };
  const handleChangeGroup = (values: string[]) => {
    if (values.length) {
      setRequireGroup(false);
      setRequireRole(false);
      form.setFields([
        {
          name: 'roles',
          errors: [],
        },
      ]);
    } else if (!values?.length && !form.getFieldValue('roles')?.length) {
      setRequireRole(true);
      setRequireGroup(true);
    } else if (!values.length && form.getFieldValue('roles')?.length && requireGroup) {
      setRequireGroup(false);
    }
    if (listGroupStatus0.length === 0) return;
    const arr = [...listGroupStatus0].filter(item => values.includes(item.groupId));
    dispatch(pushListGroupStatus0(arr));
  };

  useEffect(() => {
    if (typeModal === ActionType.ADD) return;
    if (!itemData?.groups?.length && !itemData?.roles?.length) {
      setRequireGroup(true);
      setRequireRole(true);
    } else {
      setRequireGroup(false);
      setRequireRole(false);
    }
  }, [itemData, typeModal]);

  useEffectOnce(() => {
    return () => {
      dispatch(setDataItem({}));
      form.resetFields();
    };
  });

  return (
    <StyledModal closable closeIcon={<CloseIcon />} open={showModal} maskClosable={false} onCancel={handleCancel} footer={null}>
      <Form form={form} labelAlign="left" onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <StyledTitleModal>{renderTitle()}</StyledTitleModal>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              valuePropName="checked"
              initialValue={typeModal === ActionType.ADD ? 1 : itemData?.status}
              name="status"
              className="switchActive"
              label={typeModal !== ActionType.VIEW ? intl.formatMessage({ id: 'common.active' }) : ''}
            >
              {typeModal === ActionType.VIEW ? (
                <>
                  <TagActive clr={itemData?.status ? themes.statusGreen : themes.menuDisableDark}>
                    <FormattedMessage id={itemData?.status ? 'common.active' : 'common.inactive'} />
                  </TagActive>
                </>
              ) : (
                <CSwitch disabled={typeModal !== ActionType.EDIT} />
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'common.name' })}
              name="fullName"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'common.name' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
              initialValue={itemData?.fullName}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'fullName');
                }}
                disabled={type === ActionType.VIEW}
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'accountManager.employeeCode' })}
              name="userCode"
              rules={[
                {
                  required: true,
                  message:
                    intl.formatMessage({ id: 'accountManager.employeeCode' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
                {
                  validator: (_, value) =>
                    !value || RegValidStringEnglish.test(value)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'accountManager.employeeCode' }) +
                              ' ' +
                              intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        ),
                },
              ]}
              initialValue={itemData?.userCode}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'userCode');
                }}
                disabled={type !== ActionType.ADD}
                maxLength={20}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'common.email' })}
              name="email"
              rules={[
                {
                  validator: (_, value) =>
                    !value || RegValidEmail.test(value)
                      ? Promise.resolve()
                      : Promise.reject(<FormattedMessage id="validator.requireEmail" />),
                },
                {
                  required: true,
                  message: intl.formatMessage({ id: 'common.email' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
              initialValue={itemData?.username}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'email');
                }}
                maxLength={255}
                disabled={type !== ActionType.ADD}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'role.role' })}
              name="roles"
              initialValue={itemData?.roles?.map((item: any) => item?.roleId)}
              rules={[
                {
                  required: requireRole,
                  message: intl.formatMessage({ id: 'validator.requireRoleOrGroup' }),
                },
              ]}
            >
              <CSelect
                options={orderBy([...listRole, ...listRoleStatus0], ['roleStatus', 'roleCode'], ['desc', 'asc']).map((item: any) => {
                  return {
                    value: item.roleId,
                    label:
                      item?.roleCode +
                      '_' +
                      item.roleName +
                      (item?.roleStatus === 0 ? intl.formatMessage({ id: 'common.optionInactive' }) : ''),
                  };
                })}
                mode="multiple"
                filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                disabled={type === ActionType.VIEW}
                onChange={handleChangeRole}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'userGroupManager.userGroup' })}
              name="groups"
              initialValue={itemData?.groups?.map((item: any) => item?.groupId)}
              rules={[
                {
                  required: requireGroup,
                  message: intl.formatMessage({ id: 'validator.requireRoleOrGroup' }),
                },
              ]}
            >
              <CSelect
                options={orderBy([...listGroup, ...listGroupStatus0], ['groupStatus', 'groupCode'], ['desc', 'asc']).map((item: any) => {
                  return {
                    value: item.groupId ?? item.id,
                    label:
                      item.groupCode +
                      '_' +
                      item.groupName +
                      (item?.groupStatus === 0 ? intl.formatMessage({ id: 'common.optionInactive' }) : ''),
                  };
                })}
                mode="multiple"
                filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                disabled={type === ActionType.VIEW}
                onChange={handleChangeGroup}
              />
            </Form.Item>
          </Col>
        </Row>
        <RowButton>
          {typeModal === ActionType.VIEW ? (
            <>
              {includes(listRoles, actionsType.DELETE) && (
                <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteRole}>
                  <FormattedMessage id="common.delete" />
                </CButton>
              )}
              {includes(listRoles, actionsType.UPDATE) && (
                <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} onClick={handleChangeEdit}>
                  <FormattedMessage id="common.edit" />
                </CButton>
              )}
            </>
          ) : (
            <>
              <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </CButton>

              <CButton loading={loadingBtnModal} sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} htmlType="submit">
                <FormattedMessage id="common.save" />
              </CButton>
            </>
          )}
        </RowButton>
      </Form>
    </StyledModal>
  );
};
export default AddEditViewAccount;
