import React from 'react';
import { useIntl } from 'react-intl';
import { StyledCommonTable } from './styles';

const CTable = ({ ...rest }) => {
  const intl = useIntl();
  return <StyledCommonTable locale={{ emptyText: intl.formatMessage({ id: 'common.noData' }) }} indentSize={50} {...rest} />;
};

export default CTable;
