import ListFleet from '../components/ListFleet';
import Map from '../components/GoogleMap';
import { LaneColor, Row, Wrapper } from './style';
import DetailSession from '../components/DetailSession';
import { useDispatch, useSelector } from 'react-redux';
import { isViewDetailSelector } from '../selector';
import ModalPlayVideo from '../components/PlayVideo';
import { useEffectOnce } from 'react-use';
import { resetSearch, setIsRunOffset, setIsViewDetail } from '../redux';
import DetailPlayVideo from '../components/DetailPlayVideo';
import { TitleHeader } from 'components/commons/Template/style';
import { FormattedMessage } from 'react-intl';
import DetailInfo from '../components/DetailInfo';

const MapMonitorPage = () => {
  const dispatch = useDispatch();
  const isViewDetail = useSelector(isViewDetailSelector);

  useEffectOnce(() => {
    dispatch(setIsViewDetail(false));
    return () => {
      dispatch(resetSearch());
      dispatch(setIsRunOffset(false));
    };
  });

  return (
    <>
      <TitleHeader>
        <FormattedMessage id="layout.leftMenu.liveMonitoring" />
      </TitleHeader>

      <Row>
        {isViewDetail && <DetailInfo />}
        <Wrapper $isDetail={isViewDetail}>
          {!isViewDetail ? <ListFleet /> : <DetailSession />}
          <LaneColor />
          <Map />
          <ModalPlayVideo />
          <DetailPlayVideo />
        </Wrapper>
      </Row>
    </>
  );
};

export default MapMonitorPage;
