import { Modal } from 'antd';
import { createIntl, createIntlCache } from 'react-intl';
import { store } from 'redux/store';
import { ReactComponent as ColseIcon } from 'assets/imgs/CloseIcon.svg';
import { WrapperButton } from 'modules/auth/pages/ModalLogin/styles';
import { StyledContentConfirm, StyledTitle } from './styles';
import CButton, { TypeCustom, TypeSizeCustom } from '../Button';
import language from 'languages/translate';
import { Language } from 'languages/redux';
interface ModalConfirmProps {
  title: string;
  message?: string;
  handleConfirm: () => void;
}

function ModalConfirm(props: ModalConfirmProps) {
  const { title, message, handleConfirm } = props;
  const { confirm, destroyAll } = Modal;

  const { type } = store.getState().language;
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: type,
      messages: language[Language[type]],
    },
    cache
  );

  confirm({
    title: <StyledTitle>{intl?.formatMessage({ id: title })}</StyledTitle>,
    icon: null,
    closeIcon: <ColseIcon />,
    footer: null,
    closable: true,
    centered: true,
    content: (
      <StyledContentConfirm>
        {message && <p>{intl?.formatMessage({ id: message })}</p>}
        <WrapperButton>
          <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={destroyAll}>
            {intl?.formatMessage({ id: 'common.no' })}
          </CButton>

          <CButton
            typeCustom={TypeCustom.Primary}
            sizeCustom={TypeSizeCustom.Medium}
            onClick={() => {
              handleConfirm();
              destroyAll();
            }}
          >
            {intl?.formatMessage({ id: 'common.yes' })}
          </CButton>
        </WrapperButton>
      </StyledContentConfirm>
    ),
  });
}

export default ModalConfirm;
