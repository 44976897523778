import AppLanguage from './types';

const en: AppLanguage = {
  common: {
    success: 'Successful',
    notSuccess: 'Unsuccessful',
    updateSuccess: 'Update {value} successful ',
    fail: 'Fail',
    or: 'or',
    password: 'Password',
    action: 'Action',
    desc: 'Description',
    filter: 'Filter',
    search: 'Search',
    refresh: 'Refresh',
    add: 'Add',
    addSuccess: 'Add {value} success',
    delete: 'Delete',
    deleteSuccess: 'Delete success',
    removeCondition: 'Remove condition',
    person: 'Person',
    organization: 'Organization',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    confirmDelete: 'Are you sure you want to delete the record?',
    relatedDataDeleted: 'The related data will also be deleted',
    accept: 'Accept',
    confirmCloseModalAdd: 'Are you sure you want to close the new add window?',
    confirmCloseModalUpdate: 'Are you sure you want to close the update window?',
    confirmUpdate: 'Are you sure you want to update this record?',
    cannotRevert: 'You will not be able to revert this!',
    confirmCancelAction: 'Are you sure you want Action Badge?',
    back: 'Back',
    continue: 'Continue',
    active: 'Active',
    inactive: 'Inactive',
    cccd: 'Citizenship ID',
    authen: 'Authenticating account registration.',
    checkEmailOTP: 'Please check your email to get the OTP',
    confirm: 'Confirm',
    logout: 'Log out',
    email: 'Email',
    name: 'Name',
    error: 'Error',
    noData: 'No data',
    detail: 'Detail',
    update: 'Update',
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    total: 'Total',
    item: 'items',
    placeholderSearch: 'You want to search...',
    detailSearch: 'Detail search',
    status: 'Status',
    viewDetail: 'See details',
    updateInfo: 'Update information',
    address: 'Address',
    addressDetail: 'Number of houses, roads, villages',
    commune: 'Communes/wards',
    district: 'Districts',
    province: 'Province/City',
    tax: 'Tax ID',
    phoneNumber: 'Phone number',
    accessArea: 'Access area',
    object: 'Object',
    time: 'Time',
    endDate: 'End date',
    startDate: 'Start date',
    area: 'Area',
    creator: 'Creator',
    creationDate: 'Creation date',
    updater: 'Updater',
    updatedDate: 'Updated date',
    image: 'Image',
    unit: 'Unit',
    gender: 'Gender',
    on: 'Bật',
    off: 'Tắt',
    start: 'Start',
    end: 'End',
    addList: 'Add list',
    jump_to: 'Go to',
    result: 'Result',
    condition: 'Condition',
    perform: 'Perform',
    completed: 'Completed',
    valid: 'Valid',
    invalid: 'Invalid',
    downDetailFile: 'Download detailed file',
    optionInactive: ' (Inactive)',
    helper: 'Helper',
    subscribers: 'Subscribers',
    system: 'System',
    all: 'All',
    teacher: 'Teacher',
    student: 'Student',
    startTime: 'Start time',
    endTime: 'End time',
    dateBirth: 'Date of birth',
    cameraSn: 'Camera Sn',
    serialTablet: 'Tablet serial'
  },
  layout: {
    leftMenu: {
      overview: 'Over view',
      accountAuthorization: 'Account authorization',
      managementAgencyDirectory: 'Management agency directory',
      manageRoles: 'Manage roles and powers',
      manageAccountGroups: 'Manage account groups',
      deviceDeclaration: 'Device declaration',
      manageDeviceDetails: 'Manage device details',
      accessManagement: 'Access management',
      manageAccessPerson: 'Access person management',
      accountManager: 'Account management',
      accessHistory: 'Access history',
      report: 'Report',
      accessGroup: 'Access group',
      advancedSetting: 'Advanced Setting',
      sync: 'Sync',
      management: 'Information management',
      courseManager: 'Course management',
      teacherManager: 'Instructor management',
      student: 'Student Management',
      monitor: 'Monitor',
      liveMonitoring: 'Live monitor',
      monitoringHistory: 'Monitor history',
      studentReport: 'Student report',
      vehicleReport: 'Vehicle report',
      practiceReport: 'Practice report',
      device: 'Device management',
      addDevice: 'Add devices to the system',
      addDeviceMXC: 'Add equipment to inventory',
      vehicleManager: 'Vehicle management',
      addendumA: 'A addendum',
      addendumB: 'B addendum',
      addendumC: 'C addendum',
    },
    header: {
      sync: 'Sync data',
      notSync: 'The most recent data has not been synchronized with the device',
      syncNow: 'Sync Now',
      syncing: 'Data sync is in progress',
      titleService: 'Service usage information',
      extend: 'Extend now',
    },
  },
  auth: {
    title: 'MK Access Control',
    pass: 'Password',
    remember: 'Remember me',
    forgotPassword: 'Forgot password',
    titleForgotPassword: 'Please enter your email to retrieve your password',
    login: 'Login',
    labelUser: 'User',
    signup: 'Create an Account',
    alreadyHaveAccount: 'Already have an account? Sign in',
    createAccount: 'Create an account',
    loginSuccess: 'Login successful',
    loginFail: 'Login failed',
    accountIncorrect: 'Incorrect account or password',
    nameCustomer: 'Name of person',
    nameCustomer2: 'Name of organization',
    placeholderNameCustomer: 'Name of person',
    placeholderNameCustomer2: 'Name of organization',
    placeholderCCCD: 'Enter CCCD',
    placeholderTax: 'Enter tax',
    placeholderEmail: 'Enter email',
    placeholderPass: 'Enter pass',
    placeholderConfirmPass: 'Enter confirm pass',
    placeholderChangePass: 'Enter pass',
    placeholderNewPass: 'Enter new pass',
    placeholderConfirmNewPass: 'Enter confirm new pass',
    typeCustomer: 'Customer Type',
    confirmOTP: 'Verify OTP',
    chooseClient: 'Please choose data to access!',
    signUpSuccess: 'Sign up successful',
    checkEmail: 'Please check your email to verify your account.',
    changePass: 'Change password',
    newPass: 'New password',
    oldPass: 'Old password',
    changePassSuccess: 'Change password successful',
    typeOtp: 'Please enter the OTP sent to the email',
    notFoundOTP: 'Did not receive the code? Time remaining',
    resendOTP: 'Resend OTP',
    recoveryPassSuccess: 'Password recovery successful',
    here: 'here',
    labelRegisterSuccess: 'Register success',
    checkEmailSpam: 'Please check in your Spam folder if you do not receive our message',
    backLogin: 'Back to login',
    titleOtp: 'Enter OTP',
    labelOtp: 'Please enter the verification code (OTP) sent to the email ',
    timeCount: 'Time remaining ',
    checkCode: 'Did not receive the code? ',
    sendOtpAgain: 'Send Otp again',
    resetPass: 'Reset pass',
  },
  dashBoard: {
    placeholderAreaId: 'Chose',
    errDate: 'From data is before to date',
    errRange: 'Please find in 1 years',
    titleTooltip: 'Member',
    titleLineChart: 'Total sesion',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    inter: 'Inter ',
    orther: 'Orther ',
    object: 'Object ',
  },
  validator: {
    require: ' is require',
    requireField: 'Required field',
    requireEmail: 'The input is not valid E-mail!',
    requirePass: 'Input your password!',
    requireNewPass: 'Input new password!',
    requireConfirmPass: 'Confirm your password!',
    requireConfirmNewPass: 'Confirm new password!',
    confirmPassFail: 'The two passwords that you entered do not match!',
    passMin8: 'Password must have a minimum length of 8',
    validPass: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character',
    cccdMin12: 'Minimum length of 12',
    requireNumber: 'Only number',
    requireRoleOrGroup: 'You must choose either Role or Account Group information',
    errFormat: ' not valid',
    errFormatLP: ' not valid, etc (30H12345)',
  },
  role: {
    title: 'role',
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
    role: 'Role',
    roleCode: 'Role code',
    roleName: 'Role name',
    statusName: 'Status',
    addRole: 'Add role',
    editRole: 'Edit role',
    deleteRole: 'Delete role',
    viewRole: 'View role',
    addSuccess: 'Add role successful',
    updateSuccess: 'Edit role successful',
    deleteSuccess: 'Delete role successful',
    roleAndDelegation: 'Roles & Delegation',
    decentralization: 'Decentralization',
    tooltipQS: 'Find the role code, role name, creator, updater, dates',
  },
  managementAgency: {
    title: 'agency',
    management_agency: 'Management agency directory',
    unitName: 'Unit name',
    address: 'Address',
    representative: 'Representative',
    phone: 'Phone',
    email: 'Email',
    name: 'Agency name',
    foundedYear: 'Founded year',
    signInEmail: 'Email login',
    phoneNumber: 'Phone number',
    errYear: 'The year of establishment must not be greater than the current year',
    searchForm: 'Search Unit Name, Address, Representative, Phone Number, Email',
  },
  userGroupManager: {
    groupCode: 'groupCode',
    groupName: 'groupName',
    roles: 'roles',
    delete: 'delete',
    user: 'user',
    groupNameUser: 'groupNameUser',
    groupCodeUser: 'groupCodeUser',
    userGroup: 'Group',
    title: 'group',
    tooltipQS: 'Find group code, group name, role, creator, updater, dates',
  },
  accountManager: {
    account: 'Account',
    accountCode: 'Account code',
    accountName: 'Account name',
    addAccount: 'Add account',
    editAccount: 'Edit account',
    deleteAccount: 'Delete account',
    viewAccount: 'View account',
    addSuccess: 'Add account successful',
    updateSuccess: 'Edit account successful',
    deleteSuccess: 'Delete account successful',
    employeeCode: 'Employee code',
    tooltipQS: 'Find first and last name, employee ID, email, account group, creator role, updater, dates',
  },
  report: {
    export: 'Export reports',
    errDate: 'The search period must not exceed 6 months. Please reselect your search time.',
  },
  profile: {
    accountInfo: 'Account Information',
    contactInfo: 'Contact Info',
    customerInfo: 'Customer Information',
    customerName: 'Customer Name',
    customerType: 'Type of customer',
    position: 'Position',
    identifier: "Customer's code",
    email: 'Email',
    updateSuccess: 'Update profile successful',
    confirmUpdateProfile: 'Are you sure you want to update profile?',
  },
  instructorManagement: {
    delete: 'delete',
    title: 'instructor',
    tooltipQS: 'Search First And Last Name, Certification No, Driver License Category, Phone Number, Email',
    all: 'All',
    working: 'Working',
    quitted: 'Quitted',
    portraitImage: 'Portrait Image',
    fullName: 'Full Name',
    cccd: 'Citizenship ID',
    gender: 'Gender',
    birthday: 'Birthday',
    address: 'Address',
    phoneNo: 'Phone No',
    email: 'Email',
    driverLicenseNo: 'Driver License No',
    driverLicenseCategory: 'Driver License Category',
    certificationNo: 'Certification No',
    male: 'Male',
    female: 'Female',
    workingStatus: 'Working Status',
    falsePhoneFormat: 'Phone number is not in the correct format',
    verified: 'Verified',
    downImage: 'Upload photo',
  },

  trainingFacility: {
    title: 'Management of training facilities',
    span: 'training facility',
    name: 'Unit name',
    agency: 'Management agency',
    person: 'Representative',
    foundYear: 'Founded year',
    nameFacility: 'Name of training institution',
    tooltip: 'Search for unit name, management agency, address, representative, phone number, login email',
  },
  course: {
    title: 'Course',
    courseManagement: 'Course management',
    courseCode: 'Course code',
    courseName: 'Course name',
    tranningNetwork: 'Training level',
    startDate: 'Start date',
    endDate: 'End date',
    tranningLevel: 'Training level',
    estimateExamDate: 'Estimate exam date',
    courseInfo: 'Course information',
    detailGV_HV: 'Detail GV_HV',
    detailTrainingSession: 'Detail tranning session',
    listTeacher: 'List teacher',
    litStudent: 'List student',
    tooltipQS: 'Find Course ID, Course Name, Training level, Start time, End time',
    cccd: 'Citizen identification',
    numberGPLX: 'GPLX number',
    levelGPLX: 'GPLX level',
    codeTraining: 'Training certificate number',
    typeTraining: 'Training type',
    carPratice: 'Pratice car',
    sessionTraining: 'Training session',
    addSuccess: 'Add course successfully',
    updateSuccess: 'Edit course successfully',
    deleteSuccess: 'Delete course successfully',
    optionQuitted: ' (Quitted)',
    fieldErrorDay1: 'The end date is not less than the start date',
    fieldErrorDay2: 'The estimate exam date must be greater than the end date',
  },
  student: {
    title: 'Student',
    idCard: 'ID card',
    courseCode: 'Course code',
    typesOfTraining: 'Types of training',
    phoneNumber: 'Phone number',
    email: 'Email',
    facePhoto: 'Face photo',
    gender: 'Gender',
    dateOfBirth: 'Date of birth',
    address: 'Address',
    previousTrainingClass: 'Previous training class',
    trainingClass: 'Training class',
    viewDetail: 'View detail',
    tab1: 'Personal Infomation',
    tab2: 'Training Infomation',
    accomplished: 'Accomplished',
    remaining: 'Remaining',
    trainingSessions: 'Training sessions',
    start: 'Start',
    end: 'End',
    hours: 'Hours',
    distance: 'Distance',
    overview: 'Overview',
    detail: 'Detail',
    verified: 'Verified',
    upload: 'Upload',
    tooltipQS: 'Find full name, id number, course code, phone number, email',
  },
  device: {
    device: 'Device',
    deviceCode: 'Device code',
    deviceName: 'Device name',
    deviceType: 'Device type',
    practiceCar: 'Practice car',
    serialCameraNo: 'Serial camera number',
    serialTabletNo: 'Serial tablet number',
    cameraPassword: 'Camera password',
    note: 'Note',
    addDevice: 'Add device',
    editDevice: 'Edit device',
    deleteDevice: 'Delete device',
    viewDevice: 'View device',
    addSuccess: 'Add device successful',
    registerFailed: 'Add device unsuccessful',
    updateSuccess: 'Edit device successful',
    deleteSuccess: 'Delete device successful',
    employeeCode: 'Employee code',
    tooltipQS: 'Find device name, device code, device type, practice car',
    otpGuideText: 'Please enter this OTP into your device',
    timeleft: 'Timeleft',
    resendOtpBtn: 'Resend OTP',
    otpIncorrect: 'Incorrect OTP',
    SDT_CAMERA: 'SDT Camera',
  },
  vehicleManagement: {
    delete: 'delete',
    title: 'vehicle',
    tooltipQS: 'Search license plate number, learner license number, brand, vehicle type, DAT device, owner, Vehicle owner phone number',
    all: 'All',
    licensePlate: 'License Plate',
    vehicleLicense: 'Vehicle License',
    brand: 'Brand',
    classification: 'Classification',
    device: 'DAT device',
    ownerName: 'Owner',
    ownerPhoneNumber: 'Owner Phone No',
    note: 'Note',
    falsePhoneFormat: 'Phone number is not in the correct format',
    verified: 'Verified',
  },
  liveMonitor: {
    event: 'Event',
    trip: 'Trip',
    eventSelect: 'Select event',
    copyLink: 'Copy Link',
    chooseDate: 'Choose date',
    droveCar: '{value} drove car',
    parkedCar: '{value} parked car',
    minutes: 'Minutes',
    filterMinutes: 'Filter distances over 5 minutes',
    copyLinkSuccess: 'Copy link successful',
    playVideo: 'Play video',
    now: 'Now',
    warningDate: 'Search up to 1 month',
    timeDuration: 'Time duration',
    titleMap: 'Map ({car}_{code})',
    drivingCar: '{value} driving car',
    countTime: 'Accumulated time: ',
    countDistance: 'Accumulated distance: ',
    accumulated: 'Accumulated: ',
  },
  studentReport: {
    title: 'Report on training progress of trainees',
    trainingFacility: 'Training facilities',
    lesson: 'Lesson',
    studentName: 'Student name',
    lessonCode: 'Lesson code',
    viewDetail: "View the details of the trainee's training process",
    studentInfo: 'Student Information',
    lessonInfo: 'Training process information',
    studentCode: 'Student code',
    dateTraining: 'Training day',
    total: 'Total',
    placeholderName: 'Enter your name',
  },
  vehicleReport: {
    trainingFacility: 'Training facility',
    placeholderTrainingFacility: 'Choose training facility',
    time: 'Time',
    licensePlate: 'License plate',
    placeholderLicensePlate: 'Choose license plate',
    vehicleClass: 'Vehicle class',
    placeholderVehicleClass: 'Choose vehicle class',
    owner: 'Owner',
    trainingSession: 'Training session',
    trainingStudent: 'Training student',
    trainingDate: 'Training date',
    hours: 'Hours',
    distance: 'Distance',
    titleModal: 'Details of the training process of the practice car',
    vehicleInfo: 'Vehicle information',
    trainingProcessInfo: 'Training process information',
    fromDate: 'From',
    toDate: 'to',
  },
  practiceReport: {
    trainingFacility: 'Training facilities',
    lesson: 'Lesson',
    viewDetail: 'View detailed driving practice results of the course',
    practiceInfo: 'Practice Information',
    lessonInfo: 'Training process information',
    studentCode: 'Student code',
    dateTraining: 'Training day',
    openingDay: 'Opening day',
    closingDay: 'Closing day',
    trainingDistance: 'Training distance',
    note: 'Note',
    placeholderTrainingFacility: 'Choose training facility',
    placeholderLicenseClass: 'Choose license class',
  },
  events: {
    FORWARD_COLLISION_WARNING: 'Vượt quá tốc độ',
    HEADWAY_MONITORING_WARNING: 'Đăng nhập',
    HEADWAY_MONITORING_EMERGENCY: 'Đăng xuất',
    NO_DRIVER: 'Không có tài xế',
    ASLEEP: 'Buồn ngủ',
    DROWSINESS: 'Mệt mỏi',
    YAWN: 'Ngáp ngủ',
    DAYDREAMING: 'Không tỉnh táo',
    USING_PHONE: 'Sử dụng điện thoại',
    DISTRACTED: 'Mất tập trung',
    SMOKING: 'Hút thuốc',
    NO_SEATBELT: 'Không thắt dây an toàn',
    DRIVING_HEAVY_HIT: 'Va chạm mạnh',
    DRIVING_SUSPICIOUS_HIT: 'Va chưa xác định',
    DRIVING_LIGHT_HIT: 'Va chạm nhẹ',
    HARD_ACCEL: 'Tăng tốc đột ngột I',
    HARSH_ACCEL: 'Tăng tốc đột ngột II',
    SEVERE_ACCEL: 'Tăng tốc đột ngột III',
    HARD_BRAKE: 'Phanh gấp I',
    HARSH_BRAKE: 'Phanh gấp II',
    SEVERE_BRAKE: 'Phanh gấp III',
    SHARP_TURN: 'Cua gấp I',
    HARSH_TURN: 'Cua gấp II',
    SEVERE_TURN: 'Cua gấp III',
    VIDEO_LENGTH: 'Thời lượng',
    DRIVING_AND_PARKING_KEY: 'Lái xe và đỗ xe',
    DMS_KEY: 'DMS',
    COLLISIONS_KEY: 'Va chạm',
    HARD_ACCELERATION_KEY: 'Tăng tốc đột ngột',
    HARD_BRAKE_KEY: 'Phanh gấp',
    SHARP_TURN_KEY: 'Cua gấp',
    EMERGENCY: 'EMERGENCY',
    PARKING_HIT: 'PARKING_HIT',
    PARKING_HEAVY_HIT: 'PARKING_HEAVY_HIT',
    DRIVING_HIT: 'DRIVING_HIT',
    MANUAL: 'MANUAL',
    MANUAL_LB: 'MANUAL',
    FORWARD_COLLISION: 'FORWARD_COLLISION',
    FORWARD_COLLISION_LB: 'Forward collistion',
    HEADWAY_MONITORING: 'HEADWAY_MONITORING',
  },
  mapMonitor: {
    department: 'Department',
    licensePlate: 'License plate',
    chooseLicensePlate: 'Choose license plate',
    student: 'Student',
    chooseStudent: 'Choose student',
    parking: 'Parking',
    driving: 'Driving',
    chooseCourse: 'Choose course',
    chooseDepartment: 'Choose department',
    cameraOffline: 'Camera is offline',
    wakeup: 'Wakeup',
    teacher: 'Teacher: ',
    courseName: 'Course name: ',
    studentName: 'Student: ',
    startTime: 'Start time: ',
    chooseTeacher: 'Choose teacher',
  },
  historyMonitor: {
    trainingSessionCode: 'Training session code',
    licensePlate: 'License plate',
    warningDate: 'Search up to 6 months',
    tooltipQS: 'Find training session code, course code, license plate, teacher, student, distance, time',
  },
};

export default en;
