import React from 'react';
import OTPInput from 'react-otp-input';
import { StyledOTP } from './styles';
import { OTPInputProps } from './type';

const InputOtp = (propsOtp: OTPInputProps) => {
  return (
    <StyledOTP>
      <OTPInput
        numInputs={6}
        renderSeparator={<span />}
        containerStyle={'otpInput'}
        inputType="number"
        inputStyle={'inputChild'}
        {...propsOtp}
      />
    </StyledOTP>
  );
};

export default InputOtp;
