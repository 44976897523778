import { Col, Form, Image, Row, Tag, Tooltip, Upload } from 'antd';
import CInput from '../../../components/commons/Input';
import { ActionType, optionsCompressedFile, RegValidEmail, RegValidIdNumber, RegValidPhoneNumber } from 'helper/constants';
import CSelect from '../../../components/commons/Select';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CheckCircleOutlined, CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { listCoursesProgressingIdsSelector, listCoursesProgressingSelector, studentManagerSelector } from '../selector';
import { ColStyled } from '../page/styles';
import { getListCourses, getListCoursesProgressing, getListLicense, setFilePhoto, setImgUrl } from '../redux';
import { useEffectOnce } from 'react-use';
import { allParamSelector } from '../../auth/selectors';
import moment from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import CDatePicker from '../../../components/commons/DatePicker';
import { themes } from '../../../styles';
import imageCompression from 'browser-image-compression';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { orderBy } from 'lodash';

const PersonalInfo = (props: { form: any }) => {
  const dispatch = useDispatch();
  const { typeModal, dataDetail, listCourses, listLicense, imgUrl } = useSelector(studentManagerSelector);
  const listCoursesProgressing = useSelector(listCoursesProgressingSelector);
  const [trainingClassDisabled, setTrainingClassDisabled] = useState<boolean>(false);
  const intl = useIntl();
  const allParams = useSelector(allParamSelector);
  const listCoursesProgressingIds = useSelector(listCoursesProgressingIdsSelector);
  const listAcceptImg = ['image/jpeg', 'image/jpg', 'image/png'];

  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = props.form.getFieldValue(field);
      props.form.setFieldValue(field, value?.trim());
      props.form.validateFields([field]);
    }, 0);
  };

  const handleChangeImage = async (e: any) => {
    const file = e.file as File;
    if (listAcceptImg.indexOf(file.type) === -1 || file.size > 1048576 * 5 || file.size < 0) {
      handleClearImg();
      return;
    }
    try {
      const compressedFile = await imageCompression(file, optionsCompressedFile);
      const fileCustom = new File([compressedFile], compressedFile.name);
      dispatch(setFilePhoto(fileCustom));
      const url = URL.createObjectURL(fileCustom);
      dispatch(setImgUrl(url));
    } catch (error) {
      console.warn(error);
    }
  };

  const onClearImg = (e: Event) => {
    e.stopPropagation();
    handleClearImg();
  };

  const handleClearImg = () => {
    props.form.validateFields(['faceImage']);
    props.form.setFieldValue('faceImage', undefined);
    dispatch(setImgUrl(''));
  };

  const handleGetListCourses = () => {
    dispatch(getListCourses());
  };

  const handleGetListLicense = () => {
    dispatch(getListLicense());
  };

  const onChangeTrainingType = () => {
    if (props.form.getFieldValue('trainingType') === '1') {
      setTrainingClassDisabled(true);
      props.form.setFieldValue('licenseClass', null);
    } else {
      setTrainingClassDisabled(false);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > moment();
  };

  useEffectOnce(() => {
    handleGetListLicense();
    if (props.form.getFieldValue('trainingType') === '1') {
      setTrainingClassDisabled(true);
    }
    if (typeModal !== ActionType.ADD) {
      if (dataDetail?.courses.length) {
        dispatch(getListCoursesProgressing(dataDetail?.courses));
        const orderListCourses = orderBy(dataDetail?.courses, ['startDate'], ['desc']);
        const listCourseIds = orderListCourses?.map((item: any) => item.id);
        props.form.setFieldValue('courseIds', listCourseIds);
      }
    } else {
      handleGetListCourses();
    }
  });

  const tagCourseSelected = (props: CustomTagProps) => {
    const { label, value, onClose } = props;
    return (
      <Tag closable={!listCoursesProgressingIds.includes(value)} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  return (
    <>
      <Row gutter={[32, 16]}>
        <ColStyled xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item
            labelAlign="left"
            label={intl.formatMessage({ id: 'instructorManagement.portraitImage' })}
            name="faceImage"
            initialValue={dataDetail?.faceImage || ''}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'student.facePhoto' }) + intl.formatMessage({ id: 'validator.require' }),
              },
              {
                validator(rule: any, value: any) {
                  if (typeModal === ActionType.EDIT && typeof value === 'string') {
                    return Promise.resolve();
                  }
                  if (value && (!listAcceptImg.includes(value?.file?.type) || value?.file?.size > 5242880)) {
                    return Promise.reject(
                      intl.formatMessage({ id: 'student.facePhoto' }) + intl.formatMessage({ id: 'validator.errFormat' })
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {imgUrl ? (
              <div className="avatar-uploader">
                <Image src={imgUrl} alt="avatar" style={{ width: '125px', height: '125px' }} onClick={(e: any) => e.stopPropagation()} />
                {typeModal !== ActionType.VIEW && imgUrl && (
                  <CloseCircleOutlined className="close-icon" onClick={(e: any) => onClearImg(e)} />
                )}
              </div>
            ) : (
              <Upload
                accept="image/png, image/gif, image/jpeg"
                listType="picture-card"
                className="avatar-uploader"
                name="avatar"
                showUploadList={false}
                onChange={handleChangeImage}
                customRequest={() => false}
                disabled={typeModal === ActionType.VIEW}
                beforeUpload={() => false}
              >
                <UploadOutlined />
                <p>{intl.formatMessage({ id: 'student.upload' })}</p>
              </Upload>
            )}
          </Form.Item>
        </ColStyled>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Row gutter={[32, 16]}>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'common.name' })}
                name="fullName"
                initialValue={dataDetail ? dataDetail.fullName : ''}
                rules={[
                  {
                    required: typeModal !== ActionType.VIEW,
                    message: intl.formatMessage({ id: 'common.name' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'name');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                  maxLength={255}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    {typeModal !== ActionType.ADD && dataDetail?.isAccuracy === 1 && (
                      <Tooltip title={intl.formatMessage({ id: 'student.verified' })}>
                        <CheckCircleOutlined style={{ color: themes.statusGreen, marginRight: 5 }} />
                      </Tooltip>
                    )}
                    {intl.formatMessage({ id: 'student.idCard' })}{' '}
                  </>
                }
                name="citizenIdCardNo"
                initialValue={dataDetail ? dataDetail.citizenIdCardNo : ''}
                rules={[
                  {
                    required: typeModal !== ActionType.VIEW,
                    message: intl.formatMessage({ id: 'student.idCard' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    validator: (_, value) =>
                      !value || RegValidIdNumber.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'student.idCard' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        ),
                  },
                ]}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'idCard');
                  }}
                  disabled={typeModal === ActionType.VIEW || dataDetail?.isAccuracy === 1}
                  maxLength={12}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage({ id: 'student.phoneNumber' })}
                name="phoneNo"
                initialValue={dataDetail ? dataDetail.phoneNo : ''}
                rules={[
                  {
                    required: typeModal !== ActionType.VIEW,
                    message: intl.formatMessage({ id: 'student.phoneNumber' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    validator: (_, value) =>
                      !value || RegValidPhoneNumber.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'student.phoneNumber' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        ),
                  },
                ]}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'phoneNumber');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                  maxLength={11}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ height: '16px' }}></div>
      <Row gutter={[32, 16]}>
        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item
            label={intl.formatMessage({ id: 'student.gender' })}
            name="gender"
            initialValue={dataDetail ? dataDetail?.gender?.toString() : '1'}
          >
            <CSelect options={allParams?.PERSON_SEX} disabled={typeModal === ActionType.VIEW} />
          </Form.Item>
        </Col>
        {console.log('123', allParams)}
        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item
            label={intl.formatMessage({ id: 'student.dateOfBirth' })}
            name="birthday"
            initialValue={dataDetail && dataDetail.birthday ? moment(dataDetail?.birthday) : ''}
          >
            <CDatePicker
              format={'DD/MM/YYYY'}
              allowClear={true}
              placeholder=""
              disabledDate={disabledDate}
              disabled={typeModal === ActionType.VIEW}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item
            label={intl.formatMessage({ id: 'student.email' })}
            name="email"
            initialValue={dataDetail ? dataDetail.email : ''}
            rules={[
              {
                validator: (_, value) =>
                  !value || RegValidEmail.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                      new Error(intl.formatMessage({ id: 'student.email' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' }))
                    ),
              },
            ]}
          >
            <CInput
              onPaste={(e: any) => {
                handlePates(e, 'email');
              }}
              disabled={typeModal === ActionType.VIEW}
              maxLength={255}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item label={intl.formatMessage({ id: 'student.courseCode' })} name="courseIds" initialValue={[]}>
            <CSelect
              mode="multiple"
              filterOption={(input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={[...listCourses, ...listCoursesProgressing]}
              disabled={typeModal === ActionType.VIEW}
              tagRender={tagCourseSelected}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item
            label={intl.formatMessage({ id: 'student.typesOfTraining' })}
            name="trainingType"
            initialValue={dataDetail ? dataDetail.trainingType?.toString() : allParams?.TRAINING_TYPE[0].value}
          >
            <CSelect options={allParams?.TRAINING_TYPE} disabled={typeModal === ActionType.VIEW} onChange={onChangeTrainingType} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
          <Form.Item
            label={intl.formatMessage({ id: 'student.previousTrainingClass' })}
            name="licenseClass"
            initialValue={dataDetail ? dataDetail.licenseClass : null}
            rules={[
              {
                required: typeModal !== ActionType.VIEW && !trainingClassDisabled,
                message: intl.formatMessage({ id: 'student.previousTrainingClass' }) + intl.formatMessage({ id: 'validator.require' }),
              },
            ]}
          >
            <CSelect options={listLicense} disabled={typeModal === ActionType.VIEW || trainingClassDisabled} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={intl.formatMessage({ id: 'student.address' })}
            name="address"
            initialValue={dataDetail ? dataDetail.address : ''}
          >
            <CInput
              onPaste={(e: any) => {
                handlePates(e, 'address');
              }}
              disabled={typeModal === ActionType.VIEW}
              maxLength={255}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default PersonalInfo;
