import { Col, Form, Row } from 'antd';
import { ActionType, RegValidStringEnglish } from 'helper/constants';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deviceManagerSelector, itemDataSelector, deviceModelsSelector, vehiclesSelector } from '../selector';
import {
  createDevice,
  setDataItem,
  setShowModal,
  setTypeModal,
  updateDevice,
  deleteDevice,
  getListDeviceParam,
  setVehicles,
} from '../redux';
import { StyledModal } from '../page/styles';
import { useEffectOnce } from 'react-use';
import { isEmpty } from 'helper/utils';
import ModalConfirm from 'components/commons/modalConfirm';
import CInput from 'components/commons/Input';
import CSelect from 'components/commons/Select';
import { StyledTitleModal } from 'modules/role/page/style';
import { useRolesByRouter } from 'helper/cusHooks';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { includes } from 'lodash';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { RowButton } from 'components/commons/Template/style';
import { orderBy } from 'lodash';
import CTextArea from 'components/commons/TextArea';
import AuthenOtp from './AuthenOtp';
import { allParamSelector } from 'modules/auth/selectors';
import { DeviceModelDefault } from '../constant';
import isNumeric from 'validator/lib/isNumeric';

interface Iprops {
  type: string;
}

const AddEditViewDevice = (props: Iprops) => {
  const { loadingBtnModal, showModal, typeModal } = useSelector(deviceManagerSelector);
  const { OTP_DEVICE } = useSelector(allParamSelector);
  const { type } = props;
  const dispatch = useDispatch();
  const itemData = useSelector(itemDataSelector);
  const deviceModels = useSelector(deviceModelsSelector);
  const vehicles = useSelector(vehiclesSelector);
  const intl = useIntl();
  const listRoles = useRolesByRouter();

  const [form] = Form.useForm();

  const renderTitle = () => {
    switch (type) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };

  const setFields = (field: string, message: string) => {
    form.setFields([
      {
        name: field,
        errors: [message],
      },
    ]);
  };

  const onFinish = (values: any) => {
    const data = {
      ...values,
    };
    if (type === ActionType.ADD) {
      dispatch(
        createDevice({
          ...data,
          setFields: setFields,
          timeCountDown: OTP_DEVICE[0]?.value,
        })
      );
      return;
    }
    if (type === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(
            updateDevice({
              ...data,
              id: itemData?.id,
            })
          );
        },
      });
      return;
    }
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',

        handleConfirm: () => {
          dispatch(setShowModal(false));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        modelType: null,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setShowModal(false));
          },
        });
      } else {
        dispatch(setShowModal(false));
      }
      return;
    }
    dispatch(setShowModal(false));
  };
  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
  };

  const handleDeleteRole = () => {
    if (itemData?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteDevice([itemData?.id]));
          handleCancel();
        },
      });
    }
  };

  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = form.getFieldValue(field);
      form.setFieldValue(field, value?.trim());
      form.validateFields([field]);
    }, 0);
  };

  const handleBlur = (e: any, field: string) => {
    form.setFieldValue(field, e.target.value.trim());
    form.validateFields([field]);
  };

  useEffectOnce(() => {
    dispatch(getListDeviceParam());
    if (!isEmpty({ ...itemData.vehicle })) {
      dispatch(setVehicles([...vehicles, itemData.vehicle]));
    }
    return () => {
      dispatch(setDataItem({}));
      form.resetFields();
    };
  });

  return (
    <StyledModal
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
      width={'1000px'}
    >
      <Form form={form} layout="vertical" labelAlign="left" onFinish={onFinish}>
        <StyledTitleModal>{renderTitle()}</StyledTitleModal>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.deviceCode' })}
              name="code"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'device.deviceCode' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
                {
                  validator: (_, value) =>
                    !value || RegValidStringEnglish.test(value)
                      ? Promise.resolve()
                      : Promise.reject(
                        new Error(
                          intl.formatMessage({ id: 'device.deviceCode' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' })
                        )
                      ),
                },
              ]}
              initialValue={itemData?.code}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'code');
                }}
                disabled={type === ActionType.VIEW}
                maxLength={20}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.deviceName' })}
              name="name"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'device.deviceName' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
              initialValue={itemData?.name}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'name');
                }}
                disabled={type === ActionType.VIEW}
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.deviceType' })}
              name="modelType"
              initialValue={
                itemData?.modelType ? itemData?.modelType : deviceModels.find(item => item.modelType === DeviceModelDefault)?.modelType
              }
            >
              <CSelect
                options={orderBy(deviceModels, ['modelType'], ['asc']).map((item: any) => {
                  return {
                    value: item.modelType,
                    label: item.modelType,
                  };
                })}
                filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                disabled={type !== ActionType.ADD}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.practiceCar' })}
              name="vehicleIds"
              initialValue={itemData.vehicle?.id ? itemData.vehicle?.id : null}
            >
              <CSelect
                options={orderBy(vehicles, ['licensePlate'], ['asc'])?.map((item: any) => {
                  return {
                    value: item?.id,
                    label: item?.licensePlate,
                  };
                })}
                showSearch
                filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                disabled={type === ActionType.VIEW}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.serialCameraNo' })}
              name="serialNumber"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'device.serialCameraNo' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
              initialValue={itemData?.serialNumber}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'serialNumber');
                }}
                maxLength={20}
                disabled={type !== ActionType.ADD}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.cameraPassword' })}
              name="password"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'device.cameraPassword' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
              initialValue={itemData?.password}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'password');
                }}
                maxLength={20}
                disabled={type !== ActionType.ADD}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.SDT_CAMERA' })}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'device.SDT_CAMERA' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
                {
                  min: 10,
                  message: intl.formatMessage({ id: 'device.SDT_CAMERA' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' }),
                },
                {
                  validator: (_, value) =>
                    !value || isNumeric(value) || value?.length < 10
                      ? Promise.resolve()
                      : Promise.reject(
                        new Error(
                          intl.formatMessage({ id: 'device.SDT_CAMERA' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' })
                        )
                      ),
                },
              ]}
              initialValue={itemData?.phoneNumber}
            >
              <CInput
                onBlur={(e: any) => {
                  handleBlur(e, 'phoneNumber');
                }}
                onPaste={(e: any) => {
                  handlePates(e, 'clientPhoneNumber');
                }}
                maxLength={11}
                disabled={type !== ActionType.ADD}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={intl.formatMessage({ id: 'device.serialTabletNo' })}
              name="serialTablet"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'device.serialTabletNo' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
              initialValue={itemData?.serialTablet}
            >
              <CInput
                onPaste={(e: any) => {
                  handlePates(e, 'serialTablet');
                }}
                maxLength={20}
                disabled={type === ActionType.VIEW}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={intl.formatMessage({ id: 'device.note' })} initialValue={itemData?.description || ''} name="description">
              <CTextArea
                onPaste={(e: any) => {
                  handlePates(e, 'description');
                }}
                disabled={typeModal === ActionType.VIEW}
                maxLength={255}
              />
            </Form.Item>
          </Col>
        </Row>
        <AuthenOtp formDevice={form} />
        <RowButton>
          {typeModal === ActionType.VIEW ? (
            <>
              {includes(listRoles, actionsType.DELETE) && (
                <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteRole}>
                  <FormattedMessage id="common.delete" />
                </CButton>
              )}
              {includes(listRoles, actionsType.UPDATE) && (
                <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} onClick={handleChangeEdit}>
                  <FormattedMessage id="common.edit" />
                </CButton>
              )}
            </>
          ) : (
            <>
              <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </CButton>

              <CButton loading={loadingBtnModal} sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} htmlType="submit">
                <FormattedMessage id="common.save" />
              </CButton>
            </>
          )}
        </RowButton>
      </Form>
    </StyledModal>
  );
};
export default AddEditViewDevice;
