import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataDetail, OptionSelectBox, ParamsType } from '../type';
import { ParamsPayloadType } from '../../accountManager/type';
import moment from 'moment';
import { ResFile } from 'modules/instructorManagement/api/types';

export interface StudentManagerState {
  showModal: boolean;
  typeModal: string;
  loadingTable: boolean;
  loadingBtnModal: boolean;
  imgUrl: string;
  qsValue: string;
  params: ParamsType;
  listCourses: OptionSelectBox[];
  listLicense: OptionSelectBox[];
  dataGrid: any;
  dataDetail: DataDetail | null;
  listCoursesProgressing: OptionSelectBox[];
  listCoursesProgressingIds: string[];
  loadingFile: boolean;
  dataPhoto: ResFile;
  filePhoto: File | null;
}

const initialState: StudentManagerState = {
  showModal: false,
  typeModal: '',
  loadingTable: false,
  loadingBtnModal: false,
  imgUrl: '',
  qsValue: '',
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  listCourses: [],
  listLicense: [],
  dataGrid: [],
  dataDetail: null,
  listCoursesProgressing: [],
  listCoursesProgressingIds: [],
  loadingFile: false,
  dataPhoto: {
    fileName: '',
    directory: '',
  },
  filePhoto: null,
};

const studentManagerSlice = createSlice({
  name: 'studentManagerSlice',
  initialState,
  reducers: {
    setShowModal: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.listCoursesProgressing = [];
        state.listCoursesProgressingIds = [];
      }
      state.showModal = action.payload;
    },
    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
    setParams: (state, action: PayloadAction<ParamsType>) => {
      state.params = action.payload;
    },

    setImgUrl: (state, action: PayloadAction<string>) => {
      state.imgUrl = action.payload;
    },
    setDataDetail: (state, action: PayloadAction<any>) => {
      state.dataDetail = action.payload;
    },
    getListStudent: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.loadingTable = true;
    },
    getListStudentSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.dataGrid = action.payload;
    },
    getListStudentFail: state => {
      state.loadingTable = false;
    },
    createStudent: (state, action: PayloadAction<FormData>) => {
      state.loadingBtnModal = true;
    },

    createStudentSuccess: state => {
      state.loadingBtnModal = false;
      state.imgUrl = '';
      state.dataDetail = null;
      state.showModal = false;
    },

    createStudentFail: state => {
      state.loadingBtnModal = false;
    },

    updateStudent: (state, action: PayloadAction<FormData>) => {
      state.loadingBtnModal = true;
    },
    updateStudentSuccess: state => {
      state.loadingBtnModal = false;
      state.imgUrl = '';
      state.dataDetail = null;
      state.showModal = false;
    },
    updateStudentFail: state => {
      state.loadingBtnModal = false;
    },

    deleteStudent: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteStudentSuccess: state => {
      state.loadingTable = false;
    },
    deleteStudentFail: state => {
      state.loadingTable = false;
    },

    getDetailStudent: (state, action: PayloadAction<string>) => {
      state.loadingBtnModal = true;
    },

    getDetailStudentSuccess: (state, action: PayloadAction<any>) => {
      state.loadingBtnModal = false;
      state.dataDetail = action.payload;
      state.showModal = true;
    },

    getDetailStudentFail: state => {
      state.loadingBtnModal = false;
    },

    getListCourses: state => {
      // state.loading = true;
    },

    getListCoursesSuccess: (state, action: PayloadAction<any>) => {
      //get list course not progressing
      state.listCourses = action.payload
        .filter((item: any) => {
          return !moment(item.startDate).isBefore(moment());
        })
        .map((item: any) => {
          return { value: item.id, label: item.code + '_' + item.licenseClass, disabled: false };
        });
    },

    getListCoursesProgressing: (state, action: PayloadAction<any>) => {
      const arr = action.payload
        ?.filter((item: any) => {
          return moment(item.startDate).isBefore(moment());
        })
        ?.map((item: any) => {
          return { value: item.id, label: item.code + '_' + item.licenseClass, disabled: true };
        });
      state.listCoursesProgressing = arr;
      state.listCoursesProgressingIds = arr?.map((item: OptionSelectBox) => item.value);
    },

    getListCoursesFail: state => {
      // state.loading = true;
    },

    getListLicense: state => {
      // state.loading = true;
    },

    getListLicenseSuccess: (state, action: PayloadAction<any>) => {
      // state.loading = true;
      state.listLicense = action.payload.map((item: any) => {
        return { value: item.licenseClass, label: item.licenseClass };
      });
    },
    getListLicenseFail: state => {
      // state.loading = true;
    },

    // upload file
    uploadFile: (state, action: PayloadAction<any>) => {
      state.loadingFile = true;
    },
    uploadFileSuccess: (state, action: PayloadAction<ResFile>) => {
      state.dataPhoto = action.payload;
      state.loadingFile = false;
    },
    uploadFileFail: state => {
      state.loadingFile = false;
    },

    setFilePhoto: (state, action: PayloadAction<File>) => {
      state.filePhoto = action.payload;
    },
  },
});

export const {
  setShowModal,
  setTypeModal,
  setDataDetail,
  setQsValue,
  setParams,
  getListStudent,
  getListStudentFail,
  getListStudentSuccess,
  createStudent,
  createStudentSuccess,
  createStudentFail,
  updateStudent,
  updateStudentSuccess,
  updateStudentFail,
  deleteStudent,
  deleteStudentSuccess,
  deleteStudentFail,
  getDetailStudent,
  getDetailStudentSuccess,
  getDetailStudentFail,
  getListCourses,
  getListCoursesSuccess,
  getListCoursesFail,
  getListLicense,
  getListLicenseSuccess,
  getListLicenseFail,
  setImgUrl,
  getListCoursesProgressing,
  setFilePhoto,
} = studentManagerSlice.actions;

export default studentManagerSlice.reducer;
