import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import { StyledInput } from './styles';

interface Props extends InputProps {
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
}

const CInput: FC<Props> = React.memo(({ id, children, onBlur, ...rest }) => {
  const form = Form.useFormInstance();
  const field = id as string;
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    } else {
      form.setFieldValue(field, e.target.value.trim());
      form.validateFields([field]);
    }
  };
  return (
    <StyledInput onBlur={handleBlur} {...rest}>
      {children}
    </StyledInput>
  );
});

export default CInput;
