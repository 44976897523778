import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetListFleetViewRequest,
  GetListFleetViewResponse,
  OptionItem,
  TreeItem,
  GetTripsRequest,
  GetEventsRequest,
  GetTrackRequest,
  TripItem,
  EventItem,
  InfoUser,
  OptionStudentItem,
  RecordsItem,
} from '../api/types';
import { BtnTabType } from '../constant';

export interface MapMonitorState {
  fleetView: {
    params: GetListFleetViewRequest;
    loading: boolean;
    fleetViewData: GetListFleetViewResponse;
    listTree: TreeItem[];
    listCourse: OptionItem[];
    listStudent: OptionStudentItem[];
    listInstructor: OptionStudentItem[];
    listLicensePlate: OptionItem[];
    fleetViewItem: RecordsItem;
  };
  detail: {
    btnTab: string;
    isViewDetail: boolean;
    paramsTrips: GetTripsRequest;
    paramsEvents: GetEventsRequest;
    paramsTrack: GetTrackRequest;
    trips: any[];
    events: any[];
    tracks: any[];
    loadingDetail: boolean;
    infoUser: InfoUser;
    showVideoDetail: boolean;
    playDetailUrl: string;
    indexActiveEvent: number;
    indexActiveTrip: number;
    indexActiveCar: number;
  };
  dataGoogleMap: {
    map: any;
    key: string;
    lat: number;
    lng: number;
    zoom: number;
  };
  offset: number;
  isRunOffset: boolean;
  playVideo: {
    showModalVideo: boolean;
    loadingVideo: boolean;
    playUrl: string;
    cameraSn: string;
    wakeupStatus: boolean;
  };
}

const initialState: MapMonitorState = {
  fleetView: {
    params: {
      page: 0,
      size: 10,
      clientId: [''],
      courseId: '',
      vehicleId: '',
      studentId: '',
      instructorId: '',
    },
    loading: false,
    fleetViewData: {
      content: [],
      totalElements: 0,
    },
    listTree: [],
    listCourse: [],
    listStudent: [],
    listInstructor: [],
    listLicensePlate: [],
    fleetViewItem: {} as any,
  },
  detail: {
    btnTab: BtnTabType.trip,
    isViewDetail: false,
    paramsTrips: {
      fromTime: 0,
      lessonId: '',
      toTime: 0,
      uploadType: 'event',
    },
    paramsEvents: {
      clientId: '',
      cameraSn: '',
      tripId: '',
      fromTime: 0,
      toTime: 0,
      uploadType: 'event',
    },
    paramsTrack: {
      cameraSn: '',
      tripId: '',
    },
    trips: [],
    events: [],
    tracks: [],
    loadingDetail: false,
    infoUser: {
      distance: '',
      time: '',
    },
    showVideoDetail: false,
    playDetailUrl: '',
    indexActiveEvent: -1,
    indexActiveTrip: 0,
    indexActiveCar: -1,
  },
  dataGoogleMap: {
    map: null,
    key: 'AIzaSyBYarY6_L9Oy_hO1bjSkbeW1Ss5VTj_PR4',
    lat: 21.0275333,
    lng: 105.7797953,
    zoom: 16,
  },
  offset: 0,
  isRunOffset: false,
  playVideo: {
    showModalVideo: false,
    loadingVideo: false,
    playUrl: '',
    cameraSn: '',
    wakeupStatus: true,
  },
};

const mapMonitorSlice = createSlice({
  name: 'mapMonitorSlice',
  initialState,
  reducers: {
    //start fleetView
    updateSearch: (state, action: PayloadAction<object>) => {
      state.fleetView.params = { ...state.fleetView.params, ...action.payload } || {};
    },
    updateClientId: (state, action: PayloadAction<string[]>) => {
      state.fleetView.params = { ...initialState.fleetView.params, clientId: action.payload };
      state.fleetView.fleetViewData = initialState.fleetView.fleetViewData;
    },
    resetSearch: state => {
      state.fleetView.params = initialState.fleetView.params;
      state.fleetView.fleetViewData = initialState.fleetView.fleetViewData;
      state.detail.tracks = initialState.detail.tracks;
    },
    setLoadingFleetView: (state, action: PayloadAction<boolean>) => {
      state.fleetView.loading = action.payload;
    },
    getFleetViewDataSuccess: (state, action: PayloadAction<GetListFleetViewResponse>) => {
      state.fleetView.fleetViewData = action.payload;
    },
    getListFleetStart: () => {},
    getListTreeStart: () => {},
    getListTreeSuccess: (state, action: PayloadAction<TreeItem[]>) => {
      state.fleetView.listTree = action.payload;
    },
    getListCourseSuccess: (state, action: PayloadAction<OptionItem[]>) => {
      state.fleetView.listCourse = action.payload;
    },
    getListStudentSuccess: (state, action: PayloadAction<OptionStudentItem[]>) => {
      state.fleetView.listStudent = action.payload;
    },
    getListInstructorSuccess: (state, action: PayloadAction<OptionStudentItem[]>) => {
      state.fleetView.listInstructor = action.payload;
    },
    getListLicensePlateSuccess: (state, action: PayloadAction<OptionItem[]>) => {
      state.fleetView.listLicensePlate = action.payload;
    },
    setFleetViewItem: (state, action: PayloadAction<RecordsItem>) => {
      state.fleetView.fleetViewItem = action.payload;
    },
    //end fleetView
    //start live stream
    getLiveStart: (state, action: PayloadAction<string>) => {
      state.playVideo.showModalVideo = true;
      state.playVideo.loadingVideo = true;
      state.playVideo.cameraSn = action.payload;
      state.playVideo.wakeupStatus = true;
    },
    getLiveStatusStart: (state, action: PayloadAction<string>) => {},
    setShowModalVideo: (state, action: PayloadAction<boolean>) => {
      state.playVideo.showModalVideo = action.payload;
      state.playVideo.playUrl = '';
    },
    setLoadingVideo: (state, action: PayloadAction<boolean>) => {
      state.playVideo.loadingVideo = action.payload;
    },
    setPlayUrlVideo: (state, action: PayloadAction<string>) => {
      state.playVideo.playUrl = action.payload;
    },
    setWakeupStatus: (state, action: PayloadAction<boolean>) => {
      state.playVideo.wakeupStatus = action.payload;
    },
    //end live stream
    //start GoogleMap
    setMap: (state, action: PayloadAction<any>) => {
      state.dataGoogleMap.map = action.payload;
    },
    runOffset: state => {
      state.offset = (state.offset + 1) % 100;
    },
    setIsRunOffset: (state, action: PayloadAction<boolean>) => {
      state.isRunOffset = action.payload;
    },
    //end GoogleMap
    //start detail fleetView
    setBtnTab: (state, action: PayloadAction<string>) => {
      if (action.payload === BtnTabType.prev) {
        state.detail.tracks = [];
        state.fleetView.fleetViewItem = {} as any;
        state.isRunOffset = false;

        state.detail.paramsEvents.cameraSn = '';
        state.detail.paramsTrack.cameraSn = '';
      }

      state.detail.indexActiveEvent = -1;
      state.detail.indexActiveTrip = 0;
      state.detail.indexActiveCar = -1;
      state.detail.events = [];
      state.detail.btnTab = action.payload;
    },
    setIsViewDetail: (state, action: PayloadAction<boolean>) => {
      state.detail.isViewDetail = action.payload;
    },
    getTripsStart: (state, action: PayloadAction<GetTripsRequest>) => {
      state.detail.loadingDetail = true;
    },
    getTripsSuccess: (state, action: PayloadAction<TripItem[]>) => {
      state.detail.trips = action.payload;
    },
    getListEventsStart: (state, action: PayloadAction<GetEventsRequest>) => {
      state.detail.loadingDetail = true;
    },
    getEventsSuccess: (state, action: PayloadAction<EventItem[]>) => {
      state.detail.events = action.payload;
    },
    getTrackStart: (state, action: PayloadAction<GetTrackRequest>) => {
      state.isRunOffset = false;
    },
    getTrackSuccess: (state, action: PayloadAction<any[]>) => {
      state.detail.tracks = action.payload;
      state.isRunOffset = true;
    },
    setParamsTrips: (state, action: PayloadAction<GetTripsRequest>) => {
      state.detail.paramsTrips = action.payload;
    },
    setParamsEvents: (state, action: PayloadAction<GetEventsRequest>) => {
      state.detail.paramsEvents = action.payload;
    },
    setParamsTrack: (state, action: PayloadAction<GetTrackRequest>) => {
      state.detail.paramsTrack = action.payload;
    },

    getDetailSessionStart: (state, action: PayloadAction<any>) => {
      state.detail.loadingDetail = true;
    },
    setLoadingDetail: (state, action: PayloadAction<boolean>) => {
      state.detail.loadingDetail = action.payload;
    },
    getDetailTripStart: (state, action: PayloadAction<any>) => {
      state.detail.loadingDetail = true;
    },
    setInfoUser: (state, action: PayloadAction<InfoUser>) => {
      state.detail.infoUser = action.payload;
    },
    setShowVideoDetail: (state, action: PayloadAction<boolean>) => {
      state.detail.showVideoDetail = action.payload;
    },
    setPlayDetailUrl: (state, action: PayloadAction<string>) => {
      state.detail.playDetailUrl = action.payload;
    },
    setIndexActiveEvent: (state, action: PayloadAction<number>) => {
      state.detail.indexActiveEvent = action.payload;
    },
    setIndexActiveTrip: (state, action: PayloadAction<number>) => {
      state.detail.indexActiveTrip = action.payload;
    },
    setIndexActiveCar: (state, action: PayloadAction<number>) => {
      state.detail.indexActiveCar = action.payload;
    },
    //end detail fleetView
  },
});

export const {
  //start fleetView
  updateSearch,
  resetSearch,
  setLoadingFleetView,
  getFleetViewDataSuccess,
  updateClientId,
  getListFleetStart,
  getListTreeStart,
  getListTreeSuccess,
  getListCourseSuccess,
  getListStudentSuccess,
  getListInstructorSuccess,
  getListLicensePlateSuccess,
  setFleetViewItem,
  //end fleetView
  //start live stream
  getLiveStart,
  getLiveStatusStart,
  setShowModalVideo,
  setLoadingVideo,
  setPlayUrlVideo,
  setWakeupStatus,
  //end live stream
  //start GoogleMap
  setMap,
  runOffset,
  setIsRunOffset,
  //end GoogleMap
  //start detail
  setBtnTab,
  setIsViewDetail,
  getTripsStart,
  getTripsSuccess,
  getListEventsStart,
  getEventsSuccess,
  setParamsTrips,
  setParamsEvents,
  getDetailSessionStart,
  setParamsTrack,
  getTrackStart,
  getTrackSuccess,
  setLoadingDetail,
  getDetailTripStart,
  setInfoUser,
  setShowVideoDetail,
  setPlayDetailUrl,
  setIndexActiveEvent,
  setIndexActiveTrip,
  setIndexActiveCar,
  //end detail
} = mapMonitorSlice.actions;

export default mapMonitorSlice.reducer;
