import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { VehicleManagementState } from 'modules/vehicleManagement/redux';

export const vehicleManagementSelector = (state: RootState) => state.vehicleManagement;

export const paramsSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.params);
export const loadingTableSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.loadingTable);
export const loadingBtnModalSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.loadingBtnModal);
export const loadingModalSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.loadingModal);
export const dataTableSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.data);
export const totalElementSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.totalElements);
export const typeModalSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.typeModal);
export const itemEditSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.itemEdit);
export const selectedRowKeysSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.selectedRowKeys);
export const fieldErrorsSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.fieldErrors);
export const valueSearchSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.valueSearch);
export const listDeviceSelector = createSelector(vehicleManagementSelector, (state: VehicleManagementState) => state.listDevice);