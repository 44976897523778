import HttpRequest from 'core/http/singleton/auth';
import { ParamsType } from '../type';

export const getListRoleByPr = async (params: any): Promise<any> => {
  const res = await HttpRequest.get<any>('api/roles', params);
  return res.data;
};

export const getListRolesDefault = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/objects');
  return res.data;
};

export const createRole = async (data: any): Promise<any> => {
  const res = await HttpRequest.post<any>('api/roles/create', data);
  return res.data;
};

export const getDetailRoleById = async (id: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/roles/${id}`);
  return res.data;
};

export const updateRoleById = async (data: any): Promise<any> => {
  const res = await HttpRequest.put<any>(`api/roles`, data);
  return res.data;
};

export const deleteRoleById = async (id: string): Promise<any> => {
  const res = await HttpRequest.delete<any>(`api/roles/${id}`);
  return res.data;
};

export const deleteMutiRoleById = async (ListId: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>(`api/roles`, {
    data: ListId,
  });
  return res.data;
};

export const getListRoleByQS = async (params: ParamsType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/role', params);
  return res.data;
};
