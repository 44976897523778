import { Col, Row, Spin, Tooltip } from 'antd';
import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { reportPracticeSelector } from '../selector';
import { exportReport, setShowModal } from '../redux';
import { StyledTitleModal } from 'modules/role/page/style';
import { useIntl } from 'react-intl';
import { StyledModalDetail, StyledTitle } from '../page/styles';
import moment from 'moment';
import CTable from 'components/commons/Table';
import { RowButton, Text, TextLink } from 'components/commons/Template/style';
import { ColumnsType } from 'antd/es/table';
import { TrainingInfo } from '../type';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import ModalStudentReport from 'modules/studentReport/components/ModalDetail';
import { showModalDetailSelector } from 'modules/studentReport/selector';
import { getDetail, setShowModal as setShowModalRpStudent } from 'modules/studentReport/redux';

const ModalDetail = () => {
  const { showModal, loadingModal, itemView, loadingBtnExport } = useSelector(reportPracticeSelector);
  const showModalReportStudent = useSelector(showModalDetailSelector);
  const dispatch = useDispatch();
  const intl = useIntl();

  const columns: ColumnsType<TrainingInfo> = [
    {
      title: 'STT',
      align: 'left',
      fixed: 'left',
      width: 60,
      render(value, record, index) {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.name' }),
      align: 'left',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <TextLink onClick={() => handleShowModalDetailReportA(record)}>{value}</TextLink>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.dateBirth' }),
      align: 'left',
      dataIndex: 'birthDay',
      key: 'birthDay',
      width: 120,
      render(value, record, index) {
        return (
          <Tooltip title={value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''} placement="topLeft">
            <Text>{value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'practiceReport.studentCode' }),
      align: 'left',
      dataIndex: 'studentCode',
      key: 'studentCode',
      width: 130,
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.time' }) + ' (h)',
      align: 'left',
      dataIndex: 'totalDuration',
      key: 'totalDuration',
      width: 120,
      render(value, record, index) {
        const fmtVal = moment.duration(value, 'milliseconds').asHours().toFixed(2);
        return (
          <Tooltip title={fmtVal} placement="topLeft">
            <Text>{fmtVal}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'practiceReport.trainingDistance' }) + ' (Km)',
      align: 'left',
      dataIndex: 'totalDistance',
      key: 'totalDistance',
      width: 160,
      render(value, record, index) {
        const fmtVal = (value / 1000).toFixed(2);
        return (
          <Tooltip title={fmtVal} placement="topLeft">
            <Text>{fmtVal}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'practiceReport.note' }),
      align: 'left',
      dataIndex: 'note',
      key: 'note',
      width: 180,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
  ];

  const handleShowModalDetailReportA = (record: TrainingInfo) => {
    dispatch(setShowModalRpStudent(true));
    dispatch(
      getDetail({
        idCourse: itemView.id,
        idStudent: record?.studentId || '',
      })
    );
  };
  const handleClose = () => {
    dispatch(setShowModal(false));
  };

  const handleExportRp = () => {
    dispatch(
      exportReport({
        id: itemView.id,
      })
    );
  };
  return (
    <StyledModalDetail
      width={1024}
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={loadingModal}>
        <StyledTitleModal style={{ fontSize: 20 }}>{intl.formatMessage({ id: 'practiceReport.viewDetail' })}</StyledTitleModal>
        <StyledTitle>{intl.formatMessage({ id: 'practiceReport.practiceInfo' })}</StyledTitle>
        <Row className="rowInfo">
          <Col span={24}>
            <Row>
              <Col span={4}>
                <p>{intl.formatMessage({ id: 'course.courseCode' })}</p>
                <p>{intl.formatMessage({ id: 'course.tranningLevel' })}</p>
                <p>{intl.formatMessage({ id: 'practiceReport.openingDay' })}</p>
                <p>{intl.formatMessage({ id: 'practiceReport.closingDay' })}</p>
                <p>{intl.formatMessage({ id: 'practiceReport.trainingFacility' })}</p>
              </Col>
              <Col span={20}>
                <p>{itemView.code}</p>
                <p>{itemView.licenseClass}</p>
                <p>{itemView?.startDate ? moment(itemView.startDate, 'YYYY/MM/DD').format('DD/MM/YYYY') : undefined}</p>
                <p>{itemView?.endDate ? moment(itemView.endDate, 'YYYY/MM/DD').format('DD/MM/YYYY') : undefined}</p>
                <p>{itemView.schoolName}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <StyledTitle>{intl.formatMessage({ id: 'practiceReport.lessonInfo' })}</StyledTitle>
        <CTable columns={columns} dataSource={itemView.student} style={{ width: '100%' }} pagination={false} scroll={{ y: 350 }} />
        <RowButton style={{ marginTop: 32 }}>
          <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleClose}>
            {intl.formatMessage({ id: 'common.close' })}
          </CButton>

          <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} onClick={handleExportRp} loading={loadingBtnExport}>
            {intl.formatMessage({ id: 'report.export' })}
          </CButton>
        </RowButton>
      </Spin>
      {showModalReportStudent && <ModalStudentReport />}
    </StyledModalDetail>
  );
};

export default ModalDetail;
