import { ParamsType, ParamsTF, ParamsReportItem } from '../type';
import HttpRequest from 'core/http/singleton/auth';

export const getListReportByPr = async (params: ParamsType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/reports/course', params);
  return res.data;
};

export const exportReportApi = async (data: ParamsReportItem): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/course/export/${data.id}`, {}, { responseType: 'blob' });
  return res.data;
};

export const getListCourseApi = async (clientId: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/param/course/${clientId}`);
  return res.data;
};

export const getDetailReportApi = async (data: ParamsReportItem): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/course/${data.id}`);
  return res.data;
};

export const getListTrainingFacility = async (params: ParamsTF): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/lesson-view/tree-client', params);
  return res.data;
};

export const getDrivingLicense = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/driving-student/license');
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};
