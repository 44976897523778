import styled from 'styled-components/macro';

export const StyledOTP = styled.div`
  .otpInput {
    height: 40px;
    justify-content: center;
    margin-bottom: 30px;
    .inputChild {
      height: 100%;
      width: 40px !important;
      font-size: 18px;
      border: none;
      background-color: #edeff3;
      border-radius: 10px;
      transition: all 0.25s linear;
      margin: 0 5px;
      &:focus {
        border: none;
        outline: none;
        background-color: #8fb9f5;
      }
    }
  }

  //firefox
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
