import { Col, Form, Image, Row, Spin, Tooltip, Upload } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldErrorsSelector,
  filePhotoSelector,
  imageUrlSelector,
  itemEditSelector,
  listLicenseSelector,
  loadingBtnModalSelector,
  loadingModalSelector,
  typeModalSelector,
} from '../selector';
import {
  createInstructor,
  deleteListInstructor,
  editInstructor,
  setFilePhoto,
  setImageUrl,
  setTypeModal
} from '../redux';
import { isEmptyObject } from 'helper/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalConfirm from 'components/commons/modalConfirm';
import { ActionType, RegValidEmail, optionsCompressedFile } from 'helper/constants';
import filter from 'lodash/filter';
import { RowButton } from 'components/commons/Template/style';
import Button, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CInput from 'components/commons/Input';
import Select from 'components/commons/Select';
import CModal from 'components/commons/Modal';
import { TagActive } from 'modules/role/page/style';
import { themes } from 'styles';
import { CSwitchStyle, ColStyle, DatePickerStyle, StyledInstructorForm } from '../page/style';
import { UploadOutlined } from '@ant-design/icons';
import isNumeric from 'validator/lib/isNumeric';
import moment from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import { LicenseType } from '../api/types';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { allParamSelector } from 'modules/auth/selectors';
import { orderBy } from 'lodash';
import CTextArea from 'components/commons/TextArea';
import imageCompression from 'browser-image-compression';
import type { UploadProps } from 'antd/es/upload/interface';

const ModalAddEditView = () => {
  const dateFormatList = 'DD/MM/YYYY';
  const intl = useIntl();
  const typeModal = useSelector(typeModalSelector);
  const dispatch = useDispatch();
  const itemEdit = useSelector(itemEditSelector);
  const [form] = Form.useForm();
  const fieldErrors = useSelector(fieldErrorsSelector);
  const imgUrl = useSelector(imageUrlSelector);
  const listLicense = useSelector(listLicenseSelector);
  const { PERSON_SEX } = useSelector(allParamSelector);
  const loadingBtn = useSelector(loadingBtnModalSelector);
  const loadingModal = useSelector(loadingModalSelector);
  const filePhoto = useSelector(filePhotoSelector);
  const listAcceptImg = ['image/png', 'image/gif', 'image/jpeg'];

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',
        handleConfirm: () => {
          dispatch(setTypeModal(null));
          dispatch(setImageUrl(''));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();
      const isValuesEmpty = isEmptyObject({
        ...values,
        gender: undefined,
        isWorking: undefined,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setTypeModal(null));
            dispatch(setImageUrl(''));
          },
        });
      } else {
        dispatch(setTypeModal(null));
        dispatch(setImageUrl(''));
      }
      return;
    }
    dispatch(setTypeModal(null));
    dispatch(setImageUrl(''));
  };

  const renderTitle = () => {
    switch (typeModal) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleFinishForm = async (values: any) => {
    const formData = new FormData();
    if (typeModal === ActionType.ADD) {
      formData.set('file', (filePhoto as File));
      const data = {
        ...values,
        birthday: values?.birthday ? values?.birthday.format('YYYY-MM-DD') : moment(values.moment).format('YYYY-MM-DD'),
        address: values?.address ? values?.address : undefined,
        email: values?.email ? values?.email : undefined,
        isWorking: values?.isWorking ? 1 : 0
      };
      formData.set('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));
      dispatch(createInstructor(formData));
      return;
    }
    if (typeModal === ActionType.EDIT) {
      formData.set('file', (filePhoto as File));
      const data = {
        ...values,
        birthday: values?.birthday ? values?.birthday.format('YYYY-MM-DD') : moment(values.moment).format('YYYY-MM-DD'),
        address: values?.address ? values?.address : undefined,
        email: values?.email ? values?.email : undefined,
        id: itemEdit.id,
        isWorking: values?.isWorking ? 1 : 0
      };
      formData.set('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(editInstructor(formData));
        },
      });
    }
  };

  const currentDate: RangePickerProps['disabledDate'] = (value: any) => {
    const now = new Date();
    return moment(value).isAfter(moment(now));
  };

  const handleChangeImage: UploadProps['onChange'] = (data: any) => {
    if (!listAcceptImg.includes(data?.file?.type) || data?.file?.size > 5242880) {
      handleClearImg();
      return;
    }
    imageCompression(data?.file, optionsCompressedFile)
      .then(compressedFile => {
        const file = new File([compressedFile], compressedFile.name);
        dispatch(setFilePhoto(file));
        const url = URL.createObjectURL(file);
        dispatch(setImageUrl(url));
      })
      .catch(error => {
        console.error(error);
        handleClearImg();
      });
  };

  const handleClose = () => {
    dispatch(setTypeModal(null));
    dispatch(setImageUrl(''));
  };

  useEffect(() => {
    const valueErrCitizenIdCardNo = filter(fieldErrors, { field: 'citizenIdCardNo' })[0];
    if (valueErrCitizenIdCardNo) {
      form.setFields([
        {
          name: 'citizenIdCardNo',
          errors: [valueErrCitizenIdCardNo.message],
        },
      ]);
    }

    const valueErrDriverLicenseNo = filter(fieldErrors, { field: 'driverLicenseNo' })[0];
    if (valueErrDriverLicenseNo) {
      form.setFields([
        {
          name: 'driverLicenseNo',
          errors: [valueErrDriverLicenseNo.message],
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEdit]);

  useEffect(() => {
    if (typeModal !== ActionType.ADD) {
      dispatch(setImageUrl(itemEdit.faceImageUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEdit.faceImageUrl]);

  const handleDeleteItem = (id: string) => {
    if (id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteListInstructor([id]));
        },
      });
    }
  };

  const onClearImg = (e: Event) => {
    e.stopPropagation();
    handleClearImg();
  };

  const handleClearImg = () => {
    dispatch(setImageUrl(''));
    form.validateFields(['faceImage']);
    form.setFieldValue('faceImage', undefined);
  };

  return (
    <CModal open={typeModal !== null} onCancel={handleClose} title={renderTitle()} footer={null} width={1000}>
      <Spin spinning={loadingModal}>
        <StyledInstructorForm layout="vertical" form={form} onFinish={handleFinishForm}>
          <Row gutter={16}>
            <ColStyle span={8}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.portraitImage' })}
                name="faceImage"
                initialValue={itemEdit?.faceImage || ''}
                rules={[
                  {
                    required: true,
                    message:
                      intl.formatMessage({ id: 'instructorManagement.portraitImage' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    validator(rule: any, value: any) {
                      if (typeModal === ActionType.EDIT && typeof value === 'string') {
                        return Promise.resolve();
                      }
                      if (value && (!listAcceptImg.includes(value?.file?.type) || value?.file?.size > 5242880)) {
                        return Promise.reject(
                          intl.formatMessage({ id: 'instructorManagement.portraitImage' }) +
                            intl.formatMessage({ id: 'validator.errFormat' })
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {imgUrl ? (
                  <div className="img-wrap">
                    <Image src={imgUrl} alt="avatar" onClick={(e: any) => e.stopPropagation()} />
                    {typeModal !== ActionType.VIEW && <CloseCircleOutlined className="close-icon" onClick={(e: any) => onClearImg(e)} />}
                  </div>
                ) : (
                  <Upload
                    accept="image/png, image/gif, image/jpeg"
                    listType="picture-card"
                    className="avatar-uploader"
                    name="avatar"
                    showUploadList={false}
                    onChange={handleChangeImage}
                    customRequest={() => false}
                    disabled={typeModal === ActionType.VIEW}
                    beforeUpload={() => false}
                  >
                    <UploadOutlined />
                    <p>{intl.formatMessage({ id: 'instructorManagement.downImage' })}</p>
                  </Upload>
                )}
              </Form.Item>
            </ColStyle>
            <Col span={16}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    labelAlign="left"
                    label={intl.formatMessage({ id: 'instructorManagement.fullName' })}
                    name="fullName"
                    initialValue={itemEdit?.fullName || ''}
                    rules={[
                      {
                        required: true,
                        message:
                          intl.formatMessage({ id: 'instructorManagement.fullName' }) + intl.formatMessage({ id: 'validator.require' }),
                      },
                    ]}
                  >
                    <CInput maxLength={255} disabled={typeModal === ActionType.VIEW} />
                  </Form.Item>
                </Col>
                <Col md={12} span={12}>
                  <Form.Item
                    labelAlign="left"
                    label={
                      <>
                        {typeModal !== ActionType.ADD && itemEdit.isAccuracy === 1 && (
                          <Tooltip title={intl.formatMessage({ id: 'instructorManagement.verified' })}>
                            <CheckCircleOutlined style={{ color: themes.statusGreen, marginRight: 5 }} />
                          </Tooltip>
                        )}
                        {intl.formatMessage({ id: 'instructorManagement.cccd' })}{' '}
                      </>
                    }
                    name="citizenIdCardNo"
                    initialValue={itemEdit?.citizenIdCardNo || ''}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'instructorManagement.cccd' }) + intl.formatMessage({ id: 'validator.require' }),
                      },
                      {
                        validator: (_, value) => {
                          if (!isNumeric(value) && value) {
                            return Promise.reject(
                              new Error(
                                intl.formatMessage({ id: 'instructorManagement.cccd' }) + intl.formatMessage({ id: 'validator.errFormat' })
                              )
                            );
                          } else if (value?.length < 12 && value) {
                            return Promise.reject(
                              new Error(
                                intl.formatMessage({ id: 'instructorManagement.cccd' }) + intl.formatMessage({ id: 'validator.errFormat' })
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <CInput maxLength={12} disabled={typeModal === ActionType.VIEW || itemEdit.isAccuracy === 1} />
                  </Form.Item>
                </Col>
                <Col md={12} span={24}>
                  <Form.Item
                    labelAlign="left"
                    label={intl.formatMessage({ id: 'instructorManagement.phoneNo' })}
                    name="phoneNo"
                    initialValue={itemEdit?.phoneNo || ''}
                    rules={[
                      {
                        required: true,
                        message:
                          intl.formatMessage({ id: 'instructorManagement.phoneNo' }) + intl.formatMessage({ id: 'validator.require' }),
                      },
                      {
                        validator: (_, value) => {
                          if (!isNumeric(value) && value) {
                            return Promise.reject(new Error(intl.formatMessage({ id: 'instructorManagement.falsePhoneFormat' })));
                          } else if (value?.length < 10 && value) {
                            return Promise.reject(new Error(intl.formatMessage({ id: 'instructorManagement.falsePhoneFormat' })));
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <CInput maxLength={11} disabled={typeModal === ActionType.VIEW} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col md={8} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.gender' })}
                name="gender"
                initialValue={itemEdit?.gender?.toString() || ''}
              >
                <Select disabled={typeModal === ActionType.VIEW} options={PERSON_SEX} />
              </Form.Item>
            </Col>

            <Col md={8} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.birthday' })}
                name="birthday"
                initialValue={itemEdit?.birthday ? moment(itemEdit?.birthday, 'YYYY-MM-DD') : itemEdit?.birthday}
              >
                <DatePickerStyle
                  placeholder="dd/mm/yyyy"
                  format={dateFormatList}
                  disabledDate={currentDate}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>

            <Col md={8} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.email' })}
                name="email"
                initialValue={itemEdit?.email || ''}
                rules={[
                  {
                    validator: (_, value) =>
                      !value || RegValidEmail.test(value)
                        ? Promise.resolve()
                        : Promise.reject(new Error(intl.formatMessage({ id: 'validator.requireEmail' }))),
                  },
                ]}
              >
                <CInput maxLength={255} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={8} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.driverLicenseNo' })}
                name="driverLicenseNo"
                initialValue={itemEdit?.driverLicenseNo || ''}
                rules={[
                  {
                    required: true,
                    message:
                      intl.formatMessage({ id: 'instructorManagement.driverLicenseNo' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    validator: (_, value) => {
                      if (!isNumeric(value) && value) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'instructorManagement.driverLicenseNo' }) +
                              intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        );
                      } else if (value?.length < 12 && value) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'instructorManagement.driverLicenseNo' }) +
                              intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <CInput maxLength={12} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={8} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.driverLicenseCategory' })}
                name="driverLicenseCategory"
                initialValue={itemEdit?.driverLicenseCategory || ''}
                rules={[
                  {
                    required: true,
                    message:
                      intl.formatMessage({ id: 'instructorManagement.driverLicenseCategory' }) +
                      intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <Select
                  disabled={typeModal === ActionType.VIEW}
                  options={orderBy(listLicense, ['licenseClass'], ['asc'])?.map((item: LicenseType) => ({
                    label: item.licenseClass,
                    value: item.licenseClass,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col md={8} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.certificationNo' })}
                name="certificationNo"
                initialValue={itemEdit?.certificationNo || ''}
                rules={[
                  {
                    required: true,
                    message:
                      intl.formatMessage({ id: 'instructorManagement.certificationNo' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput maxLength={20} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={24} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'instructorManagement.address' })}
                name="address"
                initialValue={itemEdit?.address || ''}
              >
                <CTextArea maxLength={255} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={24} span={24}>
              <Form.Item
                labelAlign="left"
                name="isWorking"
                valuePropName="checked"
                initialValue={typeModal === ActionType.ADD ? 1 : itemEdit?.isWorking}
              >
                {typeModal === ActionType.VIEW ? (
                  <>
                    <TagActive clr={itemEdit?.isWorking ? themes.statusGreen : themes.menuDisableDark}>
                      <FormattedMessage id={itemEdit?.isWorking ? 'instructorManagement.working' : 'instructorManagement.quitted'} />
                    </TagActive>
                  </>
                ) : (
                  <CSwitchStyle
                    disabled={typeModal !== ActionType.EDIT}
                    checkedChildren={intl.formatMessage({ id: 'instructorManagement.working' })}
                    unCheckedChildren={intl.formatMessage({ id: 'instructorManagement.quitted' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <RowButton>
            {typeModal === ActionType.VIEW && itemEdit?.courseStarted === false && (
              <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={() => handleDeleteItem(itemEdit.id!)}>
                {intl.formatMessage({ id: 'common.delete' })}
              </Button>
            )}
            {typeModal === ActionType.VIEW && (
              <Button
                typeCustom={TypeCustom.Primary}
                sizeCustom={TypeSizeCustom.Medium}
                onClick={() => dispatch(setTypeModal(ActionType.EDIT))}
              >
                {intl.formatMessage({ id: 'common.edit' })}
              </Button>
            )}
            {typeModal !== ActionType.VIEW && (
              <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </Button>
            )}
            {typeModal !== ActionType.VIEW && (
              <Button typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} htmlType="submit" loading={loadingBtn}>
                <FormattedMessage id="common.save" />
              </Button>
            )}
          </RowButton>
        </StyledInstructorForm>
      </Spin>
    </CModal>
  );
};

export default ModalAddEditView;
