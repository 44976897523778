import React, { FC } from 'react';
import { ModalProps } from 'antd';
import { StyledModal } from './style';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
interface Props extends ModalProps {
  children?: any;
}

const CModal: FC<Props> = React.memo(({ children, ...rest }) => {
  return (
    <StyledModal centered closeIcon={<CloseIcon />} maskClosable={false} {...rest}>
      {children}
    </StyledModal>
  );
});

export default CModal;
