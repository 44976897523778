import { Form } from 'antd';
import styled from 'styled-components/macro';

export const WrapperFormEmail = styled.div`
  .labelInput {
    color: white;
    text-align: left;
    margin-top: 35px;
  }
  .input-email {
    height: 40px;
  }
`;

export const StyledFormVerify = styled(Form)`
  margin-top: 20px;
  .clientSelect {
    height: 40px;

    .ant-select-selector {
      height: 100%;
    }

    .ant-select-selection-item {
      padding-top: 4px;
    }
  }

  .chooseClient {
    .ant-form-item-required {
      &::before {
        margin-inline-end: -22px !important;
      }
    }
  }
`;
