import React, { FC } from 'react';
import { StyledCascader } from './style';
import { CascaderProps } from 'antd';

const Cascader: FC<CascaderProps> = React.memo(({ children, ...rest }) => {
  return (
    <StyledCascader dropdownClassName="common-cascader-dropdown" {...rest}>
      {children}
    </StyledCascader>
  );
});

export default Cascader;
