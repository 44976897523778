import { Steps } from 'antd';
import styled from 'styled-components/macro';

export const StyledSteps = styled(Steps)`
  .ant-steps-item:last-child .ant-steps-icon-dot {
    background: ${({ theme }) => theme.statusGreen} !important;
  }

  .ant-steps-item:not(:last-child, :first-child) .ant-steps-icon-dot {
    background: ${({ theme }) => theme.statusYellow} !important;
  }

  .ant-steps-item-tail::after {
    background-color: ${({ theme }) => theme.primary} !important;
  }
`;
