import { useDispatch, useSelector } from 'react-redux';
import { CardMap, CardMapWrapper, MapRow, MapUserInfo, UserInfoItem, UserInfoWrapper } from '../style';
import { VideoCameraOutlined } from '@ant-design/icons';
import { getLiveStart } from 'modules/mapMonitor/redux';
import { infoUserSelector, paramsTrackSelector, tripsSelector } from 'modules/mapMonitor/selector';
import Avatar from 'assets/imgs/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faClock } from '@fortawesome/free-solid-svg-icons';

const UserInfo = () => {
  const dispatch = useDispatch();
  const { cameraSn } = useSelector(paramsTrackSelector);
  const infoUser = useSelector(infoUserSelector);
  const trips = useSelector(tripsSelector);

  const handleLiveStream = () => {
    cameraSn && dispatch(getLiveStart(cameraSn));
  };

  return (
    <CardMapWrapper>
      <MapRow>
        <img alt="avatar" src={Avatar} />
        <MapUserInfo>
          <div>{trips[0]?.studentName ?? ''}</div>
          <UserInfoWrapper>
            <UserInfoItem>
              <FontAwesomeIcon icon={faRoad} size="sm" />
              <div>{infoUser.distance}Km</div>
            </UserInfoItem>
            <UserInfoItem>
              <FontAwesomeIcon icon={faClock} size="sm" />
              <div>{infoUser.time}</div>
            </UserInfoItem>
          </UserInfoWrapper>
        </MapUserInfo>
      </MapRow>
      <MapRow>
        <CardMap>
          <VideoCameraOutlined />
          <div onClick={handleLiveStream}>Live camera</div>
        </CardMap>
      </MapRow>
    </CardMapWrapper>
  );
};

export default UserInfo;
