import CModal from 'components/commons/Modal';
import styled from 'styled-components';
import { Col } from 'antd';

export const Wrapper = styled.div`
  height: 100%;

  .iconReload {
    cursor: pointer;
    transition: all 0.25s linear;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledModal = styled(CModal)`
  .ant-modal {
    width: 60vw !important;
  }

  .ant-picker {
    width: 100%;
  }

  .buttonsWrap {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 10px;
      min-width: 120px;
    }
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-divider {
    margin: 20px 0;
  }

  .switchActive {
    margin-top: 12px;

    .ant-row {
      flex-direction: row;
    }

    .ant-form-item-label {
      padding-bottom: 0;

      label {
        padding-bottom: 0;
        line-height: 2.3;
      }
    }

    .spanActive {
      padding-left: 10px;
    }
  }

  span.anticon.anticon-upload {
    width: 20px;
    margin-top: 20px;
    svg {
      font-size: 20px;
    }
  }

  .ant-upload-wrapper{
    &.ant-upload-picture-card-wrapper,
    &.ant-upload-picture-circle-wrapper {
      .ant-upload.ant-upload-select >.ant-upload {
        flex-direction: column;
      }
      .ant-upload.ant-upload-select {
        width: 125px;
        height: 125px;
        margin-bottom: 0;
      }
    }
  }
`;

export const TitleTrainingTab = styled.div`
  font-size: 16px;
  line-height: 29px;
  color: ${({ theme }) => theme.titleDefault};
`;

export const UploadContainer = styled.div`
  width: 100%;
  height: 140px;
`;

export const UploadWrap = styled.div`
  display: flex;
  margin: auto;
  justify-content: flex-start;
  text-align: center;
  transition: border-color 0.3s;
  :hover {
    border-color: #004c98;
  }
  .ant-upload {
    width: 134px !important;
    height: 134px !important;
  }
`;

export const ColStyled = styled(Col)`
  .close-icon {
    position: absolute;
    transform: translateX(100%);
    cursor: pointer;
    color: ${({ theme }) => theme.statusRed};
    background-color: white;
    border-radius: 50%;
    svg {
      font-size: 18px;
    }
  }
`;
