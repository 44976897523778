import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  UserOutlined,
  NotificationOutlined,
  ReconciliationOutlined,
  FundProjectionScreenOutlined,
  InfoCircleOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { StyledMenu } from '../styles';
import { FormattedMessage, useIntl } from 'react-intl';
import paths from 'helper/pathRoutes';
import { getMenuStart, MenuItem, setCollapsed } from 'components/layouts/redux/leftMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Layout, Tooltip } from 'antd';
import { leftMenuSelector } from 'components/layouts/selector/leftMenuSelector';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { themeSelector } from 'styles/selector';
import ChangeLanguage from './ChangeLanguage';
import Logo from 'assets/imgs/mk-group-logo.png';
import Text from 'assets/imgs/mk-group-logo-text.svg';
import { ReactComponent as MenuUnfoldOutlined } from 'assets/imgs/MenuUnfoldOutlined.svg';
import { useEffectOnce } from 'react-use';

interface MenuItemConfig {
  titleId: string;
  uri?: string;
  tooltip?: string;
  icon?: React.ReactNode;
}

const { Sider } = Layout;
const LeftMenu = () => {
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loadingMenu, collapsed, menuItem } = useSelector(leftMenuSelector);
  const theme = useSelector(themeSelector);

  const handleChange = () => {
    dispatch(setCollapsed());
  };

  useEffectOnce(() => {
    dispatch(getMenuStart());
  });

  const itemMap: { [key: string]: MenuItemConfig } = useMemo(
    () => ({
      CATEGORY_PAGE: {
        titleId: 'layout.leftMenu.overview',
        uri: paths.dashboard,
        icon: <AppstoreOutlined />,
      },
      ASSIGN_ROLE_PAGE: {
        titleId: 'layout.leftMenu.accountAuthorization',
        icon: <UserOutlined />,
      },
      MANAGEMENT_AGENCY_PAGE: {
        titleId: 'layout.leftMenu.managementAgencyDirectory',
        uri: paths.management_agency,
        icon: <UserOutlined />,
      },
      ROLE_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.manageRoles',
        uri: paths.role,
      },
      USER_GROUP_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.manageAccountGroups',
        uri: paths.user_group_manager,
      },
      ACCOUNT_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.accountManager',
        uri: paths.account_manager,
      },
      TRAINING_FACILITY_MANAGEMENT_PAGE: {
        titleId: 'trainingFacility.title',
        uri: paths.trainingFacilities,
        icon: <ReconciliationOutlined />,
      },
      MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.management',
        icon: <InfoCircleOutlined />,
      },
      COURSE_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.courseManager',
        uri: paths.course_manager,
      },
      INSTRUCTOR_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.teacherManager',
        uri: paths.teacher_manager,
      },
      STUDENT_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.student',
        uri: paths.student,
      },
      DEVICE_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.device',
        uri: paths.device,
      },
      VEHICLE_MANAGEMENT_PAGE: {
        titleId: 'layout.leftMenu.vehicleManager',
        uri: paths.vehicle_manager,
      },
      MONITOR_PAGE: {
        titleId: 'layout.leftMenu.monitor',
        icon: <FundProjectionScreenOutlined />,
      },
      MONITOR_MAP_PAGE: {
        titleId: 'layout.leftMenu.liveMonitoring',
        uri: paths.liveMonitoring,
      },
      MONITOR_HISTORY_PAGE: {
        titleId: 'layout.leftMenu.monitoringHistory',
        uri: paths.monitoringHistory,
      },
      REPORT_PAGE: {
        titleId: 'layout.leftMenu.report',
        icon: <NotificationOutlined />,
      },
      APPENDIX_A_PAGE: {
        titleId: 'layout.leftMenu.addendumA',
        uri: paths.studentReport,
        tooltip: 'layout.leftMenu.studentReport',
      },
      APPENDIX_B_PAGE: {
        titleId: 'layout.leftMenu.addendumB',
        uri: paths.vehicleReport,
        tooltip: 'layout.leftMenu.vehicleReport',
      },
      APPENDIX_C_PAGE: {
        titleId: 'layout.leftMenu.addendumC',
        uri: paths.practiceReport,
        tooltip: 'layout.leftMenu.practiceReport',
      },
    }),
    []
  );

  const mappedKey = useMemo(() => {
    let activeKey = '';
    for (const key in itemMap) {
      if (itemMap[key].uri === location.pathname) {
        activeKey = key;
        break;
      }
    }
    return activeKey;
  }, [itemMap, location.pathname]);

  const convertMenuItemToItem: (menuItem: MenuItem) => ItemType | null = useCallback(
    (menuItem: MenuItem) => {
      const itemConfig: MenuItemConfig = itemMap[menuItem.code];
      if (!itemConfig) {
        return null;
      }
      const children = menuItem.items?.length ? menuItem.items.map(e => convertMenuItemToItem(e)).filter(e => !!e) : null;
      return {
        key: menuItem.code,
        icon: itemConfig.icon,
        children: children,
        label: itemConfig.uri ? (
          <Tooltip placement="right" title={itemConfig.tooltip && intl.formatMessage({ id: itemConfig.tooltip })}>
            <Link to={itemConfig.uri}>
              <FormattedMessage id={itemConfig.titleId} />
            </Link>
          </Tooltip>
        ) : (
          <FormattedMessage id={itemConfig.titleId} />
        ),
      } as ItemType;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemMap]
  );

  const [items, setItems] = useState<ItemType[]>([]);

  useEffect(() => {
    const convertedItems = menuItem.map(e => convertMenuItemToItem(e)).filter(e => !!e);
    setItems(convertedItems);
  }, [menuItem, convertMenuItemToItem]);

  return (
    <Sider style={{ background: theme.tableSelectedActive }} trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        {collapsed ? (
          <MenuUnfoldOutlined onClick={handleChange} style={{ cursor: 'pointer' }} />
        ) : (
          <>
            <img src={Logo} alt="Logo" className="image" />
            <img src={Text} alt="Logo" className="text" />
          </>
        )}
      </div>
      <Spin spinning={loadingMenu}>
        <div style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'scroll' }}>
          <StyledMenu mode="inline" selectedKeys={[mappedKey]} className="layout-leftMenu" items={items} />
        </div>
      </Spin>
      <ChangeLanguage />
    </Sider>
  );
};

export default memo(LeftMenu);
