import { combineReducers } from 'redux';
import changePassReducer from 'components/layouts/redux/changePass';
import leftMenuReducer from 'components/layouts/redux/leftMenu';
import headerReducer from 'components/layouts/redux/header';

export default combineReducers({
  changePass: changePassReducer,
  leftMenu: leftMenuReducer,
  header: headerReducer,
});
