import { all, put, takeLatest } from 'redux-saga/effects';
import { changePass, changePassFail, changePassSuccess } from 'components/layouts/redux/changePass';
import { NotificationError, NotificationSuccess } from 'components/notifications';
import { clientChangePass, clientChangePassRequest } from '../services';
import paths from 'helper/pathRoutes';

function* handleChangePassword(action: ReturnType<typeof changePass>): any {
  try {
    const params = { ...action.payload.data, location: undefined };
    if (action.payload.data.location.pathname === paths.login) {
      yield clientChangePassRequest(params);
    } else {
      yield clientChangePass(params);
    }

    NotificationSuccess('auth.changePassSuccess');
    yield put(changePassSuccess());
  } catch (err: any) {
    yield put(changePassFail());
    if (err?.fieldErrors) {
      action.payload.setFieldError(err?.fieldErrors);
      return;
    }
    NotificationError(err);
  }
}

export default function* changPassSaga(): any {
  return all([yield takeLatest(changePass, handleChangePassword)]);
}
