import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EventItem, GetEventsRequest, InfoUser, LessonItem, ParamsLessons, TrackItem, TrainingFacilityItem, TripItem } from '../type';
import { DefaultPage, Page } from 'types';
import { TypeTF } from 'modules/studentReport/type';
import moment from 'moment';
import { BtnTabType } from 'modules/mapMonitor/constant';

export interface MHState {
  params: ParamsLessons;
  lessons: Page<LessonItem>;
  lessonDetail: LessonItem;
  loadingTable: boolean;
  showModal: boolean;
  loadingModal: boolean;
  itemData: TrainingFacilityItem | null;
  typeModal: string;
  qsValue: string;
  listOptionTF: TypeTF[];
  events: EventItem[];
  tracks: { lat: number; lng: number }[];
  isRunOffset: boolean;
  trips: TripItem[];
  infoUser: InfoUser;
  indexActiveEvent: number;
  indexActiveTrip: number;
  indexActiveCar: number;
  btnTab: string;
  showVideoDetail: boolean;
  playDetailUrl: string;
  offset: number;
}

const initialState: MHState = {
  params: {
    page: 0,
    size: 10,
    q: '',
    clientId: '',
    startDate: moment().subtract(3, 'months').startOf('day').format(),
    endDate: moment().endOf('day').format(),
  },
  lessons: DefaultPage,
  lessonDetail: {
    courseCode: '',
    courseName: '',
    distance: 0,
    drivingLessonCode: '',
    duration: 0,
    endTime: '',
    startTime: '',
    id: '',
    instructorName: '',
    licensePlate: '',
    studentName: '',
  },
  loadingTable: false,
  showModal: false,
  loadingModal: false,
  itemData: {
    clientId: '',
    clientName: '',
    address: '',
    representative: '',
    foundedYear: null,
    email: '',
    phoneNumber: '',
    accessKeyId: '',
    accessKeySecret: '',
    signInEmail: '',
    status: 0,
    clientCode: '',
    clientType: 0,
    parentId: '',
  },
  typeModal: '',
  qsValue: '',
  listOptionTF: [],
  events: [],
  tracks: [],
  trips: [],
  isRunOffset: false,
  infoUser: {
    distance: '',
    time: '',
  },
  indexActiveEvent: -1,
  indexActiveTrip: 0,
  indexActiveCar: -1,
  btnTab: BtnTabType.trip,
  showVideoDetail: false,
  playDetailUrl: '',
  offset: 0,
};

const monitorHistorySlice = createSlice({
  name: 'monitorHistorySlice',
  initialState,
  reducers: {
    getLessons: (state, action: PayloadAction<any>) => {
      state.loadingTable = true;
    },
    getLessonsSuccess: (state, action: PayloadAction<Page<LessonItem>>) => {
      state.loadingTable = false;
      state.lessons = action.payload;
    },
    getLessonsFail: state => {
      state.loadingTable = false;
    },

    setLessonDetail: (state, action: PayloadAction<LessonItem>) => {
      state.lessonDetail = action.payload;
    },

    setParams: (state, action: PayloadAction<ParamsLessons>) => {
      state.params = action.payload;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.tracks = [];
        state.isRunOffset = false;
        state.events = [];
        state.indexActiveEvent = -1;
        state.indexActiveTrip = 0;
        state.indexActiveCar = -1;
      }
      state.showModal = action.payload;
    },

    setDataItem: (state, action: PayloadAction<TrainingFacilityItem | null>) => {
      state.itemData = action.payload;
    },

    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },

    getListTF: state => {},

    getListTFSuccess: (state, action: PayloadAction<TypeTF[]>) => {
      state.listOptionTF = action.payload;
    },

    getListTFFail: state => {},

    setLoadingModal: (state, action: PayloadAction<boolean>) => {
      state.loadingModal = action.payload;
    },

    getEventsStart: (state, action: PayloadAction<GetEventsRequest>) => {
      state.loadingModal = true;
    },

    getEventsSuccess: (state, action: PayloadAction<EventItem[]>) => {
      state.events = action.payload;
    },

    getTrackStart: (state, action: PayloadAction<TrackItem[]>) => {},

    getTrackSuccess: (state, action: PayloadAction<{ lat: number; lng: number }[]>) => {
      state.tracks = action.payload;
      state.isRunOffset = true;
    },

    getTripsSuccess: (state, action: PayloadAction<TripItem[]>) => {
      state.trips = action.payload;
    },

    setIsRunOffset: (state, action: PayloadAction<boolean>) => {
      state.isRunOffset = action.payload;
    },

    setInfoUser: (state, action: PayloadAction<InfoUser>) => {
      state.infoUser = action.payload;
    },

    getDetailHistoryStart: (state, action: PayloadAction<any>) => {
      state.showModal = true;
      state.loadingModal = true;
    },

    setBtnTab: (state, action: PayloadAction<string>) => {
      if (action.payload === BtnTabType.prev) {
        state.tracks = [];
        state.isRunOffset = false;
        state.events = [];
      }
      state.indexActiveEvent = -1;
      state.indexActiveTrip = 0;
      state.indexActiveCar = -1;
      state.btnTab = action.payload;
    },

    setIndexActiveEvent: (state, action: PayloadAction<number>) => {
      state.indexActiveEvent = action.payload;
    },
    setIndexActiveTrip: (state, action: PayloadAction<number>) => {
      state.indexActiveTrip = action.payload;
    },
    setIndexActiveCar: (state, action: PayloadAction<number>) => {
      state.indexActiveCar = action.payload;
    },

    setShowVideoDetail: (state, action: PayloadAction<boolean>) => {
      state.showVideoDetail = action.payload;
    },
    setPlayDetailUrl: (state, action: PayloadAction<string>) => {
      state.playDetailUrl = action.payload;
    },

    getDetailTripStart: (state, action: PayloadAction<any>) => {
      state.loadingModal = true;
    },

    runOffset: state => {
      state.offset = (state.offset + 1) % 100;
    },
  },
});

export const {
  getLessons,
  getLessonsFail,
  getLessonsSuccess,
  setParams,
  setShowModal,
  setDataItem,
  setTypeModal,
  setQsValue,
  getListTF,
  getListTFFail,
  getListTFSuccess,
  setLoadingModal,
  getEventsStart,
  getEventsSuccess,
  getTrackSuccess,
  setIsRunOffset,
  getTripsSuccess,
  setInfoUser,
  getDetailHistoryStart,
  setBtnTab,
  setIndexActiveEvent,
  setIndexActiveTrip,
  setIndexActiveCar,
  setShowVideoDetail,
  setPlayDetailUrl,
  getDetailTripStart,
  setLessonDetail,
  getTrackStart,
  runOffset,
} = monitorHistorySlice.actions;

export default monitorHistorySlice.reducer;
