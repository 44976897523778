import { Col, Form, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldErrorsSelector,
  itemEditSelector,
  loadingBtnModalSelector,
  loadingModalSelector,
  typeModalSelector,
  listDeviceSelector,
} from '../selector';
import { createVehicle, deleteListVehicle, editVehicle, setTypeModal, startGetListDevice } from '../redux';
import { isEmptyObject } from 'helper/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalConfirm from 'components/commons/modalConfirm';
import { ActionType } from 'helper/constants';
import filter from 'lodash/filter';
import { RowButton } from 'components/commons/Template/style';
import Button, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CInput from 'components/commons/Input';
import Select from 'components/commons/Select';
import CModal from 'components/commons/Modal';
import { StyledVehicleForm } from '../page/style';
import isNumeric from 'validator/lib/isNumeric';
import CTextArea from 'components/commons/TextArea';
import { orderBy } from 'lodash';
import { DeviceItem } from '../api/types';
import { useEffectOnce } from 'react-use';
const ModalAddEditView = () => {
  const intl = useIntl();
  const typeModal = useSelector(typeModalSelector);
  const dispatch = useDispatch();
  const itemEdit = useSelector(itemEditSelector);
  const [form] = Form.useForm();
  const fieldErrors = useSelector(fieldErrorsSelector);
  const loadingBtn = useSelector(loadingBtnModalSelector);
  const loadingModal = useSelector(loadingModalSelector);
  const listDevice = useSelector(listDeviceSelector);

  const optionsDevice = orderBy(listDevice.devices, ['code'], ['asc'])?.map((item: DeviceItem) => ({
    label: item.code,
    value: item.id,
  }));

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',
        handleConfirm: () => {
          dispatch(setTypeModal(null));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();
      const isValuesEmpty = isEmptyObject({
        ...values,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setTypeModal(null));
          },
        });
      } else {
        dispatch(setTypeModal(null));
      }
      return;
    }
    dispatch(setTypeModal(null));
  };

  const renderTitle = () => {
    switch (typeModal) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleFinishForm = async (values: any) => {
    if (typeModal === ActionType.ADD) {
      const data = {
        ...values,
        device: values?.device ? { id: values?.device } : undefined,
        note: values?.note ? values?.note : undefined,
        classification: values?.classification ? values?.classification : undefined,
        ownerPhoneNumber: values?.ownerPhoneNumber ? values?.ownerPhoneNumber : undefined,
      };
      dispatch(createVehicle(data));
      return;
    }
    if (typeModal === ActionType.EDIT) {
      const data = {
        ...values,
        device: values?.device ? { id: values?.device } : undefined,
        note: values?.note ? values?.note : undefined,
        classification: values?.classification ? values?.classification : undefined,
        ownerPhoneNumber: values?.ownerPhoneNumber ? values?.ownerPhoneNumber : undefined,
        id: itemEdit.id,
      };
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(editVehicle(data));
        },
      });
    }
  };

  const handleClose = () => {
    dispatch(setTypeModal(null));
  };

  useEffect(() => {
    const valueErrLicensePlate = filter(fieldErrors, { field: 'licensePlate' })[0];
    if (valueErrLicensePlate) {
      form.setFields([
        {
          name: 'licensePlate',
          errors: [valueErrLicensePlate.message],
        },
      ]);
    }

    const valueErrVehicleLicense = filter(fieldErrors, { field: 'vehicleLicense' })[0];
    if (valueErrVehicleLicense) {
      form.setFields([
        {
          name: 'vehicleLicense',
          errors: [valueErrVehicleLicense.message],
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEdit]);

  useEffectOnce(() => {
    dispatch(startGetListDevice());
  });

  const handleDeleteItem = (id: string) => {
    if (id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteListVehicle([id]));
        },
      });
    }
  };

  return (
    <CModal open={typeModal !== null} onCancel={handleClose} title={renderTitle()} footer={null} width={1000}>
      <Spin spinning={loadingModal}>
        <StyledVehicleForm layout="vertical" form={form} onFinish={handleFinishForm}>
          <Row gutter={16}>
            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.licensePlate' })}
                name="licensePlate"
                initialValue={itemEdit?.licensePlate || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'vehicleManagement.licensePlate' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    pattern: new RegExp(/^[\d\w]+(\d+)+(.\d+)*$/),
                    message:
                      intl.formatMessage({ id: 'vehicleManagement.licensePlate' }) + intl.formatMessage({ id: 'validator.errFormatLP' }),
                  },
                ]}
              >
                <CInput maxLength={15} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.vehicleLicense' })}
                name="vehicleLicense"
                initialValue={itemEdit?.vehicleLicense || ''}
                rules={[
                  {
                    required: true,
                    message:
                      intl.formatMessage({ id: 'vehicleManagement.vehicleLicense' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput maxLength={20} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.brand' })}
                name="brand"
                initialValue={itemEdit?.brand || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'vehicleManagement.brand' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput maxLength={50} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.classification' })}
                name="classification"
                initialValue={itemEdit?.classification || ''}
              >
                <CInput maxLength={50} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.ownerName' })}
                name="ownerName"
                initialValue={itemEdit?.ownerName || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'vehicleManagement.ownerName' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput maxLength={255} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.ownerPhoneNumber' })}
                name="ownerPhoneNumber"
                initialValue={itemEdit?.ownerPhoneNumber || ''}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!isNumeric(value) && value) {
                        return Promise.reject(new Error(intl.formatMessage({ id: 'vehicleManagement.falsePhoneFormat' })));
                      } else if (value?.length < 10 && value) {
                        return Promise.reject(new Error(intl.formatMessage({ id: 'vehicleManagement.falsePhoneFormat' })));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <CInput maxLength={11} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>

            <Col md={12} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.device' })}
                name="device"
                initialValue={itemEdit?.device?.id || undefined}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, optionsDevice: any) => (optionsDevice?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  disabled={typeModal === ActionType.VIEW}
                  options={
                    typeModal === ActionType.ADD || !itemEdit?.device
                      ? optionsDevice
                      : [
                        {
                          label: itemEdit.device.code,
                          value: itemEdit.device.id,
                        },
                        ...optionsDevice,
                      ]
                  }
                />
              </Form.Item>
            </Col>

            <Col md={24} span={24}>
              <Form.Item
                labelAlign="left"
                label={intl.formatMessage({ id: 'vehicleManagement.note' })}
                name="note"
                initialValue={itemEdit?.note || ''}
              >
                <CTextArea maxLength={255} disabled={typeModal === ActionType.VIEW} />
              </Form.Item>
            </Col>
          </Row>

          <RowButton>
            {typeModal === ActionType.VIEW && (
              <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={() => handleDeleteItem(itemEdit.id!)}>
                {intl.formatMessage({ id: 'common.delete' })}
              </Button>
            )}
            {typeModal === ActionType.VIEW && (
              <Button
                typeCustom={TypeCustom.Primary}
                sizeCustom={TypeSizeCustom.Medium}
                onClick={() => dispatch(setTypeModal(ActionType.EDIT))}
              >
                {intl.formatMessage({ id: 'common.edit' })}
              </Button>
            )}
            {typeModal !== ActionType.VIEW && (
              <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </Button>
            )}
            {typeModal !== ActionType.VIEW && (
              <Button typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} htmlType="submit" loading={loadingBtn}>
                <FormattedMessage id="common.save" />
              </Button>
            )}
          </RowButton>
        </StyledVehicleForm>
      </Spin>
    </CModal>
  );
};

export default ModalAddEditView;
