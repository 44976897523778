import { Cascader } from 'antd';
import styled from 'styled-components';

export const StyledCascader = styled(Cascader)`
  min-height: 36px;

  .ant-select-selector {
    height: 36px !important;
    input {
      height: 32px !important;
    }
  }

  .ant-select-selector > .ant-select-selection-item {
    line-height: 34px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 32px !important;
  }
`;
