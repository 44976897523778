/* eslint-disable react-hooks/rules-of-hooks */
import { Col, Form, Row, Spin, Tree, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelector } from '../selector';
import { createRoleStart, deleteRole, getListRoleDefault, setItemEdit, setOpenModalAddEdit, setTypeModal, updateRole } from '../redux';
import { StyledModalAddEdit, StyledTitleModal, TagActive } from '../page/style';
import { isEmpty } from 'helper/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalConfirm from 'components/commons/modalConfirm';
import { useEffectOnce } from 'react-use';
import { ActionType, RegValidStringEnglish } from 'helper/constants';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { themes } from 'styles';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { useRolesByRouter } from 'helper/cusHooks';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import CInput from 'components/commons/Input';
import { RowButton } from 'components/commons/Template/style';
import CSwitch from 'components/commons/Switch';
import { FieldErrorsType } from '../type';
import CTextArea from 'components/commons/TextArea';

const AddEditRole = () => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [showValidateTree, setShowValidateTree] = useState<boolean>(false);
  const listRoles = useRolesByRouter();
  const intl = useIntl();

  const dispatch = useDispatch();
  const { showModalAddEdit, listRoleDefault, loadingButtonSave, loadingModal, itemEdit, typeModal } = useSelector(roleSelector);
  const [form] = Form.useForm<{ apellido_empleado: string }>();
  const onExpand = (expandedKeysValue: React.Key[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const handleDeleteRole = () => {
    if (itemEdit?.roleId) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteRole(itemEdit?.roleId));
          handleClose();
        },
      });
    }
  };

  const onCheck = (checkedKeysValue: any) => {
    if (checkedKeysValue?.length) {
      setShowValidateTree(false);
    } else {
      setShowValidateTree(true);
    }
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue: React.Key[]) => {
    setSelectedKeys(selectedKeysValue);
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',

        handleConfirm: () => {
          dispatch(setOpenModalAddEdit(false));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        checkedKeys: checkedKeys.length ? checkedKeys : null,
        status: 0,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setOpenModalAddEdit(false));
          },
        });
      } else {
        dispatch(setOpenModalAddEdit(false));
      }
      return;
    }
    dispatch(setOpenModalAddEdit(false));
  };

  const getListDefault = () => {
    dispatch(getListRoleDefault());
  };

  const handleClickBtnSave = () => {
    if (!checkedKeys.length) {
      setShowValidateTree(true);
    } else {
      setShowValidateTree(false);
    }
  };

  const handleFinishForm = (values: any) => {
    if (!checkedKeys.length) {
      setShowValidateTree(true);
      return;
    }

    const data = {
      ...values,
      status: values?.status ? 1 : 0,
      checkedKeys: checkedKeys,
    };

    if (typeModal === ActionType.ADD) {
      dispatch(
        createRoleStart({
          data: data,
          setFieldError: setFieldError,
        })
      );
    }
    if (typeModal === ActionType.EDIT && itemEdit?.roleId) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(
            updateRole({
              ...data,
              roleId: itemEdit?.roleId,
            })
          );
        },
      });
    }
  };

  const handleClose = () => {
    dispatch(setOpenModalAddEdit(false));
  };

  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
  };

  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = form.getFieldValue(field);
      form.setFieldValue(field, value.trim());
      form.validateFields([field]);
    }, 0);
  };

  const renderTitle = () => {
    switch (typeModal) {
      case ActionType.ADD:
        return <FormattedMessage id="common.add" />;
      case ActionType.EDIT:
        return <FormattedMessage id="common.edit" />;
      case ActionType.VIEW:
        return <FormattedMessage id="common.viewDetail" />;
      default:
        return '';
    }
  };

  const setFieldError = (fieldErrors: FieldErrorsType[]) => {
    form.setFields(
      fieldErrors.map((item: FieldErrorsType) => ({
        name: item.field,
        errors: [item.message],
      }))
    );
  };
  useEffectOnce(() => {
    getListDefault();

    return () => {
      form.resetFields();
      dispatch(setItemEdit({}));
    };
  });

  useEffect(() => {
    if (itemEdit?.checkedKeys) {
      setCheckedKeys(itemEdit?.checkedKeys);
    }
    return () => {
      setCheckedKeys([]);
    };
  }, [itemEdit]);

  return (
    <StyledModalAddEdit
      closable
      closeIcon={<CloseIcon />}
      open={showModalAddEdit}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={loadingModal}>
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left" onFinish={handleFinishForm}>
          <StyledTitleModal>{renderTitle()}</StyledTitleModal>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                valuePropName="checked"
                initialValue={typeModal === ActionType.ADD ? 1 : itemEdit?.status}
                name="status"
                className="switchActive"
                label={typeModal !== ActionType.VIEW ? intl.formatMessage({ id: 'common.active' }) : ''}
              >
                {typeModal === ActionType.VIEW ? (
                  <>
                    <TagActive clr={itemEdit?.status ? themes.statusGreen : themes.menuDisableDark}>
                      <FormattedMessage id={itemEdit?.status ? 'common.active' : 'common.inactive'} />
                    </TagActive>
                  </>
                ) : (
                  <CSwitch disabled={typeModal !== ActionType.EDIT} />
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'role.roleCode' })}
                name="roleCode"
                initialValue={itemEdit?.roleCode || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'role.roleCode' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    validator: (_, value) =>
                      !value || RegValidStringEnglish.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(intl.formatMessage({ id: 'role.roleCode' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' }))
                          ),
                  },
                ]}
              >
                <CInput
                  maxLength={20}
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  onPaste={(e: any) => {
                    handlePates(e, 'roleCode');
                  }}
                  disabled={typeModal !== ActionType.ADD}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'role.roleName' })}
                name="roleName"
                initialValue={itemEdit?.roleName || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'role.roleName' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput
                  maxLength={100}
                  onPaste={(e: any) => {
                    handlePates(e, 'roleName');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'common.desc' })}
                initialValue={itemEdit?.roleDescription || ''}
                name="roleDescription"
              >
                <CTextArea
                  onPaste={(e: any) => {
                    handlePates(e, 'roleDescription');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={5} className="titleTree">
            <FormattedMessage id="role.decentralization" />
          </Typography.Title>
          <div className="treeWrap">
            <Tree
              disabled={typeModal === ActionType.VIEW}
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
              treeData={listRoleDefault}
            />
            {showValidateTree && (
              <span className="clr-validate">
                {intl.formatMessage({ id: 'role.decentralization' }) + intl.formatMessage({ id: 'validator.require' })}
              </span>
            )}
          </div>
          <RowButton>
            {typeModal === ActionType.VIEW ? (
              <>
                {includes(listRoles, actionsType.DELETE) && (
                  <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteRole}>
                    <FormattedMessage id="common.delete" />
                  </CButton>
                )}
                {includes(listRoles, actionsType.UPDATE) && (
                  <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} onClick={handleChangeEdit}>
                    <FormattedMessage id="common.edit" />
                  </CButton>
                )}
              </>
            ) : (
              <>
                <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                  {intl.formatMessage({ id: 'common.cancel' })}
                </CButton>

                <CButton
                  typeCustom={TypeCustom.Primary}
                  sizeCustom={TypeSizeCustom.Medium}
                  htmlType="submit"
                  loading={loadingButtonSave}
                  onClick={handleClickBtnSave}
                >
                  <FormattedMessage id="common.save" />
                </CButton>
              </>
            )}
          </RowButton>
        </Form>
      </Spin>
    </StyledModalAddEdit>
  );
};

export default AddEditRole;
