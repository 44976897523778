import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirm from 'components/commons/modalConfirm';
import { WrapperActionTable } from 'modules/role/page/style';
import { deleteTC, getDetailTC, getListAgency, getListTC, setParams, setQsValue, setShowModal, setTypeModal } from '../redux';
import ModalTraining from './ModalTraining';
import { FacilityGridItem, ParamsTrainingFc } from '../type';
import includes from 'lodash/includes';
import { useRolesByRouter } from 'helper/cusHooks';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { ActionType, StatusTrainingType, UnitType } from 'helper/constants';
import CTable from 'components/commons/Table';
import CustomSearch from 'components/commons/Search';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import { MenuProps } from 'rc-menu';
import { RowHeader, StyledTextDesc, Text, WrapperButton } from 'components/commons/Template/style';
import { CReloadButton } from 'components/commons/ReloadButton';
import { typeLanguageSelector } from 'languages/selectors';
import CSelect from 'components/commons/Select';
import { trainingFacilitySelector } from '../selector';
import { managementBaseSelector } from 'modules/auth/selectors';
import { CsdtType } from 'modules/auth/types';

const Grid = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const langType = useSelector(typeLanguageSelector);
  const dispatch = useDispatch();
  const { listTrainFacility, loadingTable, params, showModal, typeModal, qsValue, listAgency } = useSelector(trainingFacilitySelector);
  const managementBase = useSelector(managementBaseSelector);
  const listRoles = useRolesByRouter();
  const intl = useIntl();

  const options = [
    {
      label: intl.formatMessage({ id: 'common.all' }),
      value: StatusTrainingType.ALL,
    },
    {
      label: intl.formatMessage({ id: 'role.status.active' }),
      value: StatusTrainingType.ACTIVE,
    },
    {
      label: intl.formatMessage({ id: 'role.status.inactive' }),
      value: StatusTrainingType.INACTIVE,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderMenuItemsMore = (record: FacilityGridItem): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => handleEditRole(record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteRole(record)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRoles, item?.key));
  };
  const columns: ColumnsType<FacilityGridItem> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text disabled={record?.status ? false : true}>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'trainingFacility.name' }),
      dataIndex: 'clientChildName',
      width: 300,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'trainingFacility.agency' }),
      dataIndex: 'clientParentName',
      width: 300,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.address' }),
      dataIndex: 'address',
      width: 280,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <StyledTextDesc disabled={record?.status ? false : true}>{value}</StyledTextDesc>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'trainingFacility.person' }),
      dataIndex: 'representative',
      width: 180,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.phoneNumber' }),
      dataIndex: 'phoneNumber',
      align: 'left',
      width: 130,
      render: (value, record) => {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: 'Email',
      dataIndex: 'email',
      width: 180,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'role.statusName' }),
      dataIndex: 'status',
      align: 'left',
      width: 150,
      render: value => {
        return (
          <Text type={value === 1 ? 'success' : undefined} disabled={value !== 1}>
            <FormattedMessage id={value ? 'common.active' : 'common.inactive'} />
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      fixed: 'right',
      width: 150,
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewRole(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
              <IconMore className="iconMore" />
            </Dropdown>
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleSetQsValue = (value: string) => {
    dispatch(setQsValue(value));
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };

  const handleAddRole = () => {
    handleSetTypeModal(ActionType.ADD);
    dispatch(setShowModal(true));
  };

  const handleViewRole = (itemEdit: FacilityGridItem) => {
    handleSetTypeModal(ActionType.VIEW);

    dispatch(
      getDetailTC({
        id: itemEdit.clientId,
      })
    );
  };

  const handleEditRole = (itemEdit: FacilityGridItem) => {
    handleSetTypeModal(ActionType.EDIT);
    dispatch(
      getDetailTC({
        id: itemEdit?.clientId,
      })
    );
  };

  const handleDeleteRole = (itemEdit: FacilityGridItem) => {
    if (itemEdit?.clientId) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteTC([itemEdit?.clientId]));
        },
      });
    }
  };

  const handleDeleteMutiRoles = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteTC(selectedRowKeys));
        setSelectedRowKeys([]);
      },
    });
  };

  const getListUserByPr = (params: ParamsTrainingFc) => {
    dispatch(
      getListTC({
        ...params,
        clientId: managementBase?.clientType === UnitType.SO ? managementBase.parentList?.[0]?.clientId : params.clientId,
      })
    );
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterMH')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperMH')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163;
  };

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        page: 0,
        q: value,
      })
    );
  };

  const handleRefresh = () => {
    handleSetQsValue('');
    dispatch(
      setParams({
        page: 0,
        size: 10,
        q: '',
        status: StatusTrainingType.ALL,
        clientId: '',
      })
    );
  };

  const handleChange = (value: StatusTrainingType) => {
    dispatch(setParams({ ...params, status: value }));
  };
  const handleChangeUnit = (value: string[]) => {
    dispatch(setParams({ ...params, clientId: value.toString() }));
  };

  const handleGetListAgency = () => {
    dispatch(getListAgency());
  };

  useEffect(() => {
    getListUserByPr(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, langType, showModal]);

  useEffect(() => {
    if (managementBase?.clientType === UnitType.CUC) {
      handleGetListAgency();
    }
    return () => {
      handleRefresh();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'trainingFacility.tooltip' })}
            onSearch={handleQuickSearch}
            value={qsValue}
            setValue={handleSetQsValue}
          />
          <CReloadButton onClick={handleRefresh} />
          <CSelect options={options} style={{ width: 170 }} value={params.status} onChange={handleChange} />
          <CSelect
            style={{ width: 240 }}
            options={(managementBase?.clientType === UnitType.SO ? managementBase?.parentList : listAgency).map((item: CsdtType) => ({
              label: item.clientName,
              value: item.clientId,
            }))}
            disabled={managementBase?.clientType !== UnitType.CUC}
            onChange={handleChangeUnit}
            filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
            showSearch
            allowClear
            placeholder={intl.formatMessage({ id: 'trainingFacility.agency' })}
            value={
              managementBase?.clientType === UnitType.SO
                ? managementBase.parentList?.[0]?.clientId || undefined
                : params.clientId
                  ? params.clientId.split(',')
                  : undefined
            }
            mode={managementBase?.clientType === UnitType.SO ? undefined : 'multiple'}
            className="selectCSQL"
          />
        </WrapperButton>
        <WrapperButton>
          {includes(listRoles, actionsType.DELETE) && (
            <CButton
              typeCustom={TypeCustom.Secondary}
              sizeCustom={TypeSizeCustom.Medium}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMutiRoles}
            >
              <FormattedMessage id="common.delete" />
            </CButton>
          )}
          {includes(listRoles, actionsType.CREATE) && (
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddRole}>
              <FormattedMessage id="common.add" />
            </CButton>
          )}
        </WrapperButton>
      </RowHeader>
      <Row>
        <CTable
          rowSelection={rowSelection}
          loading={loadingTable}
          columns={columns}
          rowKey={'clientId'}
          dataSource={listTrainFacility.content}
          style={{ width: '100%' }}
          scroll={{ y: changeHeightTable() }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            current: params.page + 1,
            pageSize: params.size,
            size: 'small',
            total: listTrainFacility?.totalElements || 0,
            showQuickJumper: true,
            showTotal: TotalMessage,
            locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
          }}
          onChange={handleChangeTable}
        />
      </Row>
      {showModal && <ModalTraining type={typeModal} />}
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
