import { NotificationError, NotificationErrorCustom } from 'components/notifications';
import { all, put, select, takeLatest, delay, call } from 'redux-saga/effects';
import {
  GetListFleetViewRequest,
  GetListFleetViewResponse,
  GetTripsRequest,
  GetStartLiveResponse,
  GetEventsRequest,
  GetTrackRequest,
  OptionItem,
  TreeItem,
  EventItem,
  TripItem,
  TrackItem,
  GetSnapToRoadResponse,
  OptionStudentItem,
  SnappedPointsItem,
} from '../api/types';
import {
  getListCourse,
  getListFleetView,
  getListLicensePlate,
  getListStudent,
  getListTree,
  getTrips,
  getEvents,
  getStartLive,
  getLiveStatus,
  getTrack,
  getListInstructor,
} from '../api';

import {
  getListCourseSuccess,
  getListEventsStart,
  getListLicensePlateSuccess,
  getListStudentSuccess,
  getListTreeStart,
  getListTreeSuccess,
  getTripsStart,
  getTripsSuccess,
  getEventsSuccess,
  updateSearch,
  updateClientId,
  getFleetViewDataSuccess,
  getDetailSessionStart,
  setParamsTrips,
  setParamsEvents,
  setLoadingFleetView,
  getLiveStart,
  getLiveStatusStart,
  setParamsTrack,
  getTrackStart,
  getTrackSuccess,
  setLoadingDetail,
  getDetailTripStart,
  runOffset,
  setLoadingVideo,
  setPlayUrlVideo,
  setWakeupStatus,
  getListInstructorSuccess,
  setInfoUser,
} from '../redux';
import { managementBaseSelector } from 'modules/auth/selectors';
import { ManagementBaseType } from 'modules/auth/types';
import {
  paramsTripsSelector,
  paramsSelector,
  paramsEventsSelector,
  paramsTrackSelector,
  dataGoogleMapSelector,
  isRunOffsetSelector,
} from '../selector';
import last from 'lodash/last';
import { UnitType } from 'helper/constants';
import moment from 'moment';
import numeral from 'numeral';
import { chunkArray } from 'helper/utils';

function* getListTreeWorker() {
  try {
    const managementBase: ManagementBaseType | undefined = yield select(managementBaseSelector);
    if (managementBase) {
      const response: TreeItem[] = yield getListTree({ clientType: managementBase.clientType });
      yield put(getListTreeSuccess(response));
      if (managementBase.clientType === UnitType.CSDT) {
        yield put(updateClientId([response[0].id]));
      }
    }
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getListFleetViewWorker() {
  try {
    yield put(setLoadingFleetView(true));
    const params: GetListFleetViewRequest = yield select(paramsSelector);
    const response: GetListFleetViewResponse = yield getListFleetView({
      ...params,
      courseId: params.courseId ? params.courseId : undefined,
      vehicleId: params.vehicleId ? params.vehicleId : undefined,
      studentId: params.studentId ? params.studentId : undefined,
      instructorId: params.instructorId ? params.instructorId : undefined,
      clientId: last(params.clientId),
    });
    yield put(getFleetViewDataSuccess(response));
    yield put(setLoadingFleetView(false));

    if (response.content.length === 0) return;

    const { map } = yield select(dataGoogleMapSelector);
    const bounds = new window.google.maps.LatLngBounds();
    response.content?.forEach(item => {
      if (item.gpsData) {
        bounds.extend({
          lat: item.gpsData.coordinate[1],
          lng: item.gpsData.coordinate[0],
        });
      }
    });
    map?.fitBounds(bounds);
  } catch (err: any) {
    yield put(setLoadingFleetView(false));
    NotificationError(err);
  }
}

function* getListCourseWorker() {
  try {
    const { clientId } = yield select(paramsSelector);
    const response: OptionItem[] = yield getListCourse({ clientId: last(clientId) });
    yield put(getListCourseSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getListStudentWorker() {
  try {
    const { clientId } = yield select(paramsSelector);
    const response: OptionStudentItem[] = yield getListStudent({ clientId: last(clientId) });
    yield put(getListStudentSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getListInstructorWorker() {
  try {
    const { clientId } = yield select(paramsSelector);
    const response: OptionStudentItem[] = yield getListInstructor({ clientId: last(clientId) });
    yield put(getListInstructorSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getListLicensePlateWorker() {
  try {
    const { clientId } = yield select(paramsSelector);
    const response: OptionItem[] = yield getListLicensePlate({ clientId: last(clientId) });
    yield put(getListLicensePlateSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}
/* ----------------------- live stream ---------------------*/

function* getLiveWorker(action: ReturnType<typeof getLiveStart>) {
  try {
    const { clientId } = yield select(paramsSelector);
    const response: GetStartLiveResponse = yield getStartLive({ clientId: last(clientId)!, cameraSn: action.payload });
    if (response.status === 'offline') {
      NotificationErrorCustom('Camera đang offline');
      return;
    }
    yield put(getLiveStatusStart(action.payload));
  } catch (err: any) {
    yield put(setLoadingVideo(false));
    NotificationError(err);
  }
}

function* getLiveStatusWorker(action: ReturnType<typeof getLiveStatusStart>) {
  try {
    const { clientId } = yield select(paramsSelector);
    const response: GetStartLiveResponse = yield getLiveStatus({ clientId: last(clientId)!, cameraSn: action.payload });
    if (response.status === 'live') {
      yield put(setPlayUrlVideo(response.playUrl));
      yield put(setLoadingVideo(false));
    } else if (response.status === 'stopped') {
      yield put(setLoadingVideo(false));
      yield put(setWakeupStatus(false));
    } else if (response.status === 'waitForPublish' || response.status === 'waitForAwake') {
      yield put(setLoadingVideo(true));
      yield delay(5000);
      yield put(getLiveStatusStart(action.payload));
    }
  } catch (err: any) {
    yield put(setLoadingVideo(false));
    NotificationError(err);
  }
}
/* ----------------------- GoogleMap ---------------------*/

function* runOffsetWorker(): any {
  try {
    yield delay(100);
    const isRunOffset = yield select(isRunOffsetSelector);
    if (!isRunOffset) return;
    else yield put(runOffset());
  } catch (err: any) {
    NotificationError(err);
  }
}
/* ----------------------- GoogleMap ---------------------*/

/* ----------------------- detail screen ---------------------*/
function* getTripsWorker(action: ReturnType<typeof getTripsStart>): any {
  try {
    const response: TripItem[] = yield getTrips(action.payload);
    yield put(getTripsSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getEventsWorker(action: ReturnType<typeof getListEventsStart>): any {
  try {
    yield put(setParamsEvents(action.payload));
    const response: EventItem[] = yield getEvents(action.payload);
    yield put(getEventsSuccess(response));
    yield put(setLoadingDetail(false));
  } catch (err: any) {
    NotificationError(err);
    yield put(setLoadingDetail(false));
  }
}

function* getTrackWorker(action: ReturnType<typeof getTrackStart>): any {
  try {
    yield put(setParamsTrack(action.payload));
    const responseTrack: TrackItem[] = yield getTrack(action.payload);
    if (responseTrack.length === 0) return;
    const myArr: string[] = [];
    let result: string[][] = [];
    responseTrack?.forEach((items: TrackItem, index: number) => {
      if (items) {
        myArr.push(`${items.coordinate[1]},${items.coordinate[0]}`);
      }
      if (index === myArr.length - 1) {
        result = chunkArray(myArr, 100);
      }
    });

    const { key, map } = yield select(dataGoogleMapSelector);
    let arrayApi: SnappedPointsItem[] = [];
    for (let index = 0; index < result.length; index++) {
      const responseSnapToRoad: any = yield call(
        fetch,
        `https://roads.googleapis.com/v1/snapToRoads?interpolate=true&key=${key}&path=${result[index].join('|')}`,
        {
          method: 'GET',
        }
      );
      const responseBody: GetSnapToRoadResponse = yield responseSnapToRoad.json();
      arrayApi = arrayApi.concat(responseBody.snappedPoints);
    }
    let snappedCoordinates: { lat: number; lng: number }[] = [];
    const bounds = new window.google.maps.LatLngBounds();

    arrayApi.forEach((value: SnappedPointsItem) => {
      const location: { latitude: number; longitude: number } = value.location;
      bounds.extend({
        lat: location.latitude,
        lng: location.longitude,
      });
      snappedCoordinates.push({ lat: location.latitude, lng: location.longitude });
    });

    yield put(getTrackSuccess(snappedCoordinates));

    map.fitBounds(bounds);
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getDetailSessionWorker(action: ReturnType<typeof getDetailSessionStart>): any {
  try {
    const { lessonId } = action.payload;
    const paramsTrips: GetTripsRequest = yield select(paramsTripsSelector);
    const paramsEvents: GetEventsRequest = yield select(paramsEventsSelector);
    const paramsTrack: GetTrackRequest = yield select(paramsTrackSelector);
    const { clientId } = yield select(paramsSelector);
    const paramsTripsNew = {
      ...paramsTrips,
      fromTime: Number(moment().startOf('day').format('x')),
      toTime: Number(moment().format('x')),
      lessonId: lessonId ?? paramsTrips.lessonId,
    };
    yield put(setParamsTrips(paramsTripsNew));
    const resTrips: TripItem[] = yield getTrips(paramsTripsNew);
    const cameraSn = resTrips[0]?.cameraSn;
    let totalDistance = 0;
    let totalTime = 0;
    const tripsNew = resTrips?.map((item, index) => {
      totalDistance += item.distance;
      totalTime += item.time;
      return item;
    });
    yield put(getTripsSuccess(tripsNew));
    yield put(
      setInfoUser({
        distance: numeral(totalDistance / 1000).format('0.00'),
        time: moment.utc(totalTime).format('H[h] mm[m]'),
      })
    );
    const paramsEventsNew = {
      ...paramsEvents,
      fromTime: Number(moment().startOf('day').format('x')),
      toTime: Number(moment().format('x')),
      cameraSn: cameraSn ?? paramsEvents.cameraSn,
      clientId: last(clientId)!.toString() ?? paramsEvents.clientId,
    };

    const paramsTrackNew = {
      ...paramsTrack,
      cameraSn: cameraSn ?? paramsTrack.cameraSn,
    };

    yield put(setParamsEvents(paramsEventsNew));
    yield put(setParamsTrack(paramsTrackNew));

    if (resTrips.length) {
      yield put(getListEventsStart({ ...paramsEventsNew, tripId: resTrips[0]?.tripId }));
      yield put(getTrackStart({ ...paramsTrackNew, tripId: resTrips[0]?.tripId }));
    }
    yield put(setLoadingDetail(false));
  } catch (err: any) {
    yield put(setLoadingDetail(false));
    NotificationError(err);
  }
}

function* getDetailTripWorker(action: ReturnType<typeof getDetailTripStart>): any {
  try {
    const paramsEvents: GetEventsRequest = yield select(paramsEventsSelector);
    const paramsTrack: GetTrackRequest = yield select(paramsTrackSelector);
    const paramsEventsNew = {
      ...paramsEvents,
      ...action.payload,
    };
    yield put(getListEventsStart(paramsEventsNew));

    const paramsTrackNew = {
      ...paramsTrack,
      ...action.payload,
    };
    yield put(getTrackStart(paramsTrackNew));
    yield put(setLoadingDetail(false));
  } catch (err: any) {
    yield put(setLoadingDetail(false));
    NotificationError(err);
  }
}

export default function* mapMonitorSaga() {
  yield all([takeLatest(getListTreeStart, getListTreeWorker)]);
  yield all([takeLatest(updateSearch, getListFleetViewWorker)]);
  yield all([takeLatest(updateClientId, getListFleetViewWorker)]);
  yield all([takeLatest(updateClientId, getListCourseWorker)]);
  yield all([takeLatest(updateClientId, getListStudentWorker)]);
  yield all([takeLatest(updateClientId, getListInstructorWorker)]);
  yield all([takeLatest(updateClientId, getListLicensePlateWorker)]);
  /* ----------------------- live stream ---------------------*/
  yield all([takeLatest(getLiveStart, getLiveWorker)]);
  yield all([takeLatest(getLiveStatusStart, getLiveStatusWorker)]);
  /* ----------------------- GoogleMap ---------------------*/
  yield all([takeLatest(runOffset, runOffsetWorker)]);
  /* ----------------------- detail screen ---------------------*/
  yield all([takeLatest(getTripsStart, getTripsWorker)]);
  yield all([takeLatest(getListEventsStart, getEventsWorker)]);
  yield all([takeLatest(getDetailSessionStart, getDetailSessionWorker)]);
  yield all([takeLatest(getTrackStart, getTrackWorker)]);
  yield all([takeLatest(getDetailTripStart, getDetailTripWorker)]);
}
