import HttpRequest from 'core/http/singleton/auth';
import { AllParamResponse, LoginResponse } from './types';

export const loginUrl = 'api/authenticate';
export const refershTokenUrl = 'api/refresh-token';

export const handleLogin = async (data: any): Promise<LoginResponse> => {
  const res = await HttpRequest.post<LoginResponse>(loginUrl, data);
  return res.data;
};

export const handleSignUp = async (data: any): Promise<any> => {
  const res = await HttpRequest.post('api/owner/register', data);
  return res;
};

export const handleConfirmOTP = async (data: any): Promise<any> => {
  const res = await HttpRequest.post(`api/owner/otp?otp=${data?.otp}`);
  return res;
};

export const getAllParam = async (): Promise<AllParamResponse> => {
  const res = await HttpRequest.get<AllParamResponse>('api/param');
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};


