import React from 'react';
import { Wrapper } from './styles';
import Grid from '../components/Grid';
import { FormattedMessage } from 'react-intl';
import { TitleHeader } from 'components/commons/Template/style';

const CoursePage = () => {
  return (
    <Wrapper id="wrapperCoursesManager">
      <TitleHeader id="filterCoursesManager">
        <FormattedMessage id="layout.leftMenu.courseManager" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default CoursePage;
