import { Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { WrapperActionTable } from 'modules/role/page/style';
import { getDetailHistoryStart, getLessons, getLessonsSuccess, getListTF, setParams, setQsValue, setTypeModal } from '../redux';
import { LessonItem } from '../type';
import includes from 'lodash/includes';
import { useRolesByRouter } from 'helper/cusHooks';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { ActionType, UnitType } from 'helper/constants';
import CTable from 'components/commons/Table';
import CustomSearch from 'components/commons/Search';
import CButton, { TypeCustom } from 'components/commons/Button';
import { RowHeader, StyledTextDesc, Text, WrapperButton } from 'components/commons/Template/style';
import { CReloadButton } from 'components/commons/ReloadButton';
import { typeLanguageSelector } from 'languages/selectors';
import { lessonsSelector, listOptionTFSelector, loadingTableSelector, paramsSelector, qsValueSelector } from '../selector';
import Cascader from 'components/commons/Cascader';
import { managementBaseSelector } from 'modules/auth/selectors';
import { DefaultOptionType } from 'antd/es/cascader';
import { RangePickerProps } from 'antd/es/date-picker';
import { CRangePicker } from 'components/commons/DatePicker';
import moment, { Moment } from 'moment';
import { NotificationErrorCustom } from 'components/notifications';
import { DefaultPage } from 'types';
import numeral from 'numeral';

const Grid = () => {
  const langType = useSelector(typeLanguageSelector);
  const dispatch = useDispatch();
  const lessons = useSelector(lessonsSelector);
  const loadingTable = useSelector(loadingTableSelector);
  const params = useSelector(paramsSelector);
  const qsValue = useSelector(qsValueSelector);
  const listOptionTF = useSelector(listOptionTFSelector);
  const listRoles = useRolesByRouter();
  const intl = useIntl();
  const [csdt, setCSDT] = useState<any>([]);

  const managementBase = useSelector(managementBaseSelector);

  const columns: ColumnsType<LessonItem> = [
    {
      title: 'STT',
      align: 'left',
      width: 60,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'historyMonitor.trainingSessionCode' }),
      dataIndex: 'drivingLessonCode',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'course.courseCode' }),
      dataIndex: 'courseCode',
      width: 120,
      align: 'left',
      render(value, record, index) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.licensePlate' }),
      dataIndex: 'licensePlate',
      width: 120,
      align: 'left',
      render(value, record, index) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.teacher' }),
      dataIndex: 'instructorName',
      width: 150,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <StyledTextDesc>{value}</StyledTextDesc>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'mapMonitor.student' }),
      dataIndex: 'studentName',
      width: 180,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.distance' }) + ' (Km)',
      dataIndex: 'distance',
      align: 'left',
      width: 165,
      render: (value, record) => {
        return <Text>{numeral(value / 1000).format('0.00')}</Text>;
      },
    },

    {
      title: intl.formatMessage({ id: 'common.time' }) + ` (${intl.formatMessage({ id: 'liveMonitor.minutes' })})`,
      dataIndex: 'duration',
      width: 150,
      align: 'left',
      render(value, record) {
        return <Text>{moment.duration(value, 'milliseconds').minutes()}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.startTime' }),
      dataIndex: 'startTime',
      align: 'left',
      width: 160,
      render: value => {
        return <Text>{moment(value).format('DD/MM/YYYY HH:mm')}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.endTime' }),
      dataIndex: 'endTime',
      align: 'left',
      width: 160,
      render: value => {
        return <Text>{moment(value).format('DD/MM/YYYY HH:mm')}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      fixed: 'right',
      width: 130,
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.MONITOR) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewRole(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleSetQsValue = (value: string) => {
    dispatch(setQsValue(value));
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };

  const handleViewRole = (itemEdit: LessonItem) => {
    handleSetTypeModal(ActionType.VIEW);
    dispatch(getDetailHistoryStart(itemEdit));
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterAccountsManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperAccountsManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163;
  };

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        page: 0,
        q: value,
      })
    );
  };

  const handleChangeCSDT = (value: any) => {
    setCSDT(value);
    if (value) {
      dispatch(
        setParams({
          ...params,
          clientId: value?.[value.length - 1],
        })
      );
    }
  };

  const handleRefresh = () => {
    handleSetQsValue('');
    setCSDT([]);
    dispatch(getLessonsSuccess(DefaultPage));
    dispatch(
      setParams({
        page: 0,
        size: 10,
        q: '',
        endDate: moment().endOf('day').format(),
        startDate: moment().subtract(3, 'months').format(),
        clientId: managementBase?.clientType === UnitType.CSDT ? listOptionTF?.[0]?.id : '',
      })
    );
  };

  const getListTrainingFacility = () => {
    dispatch(getListTF());
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.name as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!params.clientId) return;
    dispatch(
      getLessons({
        ...params,
        clientId: managementBase?.clientType === UnitType.CSDT ? listOptionTF?.[0]?.id : params.clientId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, langType]);

  useEffect(() => {
    getListTrainingFacility();
    return () => {
      handleRefresh();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handeChangeDate = (dates: Moment[]) => {
    if (dates?.length) {
      if (moment(dates[1]).startOf('day').diff(moment(dates[0]).startOf('day'), 'months', true) > 6) {
        NotificationErrorCustom('historyMonitor.warningDate');
        return;
      }
      dispatch(
        setParams({
          ...params,
          endDate: moment(dates[1]).endOf('day').format(),
          startDate: moment(dates[0]).format(),
        })
      );
    }
  };
  return (
    <div>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'historyMonitor.tooltipQS' })}
            onSearch={handleQuickSearch}
            value={qsValue}
            setValue={handleSetQsValue}
          />
          <Cascader
            showSearch={{ filter }}
            options={listOptionTF}
            fieldNames={{
              label: 'name',
              value: 'id',
            }}
            value={managementBase?.clientType === UnitType.CSDT ? [params.clientId] : csdt}
            disabled={managementBase?.clientType === UnitType.CSDT}
            onChange={handleChangeCSDT}
            allowClear={false}
            style={{ width: 300 }}
            dropdownMenuColumnStyle={{
              maxWidth: 300,
            }}
            placeholder={intl.formatMessage({ id: 'practiceReport.placeholderTrainingFacility' })}
          />
          <CRangePicker
            format={'DD/MM/YYYY'}
            allowClear={false}
            disabledDate={disabledDate}
            defaultValue={[moment().subtract(3, 'day'), moment()]}
            onChange={(dates: Moment[]) => handeChangeDate(dates)}
            value={[moment(params.startDate), moment(params.endDate)]}
          />
          <CReloadButton onClick={handleRefresh} />
        </WrapperButton>
      </RowHeader>
      <Row>
        <CTable
          loading={loadingTable}
          columns={columns}
          rowKey={'id'}
          dataSource={lessons.content}
          style={{ width: '100%' }}
          scroll={{ y: changeHeightTable() }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            current: params.page + 1,
            pageSize: params.size,
            size: 'small',
            total: lessons?.totalElements || 0,
            showQuickJumper: true,
            showTotal: TotalMessage,
            locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
          }}
          onChange={handleChangeTable}
        />
      </Row>
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
