import { Col, Form, Tooltip, Table, Spin } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile, getDetailLesson, setDataDetail, setShowModal } from '../redux';
import { RowStyled, StyledModal, TitleDetail } from '../page/styles';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { vehicleReportSelector } from '../selector';
import { StyledTitleModal } from 'modules/role/page/style';
import { RowButton, Text, TextLink } from 'components/commons/Template/style';
import CTable from '../../../components/commons/Table';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import CButton, { TypeCustom, TypeSizeCustom } from '../../../components/commons/Button';
import { LessonVehicle, VehicleReportDefault } from '../type';
import ModalDetailLesson from 'modules/monitorHistory/components/ModalDetail';
import { showModalSelector } from 'modules/monitorHistory/selector';

const ViewDetailModal = () => {
  const { showModal, dataDetail, startDateDetail, endDateDetail, idDetail, loadingModal } = useSelector(vehicleReportSelector);
  const showModalLesson = useSelector(showModalSelector);

  const dispatch = useDispatch();

  const intl = useIntl();
  const [form] = Form.useForm();

  const handleCancel = () => {
    dispatch(setDataDetail(VehicleReportDefault));
    dispatch(setShowModal(false));
  };

  const onFinish = (values: any) => {};

  const handleBtnClose = () => {
    dispatch(setDataDetail(VehicleReportDefault));
    dispatch(setShowModal(false));
  };

  const handleExport = () => {
    dispatch(downloadFile({ id: idDetail, startDate: startDateDetail, endDate: endDateDetail }));
  };

  const handleGetDetailLesson = (lessonId: string) => {
    dispatch(getDetailLesson(lessonId));
  };
  const fmtTotalDuration = moment.duration(dataDetail.totalDuration || 0, 'milliseconds').asHours().toFixed(2);
  const fmtTotalDistance = ((dataDetail.totalDistance || 0) / 1000).toFixed(2);
  const columnsDetail: ColumnsType<LessonVehicle> = [
    {
      title: 'STT',
      align: 'left',
      width: 60,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.trainingSession' }),
      dataIndex: 'code',
      width: 150,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <TextLink onClick={() => handleGetDetailLesson(record.id)}>{value}</TextLink>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.trainingStudent' }),
      dataIndex: 'studentName',
      width: 200,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.trainingDate' }),
      dataIndex: 'startTime',
      width: 150,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip
            title={
              <span>
                {record.startTime ? moment(record.startTime).format('DD/MM/yyyy hh:mm:ss') : ''} -&nbsp;
                {record.endTime ? moment(record.endTime).format('DD/MM/yyyy hh:mm:ss') : ''}
              </span>
            }
            placement="topLeft"
          >
            <Text>
              {record.startTime ? moment(record.startTime).format('DD/MM/yyyy hh:mm:ss') : ''} -&nbsp;
              {record.endTime ? moment(record.endTime).format('DD/MM/yyyy hh:mm:ss') : ''}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.hours' }) + ' (h)',
      dataIndex: 'duration',
      width: 130,
      align: 'left',
      render(value) {
        const fmtVal = moment.duration(value, 'milliseconds').asHours().toFixed(2);
        return (
          <Tooltip title={fmtVal} placement="topLeft">
            <Text>{fmtVal}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.distance' }) + ' (Km)',
      dataIndex: 'distance',
      width: 220,
      align: 'left',
      render(value) {
        const fmtVal = (value / 1000).toFixed(2);
        return (
          <Tooltip title={fmtVal} placement="topLeft">
            <Text>{fmtVal}</Text>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <StyledModal
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleCancel}
      width={1050}
      footer={null}
    >
      <Spin spinning={loadingModal}>
        <Form
          form={form}
          labelAlign="left"
          labelCol={{ xs: { span: 5 }, sm: { span: 5 }, md: { span: 4 }, lg: { span: 4 }, xl: { span: 4 } }}
          onFinish={onFinish}
        >
          <StyledTitleModal style={{ fontSize: 20 }}>
            <FormattedMessage id={'vehicleReport.titleModal'} />
          </StyledTitleModal>
          <TitleDetail>
            <FormattedMessage id={'vehicleReport.vehicleInfo'} />
          </TitleDetail>
          <RowStyled>
            <Col span={24}>
              <Form.Item label={intl.formatMessage({ id: 'vehicleReport.licensePlate' })} name="licensePlate">
                {dataDetail.licensePlate}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={intl.formatMessage({ id: 'vehicleReport.vehicleClass' })} name="vehicleClass">
                {dataDetail.classification}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={intl.formatMessage({ id: 'vehicleReport.owner' })} name="owner">
                {dataDetail.ownerName}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={intl.formatMessage({ id: 'vehicleReport.trainingFacility' })} name="trainingFacility">
                {dataDetail.schoolName}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={''} name="time">
                <FormattedMessage id={'vehicleReport.fromDate'} />
                &nbsp;
                {dataDetail.startTime ? moment(dataDetail.startTime).format('DD/MM/YYYY') : ''}&nbsp;
                <FormattedMessage id={'vehicleReport.toDate'} />
                &nbsp;
                {dataDetail.endTime ? moment(dataDetail.endTime).format('DD/MM/YYYY') : ''}
              </Form.Item>
            </Col>
          </RowStyled>
        </Form>
        <TitleDetail>
          <FormattedMessage id={'vehicleReport.trainingProcessInfo'} />
        </TitleDetail>
        <CTable
          columns={columnsDetail}
          dataSource={dataDetail.lessonVehicle}
          style={{ width: '100%', marginBottom: '20px' }}
          pagination={false}
          scroll={{ y: 350 }}
          summary={() =>
            dataDetail.lessonVehicle?.length ? (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={4} index={0}>
                    <b>{intl.formatMessage({ id: 'studentReport.total' })}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1} index={1}>
                    <b>{fmtTotalDuration}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1} index={2}>
                    <b>{fmtTotalDistance}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            ) : (
              <></>
            )
          }
        />
        <RowButton>
          <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
            {intl.formatMessage({ id: 'common.close' })}
          </CButton>
          <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} onClick={handleExport}>
            {intl.formatMessage({ id: 'report.export' })}
          </CButton>
        </RowButton>
      </Spin>
      {showModalLesson && <ModalDetailLesson />}
    </StyledModal>
  );
};
export default ViewDetailModal;
