import React from 'react';
import { Wrapper } from './styles';
import { FormattedMessage } from 'react-intl';
import { TitleHeader } from 'components/commons/Template/style';
import Grid from '../component/Grid';

const DashboardPage = () => {
  return (
    <Wrapper>
      <TitleHeader>
        <FormattedMessage id="layout.leftMenu.overview" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default DashboardPage;
