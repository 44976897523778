import styled from 'styled-components/macro';

export const StyledWrapTabs = styled.div`
  .ant-tabs-tab-btn {
    font-size: 16px !important;
  }

  .ant-tabs-tab {
    padding-top: 0;
  }

  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 22px;
  }
`;
