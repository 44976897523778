import RestfulHttp from 'core/http/singleton/auth';
import { GetListInstructorRequest, GetListInstructorResponse } from './types';
import { urlDrivingInstructorSearch, urlDrivingInstructor, urlDrivingLicense } from './urls';

export const getListInstructor = async (params: GetListInstructorRequest): Promise<GetListInstructorResponse> => {
  const res = await RestfulHttp.get<GetListInstructorResponse>(urlDrivingInstructorSearch, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getDetailInstructorService = async (id: string): Promise<any> => {
  const res = await RestfulHttp.get<any>(`${urlDrivingInstructor}/${id}`);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getDrivingLicense = async (): Promise<any> => {
  const res = await RestfulHttp.get<any>(urlDrivingLicense);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const postAddInstructor = async (formData: FormData): Promise<any> => {
  const res = await RestfulHttp.post<any>(urlDrivingInstructor, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const putAddInstructor = async (formData: FormData): Promise<any> => {
  const res = await RestfulHttp.put<any>(urlDrivingInstructor, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const deleteAddInstructor = async (params: string[]): Promise<any> => {
  const res = await RestfulHttp.delete<any>(urlDrivingInstructor, {
    data: params,
  });
  return res.data;
};

