import { ParamsType, ParamsTF, ParamsReportItem } from '../type';
import HttpRequest from 'core/http/singleton/auth';

export const getListReportByPr = async (params: ParamsType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/reports/student', params);
  return res.data;
};

export const exportReportApi = async (data: ParamsReportItem): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/student/export/${data.idStudent}/${data.idCourse}`, {}, { responseType: 'blob' });
  return res.data;
};

export const getListCourseApi = async (clientId: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/param/course/${clientId}`);
  return res.data;
};

export const getDetailReportApi = async (data: ParamsReportItem): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/student/${data.idStudent}/${data.idCourse}`);
  return res.data;
};

export const getListTrainingFacility = async (params: ParamsTF): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/lesson-view/tree-client', params);
  return res.data;
};

export const getDetailLessonApi = async (lessonId: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`/api/v1/history/${lessonId}`);
  return res.data;
};
