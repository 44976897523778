import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import Storage from 'helper/storage';
import { DataLogin, DataOtp, DataSignUpType, LoginStartPayloadType, LoginSuccessType, ManagementBaseType } from '../types';
import { AllParamResponse, ClientType } from '../services/types';
import { fieldErrorsItem } from './type';
import moment, { Moment } from 'moment';
import { APP_CODE } from 'AppConfig';
import { UnitType } from 'helper/constants';

export interface AuthState {
  isAuthenticated: boolean;
  clientId: string;
  loadingButtonLogin: boolean;
  showModalClient: boolean;
  dataModalClient: ClientType[];
  dataLogin: DataLogin;
  username: string;
  showFormOTP: boolean;
  dataSignUp: any;
  clientSelected: ClientType;
  timeCountDownOtp: Moment;
  isOpenModalRegisterSuccess: boolean;
  otpCode: string;
  errDetail: string;
  fieldErrors: fieldErrorsItem[];
  allParam: AllParamResponse;
  managementBase: ManagementBaseType | undefined;
}

const initialState: AuthState = {
  isAuthenticated: false,
  clientId: '',
  loadingButtonLogin: false,
  showModalClient: false,
  dataModalClient: [],
  dataLogin: {
    username: '',
    password: '',
  },
  username: '',
  dataSignUp: {},
  showFormOTP: false,
  clientSelected: {
    clientCode: '',
    clientId: '',
    clientName: '',
    userCode: '',
  },
  timeCountDownOtp: moment(),
  isOpenModalRegisterSuccess: false,
  otpCode: '',
  errDetail: '',
  fieldErrors: [],
  allParam: {
    PERSON_SEX: [],
    DOOR_TYPE: [],
    TRAINING_TYPE: [],
    OTP_DEVICE: [],
  },
  managementBase: {
    clientType: UnitType.SO,
    parentList: [],
  },
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginStart: (state, action: PayloadAction<LoginStartPayloadType>) => {
      state.loadingButtonLogin = true;
      state.errDetail = initialState.errDetail;
      state.fieldErrors = initialState.fieldErrors;
    },
    loginSuccess: (state, action: PayloadAction<LoginSuccessType>) => {
      state.isAuthenticated = true;
      state.username = action.payload.username;
      state.clientId = action.payload.clientId || '';
      state.showModalClient = false;
      state.loadingButtonLogin = false;
      state.managementBase = action.payload.managementBase;
    },
    loginFail: state => {
      state.loadingButtonLogin = false;
    },
    logout: state => {
      state.isAuthenticated = false;
      state.clientId = '';
      state.username = '';
      Storage.removeToken();
      state.clientSelected = initialState.clientSelected;
      state.managementBase = initialState.managementBase;
    },
    signUp: (state, action: PayloadAction<DataSignUpType>) => {
      state.loadingButtonLogin = true;
    },
    signUpSuccess: state => {
      state.loadingButtonLogin = false;
    },
    signUpFail: state => {
      state.loadingButtonLogin = false;
    },
    setShowModalClient: (state, action: PayloadAction<boolean>) => {
      state.showModalClient = action.payload;
      state.fieldErrors = initialState.fieldErrors;
    },
    setDataModalClient: (state, action: PayloadAction<ClientType[]>) => {
      state.dataModalClient = action.payload;
    },
    setDataLogin: (state, action: PayloadAction<DataLogin>) => {
      state.dataLogin = action.payload;
    },
    setClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    confirmOTP: (state, action: PayloadAction<DataOtp>) => {
      state.loadingButtonLogin = true;
    },
    confirmOTPSuccess: state => {
      state.loadingButtonLogin = false;
      state.dataSignUp = {};
      state.showFormOTP = false;
    },
    confirmOTPFail: state => {
      state.loadingButtonLogin = false;
    },
    setClientSelected: (state, action: PayloadAction<ClientType>) => {
      state.clientSelected = action.payload;
    },
    setTimeCountDownOtp: (state, action: PayloadAction<Moment>) => {
      state.timeCountDownOtp = action.payload;
    },
    setShowModalRegisterSuccess: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalRegisterSuccess = action.payload;
    },
    setOtpCode: (state, action: PayloadAction<string>) => {
      state.otpCode = action.payload;
    },
    setErrDetail: (state, action: PayloadAction<string>) => {
      state.errDetail = action.payload;
    },
    setFieldErrors: (state, action: PayloadAction<fieldErrorsItem[]>) => {
      state.fieldErrors = action.payload;
    },
    resetErrDetail: state => {
      state.errDetail = initialState.errDetail;
    },
    setAllParam: (state, action: PayloadAction<AllParamResponse>) => {
      state.allParam = action.payload;
    },
  },
});

const persistConfig = {
  key: `${APP_CODE}:auth:root`,
  storage: storage,
  whitelist: ['isAuthenticated', 'clientId', 'username', 'clientSelected', 'allParam', 'managementBase'],
};

export const {
  loginStart,
  setDataLogin,
  loginSuccess,
  logout,
  loginFail,
  signUp,
  signUpFail,
  signUpSuccess,
  setShowModalClient,
  setDataModalClient,
  setClientId,
  setUsername,
  confirmOTP,
  confirmOTPFail,
  confirmOTPSuccess,
  setClientSelected,
  setTimeCountDownOtp,
  setShowModalRegisterSuccess,
  setOtpCode,
  setErrDetail,
  setFieldErrors,
  resetErrDetail,
  setAllParam,
} = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
