import { DetailContainer } from '../style';
import DepartmentFilter from './DepartmentFilter';
import Body from './Body';

function ListFleet() {
  return (
    <DetailContainer>
      <DepartmentFilter />
      <Body />
    </DetailContainer>
  );
}

export default ListFleet;
