import HttpRequest from 'core/http/singleton/auth';
import { ParamsDetailTC, ParamsTrainingFc, TrainingFacilityItem } from '../type';

const url = 'api/v1/management-training-facilities';
const urlQS = '/api/search/clients';

export const createTrainingFacilityApi = async (data: TrainingFacilityItem): Promise<any> => {
  const res = await HttpRequest.post<any>(url, data);
  return res.data;
};

export const getListTrainingFacilityApi = async (params: ParamsTrainingFc): Promise<any> => {
  const res = await HttpRequest.get<any>(urlQS, params);
  return res.data;
};

export const getDetailTrainingFacilityApi = async (params: ParamsDetailTC): Promise<any> => {
  const res = await HttpRequest.get<any>(url, params);
  return res.data;
};

export const updateTrainingFacilityApi = async (data: TrainingFacilityItem): Promise<any> => {
  const res = await HttpRequest.put<any>(url, data);
  return res.data;
};

export const deleteTrainingFacilityApi = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>(url, {
    data: data,
  });
  return res.data;
};

export const getListAgencyApi = async (): Promise<any> => {
  const res = await HttpRequest.get<any>(url + '/list-agency');
  return res.data;
};
