import { RouterConfig } from 'router/AppRoute';
import paths from 'helper/pathRoutes';
import StudentReportPage from './page';

const routes: RouterConfig[] = [
  {
    path: paths.studentReport,
    page: StudentReportPage,
  },
];

export default routes;
