import { DepartmentSort, ItemSort, LabelRequire } from '../style';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { getListTreeStart, updateClientId } from '../../redux';
import Cascader from 'antd/es/cascader';
import { listTreeSelector, paramsSelector } from '../../selector';
import { GetListFleetViewRequest, TreeItem } from '../../api/types';
import { managementBaseSelector } from 'modules/auth/selectors';
import { UnitType } from 'helper/constants';
import { ManagementBaseType } from 'modules/auth/types';
import type { DefaultOptionType } from 'antd/es/cascader';
import { removeVietNamese } from 'helper/utils';

export interface OptionTree {
  value: string;
  label: string;
  children?: OptionTree[];
}
function optionsTreeItem(value: TreeItem[]): OptionTree[] {
  return value.map((item: TreeItem) => {
    return {
      value: item.id,
      label: item.name,
      children: item.children ? optionsTreeItem(item.children) : undefined,
    };
  });
}

function DepartmentFilter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const optionsListTree: OptionTree[] = optionsTreeItem(useSelector(listTreeSelector));
  const params: GetListFleetViewRequest = useSelector(paramsSelector);
  const managementBase: ManagementBaseType | undefined = useSelector(managementBaseSelector);

  useEffectOnce(() => {
    dispatch(getListTreeStart());
  });

  const onChangeClientId = (value: any[]) => {
    dispatch(updateClientId(value));
  };

  const displayRender = (labels: string[]) => labels[labels.length - 1];
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      option =>
        removeVietNamese(option.label as string)
          .toLowerCase()
          .indexOf(removeVietNamese(inputValue.toLowerCase())) > -1
    );
  return (
    <DepartmentSort>
      <ItemSort>
        <LabelRequire>
          <FormattedMessage id="mapMonitor.department" />
        </LabelRequire>

        <Cascader
          disabled={managementBase?.clientType === UnitType.CSDT}
          value={[params.clientId as string[]]}
          placeholder={intl.formatMessage({ id: 'mapMonitor.chooseDepartment' })}
          options={optionsListTree}
          expandTrigger="hover"
          displayRender={displayRender}
          onChange={onChangeClientId}
          allowClear={false}
          showSearch={{ filter }}
        />
      </ItemSort>
    </DepartmentSort>
  );
}

export default DepartmentFilter;
