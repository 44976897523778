import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { CourseManagerState } from './redux';

export const courseManagerSelector = (state: RootState) => state.course;
export const courseManagerStateSelector = createSelector(courseManagerSelector, (state: CourseManagerState) => state);
export const paramsSelector = createSelector(courseManagerSelector, (state: CourseManagerState) => state.params);
export const itemDataSelector = createSelector(courseManagerSelector, (state: CourseManagerState) => state.itemData);
export const openFilterSelector = createSelector(courseManagerSelector, (state: CourseManagerState) => state.openFilter);
export const paramsLessonSelector = createSelector(courseManagerSelector, (state: CourseManagerState) => state.paramsLesson);
