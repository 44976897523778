import MonitoringHistoryPage from './page';
import { RouterConfig } from 'router/AppRoute';
import paths from 'helper/pathRoutes';

const routes: RouterConfig[] = [
  {
    path: paths.monitoringHistory,
    page: MonitoringHistoryPage,
  },
];

export default routes;
