import { RowHeader, Text, WrapperButton } from "../../../components/commons/Template/style";
import CustomSearch from "../../../components/commons/Search";
import { CReloadButton } from "../../../components/commons/ReloadButton";
import includes from "lodash/includes";
import { actionsType } from "../../../components/layouts/redux/leftMenu";
import CButton, { TypeCustom, TypeSizeCustom } from "../../../components/commons/Button";
import { FormattedMessage, useIntl } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  deleteManagement, deleteMutiManagement,
  getDetailManagement, getListManagement,
  setOpenModalAddEdit,
  setParams,
  setQsValue,
  setTypeModal
} from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { managementSelector } from "../../managementAgency/selector";
import { useRolesByRouter } from "../../../helper/cusHooks";
import ModalConfirm from "../../../components/commons/modalConfirm";
import { Dropdown, MenuProps, Row, Tooltip } from "antd";
import CTable from "../../../components/commons/Table";
import { ColumnsType } from "antd/es/table";
import { StyledTable, WrapperActionTable } from "../../managementAgency/page/style";
import { ReactComponent as IconMore } from "../../../assets/imgs/IconMore.svg";
import AddEditForm from "../../managementAgency/components/AddEditForm";
import { ManagementObjectType } from "../type";

const Grid = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const dispatch = useDispatch();
  const intl = useIntl();
  const listRoles = useRolesByRouter();
  const { listManagement, loadingTable, params, qsValue } = useSelector(managementSelector);
  const { showModalAddEdit } = useSelector(managementSelector);

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        q: value,
        page: 0,
      })
    );
  };
  const handleAddManagement = () => {
    handleSetTypeModal('ADD');
    dispatch(setOpenModalAddEdit(true));
  };
  const handleView = (itemEdit: any) => {
    handleSetTypeModal('VIEW');
    dispatch(getDetailManagement(itemEdit?.id));
  };
  const handleEdit = (itemEdit: any) => {
    handleSetTypeModal('EDIT');
    dispatch(getDetailManagement(itemEdit?.id));
  };
  const handleDelete = (itemEdit: any) => {
    if (itemEdit?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteManagement([itemEdit?.id]));
          setSelectedRowKeys([])
        },
      });
    }
  };
  const handleDeleteMuti = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteMutiManagement(selectedRowKeys));
        setSelectedRowKeys([]);
      },
    });
  };
  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };
  const setValueQS = (value: string) => {
    dispatch(setQsValue(value));
  };
  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterManagement')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperManagement')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 190;
  };
  const handleRefresh = () => {
    dispatch(
      setParams({
        page: 0,
        size: 10,
        q: '',
      })
    );
    setValueQS('');
  };
  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };
  const renderMenuItemsMore = (record: ManagementObjectType): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => handleEdit(record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDelete(record)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRoles, item?.key));
  };

  useEffect(() => {
    dispatch(getListManagement(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, showModalAddEdit]);

  const columns: ColumnsType<ManagementObjectType> = [
    {
      title: 'STT',
      align: 'left',
      width: 60,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'managementAgency.unitName' }),
      dataIndex: 'name',
      align: 'left',
      width: 180,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'managementAgency.address' }),
      dataIndex: 'address',
      align: 'left',
      width: 180,
      className: 'set-height',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'managementAgency.representative' }),
      dataIndex: 'representative',
      align: 'left',
      width: 120,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'managementAgency.phone' }),
      dataIndex: 'phoneNumber',
      align: 'left',
      width: 120,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'managementAgency.email' }),
      dataIndex: 'email',
      align: 'left',
      width: 140,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      width: 140,
      fixed: 'right',
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleView(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
              <IconMore className="iconMore" />
            </Dropdown>
          </WrapperActionTable>
        );
      },
    },
  ];
  return (
    <div>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'managementAgency.searchForm' })}
            onSearch={handleQuickSearch}
            value={qsValue}
            setValue={setValueQS}
          />
          <CReloadButton onClick={handleRefresh} />
        </WrapperButton>
        <WrapperButton>
          {includes(listRoles, actionsType.DELETE) && (
            <CButton
              typeCustom={TypeCustom.Secondary}
              sizeCustom={TypeSizeCustom.Medium}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMuti}
            >
              <FormattedMessage id="common.delete" />
            </CButton>
          )}
          {includes(listRoles, actionsType.CREATE) && (
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddManagement}>
              <FormattedMessage id="common.add" />
            </CButton>
          )}
        </WrapperButton>
      </RowHeader>
      <Row>
        <StyledTable>
          <CTable
            rowSelection={rowSelection}
            loading={loadingTable}
            columns={columns}
            rowKey={'id'}
            scroll={{ y: changeHeightTable() }}
            dataSource={listManagement?.content}
            style={{ width: '100%' }}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30],
              current: params.page + 1,
              pageSize: params.size,
              total: listManagement?.totalElements,
              size: 'small',
              showTotal: TotalMessage,
              showQuickJumper: true,
              locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
            }}
            onChange={handleChangeTable}
          />
        </StyledTable>
      </Row>
      {showModalAddEdit && <AddEditForm />}
    </div>
  );
};
export const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};
export default Grid;
