import { RouterConfig } from '../../router/AppRoute';
import paths from '../../helper/pathRoutes';
import StudentPage from './page';

const routes: RouterConfig[] = [
  {
    path: paths.student,
    page: StudentPage,
  },
];

export default routes;
