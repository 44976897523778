import { InfoWindow } from '@react-google-maps/api';
import { RecordsItem } from 'modules/mapMonitor/api/types';
import { Body, Wrapper, Content } from '../style';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faRoad } from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';
import { TimeContent, TimeWrapper, Title } from './style';
interface PropsType {
  value: RecordsItem;
}

const ModalCar = (props: PropsType) => {
  const { value } = props;
  const intl = useIntl();

  return (
    <InfoWindow
      position={{
        lat: value.gpsData?.coordinate[1],
        lng: value.gpsData?.coordinate[0],
      }}
      options={{
        pixelOffset: new window.google.maps.Size(0, -36),
      }}
    >
      <Wrapper>
        <Body>
          <Content>{`{${value.licensePlate}}_{${value.courseCode}}_${moment(value.startTime).format('DD/MM/YYYY H:mm')}`}</Content>
          <Content>
            <span>{intl.formatMessage({ id: 'mapMonitor.teacher' })}</span>
            {`${value.instructorName}_${value.instructorCitizenId}`}
          </Content>
          <Content>
            <span>{intl.formatMessage({ id: 'mapMonitor.studentName' })}</span>
            {`${value.studentName}_${value.studentCitizenId}`}
          </Content>
          <TimeContent>
            <Title>{intl.formatMessage({ id: 'liveMonitor.accumulated' })}</Title>
            <TimeWrapper>
              <div>
                <FontAwesomeIcon icon={faClock} size="lg" />
                <div>{moment.utc(value.accumulatedTime).format('H[h] mm[m]')}</div>
              </div>
              <div>
                <FontAwesomeIcon icon={faRoad} size="lg" />
                <div>{numeral(value.accumulatedDistance / 1000).format('0.00')}km</div>
              </div>
            </TimeWrapper>
          </TimeContent>
          <img
            style={{ width: '345px', height: '250px', marginTop: '6px' }}
            src="https://fms.mkvision.com/upload/picture/20230510/816123676197003264.jpg"
            alt=""
          />
        </Body>
      </Wrapper>
    </InfoWindow>
  );
};

export default ModalCar;
