import { Dropdown, MenuProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  dataTableSelector,
  loadingTableSelector,
  paramsSelector,
  selectedRowKeysSelector,
  totalElementSelector,
  typeModalSelector,
} from '../selector';
import { WrapperActionTable } from 'modules/role/page/style';
import { ContentItem, ItemEdit, RolesItem, UserItem, roleItem } from 'modules/userGroupManager/api/types';
import {
  deleteListItem,
  pushListRoleStatus0,
  pushListUserNameStatus0,
  resetSearch,
  setSelectedRowKeys,
  setTypeModal,
  updateItemEdit,
  updateSearch,
} from '../redux';
import { useEffectOnce } from 'react-use';
import ModalAddEditView from './ModalAddEditView';
import { ActionType } from 'helper/constants';
import { useRolesByRouter } from 'helper/cusHooks';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import CTable from 'components/commons/Table';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import ModalConfirm from 'components/commons/modalConfirm';
import CButton, { TypeCustom } from 'components/commons/Button';
import { TotalMessage } from 'modules/role/components/Grid';
import { Text } from 'components/commons/Template/style';
import moment from 'moment';

const Body = () => {
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const [heightTable, setHeightTable] = useState(0);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    dispatch(setSelectedRowKeys(newSelectedRowKeys));
  };

  const intl = useIntl();
  const typeModal = useSelector(typeModalSelector);
  const dispatch = useDispatch();
  const params = useSelector(paramsSelector);
  const loadingTable = useSelector(loadingTableSelector);
  const dataTable = useSelector(dataTableSelector);
  const totalElement = useSelector(totalElementSelector);
  const listRole = useRolesByRouter();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderMenuItemsMore = (record: ContentItem): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => openModalEditView(ActionType.EDIT, record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteItem(record.id)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRole, item?.key));
  };

  const columns: ColumnsType<ContentItem> = [
    {
      title: 'STT',
      align: 'left',
      fixed: 'left',
      width: 80,
      render(value, record, index) {
        return <Text disabled={record?.status ? false : true}>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'userGroupManager.groupCode' }),
      dataIndex: 'groupCode',
      width: 150,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'userGroupManager.groupName' }),
      dataIndex: 'groupName',

      width: 200,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'userGroupManager.roles' }),
      dataIndex: 'roles',
      width: 200,
      align: 'left',
      render: (value: RolesItem[], record) => {
        const text = value
          .filter(item => item.status !== 0)
          .map(item => item.roleName)
          ?.join(', ');
        return (
          <Tooltip title={text} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{text}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.creator' }),
      dataIndex: 'createdBy',
      width: 150,
      align: 'left',
      render: (value, record) => {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.creationDate' }),
      dataIndex: 'createdDate',

      width: 120,
      align: 'left',
      render: (value, record) => {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.updater' }),
      dataIndex: 'lastModifiedBy',
      width: 150,
      align: 'left',
      render: (value, record) => {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.updatedDate' }),
      dataIndex: 'lastModifiedDate',
      width: 130,
      align: 'left',
      render: (value, record) => {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'role.statusName' }),
      dataIndex: 'status',

      width: 150,
      align: 'left',
      render: value => {
        if (value === 1) {
          return (
            <Text type="success">
              <FormattedMessage id="common.active" />
            </Text>
          );
        } else
          return (
            <Text disabled>
              <FormattedMessage id="common.inactive" />
            </Text>
          );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      width: 150,
      fixed: 'right',
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRole, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => openModalEditView(ActionType.VIEW, record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            {(includes(listRole, actionsType.UPDATE) || includes(listRole, actionsType.DELETE)) && (
              <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
                <IconMore className="iconMore" />
              </Dropdown>
            )}
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleDeleteItem = (id: string) => {
    if (id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteListItem({ id: [id] }));
        },
      });
    }
  };

  const openModalEditView = (type: ActionType, record: ContentItem) => {
    dispatch(setTypeModal(type));
    let listRole: string[] = [];
    let listOptionRole: roleItem[] = [];
    record.roles.forEach(item => {
      listRole.push(item.roleId);
      if (item.status === 0) {
        listOptionRole.push(item);
      }
    });

    let listUser: string[] = [];
    let listOptionUser: UserItem[] = [];
    record.user.forEach(item => {
      listUser.push(item.userId);
      if (item.status === 0) {
        listOptionUser.push(item);
      }
    });
    dispatch(pushListRoleStatus0(listOptionRole));
    dispatch(pushListUserNameStatus0(listOptionUser));
    const item: ItemEdit = { ...record, roles: listRole, user: listUser };
    dispatch(updateItemEdit(item));
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    dispatch(
      updateSearch({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  useEffectOnce(() => {
    return () => {
      dispatch(resetSearch());
    };
  });

  const changeHeightTable = () => {
    const heightWrapper = document.getElementById('wrapperUserGroup')?.offsetHeight;
    setHeightTable((heightWrapper ?? 0) - 205);
  };
  useEffect(() => {
    setTimeout(changeHeightTable, 500);
  }, []);

  useEffect(() => {
    dispatch(
      updateSearch({
        ...params,
      })
    );
  }, [typeModal]);
  return (
    <div>
      <CTable
        rowSelection={rowSelection}
        loading={loadingTable}
        columns={columns}
        rowKey={'id'}
        dataSource={dataTable}
        scroll={{ y: heightTable }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: [10, 20, 30],
          current: params.page + 1,
          pageSize: params.size,
          total: totalElement,
          showTotal: TotalMessage,
          locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
        }}
        onChange={handleChangeTable}
      />

      {typeModal !== null && <ModalAddEditView />}
    </div>
  );
};

export default Body;
