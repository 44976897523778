import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getListReportByPr,
  getListCourseApi,
  getDetailReportApi,
  exportReportApi,
  getListTrainingFacility,
  getDrivingLicense,
} from '../service';
import {
  getListReportItem,
  getListReportItemFail,
  getListReportItemSuccess,
  exportReport,
  exportReportFail,
  exportReportSuccess,
  getListTF,
  getListTFFail,
  getListTFSuccess,
  getDetail,
  getDetailFail,
  getDetailSuccess,
  getListCourse,
  getListCourseFail,
  getListCourseSuccess,
  setParams,
  startGetListLicense,
  getListLicenseSuccess,
} from '../redux';
import { NotificationError } from 'components/notifications';
import moment from 'moment';
import { managementBaseSelector } from 'modules/auth/selectors';
import { ManagementBaseType } from 'modules/auth/types';
import { UnitType } from 'helper/constants';
import { paramsSelector } from '../selector';
import { LicenseType } from '../type';

function* getListReportItemByParams(action: ReturnType<typeof getListReportItem>): any {
  try {
    const res = yield getListReportByPr(action.payload);
    yield put(getListReportItemSuccess(res));
  } catch (e: any) {
    yield put(getListReportItemFail());
    NotificationError(e);
  }
}

function* handleGetDetail(action: ReturnType<typeof getDetail>): any {
  try {
    const res = yield getDetailReportApi(action.payload);
    yield put(
      getDetailSuccess({
        ...res,
        id: action.payload.id,
      })
    );
  } catch (e: any) {
    yield put(getDetailFail());
    NotificationError(e);
  }
}

function* handleExportListReport(action: ReturnType<typeof exportReport>): any {
  try {
    const res = yield exportReportApi(action.payload);
    const blob = new Blob([res], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = `Bao_cao_ket_qua_thuc_hanh_lai_xe_cua_khoa_hoc_${moment().format('YYYYMMDD')}`;
    a.target = '_self';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
    yield put(exportReportSuccess());
  } catch (e: any) {
    yield put(exportReportFail());
    NotificationError(e);
  }
}

function* handleGetListCourse(action: ReturnType<typeof getListCourse>): any {
  try {
    const res = yield getListCourseApi(action.payload);
    yield put(getListCourseSuccess(res));
  } catch (e: any) {
    yield put(getListCourseFail());
    NotificationError(e);
  }
}

function* handleGetListTF(): any {
  try {
    const { clientType }: ManagementBaseType = yield select(managementBaseSelector);
    const res = yield getListTrainingFacility({
      clientType: clientType,
    });
    yield put(getListTFSuccess(res));
    if (clientType === UnitType.CSDT) {
      const params = yield select(paramsSelector);
      yield put(setParams({ ...params, clientId: res?.[0]?.id }));
    }
  } catch (e: any) {
    yield put(getListTFFail());
    NotificationError(e);
  }
}

function* getListLicense() {
  try {
    const response: LicenseType[] = yield getDrivingLicense();
    yield put(getListLicenseSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}

export default function* practiceReportSaga(): any {
  return all([
    yield takeLatest(getListReportItem, getListReportItemByParams),
    yield takeLatest(exportReport, handleExportListReport),
    yield takeLatest(getListTF, handleGetListTF),
    yield takeLatest(getDetail, handleGetDetail),
    yield takeLatest(getListCourse, handleGetListCourse),
    yield takeLatest(startGetListLicense, getListLicense),
  ]);
}
