import HttpClient from '../../index';
import { SERVER_API_URL } from 'AppConfig';
export default class HttpAuth extends HttpClient {
  private static classInstance?: HttpAuth;
  private constructor() {
    super(SERVER_API_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpAuth();
    }

    return this.classInstance;
  }
}
