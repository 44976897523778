import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonRegister, ErrorDetail, LabelOTP, TitleOtp, WrapperButton } from 'modules/auth/pages/ModalLogin/styles';
import InputOtp from '../InputOtp';
import { fieldErrorsItem } from 'modules/auth/redux/type';
import Modal from '../Modal';
import TimeCountDown from 'modules/auth/pages/components/ModalClient/TimeCountDown';
import Button, { TypeCustom, TypeSizeCustom } from '../Button';
interface PropsModal {
  open: boolean;
  onClose: any;
  otp: string;
  setOtp: any;
  onFinish: any;
  email: string;
  fieldErrors?: fieldErrorsItem[];
  sendOTPagain?: any;
}

const ModalConfirmOTP = (props: PropsModal) => {
  const { onFinish, open, otp, onClose, setOtp, email, fieldErrors, sendOTPagain } = props;

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && otp.length === 6) {
      onFinish && onFinish();
    }
    if (e.key === '+' || e.key === '-') {
      return e.preventDefault();
    }
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <TitleOtp>
        <FormattedMessage id="auth.titleOtp" />
      </TitleOtp>
      <LabelOTP>
        <FormattedMessage id="auth.labelOtp" /> {email?.replace(/(.{2})(.*)(?=@)/, '$1***')}
      </LabelOTP>
      <LabelOTP>
        <FormattedMessage id="auth.timeCount" /> <TimeCountDown />
      </LabelOTP>
      <InputOtp
        renderInput={propsItem => <input {...propsItem} placeholder="-" onKeyDown={handleKeyDown} type="number" />}
        onChange={function (otp: string): void {
          setOtp(otp);
        }}
        value={otp}
      />
      {fieldErrors && fieldErrors?.length > 0 && <ErrorDetail>{fieldErrors[0].message}</ErrorDetail>}
      <LabelOTP>
        <FormattedMessage id="auth.checkCode" />
        &nbsp;
        <ButtonRegister onClick={sendOTPagain}>
          <FormattedMessage id="auth.sendOtpAgain" />
        </ButtonRegister>
      </LabelOTP>

      <WrapperButton>
        <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={onClose}>
          <FormattedMessage id="common.back" />
        </Button>
        <Button
          sizeCustom={TypeSizeCustom.Medium}
          typeCustom={TypeCustom.Primary}
          onClick={onFinish}
          disabledCustom={otp.length === 6 ? false : true}
        >
          <FormattedMessage id="common.continue" />
        </Button>
      </WrapperButton>
    </Modal>
  );
};

export default ModalConfirmOTP;
