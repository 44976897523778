import { all, put, select, takeLatest } from 'redux-saga/effects';
import { NotificationError, NotificationSuccess } from '../../../components/notifications';
import {
  createStudentApi,
  deleteStudentApi,
  getDetailStudentApi,
  getListCoursesApi,
  getListLicenseApi,
  getListStudentByParams,
  updateStudentApi,
} from '../service';
import {
  createStudent,
  createStudentFail,
  createStudentSuccess,
  deleteStudent,
  deleteStudentFail,
  deleteStudentSuccess,
  getDetailStudent,
  getDetailStudentFail,
  getDetailStudentSuccess,
  getListCourses,
  getListCoursesFail,
  getListCoursesSuccess,
  getListLicense,
  getListLicenseFail,
  getListLicenseSuccess,
  getListStudent,
  getListStudentFail,
  getListStudentSuccess,
  setImgUrl,
  updateStudent,
  updateStudentFail,
  updateStudentSuccess
} from '../redux';
import { paramsSelector } from '../selector';

function* handleGetListStudent(action: ReturnType<typeof getListStudent>): any {
  try {
    const res = yield getListStudentByParams(action?.payload);
    yield put(getListStudentSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListStudentFail());
  }
}

function* handleCreateStudent(action: ReturnType<typeof createStudent>): any {
  try {
    yield createStudentApi(action?.payload);
    yield put(createStudentSuccess());
    const params = yield select(paramsSelector);
    yield put(getListStudent(params));
    NotificationSuccess('accountManager.addSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(createStudentFail());
  }
}

function* handleUpdateStudent(action: ReturnType<typeof updateStudent>): any {
  try {
    yield updateStudentApi(action?.payload);
    yield put(updateStudentSuccess());
    const params = yield select(paramsSelector);
    yield put(getListStudent(params));
    NotificationSuccess('accountManager.updateSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(updateStudentFail());
  }
}

function* handleGetDetailStudent(action: ReturnType<typeof getDetailStudent>): any {
  try {
    const res = yield getDetailStudentApi(action.payload);
    yield put(getDetailStudentSuccess(res));
    if (res.faceImageUrl) {
      yield put(setImgUrl(res.faceImageUrl));
    }
  } catch (e: any) {
    NotificationError(e);
    yield put(getDetailStudentFail());
  }
}

function* handleDeleteStudent(action: ReturnType<typeof deleteStudent>): any {
  try {
    yield deleteStudentApi(action.payload);
    yield put(deleteStudentSuccess());
    NotificationSuccess('accountManager.deleteSuccess');
    const params = yield select(paramsSelector);
    yield put(getListStudent(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteStudentFail());
  }
}

function* handleGetListCourses(action: ReturnType<typeof getListCourses>): any {
  try {
    const res = yield getListCoursesApi();
    yield put(getListCoursesSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListCoursesFail());
  }
}

function* handleGetListLicense(action: ReturnType<typeof getListLicense>): any {
  try {
    const res = yield getListLicenseApi();
    yield put(getListLicenseSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListLicenseFail());
  }
}

export default function* studentManagerSaga(): any {
  return all([
    yield takeLatest(getListStudent, handleGetListStudent),
    yield takeLatest(getDetailStudent, handleGetDetailStudent),
    yield takeLatest(createStudent, handleCreateStudent),
    yield takeLatest(updateStudent, handleUpdateStudent),
    yield takeLatest(deleteStudent, handleDeleteStudent),
    yield takeLatest(getListCourses, handleGetListCourses),
    yield takeLatest(getListLicense, handleGetListLicense)
  ]);
}
