import { Marker } from '@react-google-maps/api';
import { fleetViewDataSelector, indexActiveCarSelector } from 'modules/mapMonitor/selector';
import { RecordsItem } from 'modules/mapMonitor/api/types';
import { useDispatch, useSelector } from 'react-redux';
import { setIndexActiveCar } from 'modules/mapMonitor/redux';
import ModalCar from './ModalCar';

const bulecar = 'https://data.waylens.com/9f713b2bc66f16a4cc741fd5a92b00b7.svg';
const greencar = 'https://data.waylens.com/d602e1e6c7b61706942990ff1dd6a7be.svg';

const MarkerMap = () => {
  const dispatch = useDispatch();
  const listItem: RecordsItem[] = useSelector(fleetViewDataSelector);
  const indexActiveCar: number = useSelector(indexActiveCarSelector);

  const handleMouseOver = (indexActive: number) => {
    dispatch(setIndexActiveCar(indexActive));
  };

  const handleMouseOut = () => {
    dispatch(setIndexActiveCar(-1));
  };

  return (
    <>
      {listItem.map((value: RecordsItem, index: number) => {
        if (!value.gpsData) return null;
        return (
          <Marker
            icon={value.mode !== 'parking' ? bulecar : greencar}
            position={{
              lat: value.gpsData.coordinate[1],
              lng: value.gpsData.coordinate[0],
            }}
            label={{ className: 'map-marker--label', text: value.licensePlate }}
            onMouseOver={() => handleMouseOver(index)}
            onMouseOut={handleMouseOut}
            key={index}
          >
            {index === indexActiveCar && <ModalCar value={value} />}
          </Marker>
        );
      })}
    </>
  );
};

export default MarkerMap;
