import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleReportSelector } from '../selector';
import { Col, Form, Row } from 'antd';
import CSelect from '../../../components/commons/Select';
import CButton, { TypeCustom, TypeSizeCustom } from '../../../components/commons/Button';
import { StyledFilter } from '../page/styles';
import { useEffectOnce } from 'react-use';
import {
  getListLicensePlate,
  getListTF,
  getListVehicle, getListVehicleSuccess,
  resetListLicensePlate,
  setEndDateDetail,
  setParams,
  setStartDateDetail,
} from '../redux';
import { DefaultOptionType } from 'rc-select/lib/Select';
import CInput from '../../../components/commons/Input';
import { RangePickerProps } from 'antd/es/date-picker';
import { managementBaseSelector } from '../../auth/selectors';
import { UnitType } from '../../../helper/constants';
import { ParamsType, TypeTF } from '../../studentReport/type';
import { orderBy } from 'lodash';
import { CRangePicker } from '../../../components/commons/DatePicker';
import moment from 'moment';
import Cascader from 'components/commons/Cascader';
import { DefaultPage } from '../../../types';
import { ParamsDefault } from '../type';

const SearchForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { listTF, listLicensePlate, params } = useSelector(vehicleReportSelector);
  const [listOptionTF, setListOptionTF] = useState<TypeTF[]>([]);
  const managementBase = useSelector(managementBaseSelector);
  const [disable, setDisable] = useState<boolean>(managementBase?.clientType !== UnitType.CSDT);
  const [form] = Form.useForm();

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    return current > moment().endOf('day');
  };

  const onFinish = (value: any) => {
    const paramSearch = {
      ...params,
      ...value,
      clientId: value.trainingFacility?.at(-1),
      startTime: value.time[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endTime: value.time[1].endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    };
    dispatch(setParams(paramSearch));
  };

  const handleBtnReset = () => {
    form.resetFields();
    dispatch(setParams(ParamsDefault));
    setDisable(managementBase?.clientType !== UnitType.CSDT);
    dispatch(getListVehicleSuccess(DefaultPage));
  }

  const getListTrainingFacilities = () => {
    dispatch(getListTF());
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.name as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const onChangeTrainingFacility = (value: any) => {
    form.resetFields(['licensePlate']);
    if (value) {
      setDisable(false);
      dispatch(
        getListLicensePlate({
          clientId: value.at(-1),
        })
      );
    } else {
      setDisable(true);
      dispatch(resetListLicensePlate());
    }
  };

  useEffectOnce(() => {
    getListTrainingFacilities();
  });

  useEffectOnce(() => {
    return () => {
      handleBtnReset();
    };
  });

  const getListReport = (params: ParamsType) => {
    dispatch(
      getListVehicle({
        ...params,
        clientId: managementBase?.clientType === UnitType.CSDT ? listTF?.[0]?.id : params.clientId,
      })
    );
  };

  useEffect(() => {
    if (!params.clientId) return;
    getListReport(params);
    dispatch(setStartDateDetail(moment(params.startTime).startOf('day').format('YYYY-MM-DDTHH:mm:ss')));
    dispatch(setEndDateDetail(moment(params.endTime).endOf('day').format('YYYY-MM-DDTHH:mm:ss')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (managementBase?.clientType === UnitType.CSDT && listTF?.length > 0) {
      setListOptionTF([
        {
          ...listTF?.[0],
          disabled: false,
        },
      ]);
      form.resetFields();
      dispatch(
        getListLicensePlate({
          clientId: listTF?.[0]?.id,
        })
      );
      getListReport(params);
      dispatch(setStartDateDetail(moment(params.startTime).startOf('day').format('YYYY-MM-DDTHH:mm:ss')));
      dispatch(setEndDateDetail(moment(params.endTime).endOf('day').format('YYYY-MM-DDTHH:mm:ss')));
      return;
    }
    setListOptionTF(orderBy(listTF, ['name'], ['asc']));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTF]);

  return (
    <StyledFilter id="filterReportManager">
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left" onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
            <Form.Item
              label={intl.formatMessage({ id: 'vehicleReport.trainingFacility' })}
              name="trainingFacility"
              initialValue={managementBase?.clientType === UnitType.CSDT ? [listTF?.[0]?.id] : []}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'vehicleReport.trainingFacility' }) + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
            >
              <Cascader
                showSearch={{ filter }}
                allowClear={false}
                options={listOptionTF}
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
                disabled={managementBase?.clientType === UnitType.CSDT}
                onChange={onChangeTrainingFacility}
                placeholder={intl.formatMessage({ id: 'vehicleReport.placeholderTrainingFacility' })}
                id="vehicleReportCascader"
                dropdownMenuColumnStyle={{
                  maxWidth: document.getElementById('vehicleReportCascader')?.offsetWidth || 300,
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
            <Form.Item
              label={intl.formatMessage({ id: 'vehicleReport.time' })}
              name="time"
              initialValue={[moment().subtract(3, 'months'), moment()]}
            >
              <CRangePicker format={'DD/MM/YYYY'} allowClear={true} disabledDate={disabledDate} disabled={disable} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
            <Form.Item label={intl.formatMessage({ id: 'vehicleReport.licensePlate' })} name="licensePlate">
              <CSelect
                options={listLicensePlate}
                allowClear={true}
                disabled={disable}
                placeholder={intl.formatMessage({ id: 'vehicleReport.placeholderLicensePlate' })}
                showSearch
                filterOption={(input, options: any) => (options?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
            <Form.Item label={intl.formatMessage({ id: 'vehicleReport.vehicleClass' })} name="classification">
              <CInput
                disabled={disable}
                maxLength={255}
                placeholder={intl.formatMessage({ id: 'vehicleReport.placeholderVehicleClass' })}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16} className="wrap-buttons">
            <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} htmlType="submit">
              {intl.formatMessage({ id: 'common.search' })}
            </CButton>
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnReset}>
              {intl.formatMessage({ id: 'common.removeCondition' })}
            </CButton>
          </Col>
        </Row>
      </Form>
    </StyledFilter>
  );
};
export default SearchForm;
