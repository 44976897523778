import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataService } from '../type';
import { TypeSyncing } from 'helper/constants';

export interface HeaderState {
  loadingModal: boolean;
  data: DataService;
  typeSync: TypeSyncing;
  isNotifyService: boolean;
}

const initialState: HeaderState = {
  loadingModal: false,
  data: {
    endDate: '',
    packageName: '',
    serviceName: '',
    startDate: '',
  },
  typeSync: TypeSyncing.NO_DATA,
  isNotifyService: true,
};

const headerSlice = createSlice({
  name: 'headerSlice',
  initialState,
  reducers: {

    getSubscribers: state => {
      state.loadingModal = true;
    },
    getSubscribersOk: (state, action: PayloadAction<DataService>) => {
      state.data = action.payload;
      state.loadingModal = false;
    },
    getSubscribersFail: state => {
      state.loadingModal = false;
    },

    setTypeSync: (state, action: PayloadAction<TypeSyncing>) => {
      state.typeSync = action.payload;
    },

    setIsNotifyService: (state, action: PayloadAction<boolean>) => {
      state.isNotifyService = action.payload;
    },
  },
});

export const {
  getSubscribers,
  getSubscribersFail,
  getSubscribersOk,
  setTypeSync,
  setIsNotifyService,
} = headerSlice.actions;

export default headerSlice.reducer;
