import React from 'react';
import Grid from '../components/Grid';
import { Wrapper } from './style';
import { FormattedMessage } from 'react-intl';
import { TitleHeader } from 'components/commons/Template/style';

const RolePage = () => {
  return (
    <Wrapper id="wrapperRolesManager">
      <TitleHeader id="filterRolesManager">
        <FormattedMessage id="role.roleAndDelegation" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default RolePage;
