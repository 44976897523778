import { useDispatch, useSelector } from 'react-redux';
import { updateSearch } from '../../../redux';
import { paramsSelector, totalElementsSelector } from 'modules/mapMonitor/selector';
import Pagination from 'antd/lib/pagination';
import { TotalMessage } from 'components/commons/Pagination';
import { FooteBody, Footer } from './style';

function PaginationFleetView() {
  const dispatch = useDispatch();
  const { page, size } = useSelector(paramsSelector);
  const totalElements: number = useSelector(totalElementsSelector);

  const handleChangePagination = (page: number, pageSize: number) => {
    dispatch(
      updateSearch({
        page: page - 1,
        size: pageSize,
      })
    );
  };

  return (
    <Footer>
      <FooteBody>
        <Pagination
          current={page + 1}
          pageSize={size}
          onChange={handleChangePagination}
          size="small"
          total={totalElements}
          showTotal={TotalMessage}
        />
      </FooteBody>
    </Footer>
  );
}

export default PaginationFleetView;
