import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  deleteListItem,
  getListFilterGroup,
  getListUserGroup,
  setTypeModal,
  setValueSearch,
  startGetListUserName,
  updateSearch,
} from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'helper/constants';
import { selectedRowKeysSelector, valueSearchSelector } from '../selector';
import { useRolesByRouter } from 'helper/cusHooks';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { RowHeader, TitleHeader, WrapperButton } from 'components/commons/Template/style';
import Button, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CustomSearch from 'components/commons/Search';
import ModalConfirm from 'components/commons/modalConfirm';
import { useEffectOnce } from 'react-use';
import { CReloadButton } from 'components/commons/ReloadButton';

function Header() {
  const dispatch = useDispatch();
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const valueSearch = useSelector(valueSearchSelector);
  const listRole = useRolesByRouter();
  const intl = useIntl();
  const handleAddRole = () => {
    dispatch(setTypeModal(ActionType.ADD));
  };

  useEffectOnce(() => {
    dispatch(startGetListUserName());
    dispatch(getListFilterGroup());
    dispatch(getListUserGroup());
  });

  const handleDeleteMuti = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteListItem({ id: selectedRowKeys as string[] }));
      },
    });
  };

  const handleSearch = (values: string) => {
    dispatch(
      updateSearch({
        q: values,
        page: 0,
      })
    );
  };

  const handleRefresh = () => {
    dispatch(
      updateSearch({
        page: 0,
        size: 10,
        q: '',
      })
    );
    dispatch(setValueSearch(''));
  };

  const setValueQS = (value: string) => {
    dispatch(setValueSearch(value));
  };

  return (
    <>
      <TitleHeader>
        <FormattedMessage id="layout.leftMenu.manageAccountGroups" />
      </TitleHeader>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'userGroupManager.tooltipQS' })}
            onSearch={handleSearch}
            value={valueSearch}
            setValue={setValueQS}
          />
          <CReloadButton onClick={handleRefresh} />
        </WrapperButton>
        <WrapperButton>
          {includes(listRole, actionsType.DELETE) && (
            <Button
              sizeCustom={TypeSizeCustom.Medium}
              typeCustom={TypeCustom.Secondary}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMuti}
            >
              <FormattedMessage id="common.delete" />
            </Button>
          )}
          {includes(listRole, actionsType.CREATE) && (
            <Button sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} icon={<PlusOutlined />} onClick={handleAddRole}>
              <FormattedMessage id="common.add" />
            </Button>
          )}
        </WrapperButton>
      </RowHeader>
    </>
  );
}

export default Header;
