import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CreateUpdateTC, FacilityGridItem, ParamsDetailTC, ParamsTrainingFc, TrainingFacilityItem } from '../type';
import { StatusTrainingType } from 'helper/constants';
import { DefaultPage, Page } from 'types';
import { CsdtType } from 'modules/auth/types';

export interface TrainingManagerState {
  params: ParamsTrainingFc;
  listTrainFacility: Page<FacilityGridItem>;
  loadingTable: boolean;
  loadingBtnModal: boolean;
  showModal: boolean;
  loadingModal: boolean;
  itemData: TrainingFacilityItem | null;
  typeModal: string;
  qsValue: string;
  listAgency: CsdtType[];
}

const initialState: TrainingManagerState = {
  params: {
    page: 0,
    size: 10,
    q: '',
    status: StatusTrainingType.ALL,
    clientId: '',
  },
  listTrainFacility: DefaultPage,
  loadingTable: false,
  loadingBtnModal: false,
  showModal: false,
  loadingModal: false,
  itemData: {
    clientId: '',
    clientName: '',
    address: '',
    representative: '',
    foundedYear: null,
    email: '',
    phoneNumber: '',
    accessKeyId: '',
    accessKeySecret: '',
    signInEmail: '',
    status: 0,
    clientCode: '',
    clientType: 0,
    parentId: '',
  },
  typeModal: '',
  qsValue: '',
  listAgency: [],
};

const trainingFacilityManagerSlice = createSlice({
  name: 'trainingFacilityManagerSlice',
  initialState,
  reducers: {
    getListTC: (state, action: PayloadAction<ParamsTrainingFc>) => {
      state.loadingTable = true;
    },
    getListTCSuccess: (state, action: PayloadAction<Page<FacilityGridItem>>) => {
      state.loadingTable = false;
      state.listTrainFacility = action.payload;
    },
    getListTCFail: state => {
      state.loadingTable = false;
    },

    setParams: (state, action: PayloadAction<ParamsTrainingFc>) => {
      state.params = action.payload;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },

    createTC: (state, action: PayloadAction<CreateUpdateTC>) => {
      state.loadingBtnModal = true;
    },

    createTCSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },

    createTCFail: state => {
      state.loadingBtnModal = false;
    },

    getDetailTC: (state, action: PayloadAction<ParamsDetailTC>) => {
      state.loadingBtnModal = true;
      state.showModal = true;
      state.loadingModal = true;
    },

    getDetailTCSuccess: (state, action: PayloadAction<TrainingFacilityItem>) => {
      state.loadingBtnModal = false;
      state.itemData = action.payload;
      state.loadingModal = false;
    },

    getDetailTCFail: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
      state.loadingModal = false;
    },

    setDataItem: (state, action: PayloadAction<TrainingFacilityItem | null>) => {
      state.itemData = action.payload;
    },
    updateTC: (state, action: PayloadAction<CreateUpdateTC>) => {
      state.loadingBtnModal = true;
    },
    updateTCSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },
    updateTCFail: state => {
      state.loadingBtnModal = false;
    },
    deleteTC: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteTCSuccess: state => {
      state.loadingTable = false;
    },
    deleteTCFail: state => {
      state.loadingTable = false;
    },
    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
    getListAgency: state => {},
    getListAgencySuccess: (state, action: PayloadAction<CsdtType[]>) => {
      state.listAgency = action.payload;
    },
  },
});

export const {
  createTC,
  createTCFail,
  createTCSuccess,
  getListTC,
  getListTCFail,
  getListTCSuccess,
  setParams,
  setShowModal,
  getDetailTC,
  getDetailTCFail,
  getDetailTCSuccess,
  setDataItem,
  updateTC,
  updateTCFail,
  updateTCSuccess,
  deleteTC,
  deleteTCFail,
  deleteTCSuccess,
  setTypeModal,
  setQsValue,
  getListAgency,
  getListAgencySuccess,
} = trainingFacilityManagerSlice.actions;

export default trainingFacilityManagerSlice.reducer;
