import { createSlice } from '@reduxjs/toolkit';

export interface ThemesType {
  primary: string;
  primaryHover: string;
  menuDefault: string;
  menuDisableDark: string;
  actionActive: string;
  actionActive2: string;
  activeButton: string;
  menuBackground: string;
  contentDropdown13: string;
  contentTable: string;
  contentPlaceholder: string;
  strokeLineLight: string;
  strokeLightDark: string;
  strokeCheckbox: string;
  titleTable: string;
  titleDefault: string;
  buttonInput: string;
  buttonPrimary: string;
  buttonWhite: string;
  buttonOutline: string;
  statusGreen: string;
  statusRed: string;
  statusYellow: string;
  statusBlue: string;
  tableSelectedActive: string;
  tableTitleBackground: string;
}
export const themes: ThemesType = {
  primary: '#004C98',
  primaryHover: '#1b65a6',
  menuDefault: '#666666',
  menuDisableDark: '#8E8E8E',
  actionActive: '#DEBB5C',
  actionActive2: '#FFFFFF',
  activeButton: '#003971',
  menuBackground: '#FFFFFF',
  contentDropdown13: '#565656',
  contentTable: '#292929',
  contentPlaceholder: '#616774',
  strokeLineLight: '#F3F3F4',
  strokeLightDark: '#3E3E40',
  strokeCheckbox: '#D9D9D9',
  titleTable: '#292929',
  titleDefault: '#151515',
  buttonInput: '#F5F5F5',
  buttonPrimary: '#004C98',
  buttonWhite: '#FFFFFF',
  buttonOutline: '#DCDCDC',
  statusGreen: '#128300',
  statusRed: '#FF3743',
  statusYellow: '#FF9900',
  statusBlue: '#1365E1',
  tableSelectedActive: '#F6F6F6',
  tableTitleBackground: '#ECECEC',
};

const initialState: ThemesType = {
  ...themes,
};

const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {},
});

export default themeSlice.reducer;
