import { Col, Form } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldErrorsSelector,
  itemEditSelector,
  listRoleSelector,
  listRoleStatus0Selector,
  listUserSelector,
  listUserStatus0Selector,
  typeModalSelector,
} from '../selector';
import {
  createUserGroupStart,
  deleteListItem,
  editUserGroupStart,
  pushListRoleStatus0,
  pushListUserNameStatus0,
  setTypeModal,
} from '../redux';
import { isEmptyObject } from 'helper/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalConfirm from 'components/commons/modalConfirm';
import { ActionType, RegValidStringEnglish } from 'helper/constants';
import CSwitch from 'components/commons/Switch';
import filter from 'lodash/filter';
import { RowButton } from 'components/commons/Template/style';
import Button, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CInput from 'components/commons/Input';
import Select from 'components/commons/Select';
import CModal from 'components/commons/Modal';
import { TagActive } from 'modules/role/page/style';
import { themes } from 'styles';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { useRolesByRouter } from 'helper/cusHooks';

const ModalAddEditView = () => {
  const intl = useIntl();
  const typeModal = useSelector(typeModalSelector);
  const dispatch = useDispatch();
  const itemEdit = useSelector(itemEditSelector);
  const [form] = Form.useForm();
  const listRole = useSelector(listRoleSelector);
  const listRoleByRouter = useRolesByRouter();
  const listRoleStatus0 = useSelector(listRoleStatus0Selector);
  const listUser = useSelector(listUserSelector);
  const listUserStatus0 = useSelector(listUserStatus0Selector);
  const fieldErrors = useSelector(fieldErrorsSelector);

  const optionsRole = orderBy([...listRole, ...listRoleStatus0], ['status', 'roleCode'], ['desc', 'asc']).map(item => {
    return {
      value: item.roleId,
      label: item.roleCode + '_' + item.roleName + (item.status === 0 ? intl.formatMessage({ id: 'common.optionInactive' }) : ''),
    };
  });
  const optionsUser = orderBy([...listUser, ...listUserStatus0], ['status', 'username'], ['desc', 'asc']).map(item => {
    return {
      value: item.userId,
      label: item.username + (item.status === 0 ? intl.formatMessage({ id: 'common.optionInactive' }) : ''),
    };
  });

  const checkListRoleStatus0 = (value: string[]) => {
    if (listRoleStatus0.length === 0) return;
    const arr = [...listRoleStatus0].filter(item => value.includes(item.roleId));
    dispatch(pushListRoleStatus0(arr));
  };

  const checkListUserStatus0 = (value: string[]) => {
    if (listUserStatus0.length === 0) return;
    const arr = [...listUserStatus0].filter(item => value.includes(item.userId));
    dispatch(pushListUserNameStatus0(arr));
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',
        handleConfirm: () => {
          dispatch(setTypeModal(null));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();
      const isValuesEmpty = isEmptyObject({
        ...values,
        status: false,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setTypeModal(null));
          },
        });
      } else {
        dispatch(setTypeModal(null));
      }
      return;
    }
    dispatch(setTypeModal(null));
  };

  const renderTitle = () => {
    switch (typeModal) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleFinishForm = (values: any) => {
    const data = { ...itemEdit, ...values, status: values.status ? 1 : 0 };
    if (typeModal === ActionType.ADD) {
      dispatch(createUserGroupStart(data));
      return;
    }
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(editUserGroupStart(data));
        },
      });
    }
  };

  const handleClose = () => {
    dispatch(setTypeModal(null));
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    const valueErrGroupCode = filter(fieldErrors, { field: 'groupCode' })[0];
    if (valueErrGroupCode) {
      form.setFields([
        {
          name: 'groupCode',
          errors: [valueErrGroupCode.message],
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors]);

  const handleDeleteItem = (id: string) => {
    if (id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteListItem({ id: [id] }));
        },
      });
    }
  };

  return (
    <CModal open={typeModal !== null} onCancel={handleClose} title={renderTitle()} footer={null}>
      <Form form={form} {...layout} onFinish={handleFinishForm}>
        <Col>
          <Form.Item
            labelAlign="left"
            label={typeModal !== ActionType.VIEW ? intl.formatMessage({ id: 'common.active' }) : ''}
            name="status"
            valuePropName="checked"
            initialValue={itemEdit.status === 1}
          >
            {typeModal === ActionType.VIEW ? (
              <>
                <TagActive clr={itemEdit?.status ? themes.statusGreen : themes.menuDisableDark}>
                  <FormattedMessage id={itemEdit?.status ? 'common.active' : 'common.inactive'} />
                </TagActive>
              </>
            ) : (
              <CSwitch disabled={typeModal !== ActionType.EDIT} />
            )}
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label={intl.formatMessage({ id: 'userGroupManager.groupCodeUser' })}
            name="groupCode"
            initialValue={itemEdit?.groupCode || ''}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'userGroupManager.groupCodeUser' }) + intl.formatMessage({ id: 'validator.require' }),
              },
              {
                validator: (_, value) =>
                  !value || RegValidStringEnglish.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          intl.formatMessage({ id: 'userGroupManager.groupCodeUser' }) +
                            ' ' +
                            intl.formatMessage({ id: 'validator.errFormat' })
                        )
                      ),
              },
            ]}
          >
            <CInput maxLength={20} disabled={typeModal === ActionType.VIEW} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label={intl.formatMessage({ id: 'userGroupManager.groupNameUser' })}
            name="groupName"
            initialValue={itemEdit?.groupName || ''}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'userGroupManager.groupNameUser' }) + intl.formatMessage({ id: 'validator.require' }),
              },
            ]}
          >
            <CInput maxLength={100} disabled={typeModal === ActionType.VIEW} />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label={intl.formatMessage({ id: 'userGroupManager.roles' })}
            name="roles"
            initialValue={itemEdit?.roles || ''}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'userGroupManager.roles' }) + intl.formatMessage({ id: 'validator.require' }),
              },
            ]}
          >
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, optionsRole: any) => (optionsRole?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              disabled={typeModal === ActionType.VIEW}
              options={optionsRole}
              onChange={checkListRoleStatus0}
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label={intl.formatMessage({ id: 'userGroupManager.user' })}
            name="user"
            initialValue={itemEdit?.user || ''}
          >
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, optionsRole: any) => (optionsRole?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              disabled={typeModal === ActionType.VIEW}
              options={optionsUser}
              onChange={checkListUserStatus0}
            />
          </Form.Item>
        </Col>

        <RowButton>
          {typeModal === ActionType.VIEW && includes(listRoleByRouter, actionsType.DELETE) && (
            <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={() => handleDeleteItem(itemEdit.id!)}>
              {intl.formatMessage({ id: 'common.delete' })}
            </Button>
          )}
          {typeModal === ActionType.VIEW && includes(listRoleByRouter, actionsType.UPDATE) && (
            <Button
              typeCustom={TypeCustom.Primary}
              sizeCustom={TypeSizeCustom.Medium}
              onClick={() => dispatch(setTypeModal(ActionType.EDIT))}
            >
              {intl.formatMessage({ id: 'common.edit' })}
            </Button>
          )}
          {typeModal !== ActionType.VIEW && (
            <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
              {intl.formatMessage({ id: 'common.cancel' })}
            </Button>
          )}
          {typeModal !== ActionType.VIEW && (
            <Button typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} htmlType="submit" loading={false}>
              <FormattedMessage id="common.save" />
            </Button>
          )}
        </RowButton>
      </Form>
    </CModal>
  );
};

export default ModalAddEditView;
