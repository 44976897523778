import AppLanguage from './types';

const vi: AppLanguage = {
  common: {
    success: 'Thành công',
    notSuccess: 'Không thành công',
    updateSuccess: 'Cập nhật {value} thành công',
    fail: 'Thất bại',
    or: 'Hoặc',
    password: 'Mật khẩu',
    action: 'Thao tác',
    desc: 'Mô tả',
    filter: 'Lọc',
    search: 'Tìm kiếm',
    refresh: 'Làm mới',
    add: 'Thêm mới',
    addSuccess: 'Thêm mới {value} thành công ',
    delete: 'Xóa',
    deleteSuccess: 'Xóa {value} thành công',
    removeCondition: 'Xóa điều kiện',
    person: 'Cá nhân',
    organization: 'Tổ chức',
    save: 'Lưu',
    cancel: 'Hủy bỏ',
    close: 'Đóng',
    confirmDelete: 'Bạn có chắc chắn muốn Xoá bản ghi không?',
    relatedDataDeleted: 'Các dữ liệu liên quan cũng sẽ bị xoá',
    accept: 'Đồng ý',
    confirmCloseModalAdd: 'Bạn có chắc chắn muốn đóng cửa sổ thêm mới?',
    confirmCloseModalUpdate: 'Bạn có chắc chắn muốn đóng cửa sổ cập nhật?',
    confirmUpdate: 'Bạn có chắc chắn muốn cập nhật bản ghi không?',
    confirmCancelAction: 'Bạn có chắc chắn muốn Huỷ thao tác không?',
    cannotRevert: 'Bạn không thể hoàn tác điều này!',
    back: 'Quay lại',
    continue: 'Tiếp tục',
    active: 'Hoạt động',
    inactive: 'Ngưng hoạt động',
    cccd: 'Số CCCD',
    authen: 'Đang xác thực đăng ký tài khoản.',
    checkEmailOTP: 'Vui lòng kiểm tra email của bạn để lấy mã OTP',
    confirm: 'Xác nhận',
    logout: 'Đăng xuất',
    name: 'Họ và tên',
    email: 'Email đăng nhập',
    error: 'Lỗi',
    noData: 'Không có dữ liệu',
    detail: 'Chi tiết',
    update: 'Cập nhật',
    edit: 'Chỉnh sửa',
    yes: 'Có',
    no: 'Không',
    total: 'Tổng',
    item: 'bản ghi',
    placeholderSearch: 'Bạn muốn tìm kiếm...',
    detailSearch: 'Tìm chi tiết',
    status: 'Trạng thái',
    viewDetail: 'Xem chi tiết',
    updateInfo: 'Cập nhật thông tin',
    address: 'Địa chỉ',
    addressDetail: 'Số nhà, đường, thôn',
    commune: 'Xã/Phường',
    district: 'Quận/Huyện',
    province: 'Tỉnh/TP',
    tax: 'Mã số thuế',
    phoneNumber: 'Số điện thoại',
    accessArea: 'Khu vực truy cập',
    object: 'Đối tượng',
    time: 'Thời gian',
    startDate: 'Từ ngày',
    endDate: 'Đến ngày',
    area: 'Khu vực',
    creator: 'Người tạo',
    creationDate: 'Ngày tạo',
    updater: 'Người cập nhật',
    updatedDate: 'Ngày cập nhật',
    image: 'Hình ảnh',
    unit: 'Đơn vị',
    gender: 'Giới tính',
    on: 'Bật',
    off: 'Tắt',
    start: 'Bắt đầu',
    end: 'Kết thúc',
    addList: 'Thêm danh sách',
    jump_to: 'Đi tới trang',
    result: 'Kết quả',
    condition: 'Tình trạng',
    perform: 'Thực hiện',
    completed: 'Hoàn tất',
    valid: 'Hợp lệ',
    invalid: 'Không hợp lệ',
    downDetailFile: 'Tải tệp chi tiết',
    optionInactive: ' (Ngưng hoạt động)',
    helper: 'Trợ giúp',
    subscribers: 'Thuê bao',
    system: 'Hệ thống',
    all: 'Tất cả',
    teacher: 'Giáo viên',
    student: 'Học viên',
    startTime: 'Thời gian bắt đầu',
    endTime: 'Thời gian kết thúc',
    dateBirth: 'Ngày sinh',
    cameraSn: 'Camera Sn',
    serialTablet: 'Tablet serial'
  },
  layout: {
    leftMenu: {
      overview: 'Tổng quan',
      accountAuthorization: 'Phân quyền tài khoản',
      managementAgencyDirectory: 'Danh mục cơ quan quản lý',
      manageRoles: 'Quản lý vai trò, quyền hạn',
      manageAccountGroups: 'Quản lý nhóm tài khoản',
      accountManager: 'Quản lý tài khoản',
      deviceDeclaration: 'Khai báo thiết bị',
      manageDeviceDetails: 'Quản lý chi tiết thiết bị',
      accessManagement: 'Quản lý truy cập',
      manageAccessPerson: 'Người truy cập',
      accessHistory: 'Lịch sử truy cập',
      report: 'Báo cáo',
      accessGroup: 'Nhóm truy cập',
      advancedSetting: 'Thiết lập nâng cao',
      sync: 'Đồng bộ',
      management: 'Quản lý thông tin',
      courseManager: 'Quản lý khóa học',
      teacherManager: 'Quản lý giáo viên',
      student: 'Quản lý học viên',
      monitor: 'Giám sát',
      liveMonitoring: 'Giám sát trực tuyến',
      monitoringHistory: 'Lịch sử giám sát',
      studentReport: 'Báo cáo quá trình đào tạo của học viên',
      vehicleReport: 'Báo cáo quá trình đào tạo của xe tập lái',
      practiceReport: 'Báo cáo kết quả thực hành lái xe của khóa học',
      device: 'Quản lý thiết bị',
      addDevice: 'Thêm thiết bị vào hệ thống',
      addDeviceMXC: 'Thêm thiết bị vào kho',
      vehicleManager: 'Quản lý phương tiện',
      addendumA: 'Phụ lục A',
      addendumB: 'Phụ lục B',
      addendumC: 'Phụ lục C',
    },
    header: {
      sync: 'Đồng bộ dữ liệu',
      notSync: 'Dữ liệu gần nhất chưa được đồng bộ với thiết bị',
      syncNow: 'Đồng bộ ngay',
      syncing: 'Đang thực hiện đồng bộ dữ liệu',
      titleService: 'Thông tin sử dụng dịch vụ',
      extend: 'Gia hạn ngay',
    },
  },
  auth: {
    title: 'MK Access Control',
    pass: 'Mật khẩu',
    remember: 'Nhớ mật khẩu',
    forgotPassword: 'Quên mật khẩu',
    titleForgotPassword: 'Vui lòng nhập email để lấy lại mật khẩu',
    login: 'Đăng nhập',
    labelUser: 'Tên đăng nhập',
    signup: 'Đăng ký',
    alreadyHaveAccount: 'Nếu bạn đã có tài khoản. Bạn có thể đăng nhập ',
    createAccount: 'Nếu bạn chưa có tài khoản. Bạn có thể đăng ký ',
    loginSuccess: 'Đăng nhập thành công',
    loginFail: 'Đăng nhập thất bại',
    accountIncorrect: 'Tài khoản hoặc mật khẩu không chính xác',
    nameCustomer: 'Tên cá nhân',
    nameCustomer2: 'Tên tổ chức',
    placeholderNameCustomer: 'Nhập tên cá nhân',
    placeholderNameCustomer2: 'Nhập tên tổ chức',
    placeholderCCCD: 'Nhập CCCD',
    placeholderTax: 'Nhập mã số thuế',
    placeholderEmail: 'Nhập địa chỉ email',
    placeholderPass: 'Nhập mật khẩu',
    placeholderConfirmPass: 'Nhập xác nhận mật khẩu',
    placeholderChangePass: 'Nhập mật khẩu hiện tại',
    placeholderNewPass: 'Nhập mật khẩu mới',
    placeholderConfirmNewPass: 'Nhập xác nhận mật khẩu mới',
    typeCustomer: 'Ứng dụng được dùng cho',
    confirmOTP: 'Xác thực OTP',
    chooseClient: 'Vui lòng chọn đơn vị để truy cập',
    signUpSuccess: 'Đăng ký thành công.',
    checkEmail: 'Vui lòng kiểm tra email của bạn để xác thực tài khoản',
    changePass: 'Đổi mật khẩu',
    newPass: 'Mật khẩu mới',
    oldPass: 'Mật khẩu hiện tại',
    changePassSuccess: 'Đổi mật khẩu thành công',
    typeOtp: 'Vui lòng nhập mã xác thực(OTP) gửi đến email',
    notFoundOTP: 'Không nhận được mã? Thời gian còn lại',
    resendOTP: 'Gửi lại OTP',
    recoveryPassSuccess: 'Khôi phục mật khẩu thành công',
    here: 'tại đây',
    labelRegisterSuccess: 'Thư kích hoạt đã được gửi thành công!',
    checkEmailSpam: 'Hãy kiểm tra trong thư mục Spam nếu bạn không nhận được thư của chúng tôi',
    backLogin: 'Quay lại đăng nhập',
    titleOtp: 'Nhập Mã OTP',
    labelOtp: 'Vui lòng nhập mã xác thực (OTP) gửi đến email ',
    timeCount: 'Thời gian còn lại ',
    checkCode: 'Không nhận được mã? ',
    sendOtpAgain: 'Gửi lại OTP',
    resetPass: 'Đặt lại mật khẩu',
  },
  dashBoard: {
    placeholderAreaId: 'Tất cả khu vực',
    errDate: 'Từ ngày không thể lớn hơn đến ngày',
    errRange: 'Thời gian tìm kiếm không được quá 12 tháng. Vui lòng chọn lại thời gian tìm kiếm',
    titleTooltip: 'Lượt truy cập',
    titleLineChart: 'Tổng lượng truy cập (Đơn vị: lượt)',
    day: 'Ngày',
    week: 'Tuần',
    month: 'Tháng',
    inter: 'Nội bộ ',
    orther: 'Khác ',
    object: 'Đối tượng ',
  },
  validator: {
    require: ' không được bỏ trống',
    requireField: 'Trường bắt buộc',
    requireEmail: 'Email không đúng định dạng',
    requirePass: 'Mật khẩu không được bỏ trống',
    requireConfirmPass: 'Xác nhận mật khẩu',
    requireConfirmNewPass: 'Xác nhận mật khẩu mới',
    confirmPassFail: 'Xác nhận mật khẩu chưa khớp. Hãy thử lại.',
    requireNewPass: 'Nhập mật khẩu mới!',
    passMin8: 'Có độ dài tối thiểu 08 ký tự',
    validPass: 'Mật khẩu phải chứa ít nhất một chữ thường, chữ hoa, số và ký tự đặc biệt',
    cccdMin12: ' không đúng định dạng',
    requireNumber: 'Chỉ nhập ký tự số',
    requireRoleOrGroup: 'Vai trò hoặc Nhóm tài khoản không được bỏ trống',
    errFormat: ' không đúng định dạng',
    errFormatLP: ' không đúng định dạng, ví dụ (30H12345)',
  },
  role: {
    title: 'vai trò',
    status: {
      active: 'Hoạt động',
      inactive: 'Ngưng hoạt động',
    },
    role: 'Vai trò',
    roleCode: 'Mã vai trò',
    roleName: 'Tên vai trò',
    statusName: 'Trạng thái',
    addRole: 'Thêm vai trò',
    editRole: 'Sửa vai trò',
    deleteRole: 'Xoá vai trò',
    viewRole: 'Xem vai trò',
    addSuccess: 'Thêm mới vai trò thành công',
    updateSuccess: 'Cập nhật vai trò thành công',
    deleteSuccess: 'Xoá vai trò thành công',
    roleAndDelegation: 'Quản lý vai trò, quyền hạn',
    decentralization: 'Phân quyền',
    tooltipQS: 'Tìm mã vai trò, tên vai trò, người tạo, người cập nhật, các ngày',
  },
  managementAgency: {
    title: 'cơ quan quản lý',
    management_agency: 'Danh mục cơ quan quản lý',
    unitName: 'Tên đơn vị',
    address: 'Địa chỉ',
    representative: 'Người đại diện',
    phone: 'Số điện thoại',
    email: 'Email',
    name: 'Tên cơ quan quản lý',
    foundedYear: 'Năm thành lập',
    signInEmail: 'Email đăng nhập',
    phoneNumber: 'Số điện thoại',
    errYear: 'Năm thành lập không được lớn hơn năm hiện tại',
    searchForm: 'Tìm kiếm Tên đơn vị, Địa chỉ, Người đại diện, Số điện thoại, Email',
  },
  userGroupManager: {
    groupCode: 'Mã nhóm',
    groupName: 'Tên nhóm',
    roles: 'Vai trò',
    delete: 'Xoá điều kiện',
    user: 'Tài khoản',
    groupNameUser: 'Tên nhóm tài khoản',
    groupCodeUser: 'Mã nhóm tài khoản',
    userGroup: 'Nhóm tài khoản',
    title: 'nhóm tài khoản',
    tooltipQS: 'Tìm mã nhóm, tên nhóm, vai trò, người tạo, người cập nhật, các ngày',
  },
  accountManager: {
    account: 'Tài khoản',
    accountCode: 'Mã tài khoản',
    accountName: 'Tên tài khoản',
    addAccount: 'Thêm tài khoản',
    editAccount: 'Sửa tài khoản',
    deleteAccount: 'Xoá tài khoản',
    viewAccount: 'Chi tiết tài khoản',
    addSuccess: 'Thêm mới tài khoản thành công.',
    updateSuccess: 'Cập nhật tài khoản thành công.',
    deleteSuccess: 'Xoá tài khoản thành công.',
    employeeCode: 'Mã nhân viên',
    tooltipQS: 'Tìm họ và tên, mã nhân viên, email, nhóm tài khoản, vai trò, người tạo, người cập nhật, các ngày',
  },
  report: {
    export: 'Xuất báo cáo',
    errDate: 'Thời gian tìm kiếm không được quá 6 tháng. Vui lòng chọn lại thời gian tìm kiếm.',
  },
  profile: {
    accountInfo: 'Thông tin tài khoản',
    contactInfo: 'Thông tin liên hệ',
    customerInfo: 'Thông tin khách hàng',
    customerName: 'Tên khách hàng',
    customerType: 'Loại khách hàng',
    position: 'Chức vụ',
    identifier: 'Mã khách hàng',
    email: 'Email',
    updateSuccess: 'Cập nhật thông tin thành công',
    confirmUpdateProfile: 'Bạn có chắc chắn muốn cập nhật thông tin không?',
  },
  instructorManagement: {
    delete: 'Xoá điều kiện',
    title: 'giáo viên',
    tooltipQS: 'Tìm họ và tên, số CCCD gắn chip, Hạng GPLX, Số điện thoại, Email',
    all: 'Tất cả',
    working: 'Đang làm việc',
    quitted: 'Đã nghỉ việc',
    portraitImage: 'Ảnh chân dung',
    fullName: 'Họ và tên',
    cccd: 'CCCD gắn chíp',
    gender: 'Giới tính',
    birthday: 'Ngày sinh',
    address: 'Địa chỉ',
    phoneNo: 'Số điện thoại',
    email: 'Email',
    driverLicenseNo: 'Số GPLX',
    driverLicenseCategory: 'Hạng GPLX',
    certificationNo: 'Số giấy chứng nhận',
    male: 'Nam',
    female: 'Nữ',
    workingStatus: 'Trạng thái làm việc',
    falsePhoneFormat: 'Số điện thoại không đúng định dạng',
    verified: 'Đã xác thực',
    downImage: 'Tải ảnh lên',
  },
  trainingFacility: {
    title: 'Quản lý cơ sở đào tạo',
    span: 'cơ sở đào tạo',
    name: 'Tên đơn vị',
    agency: 'Cơ quan quản lý',
    person: 'Người đại diện',
    foundYear: 'Năm thành lập',
    nameFacility: 'Tên cơ sở đào đạo',
    tooltip: 'Tìm kiếm tên đơn vị, cơ quan quản lý, địa chỉ, người đại diện, số điện thoại, email đăng nhập',
  },
  course: {
    title: 'Khóa học',
    courseManagement: 'Quản lý khóa học',
    courseCode: 'Mã khóa học',
    courseName: 'Tên khóa học',
    tranningNetwork: 'Hạng đào tạo',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',
    tranningLevel: 'Hạng đào tạo',
    estimateExamDate: 'Ngày thi dự kiến',
    courseInfo: 'Thông tin khóa học',
    detailGV_HV: 'Chi tiết GV_HV',
    detailTrainingSession: 'Chi tiết phiên đào tạo',
    listTeacher: 'Danh sách giáo viên',
    litStudent: 'Danh sách học viên',
    tooltipQS: 'Tìm mã khóa học, tên khóa học, hạng đào tạo, thời gian bắt đầu, thời gian kết thúc',
    cccd: 'CCCD gắn chíp',
    numberGPLX: 'Số GPLX',
    levelGPLX: 'Hạng GPLX',
    codeTraining: 'Số giấy chứng nhận đào tạo',
    typeTraining: 'Loại hình đào tạo',
    carPratice: 'Xe tập lái',
    sessionTraining: 'Phiên đào tạo',
    addSuccess: 'Thêm mới khóa học thành công',
    updateSuccess: 'Cập nhật khóa học thành công',
    deleteSuccess: 'Xoá khóa học thành công',
    optionQuitted: ' (Đã nghỉ việc)',
    fieldErrorDay1: 'Ngày kết thúc không nhỏ hơn ngày bắt đầu',
    fieldErrorDay2: 'Ngày thi dự kiến phải lớn hơn ngày kết thúc',
  },
  student: {
    title: 'Quản lý học viên',
    idCard: 'CCCD gắn chip',
    courseCode: 'Mã khóa học',
    typesOfTraining: 'Loại hình đào tạo',
    phoneNumber: 'Số điện thoại',
    email: 'Email',
    facePhoto: 'Ảnh chân dung',
    gender: 'Giới tính',
    dateOfBirth: 'Ngày sinh',
    address: 'Địa chỉ',
    previousTrainingClass: 'Hạng GPLX trước đó',
    trainingClass: 'Hạng đào tạo',
    viewDetail: 'Xem chi tiết',
    tab1: 'Thông tin cá nhân',
    tab2: 'Thông tin đào tạo',
    accomplished: 'Đã hoàn thành',
    remaining: 'Còn phải hoàn thành',
    trainingSessions: 'Phiên đào tạo',
    start: 'Bắt đầu',
    end: 'Kết thúc',
    hours: 'Số giờ',
    distance: 'Quãng đường',
    overview: 'Tổng quan',
    detail: 'Chi tiết',
    verified: 'Đã xác thực',
    upload: 'Tải ảnh lên',
    tooltipQS: 'Tìm họ và tên, số CCCD gắn chip, mã khóa học, SĐT, email',
  },
  device: {
    device: 'Thiết bị',
    deviceCode: 'Mã thiết bị',
    deviceName: 'Tên thiết bị',
    deviceType: 'Loại thiết bị',
    practiceCar: 'Xe tập lái',
    serialCameraNo: 'Số serial camera',
    serialTabletNo: 'Số serial tablet',
    cameraPassword: 'Mật khẩu camera',
    note: 'Ghi chú',
    addDevice: 'Thêm thiết bị',
    editDevice: 'Sửa thiết bị',
    deleteDevice: 'Xoá thiết bị',
    viewDevice: 'Chi tiết thiết bị',
    addSuccess: 'Thêm mới thiết bị thành công',
    registerFailed: 'Thêm mới thiết bị không thành công',
    updateSuccess: 'Cập nhật thiết bị thành công',
    deleteSuccess: 'Xoá thiết bị thành công',
    employeeCode: 'Mã nhân viên',
    tooltipQS: 'Tìm tên thiết bị, mã thiết bị, loại thiết bị, xe tập lái',
    otpGuideText: 'Vui lòng nhập mã OTP này lên thiết bị đăng ký',
    timeleft: 'Thời gian còn lại',
    resendOtpBtn: 'Gửi lại OTP',
    otpIncorrect: 'OTP không chính xác',
    SDT_CAMERA: 'SĐT camera',
  },
  vehicleManagement: {
    delete: 'Xoá điều kiện',
    title: 'phương tiện',
    tooltipQS: 'Tìm biển số xe, số giấy phép xe tập lái, nhãn hiệu, loại xe, thiết bị DAT, chủ sở hữu, Số điện thoại chủ xe',
    all: 'Tất cả',
    licensePlate: 'Biển số xe',
    vehicleLicense: 'Số giấy phép xe tập lái',
    brand: 'Nhãn hiệu',
    classification: 'Loại xe',
    device: 'Thiết bị DAT',
    ownerName: 'Chủ sở hữu',
    ownerPhoneNumber: 'Số điện thoại chủ xe',
    falsePhoneFormat: 'Số điện thoại không đúng định dạng',
    verified: 'Đã xác thực',
    note: 'Ghi chú',
  },
  liveMonitor: {
    event: 'Sự kiện',
    trip: 'Chuyến đi',
    eventSelect: 'Chọn sự kiện',
    copyLink: 'Sao chép link',
    chooseDate: 'Chọn ngày',
    droveCar: '{value} đã lái xe',
    parkedCar: '{value} đã đỗ xe',
    minutes: 'Phút',
    filterMinutes: 'Lọc các quãng đường trên 5 phút',
    copyLinkSuccess: 'Sao chép link thành công',
    playVideo: 'Xem video',
    now: 'Bây giờ',
    warningDate: 'Tra cứu tối đa 1 tháng',
    timeDuration: 'Thời lượng',
    titleMap: 'Bản đồ ({car}_{code})',
    drivingCar: '{value} đang lái xe',
    countTime: 'Số giờ đã tích lũy: ',
    countDistance: 'Quãng đường đã tích lũy: ',
    accumulated: 'Tích lũy: ',
  },
  studentReport: {
    title: 'Báo cáo quá trình đào tạo của học viên',
    trainingFacility: 'Cơ sở đào tạo',
    lesson: 'Khoá học',
    lessonCode: 'Mã khoá học',
    studentName: 'Họ và tên học viên',
    viewDetail: 'Xem chi tiết quá trình đào tạo học viên',
    studentInfo: 'Thông tin học viên',
    lessonInfo: 'Thông tin quá trình đào tạo',
    studentCode: 'Mã học viên',
    dateTraining: 'Ngày đào tạo',
    total: 'Tổng cộng',
    placeholderName: 'Nhập họ tên',
  },

  vehicleReport: {
    trainingFacility: 'Cơ sở đào tạo',
    placeholderTrainingFacility: 'Chọn trung tâm',
    time: 'Thời gian',
    licensePlate: 'Biển số xe',
    placeholderLicensePlate: 'Chọn biển số xe',
    vehicleClass: 'Hạng xe',
    placeholderVehicleClass: 'Nhập hạng xe',
    owner: 'Chủ sở hữu',
    trainingSession: 'Phiên đào tạo',
    trainingStudent: 'Học viên đào tạo',
    trainingDate: 'Ngày đào tạo',
    hours: 'Số giờ',
    distance: 'Quãng đường',
    titleModal: 'Xem chi tiết quá trình đào tạo của xe tập lái',
    vehicleInfo: 'Thông tin xe tập lái',
    trainingProcessInfo: 'Thông tin quá trình đào tạo',
    fromDate: 'Từ ngày',
    toDate: 'đến ngày',
  },
  practiceReport: {
    trainingFacility: 'Cơ sở đào tạo',
    lesson: 'Khoá học',
    viewDetail: 'Xem chi tiết kết quả thực hành lái xe của khóa học',
    practiceInfo: 'Thông tin khóa học',
    lessonInfo: 'Thông tin quá trình đào tạo',
    studentCode: 'Mã học viên',
    dateTraining: 'Ngày đào tạo',
    openingDay: 'Ngày khai giảng',
    closingDay: 'Ngày bế giảng',
    trainingDistance: 'Quãng đường',
    note: 'Ghi chú',
    placeholderTrainingFacility: 'Chọn trung tâm',
    placeholderLicenseClass: 'Chọn hạng đào tạo',
  },
  events: {
    FORWARD_COLLISION_WARNING: 'Vượt quá tốc độ',
    HEADWAY_MONITORING_WARNING: 'Đăng nhập',
    HEADWAY_MONITORING_EMERGENCY: 'Đăng xuất',
    NO_DRIVER: 'Không có tài xế',
    ASLEEP: 'Buồn ngủ',
    DROWSINESS: 'Mệt mỏi',
    YAWN: 'Ngáp ngủ',
    DAYDREAMING: 'Không tỉnh táo',
    USING_PHONE: 'Sử dụng điện thoại',
    DISTRACTED: 'Mất tập trung',
    SMOKING: 'Hút thuốc',
    NO_SEATBELT: 'Không thắt dây an toàn',
    DRIVING_HEAVY_HIT: 'Va chạm mạnh',
    DRIVING_SUSPICIOUS_HIT: 'Va chưa xác định',
    DRIVING_LIGHT_HIT: 'Va chạm nhẹ',
    HARD_ACCEL: 'Tăng tốc đột ngột I',
    HARSH_ACCEL: 'Tăng tốc đột ngột II',
    SEVERE_ACCEL: 'Tăng tốc đột ngột III',
    HARD_BRAKE: 'Phanh gấp I',
    HARSH_BRAKE: 'Phanh gấp II',
    SEVERE_BRAKE: 'Phanh gấp III',
    SHARP_TURN: 'Cua gấp I',
    HARSH_TURN: 'Cua gấp II',
    SEVERE_TURN: 'Cua gấp III',
    VIDEO_LENGTH: 'Thời lượng',
    DRIVING_AND_PARKING_KEY: 'Lái xe và đỗ xe',
    DMS_KEY: 'DMS',
    COLLISIONS_KEY: 'Va chạm',
    HARD_ACCELERATION_KEY: 'Tăng tốc đột ngột',
    HARD_BRAKE_KEY: 'Phanh gấp',
    SHARP_TURN_KEY: 'Cua gấp',
    EMERGENCY: 'EMERGENCY',
    PARKING_HIT: 'PARKING_HIT',
    PARKING_HEAVY_HIT: 'PARKING_HEAVY_HIT',
    DRIVING_HIT: 'DRIVING_HIT',
    MANUAL: 'MANUAL',
    MANUAL_LB: 'MANUAL',
    FORWARD_COLLISION: 'FORWARD_COLLISION',
    FORWARD_COLLISION_LB: 'Forward collistion',
    HEADWAY_MONITORING: 'HEADWAY_MONITORING',
  },
  mapMonitor: {
    department: 'Đơn vị',
    licensePlate: 'Biển số xe',
    chooseLicensePlate: 'Chọn biển số xe',
    student: 'Học viên',
    chooseStudent: 'Chọn học viên',
    parking: 'Đỗ xe',
    driving: 'Đang lái xe',
    chooseCourse: 'Chọn khóa học',
    chooseDepartment: 'Chọn đơn vị',
    cameraOffline: 'Camera đang offline',
    wakeup: 'Đánh thức lại',
    teacher: 'Giáo viên: ',
    courseName: 'Tên khóa học: ',
    studentName: 'Học viên: ',
    startTime: 'Thời gian bắt đầu: ',
    chooseTeacher: 'Chọn giáo viên',
  },
  historyMonitor: {
    trainingSessionCode: 'Mã phiên đào tạo',
    licensePlate: 'Biển số xe: ',
    warningDate: 'Tra cứu tối đa 6 tháng',
    tooltipQS: 'Tìm mã phiên đào tạo, mã khóa học, biển số xe, giáo viên, học viên, quãng đường, thời gian',
  },
};

export default vi;
