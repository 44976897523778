import { RootState } from '../../redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { StudentManagerState } from './redux';

export const studentManagerSelector = (state: RootState) => state.studentManager;

export const listCoursesProgressingSelector = (state: RootState) => state.studentManager.listCoursesProgressing;
export const paramsSelector = (state: RootState) => state.studentManager.params;
export const listCoursesProgressingIdsSelector = createSelector(
  studentManagerSelector,
  (state: StudentManagerState) => state.listCoursesProgressingIds
);
export const filePhotoSelector = createSelector(studentManagerSelector, (state: StudentManagerState) => state.filePhoto);
