import React from 'react';
import { StyledSearch } from './styles';
import { ReactComponent as SearchIcon } from 'assets/imgs/SearchIcon.svg';
import { useIntl } from 'react-intl';
import { SearchProps } from 'antd/es/input/Search';
import { Tooltip } from 'antd';

interface Props extends SearchProps {
  onSearch: (
    value: string,
    event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  loading?: boolean;
  disabled?: boolean;
  value?: string;
  setValue?: any;
  tooltip?: string;
}

const CustomSearch = (props: Props) => {
  const { onSearch, loading, disabled, value, setValue, tooltip, ...rest } = props;
  const intl = useIntl();

  const onBlur = (e: any) => {
    setValue && setValue(e.target.value.trim());
  };

  const onChange = (e: any) => {
    setValue && setValue(e.target.value);
  };

  const onPates = async (e: any) => {
    setTimeout(() => {
      setValue && setValue(e.target.value.trim());
    }, 0);
  };

  return (
    <Tooltip
      title={tooltip || intl.formatMessage({ id: 'common.placeholderSearch' })}
      placement="right"
      overlayClassName="quickSearchOverlay"
    >
      <StyledSearch
        onSearch={onSearch}
        onBlur={onBlur}
        value={value}
        onChange={onChange}
        loading={loading}
        disabled={disabled}
        onPaste={onPates}
        enterButton={intl.formatMessage({ id: 'common.search' })}
        placeholder={tooltip || intl.formatMessage({ id: 'common.placeholderSearch' })}
        {...rest}
        prefix={<SearchIcon />}
      />
    </Tooltip>
  );
};

export default CustomSearch;
