import { NotificationError, NotificationSuccess } from 'components/notifications';
import {
  deleteAddUserGroup,
  getListGroupCode,
  getListRoles,
  getListUser,
  getListUserName,
  postAddUserGroup,
  putAddUserGroup,
} from 'modules/userGroupManager/api';
import { GetListUserRequest, GetListUserResponse, GroupCodeItem, roleItem, UserItem } from 'modules/userGroupManager/api/types';
import { all, put, select, spawn, takeLatest } from 'redux-saga/effects';
import {
  getGroupCodeSuccess,
  getListFilterGroup,
  getListRoleSuccess,
  getListUserFail,
  getListUserGroup,
  getListUserSuccess,
  updateSearch,
  startGetListUserName,
  getListUserNameSuccess,
  createUserGroupStart,
  editUserGroupStart,
  setTypeModal,
  deleteListItem,
  setFieldErrors,
} from 'modules/userGroupManager/redux/index';
import { paramsSelector } from '../selector';
import { clientIdSelector } from 'modules/auth/selectors';

function* getListUserByParams() {
  try {
    const params: GetListUserRequest = yield select(paramsSelector);
    const response: GetListUserResponse = yield getListUser(params);

    yield put(getListUserSuccess(response));
  } catch (err: any) {
    NotificationError(err);
    yield put(getListUserFail());
  }
}

function* getListRoleByParams() {
  try {
    const clientId: string = yield select(clientIdSelector);
    const response: { content: roleItem[] } = yield getListRoles({ clientId });
    yield put(getListRoleSuccess(response.content));
  } catch (err: any) {
    NotificationError(err);
  }
}
function* getListGroupCodeWorker() {
  try {
    const clientId: string = yield select(clientIdSelector);
    const response: { content: GroupCodeItem[] } = yield getListGroupCode({ clientId });
    yield put(getGroupCodeSuccess(response.content));
  } catch (err: any) {
    NotificationError(err);
  }
}
function* getListUserNameWorker() {
  try {
    const clientId: string = yield select(clientIdSelector);
    const response: { content: UserItem[] } = yield getListUserName({ clientId });
    yield put(getListUserNameSuccess(response.content));
  } catch (err: any) {
    NotificationError(err);
  }
}
function* createUserGroupStartWorker(action: ReturnType<typeof createUserGroupStart>) {
  try {
    const clientId: string = yield select(clientIdSelector);
    const params = { ...action.payload, clientId };
    yield postAddUserGroup(params);
    NotificationSuccess('common.addSuccess', { value: 'userGroupManager.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
    yield spawn(getListGroupCodeWorker);
  } catch (err: any) {
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}
function* editUserGroupStartWorker(action: ReturnType<typeof editUserGroupStart>) {
  try {
    const clientId: string = yield select(clientIdSelector);
    const params = { ...action.payload, clientId };
    yield putAddUserGroup(params);
    NotificationSuccess('common.updateSuccess', { value: 'userGroupManager.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
    yield spawn(getListGroupCodeWorker);
  } catch (err: any) {
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}
function* deleteListItemWorker(action: ReturnType<typeof deleteListItem>) {
  try {
    yield deleteAddUserGroup(action.payload);
    NotificationSuccess('common.deleteSuccess', { value: 'userGroupManager.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
    yield spawn(getListGroupCodeWorker);
  } catch (err: any) {
    NotificationError(err);
  }
}

export default function* userGroupManagerSaga() {
  yield all([
    takeLatest(getListUserGroup, getListUserByParams),
    takeLatest(updateSearch, getListUserByParams),
    takeLatest(getListFilterGroup, getListRoleByParams),
    takeLatest(getListFilterGroup, getListGroupCodeWorker),
    takeLatest(startGetListUserName, getListUserNameWorker),
    takeLatest(createUserGroupStart, createUserGroupStartWorker),
    takeLatest(editUserGroupStart, editUserGroupStartWorker),
    takeLatest(deleteListItem, deleteListItemWorker),
  ]);
}
