import { Line, LineConfig } from '@ant-design/plots';
import { StyledTooltip } from '../page/styles';

const ChartSession = () => {
  const data = [
    {
      time: '0h',
      value: 100,
    },
    {
      time: '1h',
      value: 100,
    },
    {
      time: '2h',
      value: 200,
    },
    {
      time: '3h',
      value: 250,
    },
    {
      time: '4h',
      value: 300,
    },
    {
      time: '5h',
      value: 450,
    },
    {
      time: '6h',
      value: 500,
    },
    {
      time: '7h',
      value: 1000,
    },
    {
      time: '8h',
      value: 1720,
    },
    {
      time: '9h',
      value: 1900,
    },
    {
      time: '10h',
      value: 2000,
    },
    {
      time: '11h',
      value: 1800,
    },
    {
      time: '12h',
      value: 1818,
    },
    {
      time: '13h',
      value: 2100,
    },
    {
      time: '14h',
      value: 2200,
    },
    {
      time: '15h',
      value: 2000,
    },
    {
      time: '16h',
      value: 1920,
    },
    {
      time: '17h',
      value: 1800,
    },
    {
      time: '18h',
      value: 1700,
    },
    {
      time: '19h',
      value: 1600,
    },
    {
      time: '20h',
      value: 1802,
    },
    {
      time: '21h',
      value: 1600,
    },
    {
      time: '22h',
      value: 1200,
    },
    {
      time: '23h',
      value: 800,
    },
    {
      time: '24h',
      value: 300,
    },
  ];

  const config: LineConfig = {
    data,
    padding: 'auto',
    xField: 'time',
    yField: 'value',
    xAxis: {
      // type: 'timeCat',
      tickCount: 7,
      tickLine: {
        length: 0,
      },
      grid: {
        line: {
          style: {
            opacity: 0.1,
            fill: '#f7f7f7',
            height: 40,
          },
        },
      },
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
      line: {
        style: {
          opacity: 0,
        },
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            opacity: 0.5,
            fill: '#f7f7f7',
          },
        },
      },
      label: {
        style: {
          fill: 'var(--2-b-2-b-2-b, #2B2B2B)',
        },
      },
    },
    color(datum, defaultColor) {
      return '#165BAA';
    },

    tooltip: {
      customContent(title, data) {
        return (
          <StyledTooltip>
            <p>{data[0]?.value}</p> <span>Phiên đào tạo</span>
          </StyledTooltip>
        );
      },
    },
  };

  return <Line {...config} style={{ marginTop: 20 }} />;
};

export default ChartSession;
