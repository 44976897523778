import { useSelector } from 'react-redux';
import CarItem from './CardItem';
import { ListCardBox } from './style';
import { fleetViewDataSelector, loadingSelector } from 'modules/mapMonitor/selector';
import { RecordsItem } from 'modules/mapMonitor/api/types';
import Spin from 'antd/lib/spin';
import { EventNoData } from '../../style';
import NoData from 'assets/imgs/NoData.svg';
import { FormattedMessage } from 'react-intl';

function ListCard() {
  const listItem: RecordsItem[] = useSelector(fleetViewDataSelector);
  const loading = useSelector(loadingSelector);
  return (
    <ListCardBox>
      <Spin spinning={loading}>
        {listItem.length > 0 ? (
          listItem.map(value => {
            return <CarItem key={value.id} {...value} />;
          })
        ) : (
          <EventNoData>
            <img src={NoData} alt="no data" />
            <div>
              <FormattedMessage id="common.noData" />
            </div>
          </EventNoData>
        )}
      </Spin>
    </ListCardBox>
  );
}

export default ListCard;
