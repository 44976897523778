import { CardEventTrip, CardEventWrapper, EventNoData, ListCarItemBox } from './style';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListEventsStart, setIndexActiveEvent, setPlayDetailUrl, setShowVideoDetail } from '../redux';
import { eventsSelector, indexActiveEventSelector, paramsEventsSelector } from '../selector';
import { Col, Row } from 'antd';
import moment from 'moment';
import { groupBy } from 'lodash';
import { RightOutlined } from '@ant-design/icons';
import NoData from 'assets/imgs/NoData.svg';
import { videoIcon } from '../constant';

function EventTrip() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const paramsEvents = useSelector(paramsEventsSelector);
  const events = useSelector(eventsSelector);
  const [eventsGroup, setEventGroup] = useState<any>({});
  const indexActiveEvent: number = useSelector(indexActiveEventSelector);

  const handlePlayVideo = (value: any) => {
    dispatch(setShowVideoDetail(true));
    dispatch(setPlayDetailUrl(value.mp4Url));
  };

  useEffect(() => {
    const arr = events.map(event => ({
      ...event,
      date: moment(event.gps?.time).format('DD/MM/YYYY'),
    }));
    const arrGroup = groupBy(arr, 'date');
    setEventGroup(arrGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  useEffect(() => {
    if (!paramsEvents.cameraSn) return;
    const paramsNew = {
      ...paramsEvents,
      tripId: '',
      fromTime: Number(moment().startOf('day').format('x')),
      toTime: Number(moment().endOf('day').format('x')),
    };
    dispatch(getListEventsStart(paramsNew));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowEvent = (indexActive: number) => {
    if (indexActive === indexActiveEvent) {
      dispatch(setIndexActiveEvent(-1));
    } else {
      dispatch(setIndexActiveEvent(indexActive));
    }
  };

  return (
    <ListCarItemBox>
      {events.length ? (
        Object.keys(eventsGroup).map(date => (
          <CardEventWrapper>
            {eventsGroup[date]?.map((event: any, index: number) => {
              return (
                <CardEventTrip key={index}>
                  <Row justify={'space-between'} align={'middle'}>
                    <Col>
                      <div className="trip-card-event--time">{moment(event.gps?.time).format('HH:mm') ?? 0}</div>
                      <div>{intl.formatMessage({ id: `events.${event?.eventType}` })}</div>
                    </Col>
                    <Col>
                      {event.gps?.coordinate ? (
                        <RightOutlined onClick={() => handleShowEvent(index)} />
                      ) : (
                        <img src={videoIcon} alt="video" onClick={() => handlePlayVideo(event)} className="event-play-video" />
                      )}
                    </Col>
                  </Row>
                </CardEventTrip>
              );
            })}
          </CardEventWrapper>
        ))
      ) : (
        <EventNoData>
          <img src={NoData} alt="no data" />
          <div>{intl.formatMessage({ id: 'common.noData' })}</div>
        </EventNoData>
      )}
    </ListCarItemBox>
  );
}

export default EventTrip;
