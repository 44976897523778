import { all } from 'redux-saga/effects';
import authSaga from 'modules/auth/saga';
import roleSaga from 'modules/role/saga';
import userGroupManagerSaga from 'modules/userGroupManager/saga';
import ssoSaga from 'modules/sso/saga';
import layoutSaga from 'components/layouts/saga';
import forgotPassSaga from 'modules/forgotPass/saga';
import accountManagerSaga from 'modules/accountManager/saga';
import reportSaga from 'modules/report/saga';
import instructorManagementSaga from 'modules/instructorManagement/saga';
import trainingFacilityManagerSaga from 'modules/trainingFacilities/saga';
import courseSaga from 'modules/course/saga';
import mapMonitorSaga from 'modules/mapMonitor/saga';
import studentManagerSaga from '../modules/studentManagement/saga';
import managementSaga from 'modules/managementAgency/saga';
import deviceSaga from 'modules/device/saga';
import vehicleManagementSaga from 'modules/vehicleManagement/saga';
import studentReportSaga from 'modules/studentReport/saga';
import vehicleReportSaga from '../modules/vehicleReport/saga';
import practiceReportSaga from 'modules/practiceReport/saga';
import monitorHistorySaga from 'modules/monitorHistory/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    roleSaga(),
    userGroupManagerSaga(),
    ssoSaga(),
    layoutSaga(),
    forgotPassSaga(),
    accountManagerSaga(),
    reportSaga(),
    instructorManagementSaga(),
    trainingFacilityManagerSaga(),
    courseSaga(),
    mapMonitorSaga(),
    studentManagerSaga(),
    managementSaga(),
    deviceSaga(),
    vehicleManagementSaga(),
    studentReportSaga(),
    vehicleReportSaga(),
    practiceReportSaga(),
    monitorHistorySaga(),
  ]);
}
