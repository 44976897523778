import HttpRequest from 'core/http/singleton/auth';
import { GetTripsRequest, ParamsLessons, GetEventsRequest, TripItem, EventItem } from '../type';
import { ParamsTF } from 'modules/studentReport/type';

const url = 'api/v1/history';
const urlQS = '/api/search/lessons';

export const getListLessonApi = async (params: ParamsLessons): Promise<any> => {
  const res = await HttpRequest.get<any>(urlQS, params);
  return res.data;
};

export const getTrips = async (params: GetTripsRequest): Promise<TripItem[]> => {
  const res = await HttpRequest.get<any>(url + '/trips', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getEvents = async (params: GetEventsRequest): Promise<EventItem[]> => {
  const res = await HttpRequest.get<any>(url + '/events', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListTrainingFacility = async (params: ParamsTF): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/lesson-view/tree-client', params);
  return res.data;
};
