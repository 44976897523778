import CModal from 'components/commons/Modal';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .ant-image-img {
    border-radius: 8px !important;
  }
  .ant-image-mask-info {
    color: ${({ theme }) => theme.menuBackground};
  }
`;

export const StyledFilter = styled('div')`
  width: 100%;
  background-color: ${({ theme }) => theme.tableTitleBackground};
  border-radius: 8px;
  margin-top: 16px;
  transition: all 0.5 linear;
  padding: 20px 25px;
  margin-bottom: 20px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-collapse-header {
    display: none !important;
  }

  .ant-picker {
    width: 100%;
  }

  .wrap-buttons {
    text-align: right;
    button {
      margin-left: 16px;
    }
  }
  .ant-row {
    align-items: unset;
  }
`;

export const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
`;

export const StyledModalDetail = styled(CModal)`
  .rowInfo {
    margin-left: 30px;
    p {
      margin-top: 0;
    }
    .ant-col.ant-col-4 p:after {
      content: ':';
      margin-left: 2px;
    }  
  }

  .imgStudent {
    width: 100%;
    max-height: 202px;
  }
`;
