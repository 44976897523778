/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListCourse,
  getListCourseSuccess,
  getListReportItem,
  getListReportItemSuccess,
  getListTF,
  setParams,
  startGetListLicense,
} from '../redux';
import { reportPracticeSelector, listLicenseSelector } from '../selector';
import { StyledFilter } from '../page/styles';
import { Col, Form, Row } from 'antd';
import CSelect from 'components/commons/Select';
import moment from 'moment';
import { useEffectOnce } from 'react-use';
import Cascader from 'components/commons/Cascader';
import { DefaultOptionType } from 'antd/es/cascader';
import { ParamsType, TypeTF, LicenseType } from '../type';
import { managementBaseSelector } from 'modules/auth/selectors';
import { UnitType } from 'helper/constants';
import { orderBy } from 'lodash';
import { DefaultPage } from 'types';
import { CRangePicker } from 'components/commons/DatePicker';
import { RangePickerProps } from 'antd/es/date-picker';

const Header = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { listCourse, params, listTF } = useSelector(reportPracticeSelector);
  const managementBase = useSelector(managementBaseSelector);
  const listLicense = useSelector(listLicenseSelector);
  const [listOptionTF, setListOptionTF] = useState<TypeTF[]>([]);
  const [disable, setDisable] = useState<boolean>(managementBase?.clientType !== UnitType.CSDT);

  const handleRefreshForm = () => {
    form.resetFields();
    handleRefresh();
    managementBase?.clientType !== UnitType.CSDT && dispatch(getListReportItemSuccess(DefaultPage));
  };

  const handleFinishFrom = (values: any) => {
    const paramSearch = {
      endDate: values.time[1].format('YYYY-MM-DD'),
      startDate: values.time[0].format('YYYY-MM-DD'),
      clientId: values?.clientId?.[values?.clientId?.length - 1],
      code: values.code || undefined,
      licenseClass: values.licenseClass || undefined,
    };
    dispatch(
      setParams({
        ...params,
        ...paramSearch,
        page: 0,
      })
    );
  };

  const getListTrainingFacility = () => {
    dispatch(getListTF());
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.name as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleChangeCSDT = (value: any) => {
    if (value) {
      const clientId = value?.[value.length - 1];
      dispatch(getListCourse(clientId));
      setDisable(false);
    } else {
      dispatch(getListCourseSuccess([]));
      dispatch(getListReportItemSuccess(DefaultPage));

      if (managementBase?.clientType !== UnitType.CSDT) {
        setDisable(true);
      }
    }
  };

  const getListReport = (params: ParamsType) => {
    dispatch(
      getListReportItem({
        ...params,
        clientId: managementBase?.clientType === UnitType.CSDT ? listTF?.[0]?.id : params.clientId,
      })
    );
  };

  const handleRefresh = () => {
    dispatch(
      setParams({
        page: 0,
        size: 10,
        endDate: moment().format('YYYY-MM-DD'),
        startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        clientId: managementBase?.clientType === UnitType.CSDT ? listTF?.[0]?.id : '',
      })
    );
    setDisable(managementBase?.clientType !== UnitType.CSDT);
    dispatch(getListReportItemSuccess(DefaultPage));
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (!params.clientId) return;
    getListReport(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffectOnce(() => {
    return () => {
      handleRefresh();
    };
  });

  useEffectOnce(() => {
    getListTrainingFacility();
    dispatch(startGetListLicense());
  });

  useEffect(() => {
    if (managementBase?.clientType === UnitType.CSDT) {
      setListOptionTF([
        {
          ...listTF?.[0],
        },
      ]);

      form.resetFields();
      if (listTF?.[0]?.id) {
        dispatch(getListCourse(listTF?.[0]?.id));
      }
      return;
    }
    setListOptionTF(orderBy(listTF, ['name'], ['asc']));
  }, [listTF]);

  return (
    <StyledFilter id="filterReportManager">
      <Form autoComplete="off" labelAlign="left" onFinish={handleFinishFrom} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <Row gutter={[24, 24]} align={'bottom'}>
          <Col xxl={8} lg={12} md={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'practiceReport.trainingFacility' })}
              name={'clientId'}
              initialValue={managementBase?.clientType === UnitType.CSDT ? [listTF?.[0]?.id] : []}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'practiceReport.trainingFacility' }) + intl.formatMessage({ id: 'validator.require' }),
                },
              ]}
            >
              <Cascader
                showSearch={{ filter }}
                options={listOptionTF}
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
                disabled={managementBase?.clientType === UnitType.CSDT}
                onChange={handleChangeCSDT}
                allowClear={false}
                id="practiceReportCascader"
                dropdownMenuColumnStyle={{
                  maxWidth: document.getElementById('practiceReportCascader')?.offsetWidth || 300,
                }}
                placeholder={intl.formatMessage({ id: 'practiceReport.placeholderTrainingFacility' })}
              />
            </Form.Item>
          </Col>
          <Col xxl={8} lg={12} md={24}>
            <Form.Item
              label={intl.formatMessage({ id: 'common.time' })}
              name="time"
              initialValue={[moment().subtract(3, 'months'), moment()]}
            >
              <CRangePicker format={'DD/MM/YYYY'} allowClear={false} disabledDate={disabledDate} disabled={disable} />
            </Form.Item>
          </Col>
          <Col xxl={8} lg={12} md={24}>
            <Form.Item label={intl.formatMessage({ id: 'course.courseCode' })} name="code">
              <CSelect
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, optionsParentId: any) => (optionsParentId?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={listCourse.map(item => ({ label: item.code, value: item.code }))}
                disabled={disable}
                placeholder={intl.formatMessage({ id: 'mapMonitor.chooseCourse' })}
              />
            </Form.Item>
          </Col>

          <Col xxl={8} lg={12} md={24}>
            <Form.Item label={intl.formatMessage({ id: 'course.tranningNetwork' })} name="licenseClass">
              <CSelect
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, optionsParentId: any) => (optionsParentId?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={orderBy(listLicense, ['licenseClass'], ['asc'])?.map((item: LicenseType) => ({
                  label: item.licenseClass,
                  value: item.licenseClass,
                }))}
                disabled={disable}
                placeholder={intl.formatMessage({ id: 'practiceReport.placeholderLicenseClass' })}
              />
            </Form.Item>
          </Col>
          <Col xxl={8} lg={12} md={0} />
          <Col xxl={8} lg={12} md={24} className="wrap-buttons">
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} htmlType="submit">
              {intl.formatMessage({ id: 'common.search' })}
            </CButton>
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} onClick={handleRefreshForm}>
              {intl.formatMessage({ id: 'userGroupManager.delete' })}
            </CButton>
          </Col>
        </Row>
      </Form>
    </StyledFilter>
  );
};

export default Header;
