import AccountPage from './page';
import { RouterConfig } from 'router/AppRoute';
import paths from 'helper/pathRoutes';

const routes: RouterConfig[] = [
  {
    path: paths.account_manager,
    page: AccountPage,
  },
];

export default routes;
