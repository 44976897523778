export interface ParamsType {
  page: number;
  size: number;
  clientId?: string;
  startDate?: string;
  endDate?: string;
  code?: string;
  licenseClass?: string;
}

export interface ParamsTF {
  clientType: number;
}

export interface ParamsReportItem {
  id: string;
}

export interface PracticeReportItem {
  fullName: string;
  birthday: string;
  code: string;
  id: string;
  licenseClass: string;
  courseId: string;
}

export interface TypeTF {
  children: null | TypeTF[];
  id: string;
  name: string;
  parentId: null | string;
  disabled?: boolean;
}

export interface TrainingInfo {
  stt: number;
  code: string;
  fullName: string;
  studentCode: string;
  studentId?: string;
  birthDay: string;
  totalDuration: null | string;
  totalDistance: null | string;
  note: string;
}

export interface TypeItemView {
  code: string;
  id: string;
  licenseClass: string;
  schoolName: string;
  student: TrainingInfo[];
  startDate: string;
  endDate: string;
}

export interface CourseType {
  id: string;
  code: string;
  name: string;
  startDate: string;
  endDate: string;
  licenseClass: string;
}

export interface LicenseType {
  id: string;
  licenseClass: string;
  hour: number;
  distance: number;
}

export const dataItemViewDefault = {
  fullName: '',
  birthday: '',
  code: '',
  id: '',
  licenseClass: '',
  schoolName: '',
  student: [],
  startDate: '',
  endDate: '',
};
