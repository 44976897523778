import styled from 'styled-components/macro';

export const WakeupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 300px;
  .fa-2xl {
    font-size: 5em;
    color: #606266;
  }
`;

export const WakeupTitle = styled.div`
  font-size: 25px;
  color: #606266;
`;
