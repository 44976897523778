import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MenuFoldOutlined } from 'assets/imgs/MenuFoldOutlined.svg';

import { collapsedSelect } from 'components/layouts/selector/leftMenuSelector';
import { setCollapsed } from 'components/layouts/redux/leftMenu';
import { clientSelectedSelector } from 'modules/auth/selectors';
import { ClientName, IconMenu, WrapperLeftHeader } from '../styles';

const LeftHeader = () => {
  const dispatch = useDispatch();
  const collapsed = useSelector(collapsedSelect);
  const clientSelected = useSelector(clientSelectedSelector);
  const handleChange = () => {
    dispatch(setCollapsed());
  };
  return (
    <WrapperLeftHeader>
      <IconMenu>{!collapsed && <MenuFoldOutlined onClick={handleChange} />}</IconMenu>
      <ClientName>{clientSelected.clientName}</ClientName>
    </WrapperLeftHeader>
  );
};

export default LeftHeader;
