import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirm from 'components/commons/modalConfirm';
import { deviceManagerSelector, openFilterSelector } from '../selector';
import { WrapperActionTable } from 'modules/role/page/style';
import {
  deleteAddDevice,
  deleteDevice,
  deleteDeviceWareHouse,
  getDetailDevice,
  getListDevice,
  getListDeviceParam,
  getListWareHouse,
  setParams,
  setQsValue,
  setShowModal,
  setTypeModal,
} from '../redux';
import AddEditViewDevice from './AddEditViewDevice';
import AddEditViewADevice from './AddEditViewAddDevice';
import { ParamsPayloadType } from '../type';
import includes from 'lodash/includes';
import { useRolesByRouter } from 'helper/cusHooks';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { ActionType } from 'helper/constants';
import CTable from 'components/commons/Table';
import CustomSearch from 'components/commons/Search';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import { MenuProps } from 'rc-menu';
import { RowHeader, Text, WrapperButton } from 'components/commons/Template/style';
import { CReloadButton } from 'components/commons/ReloadButton';
import { typeLanguageSelector } from 'languages/selectors';
import { Tabs } from 'antd';
import HttpRequest from 'core/http/singleton/auth';


interface DataType {
  key: React.Key;
  name: string;
  code: string;
  status: string;
}
const Grid = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [tab, setTab] = useState('1');
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const openFilter = useSelector(openFilterSelector);
  const langType = useSelector(typeLanguageSelector);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const dispatch = useDispatch();
  const { listDevice, loadingTable, params, showModal, typeModal, qsValue, listWareHouse } = useSelector(deviceManagerSelector);
  const listRoles = useRolesByRouter();
  const intl = useIntl();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const renderMenuItemsMore = (record: DataType): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => handleEditDevice(record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteDevice(record)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRoles, item?.key));
  };
  const columnsDevice: ColumnsType<DataType> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'device.deviceCode' }),
      dataIndex: 'code',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'device.deviceName' }),
      dataIndex: 'name',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'device.deviceType' }),
      dataIndex: 'modelType',
      width: 200,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'device.practiceCar' }),
      dataIndex: 'vehicle',
      width: 200,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value?.licensePlate} placement="topLeft">
            <Text>{value?.licensePlate}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      fixed: 'right',
      width: 150,
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewDevice(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
              <IconMore className="iconMore" />
            </Dropdown>
          </WrapperActionTable>
        );
      },
    },
  ];

  const columnsWarehouse: ColumnsType<DataType> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.cameraSn' }),
      dataIndex: 'cameraSn',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.password' }),
      dataIndex: 'password',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.serialTablet' }),
      dataIndex: 'serialTablet',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      fixed: 'right',
      width: 150,
      render(value, record, index) {
        return (
          <CButton typeCustom={TypeCustom.Action} onClick={() => handleDelete(record)}>
            {<FormattedMessage id={'common.delete'} />}
          </CButton>
        );
      },
    },
  ];

  const handleSetQsValue = (value: string) => {
    dispatch(setQsValue(value));
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };

  const handleAddDevice = () => {
    handleSetTypeModal(ActionType.ADD);
    dispatch(setShowModal(true));
  };

  const handleAddWareHouse = () => {
    handleSetTypeModal(ActionType.ADD);
    dispatch(setShowModal(true));
  };

  const handleViewDevice = (itemEdit: any) => {
    handleSetTypeModal(ActionType.VIEW);
    dispatch(getDetailDevice(itemEdit?.id));
  };

  const handleEditDevice = (itemEdit: any) => {
    handleSetTypeModal(ActionType.EDIT);
    dispatch(getDetailDevice(itemEdit?.id));
  };

  const handleDeleteDevice = (itemEdit: any) => {
    if (itemEdit?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteDevice([itemEdit?.id]));
        },
      });
    }
  };

  const handleDeleteMutiDevices = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteAddDevice(selectedRowKeys));
        setSelectedRowKeys([]);
      },
    });
  };

  const handleDeleteMutiAddDevices = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: async () => {
        dispatch(deleteDeviceWareHouse(selectedRowKeys));
        dispatch(
          setParams(params)
        );
        setSelectedRowKeys([]);
      },
    });
  };

  const getListUserByPr = (params: ParamsPayloadType) => {
    dispatch(getListDevice(params));
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterDevicesManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperDevicesManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163;
  };

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        page: 0,
        q: value,
      })
    );
  };

  const handleQuickSearchWareHouse = (value: string) => {
    dispatch(
      setParams({
        ...params,
        page: 0,
        q: value,
      })
    );
  }

  const handleRefresh = () => {
    handleSetQsValue('');
    dispatch(setParams({ page: 0, size: 9, q: '' }));
  };

  useEffect(() => {
    setTimeout(changeHeightTable, 500);
  }, [openFilter]);

  useEffect(() => {
    getListUserByPr(params);
    dispatch(getListWareHouse((params)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, langType]);

  useEffect(() => {
    dispatch(getListDeviceParam());
    return () => {
      dispatch(setParams({ page: 0, size: 9, q: '' }));
      handleSetQsValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [
    {
      key: '1',
      label: <FormattedMessage id={'layout.leftMenu.addDevice'} />,
      children: '',
    },
    {
      key: '2',
      label: <FormattedMessage id={'layout.leftMenu.addDeviceMXC'} />,
      children: '',
    },
  ];

  const handleDelete = async (data: any) => {
    if (data?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: async () => {
          let res = await dispatch(deleteDeviceWareHouse([data.id]));
          if (res.payload) {
            dispatch(getListWareHouse((params)))
          }
        },
      });
    }

  }

  const onChangeTab = (key: string) => {
    setTab(key)
  };

  useEffect(() => {
    handleQuickSearch('')
    handleSetQsValue('')
  }, [])


  useEffect(() => {
    dispatch(getListWareHouse((params)))
    dispatch(getListDevice((params)))
  }, [showModal])


  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
      {tab === '1' ? <div>
        <RowHeader>
          <WrapperButton>
            <CustomSearch
              tooltip={intl.formatMessage({ id: 'device.tooltipQS' })}
              onSearch={handleQuickSearch}
              value={qsValue}
              setValue={handleSetQsValue}
            />
            <CReloadButton onClick={handleRefresh} />
          </WrapperButton>
          <WrapperButton>
            {includes(listRoles, actionsType.DELETE) && (
              <CButton
                typeCustom={TypeCustom.Secondary}
                sizeCustom={TypeSizeCustom.Medium}
                activeCustom={selectedRowKeys.length ? true : false}
                disabledCustom={selectedRowKeys.length ? false : true}
                onClick={handleDeleteMutiDevices}
              >
                <FormattedMessage id="common.delete" />
              </CButton>
            )}
            {includes(listRoles, actionsType.CREATE) && (
              <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddDevice}>
                <FormattedMessage id="common.add" />
              </CButton>
            )}
          </WrapperButton>
        </RowHeader>
        <Row>
          <CTable
            rowSelection={rowSelection}
            loading={loadingTable}
            columns={columnsDevice}
            rowKey={'id'}
            dataSource={listDevice?.content}
            style={{ width: '100%' }}
            scroll={{ y: changeHeightTable() }}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: [9, 18, 27],
              current: params.page + 1,
              pageSize: params.size,
              size: 'small',
              total: listDevice?.totalElements,
              showQuickJumper: true,
              showTotal: TotalMessage,
              locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
            }}
            onChange={handleChangeTable}
          />
        </Row>
        {showModal && <AddEditViewDevice type={typeModal} />}
      </div> : ''}
      {tab === '2' ? <div>
        <RowHeader>
          <WrapperButton>
            <CustomSearch
              tooltip={intl.formatMessage({ id: 'device.tooltipQS' })}
              onSearch={handleQuickSearchWareHouse}
              value={qsValue}
              setValue={handleSetQsValue}
            />
            <CReloadButton onClick={handleRefresh} />
          </WrapperButton>
          <WrapperButton>
            {includes(listRoles, actionsType.DELETE) && (
              <CButton
                typeCustom={TypeCustom.Secondary}
                sizeCustom={TypeSizeCustom.Medium}
                activeCustom={selectedRowKeys.length ? true : false}
                disabledCustom={selectedRowKeys.length ? false : true}
                onClick={handleDeleteMutiAddDevices}
              >
                <FormattedMessage id="common.delete" />
              </CButton>
            )}
            {includes(listRoles, actionsType.CREATE) && (
              <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddWareHouse}>
                <FormattedMessage id="common.add" />
              </CButton>
            )}
          </WrapperButton>
        </RowHeader>
        <Row>
          <CTable
            rowSelection={rowSelection}
            loading={loadingTable}
            columns={columnsWarehouse}
            rowKey={'id'}
            dataSource={listWareHouse?.content}
            style={{ width: '100%' }}
            scroll={{ y: changeHeightTable() }}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: [9, 18, 27],
              current: params.page + 1,
              pageSize: params.size,
              size: 'small',
              total: listDevice?.totalElements,
              showQuickJumper: true,
              showTotal: TotalMessage,
              locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
            }}
            onChange={handleChangeTable}
          />
        </Row>
        {showModal && <AddEditViewADevice type={typeModal} />}
      </div> : ''}
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;




