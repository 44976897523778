import { Col } from 'antd';
import CModal from 'components/commons/Modal';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  .iconReload {
    cursor: pointer;
    transition: all 0.25s linear;
    &:hover {
      opacity: 0.7;
    }
  }

  td.table-course-action > div {
    height: 32px;
  }

  td.table-course-action button {
    position: absolute;
    left: 16px;
    height: 32px;
  }

  td.table-course-action svg {
    position: absolute;
    left: 100px;
  }
`;

export const StyledModal = styled(CModal)`
  .buttonsWrap {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 10px;
      min-width: 120px;
    }
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-divider {
    margin: 20px 0;
  }

  .ant-picker {
    width: 100% !important;
  }

  .switchActive {
    margin-top: 12px;

    .ant-row {
      flex-direction: row;
    }

    .ant-form-item-label {
      padding-bottom: 0;

      label {
        padding-bottom: 0;
        line-height: 2.3;
      }
    }

    .spanActive {
      padding-left: 10px;
    }
  }

  .ant-select-selector {
    max-height: 205px;
    overflow: auto;
  }
`;

export const WrapperBtnAction = styled(Col)`
  text-align: right;
  button {
    margin-left: 8px;
  }
`;

export const StyledTitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.primary};
  margin: 0 0 12px;
  font-weight: 500;
  line-height: 18px;
`;
