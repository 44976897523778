import { all, put, takeLatest } from 'redux-saga/effects';
import { NotificationError } from 'components/notifications';
import { apiGetMenu } from '../services';
import { getMenuStart, getMenuSuccess, getMenuFail, MenuItem } from 'components/layouts/redux/leftMenu';
import { logout } from 'modules/auth/redux';
import Storage from 'helper/storage';

function* getMenuWorker(): any {
  try {
    const refreshToken = Storage.getRefreshToken();
    if (!refreshToken) {
      yield put(getMenuFail());
      yield put(logout());
      return;
    }
    const res: MenuItem[] = yield apiGetMenu();
    yield put(getMenuSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getMenuFail());
  }
}

export default function* leftMenuSaga(): any {
  return all([yield takeLatest(getMenuStart, getMenuWorker)]);
}
