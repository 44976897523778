import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { DeviceManagerState } from './redux';

export const deviceManagerSelector = (state: RootState) => state.device;
export const deviceManagerStateSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state);
export const paramsSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state.params);
export const itemDataSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state.itemData);
export const openFilterSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state.openFilter);
export const deviceModelsSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state.deviceModels);
export const vehiclesSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state.vehicles);
export const timeCountDownOtpSelector = createSelector(deviceManagerSelector, (state: DeviceManagerState) => state.timeCountDownOtp);
