import { ItemSort, Label } from '../../style';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'antd/es/select';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearch } from '../../../redux';
import {
  listCourseSelector,
  listLicensePlateSelector,
  listStudentSelector,
  paramsSelector,
  listInstructorSelector,
} from '../../../selector';
import { GetListFleetViewRequest, OptionItem, OptionStudentItem } from '../../../api/types';
import { removeVietNamese } from 'helper/utils';
import { ListFilter } from './style';

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const params: GetListFleetViewRequest = useSelector(paramsSelector);
  const optionLicensePlate: OptionItem[] = useSelector(listLicensePlateSelector);
  const optionListStudent: OptionStudentItem[] = useSelector(listStudentSelector);
  const optionListInstructor: OptionStudentItem[] = useSelector(listInstructorSelector);
  const optionListCourse: OptionItem[] = useSelector(listCourseSelector);

  const onChange = (value: object) => {
    dispatch(updateSearch(value));
  };

  return (
    <ListFilter>
      <ItemSort>
        <Label>
          <FormattedMessage id="course.title" />
        </Label>
        <Select
          size={'small'}
          placeholder={intl.formatMessage({ id: 'mapMonitor.chooseCourse' })}
          showSearch
          allowClear
          options={optionListCourse}
          onChange={value => onChange({ courseId: value })}
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          filterOption={(input: any, option: any) =>
            removeVietNamese(option?.name?.toLowerCase() ?? '').includes(removeVietNamese(input?.toLowerCase()))
          }
          value={params.courseId || undefined}
        />
      </ItemSort>
      <ItemSort>
        <Label>
          <FormattedMessage id="mapMonitor.licensePlate" />
        </Label>
        <Select
          size={'small'}
          placeholder={intl.formatMessage({ id: 'mapMonitor.chooseLicensePlate' })}
          showSearch
          allowClear
          options={optionLicensePlate}
          onChange={value => onChange({ vehicleId: value })}
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          filterOption={(input: any, option: any) =>
            removeVietNamese(option?.name?.toLowerCase() ?? '').includes(removeVietNamese(input?.toLowerCase()))
          }
          value={params.vehicleId || undefined}
        />
      </ItemSort>

      <ItemSort>
        <Label>
          <FormattedMessage id="common.teacher" />
        </Label>
        <Select
          size={'small'}
          placeholder={intl.formatMessage({ id: 'mapMonitor.chooseTeacher' })}
          showSearch
          allowClear
          options={optionListInstructor.map(item => {
            return {
              value: item.id,
              label: item.name + '_' + item.citizenIdCardNo,
            };
          })}
          onChange={value => onChange({ instructorId: value })}
          filterOption={(input: any, option: any) =>
            removeVietNamese(option?.label?.toLowerCase() ?? '').includes(removeVietNamese(input?.toLowerCase()))
          }
          value={params.instructorId || undefined}
        />
      </ItemSort>
      <ItemSort>
        <Label>
          <FormattedMessage id="mapMonitor.student" />
        </Label>
        <Select
          size={'small'}
          placeholder={intl.formatMessage({ id: 'mapMonitor.chooseStudent' })}
          showSearch
          allowClear
          options={optionListStudent.map(item => {
            return {
              value: item.id,
              label: item.name + '_' + item.citizenIdCardNo,
            };
          })}
          onChange={value => onChange({ studentId: value })}
          filterOption={(input: any, option: any) =>
            removeVietNamese(option?.label?.toLowerCase() ?? '').includes(removeVietNamese(input?.toLowerCase()))
          }
          value={params.studentId || undefined}
        />
      </ItemSort>
    </ListFilter>
  );
}

export default Filter;
