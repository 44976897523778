import { Col, Form, Row, FormInstance } from 'antd';
import { ActionType, DateFormat } from 'helper/constants';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { courseManagerSelector, itemDataSelector } from '../selector';
import CInput from 'components/commons/Input';
import CSelect from 'components/commons/Select';
import { orderBy } from 'lodash';
import CDatePicker from 'components/commons/DatePicker';
import { RangePickerProps } from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import { setInstructorsWorking0 } from '../redux';
interface Iprops {
  form: FormInstance;
  type: string;
}

const FormCourse = (props: Iprops) => {
  const { typeModal, students, licenses, instructors, instructorsWorking0 } = useSelector(courseManagerSelector);
  const { type, form } = props;
  const itemData = useSelector(itemDataSelector);
  const intl = useIntl();
  const dispatch = useDispatch();

  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = form.getFieldValue(field);
      form.setFieldValue(field, value.trim());
      form.validateFields([field]);
    }, 0);
  };

  const disabledStartDate: RangePickerProps['disabledDate'] = (startValue: any) => {
    return moment(startValue).isBefore(moment());
  };

  const disabledEndDate: RangePickerProps['disabledDate'] = (endValue: any) => {
    return moment(endValue).isBefore(moment());
  };

  const disabledExaminationDate: RangePickerProps['disabledDate'] = (expectedExaminationDate: any) => {
    return moment(expectedExaminationDate).isBefore(moment());
  };

  const handleChangeSelectIntructors = (values: string[]) => {
    if (instructorsWorking0.length === 0) return;
    const arr = [...instructorsWorking0].filter(item => values.includes(item?.id));
    dispatch(setInstructorsWorking0(arr));
  };

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item
          label={intl.formatMessage({ id: 'course.courseCode' })}
          name="code"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'course.courseCode' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
            },
          ]}
          initialValue={itemData?.code}
        >
          <CInput
            onPaste={(e: any) => {
              handlePates(e, 'code');
            }}
            disabled={type === ActionType.VIEW}
            maxLength={20}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={intl.formatMessage({ id: 'course.courseName' })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'course.courseName' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
            },
          ]}
          initialValue={itemData?.name}
        >
          <CInput
            onPaste={(e: any) => {
              handlePates(e, 'name');
            }}
            disabled={type === ActionType.VIEW}
            maxLength={255}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={intl.formatMessage({ id: 'course.tranningLevel' })}
          name="licenseClass"
          initialValue={itemData?.licenseClass}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'course.tranningLevel' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
            },
          ]}
        >
          <CSelect
            options={orderBy(licenses)?.map((item: string) => {
              return {
                value: item,
                label: item,
              };
            })}
            filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            disabled={type === ActionType.VIEW}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={'startDate'}
          label={intl.formatMessage({ id: 'course.startDate' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'course.startDate' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
            },
          ]}
          initialValue={itemData?.startDate ? moment(itemData?.startDate) : ''}
        >
          <CDatePicker
            format={DateFormat}
            onChange={() => form.getFieldValue('endDate') && form.validateFields(['endDate'])}
            disabledDate={disabledStartDate}
            disabled={typeModal === ActionType.VIEW}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={'endDate'}
          label={intl.formatMessage({ id: 'course.endDate' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'course.endDate' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
            },
            ({ getFieldValue }: any) => ({
              validator(rule: any, value: Moment) {
                if (!value) {
                  return Promise.reject();
                }
                if (getFieldValue('startDate')) {
                  if (moment(value).startOf('day').isBefore(getFieldValue('startDate').startOf('day'))) {
                    return Promise.reject(intl.formatMessage({ id: 'course.fieldErrorDay1' }));
                  } else {
                    return Promise.resolve();
                  }
                }
              },
            }),
          ]}
          initialValue={itemData?.endDate ? moment(itemData?.endDate) : ''}
        >
          <CDatePicker
            format={DateFormat}
            onChange={() => form.getFieldValue('expectedExaminationDate') && form.validateFields(['expectedExaminationDate'])}
            disabledDate={disabledEndDate}
            disabled={typeModal === ActionType.VIEW}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={'expectedExaminationDate'}
          label={intl.formatMessage({ id: 'course.estimateExamDate' })}
          initialValue={itemData?.expectedExaminationDate ? moment(itemData?.expectedExaminationDate) : ''}
          rules={[
            ({ getFieldValue }: any) => ({
              validator(rule: any, value: Moment) {
                if (getFieldValue('endDate')) {
                  if (moment(value).subtract(1, 'day').startOf('day').isBefore(getFieldValue('endDate').startOf('day'))) {
                    return Promise.reject(intl.formatMessage({ id: 'course.fieldErrorDay2' }));
                  } else {
                    return Promise.resolve();
                  }
                }
              },
            }),
          ]}
        >
          <CDatePicker format={DateFormat} disabledDate={disabledExaminationDate} disabled={typeModal === ActionType.VIEW} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={intl.formatMessage({ id: 'common.teacher' })}
          name="instructors"
          initialValue={itemData?.instructors?.map((item: any) => item?.id)}
        >
          <CSelect
            options={orderBy(
              [...instructors, ...instructorsWorking0],
              ['isWorking', 'fullName', 'citizenIdCardNo'],
              ['desc', 'asc', 'asc']
            )?.map((item: any) => {
              return {
                value: item.id,
                label:
                  item.fullName +
                  '_' +
                  item.citizenIdCardNo +
                  (item?.isWorking === 0 ? intl.formatMessage({ id: 'course.optionQuitted' }) : ''),
              };
            })}
            mode="multiple"
            filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            disabled={type === ActionType.VIEW}
            onChange={handleChangeSelectIntructors}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={intl.formatMessage({ id: 'common.student' })}
          name="students"
          initialValue={itemData?.students?.map((item: any) => item?.id)}
        >
          <CSelect
            options={orderBy(students, ['fullName', 'citizenIdCardNo'], ['asc', 'asc'])?.map((item: any) => {
              return {
                value: item.id,
                label: `${item.fullName}_${item.citizenIdCardNo}`,
              };
            })}
            mode="multiple"
            filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            disabled={type === ActionType.VIEW}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default FormCourse;
