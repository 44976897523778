import React from 'react';
import { Line, LineConfig } from '@ant-design/plots';

const ChartStudentLine = () => {
  const data = [
    {
      year: '1',
      value: 100,
      category: 'Hà Nội',
    },
    {
      year: '1',
      value: 200,
      category: 'Đà Nẵng',
    },
    {
      year: '1',
      value: 80,
      category: 'Nam Định',
    },
    {
      year: '1',
      value: 150,
      category: 'TP Hồ Chí Minh',
    },
    {
      year: '2',
      value: 200,
      category: 'Hà Nội',
    },
    {
      year: '2',
      value: 300,
      category: 'Đà Nẵng',
    },
    {
      year: '2',
      value: 120,
      category: 'Nam Định',
    },
    {
      year: '2',
      value: 260,
      category: 'TP Hồ Chí Minh',
    },
    {
      year: '3',
      value: 300,
      category: 'Hà Nội',
    },
    {
      year: '3',
      value: 200,
      category: 'Đà Nẵng',
    },
    {
      year: '3',
      value: 60,
      category: 'Nam Định',
    },
    {
      year: '3',
      value: 350,
      category: 'TP Hồ Chí Minh',
    },
    {
      year: '4',
      value: 100,
      category: 'Hà Nội',
    },
    {
      year: '4',
      value: 150,
      category: 'Đà Nẵng',
    },
    {
      year: '4',
      value: 50,
      category: 'Nam Định',
    },
    {
      year: '4',
      value: 100,
      category: 'TP Hồ Chí Minh',
    },
    {
      year: '5',
      value: 150,
      category: 'Hà Nội',
    },
    {
      year: '5',
      value: 200,
      category: 'Đà Nẵng',
    },
    {
      year: '5',
      value: 100,
      category: 'Nam Định',
    },
    {
      year: '5',
      value: 60,
      category: 'TP Hồ Chí Minh',
    },
    {
      year: '6',
      value: 350,
      category: 'Hà Nội',
    },
    {
      year: '6',
      value: 500,
      category: 'Đà Nẵng',
    },
    {
      year: '6',
      value: 400,
      category: 'Nam Định',
    },
    {
      year: '6',
      value: 350,
      category: 'TP Hồ Chí Minh',
    },
  ];
  const config: LineConfig = {
    data,
    xField: 'year',
    yField: 'value',
    seriesField: 'category',
    point: {
      shape: ({ category }: any) => {
        return 'circle';
      },
    },

    color: ({ category }: any) => {
      switch (category) {
        case 'Hà Nội':
          return '#A155B9';
        case 'Đà Nẵng':
          return '#00B4D8';
        case 'Nam Định':
          return '#F765A3';
        case 'TP Hồ Chí Minh':
          return '#165BAA';

        default:
          return 'black';
      }
    },
    yAxis: {
      grid: {
        line: { style: { fill: '#f7f7f7', opacity: 0.5 } },
      },
      label: {
        style: {
          fill: 'var(--2-b-2-b-2-b, #2B2B2B)',
        },
      },
    },

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
      line: {
        style: {
          opacity: 0,
        },
      },
      tickLine: {
        length: 0,
      },
      title: {
        text: 'Tháng',
        position: 'left',
        offset: 14,
        style: {
          x: 20,
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
    },
    legend: {
      flipPage: false,
    },
    tooltip: {
      title(title, datum) {
        return `Tháng ${title}`;
      },
    },
  };

  return <Line {...config} style={{ marginTop: 10, height: '30.5vh' }} />;
};

export default ChartStudentLine;
