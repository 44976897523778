import { Button, Layout, Menu, Avatar } from 'antd';
import Modal from 'components/commons/Modal';
import { Text } from 'components/commons/Template/style';
import styled from 'styled-components/macro';

export const WrapperFlagLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 9px 7px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.buttonWhite};
`;

export const IconFlag = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperChangeLanguage = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 26px 24px;

  .ant-select-arrow {
    inset-inline-end: 50px;
  }
`;

export const IconMenu = styled.div`
  cursor: pointer;
  position: absolute;
  left: -20px;
  top: 16px;
`;

export const WrapperLeftHeader = styled.div`
  display: flex;
  padding: 0 34px;
  height: 100%;
  align-items: flex-end;
`;

export const ClientName = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.primary};
`;

export const AvatarBox = styled(Avatar)`
  background-color: ${({ theme }) => theme.primary};
  cursor: pointer;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;

  .ant-typography {
    cursor: pointer;
    color: ${({ theme }) => theme.contentPlaceholder};
    margin: 8px;
    transition: all 0.25s linear;

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const HeaderAccount = styled.div`
  display: flex;
  padding-right: 36px;
  height: 56px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;

  .helperWrap {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(12px);
    cursor: pointer;
    transition: all 0.25s linear;
    height: 100%;
    padding-top: 8px;

    &:hover {
      opacity: 0.7;
    }

    span {
      line-height: 25px;
      top: 0;
      color: ${({ theme }) => theme.menuDisableDark};
    }
  }
`;

export const BtnSubmit = styled(Button)`
  display: block;
  margin: 30px auto 0;
`;

export const ModalAntd = styled(Modal)`
  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .btnWrap {
    text-align: center;
    .ant-btn {
      min-width: 120px;
      margin: 20px 15px 0;
    }
  }
`;

export const StyledLayout = styled(Layout)`
  height: 100vh;
  width: 100vw;
  .site-layout {
    background: ${({ theme }) => theme.menuBackground};
  }

  .logo {
    flex-direction: column;
    padding: 12px 0 12px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      &.image {
        height: 48px;
      }
      &.text {
        margin-top: 10px;
        height: 14px;
      }
    }
  }

  .breadcrumb {
    margin: 12px 24px 0 24px;
  }

  .trigger {
    margin-left: 20px;
    font-size: 20px;
  }
  .ant-layout-sider {
    max-width: 300px !important;
    flex: 0 0 260px !important;
  }

  .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
  }
`;

export const StyledMenu = styled(Menu)`
  &.ant-menu-light {
    background: ${({ theme }) => theme.tableSelectedActive} !important;
  }
  .ant-menu-item-selected {
    background: ${({ theme }) => theme.buttonPrimary} !important;
    color: ${({ theme }) => theme.buttonWhite} !important;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    color: ${({ theme }) => theme.menuDefault};
  }

  .ant-menu-title-content {
    font-size: 14px;
  }
  

  &.ant-menu {
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: #ffffff !important;
        background: ${({ theme }) => theme.primary} !important;
        svg {
          path {
            fill: #ffffff !important;
          }
        }
      }
      .ant-menu-item-selected {
        font-weight: 600;
        color: ${({ theme }) => theme.primary} !important;
        background-color: transparent !important;
      }
    }
  }
`;

export const StyledWrapSync = styled('div') <{ $hasChange?: boolean }>`
  z-index: 1;
  margin-left: 50px;
  background-color: ${({ theme, $hasChange }) => ($hasChange ? '#F6D300' : theme.strokeLineLight)};
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 20px;
  height: 40px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;

    button {
      height: 27px;
      line-height: 14px;

      box-shadow: none;
      margin-right: 4px;

      &:disabled {
        background-color: #f5f5f5;
      }
    }
  }

  .textLeft {
    padding-left: 10px;
    span {
      color: ${({ theme }) => theme.primary};
      font-weight: bold;
    }
  }

  .textCenter {
    span {
      font-weight: bold;
      color: ${({ theme }) => theme.titleDefault};
    }
  }
`;

export const StyledText = styled(Text)`
  color: rgba(0, 0, 0, 0.88);
  font-weight: bold;
  font-size: 14px;
`;

export const StyleServiceNotify = styled.div<{ $isDisplayed: boolean }>`
  background-color: rgba(246, 211, 0, 0.6);
  height: 36px;
  align-items: center;
  display: ${({ $isDisplayed }) => ($isDisplayed ? 'flex' : 'none')};
  .anticon {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    color: ${({ theme }) => theme.statusRed};
  }
  .anticon.anticon-close {
    position: absolute;
    right: 36px;
    margin-right: 0 !important;
    cursor: pointer;
  }
  .notify-payment {
    font-weight: 600;
    font-size: 14px;
  }
  .notify-payment div,
  .notify-payment span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.statusRed};
  }
`;

export const UserCode = styled.div`
  line-height: 32px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`
