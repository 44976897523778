import { all, put, select, takeLatest, call, delay } from 'redux-saga/effects';
import {
  getLessons,
  getLessonsSuccess,
  getLessonsFail,
  getListTFSuccess,
  setParams,
  getListTFFail,
  getListTF,
  setLoadingModal,
  getEventsSuccess,
  getEventsStart,
  getTripsSuccess,
  setInfoUser,
  getDetailHistoryStart,
  getDetailTripStart,
  setLessonDetail,
  getTrackSuccess,
  getTrackStart,
  runOffset,
} from '../redux';
import { getEvents, getListLessonApi, getListTrainingFacility, getTrips } from '../services';
import { NotificationError } from 'components/notifications';
import { indexActiveTripSelector, isRunOffsetSelector, paramsSelector, tripsSelector } from '../selector';
import { ManagementBaseType } from 'modules/auth/types';
import { managementBaseSelector } from 'modules/auth/selectors';
import { UnitType } from 'helper/constants';
import { EventItem, TrackItem, TripItem } from '../type';
import moment from 'moment';
import numeral from 'numeral';
import { chunkArray } from 'helper/utils';
import { dataGoogleMapSelector } from 'modules/mapMonitor/selector';
import { GetSnapToRoadResponse, SnappedPointsItem } from 'modules/mapMonitor/api/types';
import { setMap } from 'modules/mapMonitor/redux';

function* handleGetLessons(action: ReturnType<typeof getLessons>): any {
  try {
    const res = yield getListLessonApi(action.payload);
    yield put(getLessonsSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getLessonsFail());
  }
}

function* getEventsWorker(action: ReturnType<typeof getEventsStart>): any {
  try {
    const trips = yield select(tripsSelector);
    const indexActiveTrip = yield select(indexActiveTripSelector);
    const response: EventItem[] = yield getEvents(action.payload);
    yield put(getEventsSuccess(response));
    yield put(setLoadingModal(false));
    if (indexActiveTrip !== -1) {
      yield put(getTrackStart(trips[indexActiveTrip].gpsSeq));
    }
  } catch (err: any) {
    NotificationError(err);
    yield put(setLoadingModal(false));
  }
}

function* getTrackWorker(action: ReturnType<typeof getTrackStart>): any {
  try {
    const responseTrack: TrackItem[] = action.payload;
    if (responseTrack.length === 0) return;
    const myArr: string[] = [];
    let result: string[][] = [];
    responseTrack?.forEach((items: TrackItem, index: number) => {
      if (items) {
        myArr.push(`${items.coordinate[1]},${items.coordinate[0]}`);
      }
      if (index === myArr.length - 1) {
        result = chunkArray(myArr, 100);
      }
    });

    const { key, map } = yield select(dataGoogleMapSelector);
    let arrayApi: SnappedPointsItem[] = [];
    for (let index = 0; index < result.length; index++) {
      const responseSnapToRoad: any = yield call(
        fetch,
        `https://roads.googleapis.com/v1/snapToRoads?interpolate=true&key=${key}&path=${result[index].join('|')}`,
        {
          method: 'GET',
        }
      );
      const responseBody: GetSnapToRoadResponse = yield responseSnapToRoad.json();
      arrayApi = arrayApi.concat(responseBody.snappedPoints);
    }
    let snappedCoordinates: { lat: number; lng: number }[] = [];
    const bounds = new window.google.maps.LatLngBounds();

    arrayApi.forEach((value: SnappedPointsItem) => {
      const location: { latitude: number; longitude: number } = value.location;
      bounds.extend({
        lat: location.latitude,
        lng: location.longitude,
      });
      snappedCoordinates.push({ lat: location.latitude, lng: location.longitude });
    });

    yield put(getTrackSuccess(snappedCoordinates));
    map.fitBounds(bounds);
  } catch (err: any) {
    NotificationError(err);
  }
}

function* setMapWorker(action: ReturnType<typeof setMap>): any {
  try {
    if (action.payload) {
      const trips = yield select(tripsSelector);
      if (!trips[0]?.gpsSeq) return;
      yield put(getTrackStart(trips[0].gpsSeq));
    }
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getDetailHistoryWorker(action: ReturnType<typeof getDetailHistoryStart>): any {
  try {
    yield put(setLessonDetail(action.payload));
    const resTrips: TripItem[] = yield getTrips({ lessonId: action.payload.id });
    let totalDistance = 0;
    let totalTime = 0;
    const tripsNew = resTrips?.map((item, index) => {
      totalDistance += item.distance;
      totalTime += item.time;
      return item;
    });
    yield put(getTripsSuccess(tripsNew));
    yield put(
      setInfoUser({
        distance: numeral(totalDistance / 1000).format('0.00'),
        time: moment.utc(totalTime).format('H[h] mm[m]'),
      })
    );

    if (resTrips?.length) {
      yield put(getEventsStart({ tripId: resTrips[0]?.tripId }));
    }
    yield put(setLoadingModal(false));
  } catch (err: any) {
    yield put(setLoadingModal(false));
    NotificationError(err);
  }
}

function* getDetailTripWorker(action: ReturnType<typeof getDetailTripStart>): any {
  try {
    yield put(getEventsStart(action.payload));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* handleGetListTF(): any {
  try {
    const { clientType }: ManagementBaseType = yield select(managementBaseSelector);
    const res = yield getListTrainingFacility({
      clientType: clientType,
    });
    yield put(getListTFSuccess(res));
    if (clientType === UnitType.CSDT) {
      const params = yield select(paramsSelector);
      yield put(setParams({ ...params, clientId: res?.[0]?.id }));
    }
  } catch (e: any) {
    yield put(getListTFFail());
    NotificationError(e);
  }
}

function* runOffsetWorker(): any {
  try {
    yield delay(100);
    const isRunOffset = yield select(isRunOffsetSelector);
    if (!isRunOffset) return;
    else yield put(runOffset());
  } catch (err: any) {
    NotificationError(err);
  }
}

export default function* monitorHistorySaga(): any {
  return all([
    yield takeLatest(getLessons, handleGetLessons),
    yield takeLatest(getEventsStart, getEventsWorker),
    yield takeLatest(getListTF, handleGetListTF),
    yield takeLatest(getDetailHistoryStart, getDetailHistoryWorker),
    yield takeLatest(getDetailTripStart, getDetailTripWorker),
    yield takeLatest(getTrackStart, getTrackWorker),
    yield takeLatest(runOffset, runOffsetWorker),
    yield takeLatest(setMap, setMapWorker),
  ]);
}
