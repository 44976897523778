import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AccountManagerState } from './redux';

export const accountManagerSelector = (state: RootState) => state.accountManager;
export const accountManagerStateSelector = createSelector(accountManagerSelector, (state: AccountManagerState) => state);
export const paramsSelector = createSelector(accountManagerSelector, (state: AccountManagerState) => state.params);
export const itemDataSelector = createSelector(accountManagerSelector, (state: AccountManagerState) => state.itemData);
export const listRolesSelector = createSelector(accountManagerSelector, (state: AccountManagerState) => state.listRole);
export const listGroupsSelector = createSelector(accountManagerSelector, (state: AccountManagerState) => state.listUserGroup);
export const openFilterSelector = createSelector(accountManagerSelector, (state: AccountManagerState) => state.openFilter);
