import React from 'react';
import ImgBackground from '../../asset/imgs/login-background.png';
import Logo from 'assets/imgs/mk-group-logo.png';
import Text from 'assets/imgs/mk-group-logo-text.svg';
import { Col, Spin } from 'antd';
import { Wrapper, WrapperFormLogin, WrapperImage, WrapperRow, LogoMk } from './styles';
import ModalChangePassword from 'components/layouts/authLayout/components/ModalChangePassword';
import FormLogin from '../components/FormLogin';
import { useSelector } from 'react-redux';
import { loadingSelector } from 'modules/sso/selectors';

const LoginPage = () => {
  const loading = useSelector(loadingSelector);
  return (
    <Spin spinning={loading}>
      <Wrapper>
        <WrapperRow>
          <Col md={{ span: 10 }} span={24} style={{ height: '100%' }}>
            <LogoMk>
              <img src={Logo} alt="Logo" className="image" />
              <img src={Text} alt="Logo" className="text" />
            </LogoMk>

            <WrapperFormLogin>
              <Col xl={{ span: 16, offset: 4 }} span={22}>
                <FormLogin />
              </Col>
            </WrapperFormLogin>
          </Col>
          <Col md={{ span: 14 }} span={0} style={{ height: '100%' }}>
            <WrapperImage>
              <img src={ImgBackground} alt="ImgBackground" />
            </WrapperImage>
          </Col>
        </WrapperRow>

        <ModalChangePassword />
      </Wrapper>
    </Spin>
  );
};

export default LoginPage;
