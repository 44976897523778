import CModal from 'components/commons/Modal';
import styled from 'styled-components';
import { Col } from 'antd';

export const Wrapper = styled.div`
  height: 100%;

  .iconReload {
    cursor: pointer;
    transition: all 0.25s linear;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledModal = styled(CModal)`
  .ant-modal {
    width: 60vw !important;
  }

  .ant-picker {
    width: 100%;
  }

  .buttonsWrap {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 10px;
      min-width: 120px;
    }
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-divider {
    margin: 20px 0;
  }

  .switchActive {
    margin-top: 12px;

    .ant-row {
      flex-direction: row;
    }

    .ant-form-item-label {
      padding-bottom: 0;

      label {
        padding-bottom: 0;
        line-height: 2.3;
      }
    }

    .spanActive {
      padding-left: 10px;
    }
  }
`;

export const TitleTrainingTab = styled.div`
  font-size: 16px;
  line-height: 29px;
  color: ${({ theme }) => theme.titleDefault};
`;

export const UploadContainer = styled.div`
  width: 100%;
  height: 140px;
`;

export const UploadWrap = styled.div`
  display: flex;
  margin: auto;
  justify-content: flex-start;
  text-align: center;
  transition: border-color 0.3s;
  :hover {
    border-color: #004c98;
  }
  .ant-upload {
    width: 134px !important;
    height: 134px !important;
  }
`;

export const ColStyled = styled(Col)`
  .close-icon {
    position: absolute;
    transform: translateX(100%);
    cursor: pointer;
    color: ${({ theme }) => theme.statusRed};
    background-color: white;
    border-radius: 50%;
    svg {
      font-size: 18px;
    }
  }
`;

export const FormContainer = styled.div`
  margin-bottom: 10px;
`

export const TitleDetail = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const RowStyled = styled.div`
  padding-left: 30px;
  margin-bottom: 24px;
  & > div > .ant-form-item {
    margin-bottom: 4px;
  }
`

export const StyledFilter = styled('div')`
  width: 100%;
  background-color: ${({ theme }) => theme.tableTitleBackground};
  border-radius: 8px;
  margin-top: 16px;
  //transition: all 0.5 linear;
  padding: 20px 25px;
  margin-bottom: 20px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-collapse-header {
    display: none !important;
  }

  .ant-picker {
    width: 100%;
  }

  .wrap-buttons {
    text-align: right;
    button {
      margin-left: 16px;
    }
  }
  .ant-row {
    align-items: unset;
  }
`;
