import CTable from '../../../components/commons/Table';
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Text } from '../../../components/commons/Template/style';
import { TabsProps, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { TitleTrainingTab } from '../page/styles';
import { useSelector } from 'react-redux';
import { studentManagerSelector } from '../selector';
import CTabs from '../../../components/commons/Tabs';
import { OverviewCourses } from '../type';
import { faClock, faRoad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

interface DataType {
  key: React.Key;
  name: string;
  code: string;
  status: string;
}
const TrainingInfo = () => {
  const intl = useIntl();
  const { dataDetail } = useSelector(studentManagerSelector)
  const [courseDetail, setCourseDetail] = useState<any>(dataDetail?.viewLicenses[0]);
  const [overview, setOverview] = useState<OverviewCourses[]>([]);
  const columnsOverview: ColumnsType<DataType> = [
    {
      title: '',
      align: 'center',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text>
          {index === 0 ? <FontAwesomeIcon icon={faClock} size="lg" /> : <FontAwesomeIcon icon={faRoad} size="lg" />}
        </Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'student.accomplished' }),
      dataIndex: 'accomplished',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.remaining' }),
      dataIndex: 'remaining',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
  ]

  const columnsDetail: ColumnsType<DataType> = [
    {
      title: intl.formatMessage({ id: 'student.trainingSessions' }),
      dataIndex: 'code',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.start' }),
      dataIndex: 'startTime',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{moment(value).format('hh:mm:ss DD/MM/yyyy')}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.end' }),
      dataIndex: 'endTime',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{moment(value).format('hh:mm:ss DD/MM/yyyy')}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.hours' }),
      dataIndex: 'duration',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value + 'h'}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.distance' }),
      dataIndex: 'distance',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value + 'km'}</Text>
          </Tooltip>
        );
      },
    },
  ]

  const items: TabsProps['items'] = dataDetail?.viewLicenses.map((item: any, index: number) => {
    return {
      key: index,
      label: item.courseCode + '_' + item.licenseClass
    };
  }) as unknown as TabsProps['items'];

  const onChangeTab = ((key: any) => {
    setCourseDetail(dataDetail?.viewLicenses[key])
  });

  useEffect(() => {
    setOverview([
      { accomplished: courseDetail?.hoursCompleted ? courseDetail?.hoursCompleted + 'h' : '', remaining: courseDetail?.hoursRemaining ? courseDetail?.hoursRemaining + 'h' : '' },
      { accomplished: courseDetail?.distanceCompleted ? courseDetail?.distanceCompleted + 'km' : '', remaining: courseDetail?.distanceRemaining ? courseDetail?.distanceRemaining + 'km' : '' }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetail]);

  return (
    <>
      <TitleTrainingTab>
        <CTabs defaultActiveKey="area" items={items} onChange={onChangeTab} />
      </TitleTrainingTab>
      <TitleTrainingTab>
        <FormattedMessage id="student.overview" />
      </TitleTrainingTab>
      <CTable
        columns={columnsOverview}
        dataSource={overview}
        style={{ width: '100%', marginBottom: '20px' }}
        pagination={false}
      />
      <TitleTrainingTab>
        <FormattedMessage id="student.detail" />
      </TitleTrainingTab>
      <CTable
        columns={columnsDetail}
        dataSource={courseDetail?.lesson}
        style={{ width: '100%', marginBottom: '20px' }}
        pagination={false}
      />
    </>
  )
}
export default TrainingInfo;
