import RestfulHttp from 'core/http/singleton/auth';
import { GetListVehicleRequest, GetListVehicleResponse, ItemEdit } from './types';
import { urlVehicleSearch, urlVehicle, urlParamDevice } from './urls';

export const getListVehicle = async (params: GetListVehicleRequest): Promise<GetListVehicleResponse> => {
  const res = await RestfulHttp.get<GetListVehicleResponse>(urlVehicleSearch, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getDetailVehicleService = async (id: string): Promise<any> => {
  const res = await RestfulHttp.get<any>(`${urlVehicle}/${id}`);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getParamDevice = async (): Promise<any> => {
  const res = await RestfulHttp.get<any>(urlParamDevice);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const postAddVehicle = async (params: ItemEdit): Promise<any> => {
  const res = await RestfulHttp.post<any>(urlVehicle, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const putAddVehicle = async (params: ItemEdit): Promise<any> => {
  const res = await RestfulHttp.put<any>(urlVehicle, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const deleteAddVehicle = async (params: string[]): Promise<any> => {
  const res = await RestfulHttp.delete<any>(urlVehicle, {
    data: params,
  });
  return res.data;
};
