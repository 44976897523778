import { Dropdown, MenuProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  dataTableSelector,
  loadingTableSelector,
  paramsSelector,
  selectedRowKeysSelector,
  totalElementSelector,
  typeModalSelector,
} from '../selector';
import { WrapperActionTable } from 'modules/role/page/style';
import { ContentItem } from 'modules/vehicleManagement/api/types';
import { deleteListVehicle, resetSearch, setSelectedRowKeys, setTypeModal, updateSearch, getDetailVehicle } from '../redux';
import { useEffectOnce } from 'react-use';
import ModalAddEditView from './ModalAddEditView';
import { ActionType } from 'helper/constants';
import { useRolesByRouter } from 'helper/cusHooks';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import CTable from 'components/commons/Table';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import ModalConfirm from 'components/commons/modalConfirm';
import CButton, { TypeCustom } from 'components/commons/Button';
import { TotalMessage } from 'modules/role/components/Grid';
import { Text } from 'components/commons/Template/style';

const Body = () => {
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const [heightTable, setHeightTable] = useState(0);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    dispatch(setSelectedRowKeys(newSelectedRowKeys));
  };

  const intl = useIntl();
  const typeModal = useSelector(typeModalSelector);
  const dispatch = useDispatch();
  const params = useSelector(paramsSelector);
  const loadingTable = useSelector(loadingTableSelector);
  const dataTable = useSelector(dataTableSelector);
  const totalElement = useSelector(totalElementSelector);
  const listRole = useRolesByRouter();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderMenuItemsMore = (record: ContentItem): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => openModalEditView(ActionType.EDIT, record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteItem(record.id)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRole, item?.key));
  };

  const columns: ColumnsType<ContentItem> = [
    {
      title: 'STT',
      align: 'left',
      fixed: 'left',
      width: 80,
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.licensePlate' }),
      dataIndex: 'licensePlate',
      width: 180,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.vehicleLicense' }),
      dataIndex: 'vehicleLicense',

      width: 200,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.brand' }),
      dataIndex: 'brand',

      width: 120,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.classification' }),
      dataIndex: 'classification',

      width: 120,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.device' }),
      dataIndex: 'device',

      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value?.code} placement="topLeft">
            <Text>{value?.code}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.ownerName' }),
      dataIndex: 'ownerName',

      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.ownerPhoneNumber' }),
      dataIndex: 'ownerPhoneNumber',

      width: 180,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      width: 140,
      fixed: 'right',
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRole, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => openModalEditView(ActionType.VIEW, record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            {(includes(listRole, actionsType.UPDATE) || includes(listRole, actionsType.DELETE)) && (
              <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
                <IconMore className="iconMore" />
              </Dropdown>
            )}
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleDeleteItem = (id: string) => {
    if (id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteListVehicle([id]));
        },
      });
    }
  };

  const openModalEditView = (type: ActionType, record: ContentItem) => {
    dispatch(setTypeModal(type));
    dispatch(getDetailVehicle(record.id));
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    dispatch(
      updateSearch({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  useEffectOnce(() => {
    return () => {
      dispatch(resetSearch());
    };
  });

  const changeHeightTable = () => {
    const heightWrapper = document.getElementById('wrapperUserGroup')?.offsetHeight;
    setHeightTable((heightWrapper ?? 0) - 205);
  };
  useEffect(() => {
    setTimeout(changeHeightTable, 500);
  }, []);
  return (
    <div>
      <CTable
        rowSelection={rowSelection}
        loading={loadingTable}
        columns={columns}
        rowKey={'id'}
        dataSource={dataTable}
        scroll={{ y: heightTable }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: [10, 20, 30],
          current: params.page + 1,
          pageSize: params.size,
          total: totalElement,
          showTotal: TotalMessage,
          locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
        }}
        onChange={handleChangeTable}
      />

      {typeModal !== null && <ModalAddEditView />}
    </div>
  );
};

export default Body;
