import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SsoState {
  loading: boolean;
}

export interface ConfirmTokenType {
  token: string;
}

const initialState: SsoState = {
  loading: false,
};

const ssoSlice = createSlice({
  name: 'ssoSlice',
  initialState,
  reducers: {
    confirmToken: (state, action: PayloadAction<ConfirmTokenType>) => {
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { confirmToken, setLoading } = ssoSlice.actions;

export default ssoSlice.reducer;
