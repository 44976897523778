import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { State as LanguageState } from "languages/redux";

export const dataSelector = (state: RootState) => state.language;

export const intlSelector = createSelector(
  dataSelector,
  (languageState: LanguageState) => languageState.intl
);

export const languageSelector = createSelector(
  dataSelector,
  (languageState: LanguageState) => languageState.lang
);

export const typeLanguageSelector = createSelector(
  dataSelector,
  (languageState: LanguageState) => languageState.type
);
