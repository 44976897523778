import { Input } from 'antd';
import styled from 'styled-components/macro';

export const StyledSearch = styled(Input.Search)`
  height: 36px;
  width: 379px !important;
  position: relative;

  .ant-input-wrapper {
    border-radius: 8px !important;

    .ant-input-affix-wrapper {
      min-height: 36px;
      border-radius: 8px !important;

      &:hover,
      :focus {
        border: 1px solid ${({ theme }) => theme.primary};
      }
    }

    .ant-input-group-addon {
      position: absolute;
      top: 0;
      width: 87px;
      inset-inline-start: calc(100% - 91px) !important;
      button {
        border-radius: 6px !important;
        height: 28px;
        margin-top: 4px;
        width: 100%;
        z-index: 10;
        font-weight: 600;
        font-size: 13px;
        box-shadow: none !important;
      }
    }
    .ant-input {
      width: calc(100% - 110px);
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: ${({ theme }) => theme.contentPlaceholder};
    }
  }
`;
