import React from 'react';
import { Wrapper } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { Spin, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { confirmToken } from '../redux';
import paths from 'helper/pathRoutes';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SsoPage = () => {
  let query = useQuery();
  const history = useHistory();
  const token = query.get('token');
  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (token) {
      dispatch(
        confirmToken({
          token: token,
        })
      );
    }
    history.push(paths.login);
  });

  return (
    <Wrapper>
      <Typography.Title level={2}>
        <div className="spin">
          <Spin size="large" />
        </div>

        <FormattedMessage id="common.authen" />
      </Typography.Title>
    </Wrapper>
  );
};

export default SsoPage;
