import HttpRequest from 'core/http/singleton/auth';
import {ParamsType} from '../type';

export const getListManagementByQS = async (params: ParamsType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/management-agency', params);
  return res.data;
};

export const getDetail = async (id: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/management-agency/${id}`);
  return res.data;
};

export const createManagement = async (data: any): Promise<any> => {
  const res = await HttpRequest.post<any>('api/management-agency', data);
  return res.data;
};

export const updateManagementById = async (data: any): Promise<any> => {
  const res = await HttpRequest.put<any>(`api/management-agency`, data);
  return res.data;
};

export const deleteManagementById = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>('api/management-agency', {
    data: data,
  });
  return res.data;
}

export const deleteMutiManagementById = async (ListId: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>(`api/management-agency`, {
    data: ListId,
  });
  return res.data;
};
