import React from 'react';
import Grid from '../components/Grid';
import { Wrapper } from './style';
import { FormattedMessage } from 'react-intl';
import { TitleHeader } from 'components/commons/Template/style';

const ManagementAgency = () => {
  return (
    <Wrapper id="wrapperManagement">
      <TitleHeader id="filterManagement">
        <FormattedMessage id="managementAgency.management_agency" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default ManagementAgency;
