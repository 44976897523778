import HttpRequest from 'core/http/singleton/auth';
import { DataPayloadAddDevice, DataPayloadCreateDevice, DrivingDevicesParamType, ParamsPayloadType } from '../type';

export const createDeviceService = async (data: DataPayloadCreateDevice): Promise<any> => {
  const res = await HttpRequest.post<any>('api/driving-devices', data);
  return res.data;
};

export const createWareHouseService = async (data: DataPayloadAddDevice): Promise<any> => {
  const res = await HttpRequest.post<any>('api/device-warehouse', data);
  return res.data;
};

export const getListDeviceByParams = async (params: ParamsPayloadType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/drivingDevice', params);
  return res.data;
};

export const getListWareHouseByParams = async (params: ParamsPayloadType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/deviceWarehouse', params);
  return res.data;
};

export const getDetailDeviceService = async (params: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/driving-devices/${params}`);
  return res.data;
};

export const updateDeviceService = async (data: DataPayloadCreateDevice): Promise<any> => {
  const res = await HttpRequest.put<any>('api/driving-devices', data);
  return res.data;
};

export const deleteDeviceService = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>('api/driving-devices', {
    data: data,
  });
  return res.data;
};

export const deleteAddDeviceService = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>('api/device-warehouse', {
    data: data,
  });
  return res.data;
};

export const getListDeviceParamService = async (): Promise<DrivingDevicesParamType> => {
  const res = await HttpRequest.get<any>('api/driving-devices/param');
  return res.data;
};




