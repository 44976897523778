import { Table } from 'antd';
import styled from 'styled-components/macro';

export const StyledCommonTable = styled(Table)`
  .ant-table {
    border-left: 1px solid ${({ theme }) => theme.strokeLineLight};
    border-right: 1px solid ${({ theme }) => theme.strokeLineLight};
    border-radius: 8px;
  }
  .ant-table-header {
    .ant-table-cell {
      background-color: ${({ theme }) => theme.strokeLineLight};
      color: ${({ theme }) => theme.titleTable};
    }
  }
  .ant-table-cell {
    color: ${({ theme }) => theme.contentTable};
    height: ${({ size }) => size === 'small' ? '40px' : '53px'};
  }

  .ant-table-row-selected {
    .ant-table-cell {
      background-color: ${({ theme }) => theme.tableSelectedActive} !important;
    }
  }

  .ant-pagination {
    margin-bottom: 11px !important;
    &-total-text {
      font-weight: 500;
      line-height: 22px;
      font-size: 14px;
      color: ${({ theme }) => theme.contentPlaceholder};
    }
  }
  .ant-pagination-options {
    position: absolute;
    left: 0;
  }

  .ant-select-dropdown {
    top: -110px !important;
  }
`;
