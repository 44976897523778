import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppRoute from 'router/AppRoute';
import { routes } from 'router/routes';
import { memo } from 'react';
import LoginPage from 'modules/auth/pages/ModalLogin';
import paths from 'helper/pathRoutes';
import MainLayout from 'components/layouts/mainLayout';
import NotFoundPage from 'modules/notfound/page';
import SsoPage from 'modules/sso/page';
import ForgotPasswordPage from 'modules/forgotPass/page';
import { ErrorBoundary } from 'react-error-boundary';

function AppRouter() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ErrorBoundary FallbackComponent={NotFoundPage}>
        <Switch>
          {/* Page ko có header, sideBar thì đặt ở đây */}
          {/* <Route path="abc" component={NotFoundPage} /> */}
          <Route path={paths.login} component={LoginPage} />
          <Route path={paths.sso} component={SsoPage} />
          <Route path={paths.forgotPassword} component={ForgotPasswordPage} />
          <MainLayout>
            {routes.map(route => {
              return <AppRoute key={route.path} {...route} />;
            })}
          </MainLayout>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

export default memo(AppRouter);
