import { Language, LanguageType, setIntlContext } from './redux';
import { IntlProvider, createIntl } from 'react-intl';
import React from 'react';
import { store, RootState } from '../redux/store';
import translate from './translate';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import initialLocale from 'vi-mkGroup.locale';

const IntlController: React.FC = ({ children }) => {
  const { lang } = useSelector((state: RootState) => state.language);
  return (
    <IntlProvider messages={translate[lang]} locale={lang || Language[LanguageType.VI]}>
      {children}
    </IntlProvider>
  );
};

initialLocale();
numeral.locale('vi-mkGroup');

const getCurrentLanguage = () => store.getState().language.lang;
const intl = createIntl({
  messages: translate[getCurrentLanguage()],
  locale: getCurrentLanguage() || Language[LanguageType.VI],
});

store.dispatch(setIntlContext(intl));

export default IntlController;
