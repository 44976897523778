import RestfulHttp from 'core/http/singleton/auth';
import { GetListUserRequest, GetListGroupCodeParam, GetListUserResponse, ListId, ItemEdit } from './types';
import { urlGroupsSearch, urlRoles, urlGroupCode, urlUserName, urlGroups } from './urls';

export const getListUser = async (params: GetListUserRequest): Promise<GetListUserResponse> => {
  const res = await RestfulHttp.get<GetListUserResponse>(urlGroupsSearch, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListRoles = async (params: GetListGroupCodeParam): Promise<any> => {
  const res = await RestfulHttp.get<any>(urlRoles, { ...params, page: 0, size: 9999 });
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListGroupCode = async (params: GetListGroupCodeParam): Promise<any> => {
  const res = await RestfulHttp.get<any>(urlGroupCode, { ...params, page: 0, size: 9999 });
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListUserName = async (params: GetListGroupCodeParam): Promise<any> => {
  const res = await RestfulHttp.get<any>(urlUserName, { ...params, page: 0, size: 9999 });
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const postAddUserGroup = async (params: ItemEdit): Promise<any> => {
  const res = await RestfulHttp.post<any>(urlGroups, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const putAddUserGroup = async (params: ItemEdit): Promise<any> => {
  const res = await RestfulHttp.put<any>(urlGroups, params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const deleteAddUserGroup = async (params: ListId): Promise<any> => {
  const res = await RestfulHttp.delete<any>(urlGroups, {
    data: params,
  });
  return res.data;
};
