import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { MapMonitorState } from './redux';

export const mapMonitorSelector = (state: RootState) => state.mapMonitor;

//start fleetView
export const paramsSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.params);

export const listTreeSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.listTree);
export const loadingSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.loading);
export const listLicensePlateSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.listLicensePlate);
export const listStudentSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.listStudent);
export const listInstructorSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.listInstructor);
export const listCourseSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.listCourse);
export const fleetViewDataSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.fleetViewData.content);
export const totalElementsSelector = createSelector(
  mapMonitorSelector,
  (state: MapMonitorState) => state.fleetView.fleetViewData.totalElements
);
export const fleetViewItemSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.fleetView.fleetViewItem);
//end fleetView
//start detail
export const btnTabSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.btnTab);
export const isViewDetailSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.isViewDetail);
export const paramsTripsSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.paramsTrips);
export const paramsEventsSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.paramsEvents);
export const tripsSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.trips);
export const eventsSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.events);
export const paramsTrackSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.paramsTrack);
export const loadingDetailSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.loadingDetail);
export const dataTracksSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.tracks);
export const infoUserSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.infoUser);
export const showVideoDetailSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.showVideoDetail);
export const playDetailUrlSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.playDetailUrl);
export const indexActiveEventSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.indexActiveEvent);
export const indexActiveTripSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.indexActiveTrip);
export const indexActiveCarSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.detail.indexActiveCar);
//end detail

export const dataGoogleMapSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.dataGoogleMap);
export const offsetSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.offset);
export const isRunOffsetSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.isRunOffset);

//start video
export const showModalVideoSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.playVideo.showModalVideo);
export const loadingVideoSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.playVideo.loadingVideo);
export const playUrlSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.playVideo.playUrl);
export const cameraSnVideoSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.playVideo.cameraSn);
export const wakeupStatusSelector = createSelector(mapMonitorSelector, (state: MapMonitorState) => state.playVideo.wakeupStatus);
//end video
