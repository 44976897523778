import { Form, Select } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledFormVerify } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPasswordOTP, getOtpChangePass, setFieldErrors, setShowModalOTP, setshowModalResetPass } from 'modules/forgotPass/redux';
import { fieldErrorsSelector, forgotPassSelect, showModalResetPassSelector } from 'modules/forgotPass/selector';
import ModalConfirmOTP from 'components/commons/ModalConfrimOTP';
import { TitleOtp, WrapperButton } from 'modules/auth/pages/ModalLogin/styles';
import Modal from 'components/commons/Modal';
import { RegValidPass } from 'helper/constants';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CInputPassword from 'components/commons/InputPass';

const FormVerify = () => {
  const dispatch = useDispatch();
  const { listClient, showModalOTP, dataForm } = useSelector(forgotPassSelect);
  const [dataSelect, setDataSelect] = useState<any[]>([]);
  const [otp, setOtp] = useState('');
  const intl = useIntl();
  const openModal = useSelector(showModalResetPassSelector);
  const fieldErrors = useSelector(fieldErrorsSelector);
  const [form] = Form.useForm();
  const closeModalOTP = () => {
    dispatch(setFieldErrors([]));
    dispatch(setShowModalOTP(false));
    setOtp('');
  };

  const handleFinishFormOTP = () => {
    dispatch(
      confirmPasswordOTP({
        ...dataForm,
        otp: otp,
      })
    );
  };
  const onFinish = (values: any) => {
    let clientCode = values?.clientCode;
    if (listClient?.length === 1) {
      clientCode = listClient[0]?.client?.clientCode;
    }
    dispatch(
      getOtpChangePass({
        email: listClient[0]?.username,
        dataForm: {
          clientCode,
          password: values?.newPassword,
          username: listClient[0]?.username,
        },
      })
    );
  };

  useEffect(() => {
    setDataSelect(
      listClient.map((item: any) => {
        return {
          value: item?.client?.clientCode,
          label: item?.client?.clientName,
        };
      })
    );
    if (listClient.length > 1) {
      form.setFieldValue('clientCode', listClient[0]?.client?.clientCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listClient]);

  const handleClose = () => {
    dispatch(setshowModalResetPass(false));
  };

  const sendOTPagain = () => {
    setOtp('');
    dispatch(
      getOtpChangePass({
        email: listClient[0]?.username,
      })
    );
  };

  return (
    <>
      <Modal centered open={openModal} footer={null} onCancel={handleClose}>
        <StyledFormVerify
          style={{ marginTop: 0 }}
          form={form}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          labelAlign="left"
          onFinish={onFinish}
          autoComplete="off"
        >
          <TitleOtp>
            <FormattedMessage id="auth.resetPass" />
          </TitleOtp>
          {listClient?.length > 1 && (
            <Form.Item
              name="clientCode"
              label={<FormattedMessage id="auth.chooseClient" />}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={'validator.requireField'} />,
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={dataSelect?.[0]?.value}
              className="chooseClient"
            >
              <Select options={dataSelect} className="clientSelect" />
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage({ id: 'auth.resetPass' })}
            name="newPassword"
            labelAlign="left"
            rules={[
              { required: true, message: intl.formatMessage({ id: 'common.password' }) + intl.formatMessage({ id: 'validator.require' }) },
              { min: 8, message: intl.formatMessage({ id: 'validator.passMin8' }) },
              {
                pattern: new RegExp(RegValidPass),
                message: intl.formatMessage({ id: 'validator.validPass' }),
              },
            ]}
          >
            <CInputPassword className="inputPass" placeholder={intl.formatMessage({ id: 'auth.placeholderPass' })} maxLength={30} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'validator.requireConfirmPass' })}
            name="rePassword"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'validator.requireConfirmPass' }) + intl.formatMessage({ id: 'validator.require' }),
              },
              ({ getFieldValue }: any) => ({
                validator(rule: any, value: any) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(<FormattedMessage id="validator.confirmPassFail" />);
                },
              }),
            ]}
          >
            <CInputPassword className="inputPass" placeholder={intl.formatMessage({ id: 'auth.placeholderConfirmPass' })} maxLength={30} />
          </Form.Item>
          <WrapperButton style={{ margin: '30px 0 0' }}>
            <CButton onClick={handleClose} sizeCustom={TypeSizeCustom.Medium}>
              <FormattedMessage id="common.cancel" />
            </CButton>
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} htmlType="submit">
              <FormattedMessage id="common.continue" />
            </CButton>
          </WrapperButton>
        </StyledFormVerify>
      </Modal>
      {showModalOTP && (
        <ModalConfirmOTP
          open={showModalOTP}
          onClose={closeModalOTP}
          email={dataForm.username}
          onFinish={handleFinishFormOTP}
          otp={otp}
          setOtp={setOtp}
          fieldErrors={fieldErrors}
          sendOTPagain={sendOTPagain}
        />
      )}
    </>
  );
};

export default memo(FormVerify);
