import React, { FC } from 'react';
import { ButtonProps } from 'antd';
import { StyledButton } from './styles';

export enum TypeSizeCustom {
  Medium = 'Medium',
  Small = 'Small',
}
export enum TypeCustom {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Action = 'Action',
}
interface Props extends ButtonProps {
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
  typeCustom?: TypeCustom;
  sizeCustom?: TypeSizeCustom;
  disabledCustom?: boolean;
  activeCustom?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>;
}

const CButton: FC<Props> = React.memo(({ children, typeCustom, sizeCustom, disabledCustom, activeCustom, onClick, ...rest }) => {
  const onPress = (e: any) => {
    if (disabledCustom) return;
    onClick && onClick(e);
  };

  return (
    <StyledButton
      $disabledCustom={disabledCustom}
      $typeCustom={typeCustom}
      $sizeCustom={sizeCustom}
      $activeCustom={activeCustom}
      onClick={onPress}
      {...rest}
    >
      {children}
    </StyledButton>
  );
});

export default CButton;
