import styled from 'styled-components/macro';

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 30px);
  flex-direction: column;
`;

export const Wrapper = styled('div')`
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  background: ${({ theme }) => theme.tableSelectedActive};
  padding: 10px;
  border-radius: 12px;
  margin-top: 12px;
`;

export const LaneColor = styled.div`
  width: 14px;
  background-color: #fff;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 10px;
`;

export const StyledTitleModal = styled.section`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.titleDefault};
  margin: 0 0 12px;

  span {
    color: ${({ theme }) => theme.primary};
  }
`;
