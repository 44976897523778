import { GoogleMap as GoogleMapReact, LoadScript } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { dataGoogleMapSelector } from 'modules/mapMonitor/selector';
import PolylineMap from './PolylineMap';
import { MapTripStyled } from 'modules/mapMonitor/components/style';
import UserInfo from './UserInfoMap';
import { useCallback } from 'react';
import { setMap } from 'modules/mapMonitor/redux';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GoogleMap = () => {
  const dispatch = useDispatch();
  const dataGoogleMap = useSelector(dataGoogleMapSelector);

  const onLoad = useCallback(function callback(map) {
    dispatch(setMap(map));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(map) {
    dispatch(setMap(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MapTripStyled>
      <LoadScript googleMapsApiKey={dataGoogleMap.key}>
        <GoogleMapReact
          id="historyMap"
          mapContainerStyle={containerStyle}
          center={{
            lat: dataGoogleMap.lat,
            lng: dataGoogleMap.lng,
          }}
          zoom={dataGoogleMap.zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <PolylineMap />
        </GoogleMapReact>
        <UserInfo />
      </LoadScript>
    </MapTripStyled>
  );
};

export default GoogleMap;
