import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirm from 'components/commons/modalConfirm';
import { accountManagerSelector, openFilterSelector } from '../selector';
import { WrapperActionTable } from 'modules/role/page/style';
import {
  deleteAccount,
  getDetailAccount,
  getListAccount,
  setParams,
  setQsValue,
  setShowModal,
  setTypeModal,
  getListGroupUser,
  getListRoles,
} from '../redux';
import AddEditViewAccount from './AddEditViewAccount';
import { ParamsPayloadType } from '../type';
import includes from 'lodash/includes';
import { useRolesByRouter } from 'helper/cusHooks';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { ActionType } from 'helper/constants';
import CTable from 'components/commons/Table';
import CustomSearch from 'components/commons/Search';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import { MenuProps } from 'rc-menu';
import { RowHeader, Text, WrapperButton } from 'components/commons/Template/style';
import moment from 'moment';
import { CReloadButton } from 'components/commons/ReloadButton';
import { typeLanguageSelector } from 'languages/selectors';

interface DataType {
  key: React.Key;
  name: string;
  code: string;
  status: string;
}
const Grid = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const openFilter = useSelector(openFilterSelector);
  const langType = useSelector(typeLanguageSelector);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const dispatch = useDispatch();
  const { listAccount, loadingTable, params, showModal, typeModal, qsValue } = useSelector(accountManagerSelector);
  const listRoles = useRolesByRouter();
  const intl = useIntl();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const renderMenuItemsMore = (record: DataType): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => handleEditRole(record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteRole(record)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRoles, item?.key));
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text disabled={record?.status ? false : true}>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.name' }),
      dataIndex: 'fullName',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'accountManager.employeeCode' }),
      dataIndex: 'userCode',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.email' }),
      dataIndex: 'username',
      width: 200,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'userGroupManager.userGroup' }),
      dataIndex: 'groups',
      width: 200,
      align: 'left',
      render(value, record) {
        const groupName = value
          ?.filter((item: any) => item.status !== 0)
          ?.map((item: any) => item.groupName)
          ?.join(', ');
        return (
          <Tooltip title={groupName} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{groupName}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'role.role' }),
      dataIndex: 'roles',
      align: 'left',
      width: 200,
      render: (value, record) => {
        const roleName = value
          ?.filter((item: any) => item.status !== 0)
          ?.map((item: any) => item.roleName)
          ?.join(', ');
        return (
          <Tooltip title={roleName} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{roleName}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.creator' }),
      dataIndex: 'createdBy',
      align: 'left',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.creationDate' }),
      dataIndex: 'createdDate',
      align: 'left',
      width: 120,
      render(value, record, index) {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'common.updater' }),
      dataIndex: 'lastModifiedBy',
      align: 'left',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.updatedDate' }),
      dataIndex: 'lastModifiedDate',
      align: 'left',
      width: 130,
      render(value, record, index) {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'role.statusName' }),
      dataIndex: 'status',
      align: 'left',
      width: 150,
      render: value => {
        return (
          <Text type={value === 1 ? 'success' : undefined} disabled={value !== 1}>
            <FormattedMessage id={value ? 'common.active' : 'common.inactive'} />
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      fixed: 'right',
      width: 150,
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewRole(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
              <IconMore className="iconMore" />
            </Dropdown>
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleSetQsValue = (value: string) => {
    dispatch(setQsValue(value));
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };

  const handleAddRole = () => {
    handleSetTypeModal(ActionType.ADD);
    dispatch(setShowModal(true));
  };

  const handleViewRole = (itemEdit: any) => {
    handleSetTypeModal(ActionType.VIEW);
    dispatch(
      getDetailAccount({
        userId: itemEdit?.userId,
      })
    );
  };

  const handleEditRole = (itemEdit: any) => {
    handleSetTypeModal(ActionType.EDIT);
    dispatch(
      getDetailAccount({
        userId: itemEdit?.userId,
      })
    );
  };

  const handleDeleteRole = (itemEdit: any) => {
    if (itemEdit?.userId) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteAccount([itemEdit?.userId]));
        },
      });
    }
  };

  const handleDeleteMutiRoles = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteAccount(selectedRowKeys));
        setSelectedRowKeys([]);
      },
    });
  };

  const getListUserByPr = (params: ParamsPayloadType) => {
    dispatch(getListAccount(params));
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterAccountsManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperAccountsManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163;
  };

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        page: 0,
        q: value,
      })
    );
  };

  const handleRefresh = () => {
    handleSetQsValue('');
    dispatch(setParams({ page: 0, size: 10, q: '' }));
  };

  useEffect(() => {
    setTimeout(changeHeightTable, 500);
  }, [openFilter]);

  useEffect(() => {
    getListUserByPr(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, langType, showModal]);

  useEffect(() => {
    dispatch(getListGroupUser({ page: 0, size: 9999, status: 1 }));
    dispatch(getListRoles({ page: 0, size: 9999, status: 1 }));
    return () => {
      dispatch(setParams({ page: 0, size: 10, q: '' }));
      handleSetQsValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'accountManager.tooltipQS' })}
            onSearch={handleQuickSearch}
            value={qsValue}
            setValue={handleSetQsValue}
          />
          <CReloadButton onClick={handleRefresh} />
        </WrapperButton>
        <WrapperButton>
          {includes(listRoles, actionsType.DELETE) && (
            <CButton
              typeCustom={TypeCustom.Secondary}
              sizeCustom={TypeSizeCustom.Medium}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMutiRoles}
            >
              <FormattedMessage id="common.delete" />
            </CButton>
          )}
          {includes(listRoles, actionsType.CREATE) && (
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddRole}>
              <FormattedMessage id="common.add" />
            </CButton>
          )}
        </WrapperButton>
      </RowHeader>
      <Row>
        <CTable
          rowSelection={rowSelection}
          loading={loadingTable}
          columns={columns}
          rowKey={'userId'}
          dataSource={listAccount?.content}
          style={{ width: '100%' }}
          scroll={{ y: changeHeightTable() }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            current: params.page + 1,
            pageSize: params.size,
            size: 'small',
            total: listAccount?.totalElements,
            showQuickJumper: true,
            showTotal: TotalMessage,
            locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
          }}
          onChange={handleChangeTable}
        />
      </Row>
      {showModal && <AddEditViewAccount type={typeModal} />}
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
