import { Popover, Typography } from 'antd';
import React, { useState } from 'react';
import { AvatarBox, ContentStyled, UserCode } from '../styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setModalChangePass } from 'components/layouts/redux/changePass';
import { logout } from 'modules/auth/redux';
import { clientSelectedSelector, usernameSelector } from 'modules/auth/selectors';
import paths from 'helper/pathRoutes';

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accountName = useSelector(usernameSelector);
  const clientSelected = useSelector(clientSelectedSelector);
  const { Text } = Typography;
  const [open, setOpen] = useState(false);
  const handleShowModalChangPass = () => {
    setOpen(false);
    dispatch(setModalChangePass(true));
  };
  const handleLogout = () => {
    setOpen(false);
    dispatch(logout());
    history.push(paths.login);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  // const handleProfileBtn = () => {
  //   setOpen(false);
  //   history.push(paths.profile);
  // };

  const content = (
    <ContentStyled>
      {/* <Text onClick={handleProfileBtn}>
        <FormattedMessage id="common.updateInfo" />
      </Text> */}
      <Text onClick={handleShowModalChangPass}>
        <FormattedMessage id="auth.changePass" />
      </Text>
      <Text onClick={handleLogout}>
        <FormattedMessage id="common.logout" />
      </Text>
    </ContentStyled>
  );
  return (
    <>
      <UserCode>{clientSelected.userCode}</UserCode>
      <Popover placement="bottomLeft" content={content} open={open} onOpenChange={handleOpenChange} trigger="click">
        <AvatarBox>{accountName[0]?.toUpperCase()}</AvatarBox>
      </Popover>
    </>
  );
};

export default Profile;
