import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { MHState } from './redux';

export const monitorHistorySelector = (state: RootState) => state.monitorHistory;
export const accountManagerStateSelector = createSelector(monitorHistorySelector, (state: MHState) => state);
export const paramsSelector = createSelector(monitorHistorySelector, (state: MHState) => state.params);
export const itemDataSelector = createSelector(monitorHistorySelector, (state: MHState) => state.itemData);
export const loadingModalSelector = createSelector(monitorHistorySelector, (state: MHState) => state.loadingModal);
export const lessonDetailSelector = createSelector(monitorHistorySelector, (state: MHState) => state.lessonDetail);
export const showModalSelector = createSelector(monitorHistorySelector, (state: MHState) => state.showModal);
export const lessonsSelector = createSelector(monitorHistorySelector, (state: MHState) => state.lessons);
export const loadingTableSelector = createSelector(monitorHistorySelector, (state: MHState) => state.loadingTable);
export const qsValueSelector = createSelector(monitorHistorySelector, (state: MHState) => state.qsValue);
export const listOptionTFSelector = createSelector(monitorHistorySelector, (state: MHState) => state.listOptionTF);

export const btnTabSelector = createSelector(monitorHistorySelector, (state: MHState) => state.btnTab);
export const tripsSelector = createSelector(monitorHistorySelector, (state: MHState) => state.trips);
export const eventsSelector = createSelector(monitorHistorySelector, (state: MHState) => state.events);
export const dataTracksSelector = createSelector(monitorHistorySelector, (state: MHState) => state.tracks);
export const infoUserSelector = createSelector(monitorHistorySelector, (state: MHState) => state.infoUser);
export const showVideoDetailSelector = createSelector(monitorHistorySelector, (state: MHState) => state.showVideoDetail);
export const playDetailUrlSelector = createSelector(monitorHistorySelector, (state: MHState) => state.playDetailUrl);
export const indexActiveEventSelector = createSelector(monitorHistorySelector, (state: MHState) => state.indexActiveEvent);
export const indexActiveTripSelector = createSelector(monitorHistorySelector, (state: MHState) => state.indexActiveTrip);
export const indexActiveCarSelector = createSelector(monitorHistorySelector, (state: MHState) => state.indexActiveCar);
export const offsetSelector = createSelector(monitorHistorySelector, (state: MHState) => state.offset);
export const isRunOffsetSelector = createSelector(monitorHistorySelector, (state: MHState) => state.isRunOffset);
