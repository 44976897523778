import styled from 'styled-components/macro';
import { store } from 'redux/store';
import { ThemesType } from 'styles';
const theme: ThemesType = store.getState().theme;

export const StyledContentConfirm = styled.div`
  text-align: center;
  width: calc(100% - 24px);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0 12px 12px;
  color: ${theme.contentTable};
  p {
    margin: 0 0 24px;
    font-style: italic;
  }
`;
export const StyledTitle = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.titleDefault};
  margin: 24px 20px 8px;
`;
