import RestfulHttp from 'core/http/singleton/auth';
import {
  OptionItem,
  GetListFleetViewRequest,
  GetListFleetViewResponse,
  GetListRequest,
  GetListTreeRequest,
  TreeItem,
  GetTripsRequest,
  GetEventsRequest,
  GetStartLiveRequest,
  GetStartLiveResponse,
  GetTrackRequest,
  TripItem,
  EventItem,
  TrackItem,
  OptionStudentItem,
} from './types';

export const getListFleetView = async (params: GetListFleetViewRequest): Promise<GetListFleetViewResponse> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/page', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListTree = async (params: GetListTreeRequest): Promise<TreeItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/tree-client', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListCourse = async (params: GetListRequest): Promise<OptionItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/list-course', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListStudent = async (params: GetListRequest): Promise<OptionStudentItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/list-student', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListInstructor = async (params: GetListRequest): Promise<OptionStudentItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/list-instructor', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getListLicensePlate = async (params: GetListRequest): Promise<OptionItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/list-license-plate', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};
/* ----------------------- live stream ---------------------*/
export const getStartLive = async (params: GetStartLiveRequest): Promise<GetStartLiveResponse> => {
  const res = await RestfulHttp.get<any>(`/api/v1/lesson-view/${params.clientId}/${params.cameraSn}/start-live`);
  if (!res || !res.data) throw new Error('Opps');
  return res.data.data;
};
export const getLiveStatus = async (params: GetStartLiveRequest): Promise<GetStartLiveResponse> => {
  const res = await RestfulHttp.get<any>(`/api/v1/lesson-view/${params.clientId}/${params.cameraSn}/live-status`);
  if (!res || !res.data) throw new Error('Opps');
  return res.data.data;
};
/* ----------------------- detail screen ---------------------*/

export const getTrips = async (params: GetTripsRequest): Promise<TripItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/trips', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getTrack = async (params: GetTrackRequest): Promise<TrackItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/track', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};

export const getEvents = async (params: GetEventsRequest): Promise<EventItem[]> => {
  const res = await RestfulHttp.get<any>('/api/v1/lesson-view/events', params);
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};
