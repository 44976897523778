import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { InstructorManagementState } from 'modules/instructorManagement/redux';

export const instructorManagementSelector = (state: RootState) => state.instructorManagement;

export const paramsSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.params);
export const loadingTableSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.loadingTable);
export const loadingBtnModalSelector = createSelector(
  instructorManagementSelector,
  (state: InstructorManagementState) => state.loadingBtnModal
);
export const loadingModalSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.loadingModal);
export const dataTableSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.data);
export const totalElementSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.totalElements);
export const typeModalSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.typeModal);
export const itemEditSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.itemEdit);
export const selectedRowKeysSelector = createSelector(
  instructorManagementSelector,
  (state: InstructorManagementState) => state.selectedRowKeys
);
export const fieldErrorsSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.fieldErrors);
export const valueSearchSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.valueSearch);
export const imageUrlSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.imageUrl);
export const listLicenseSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.listLicense);
export const filePhotoSelector = createSelector(instructorManagementSelector, (state: InstructorManagementState) => state.filePhoto);
