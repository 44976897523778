import { Form, Select } from 'antd';
import { loginStart, setClientSelected, setDataModalClient, setOtpCode, setShowModalClient } from 'modules/auth/redux';
import {
  showModalClientSelector,
  loadingButtonLoginSelector,
  dataLoginSelector,
  clientSelectedSelector,
  dataModalClientSelector,
  otpCodeSelector,
  fieldErrorsSelector,
} from 'modules/auth/selectors';
import { ClientType } from 'modules/auth/services/types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonRegister, LabelOTP, WrapperButton, TitleOtp, ErrorDetail } from '../../ModalLogin/styles';
import { useHistory } from 'react-router-dom';
import Button, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import TimeCountDown from './TimeCountDown';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import CModal from 'components/commons/Modal';
import InputOtp from 'components/commons/InputOtp';
import { StyledFormVerify } from 'modules/forgotPass/page/styles';
interface OptionType {
  value: string;
  label: string;
  id?: string;
}

const ModalClient = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingButtonLogin = useSelector(loadingButtonLoginSelector);
  const dataLogin = useSelector(dataLoginSelector);
  const showModalClient = useSelector(showModalClientSelector);
  const dataModalClient = useSelector(dataModalClientSelector);
  const fieldErrors = useSelector(fieldErrorsSelector);
  const clientSelected = useSelector(clientSelectedSelector);
  const otp = useSelector(otpCodeSelector);
  const [dataSelect, setDataSelect] = useState<OptionType[]>([]);

  const handleClose = () => {
    dispatch(setShowModalClient(false));
    dispatch(setDataModalClient([]));
    dispatch(setOtpCode(''));
  };

  const handleChange = (value: string, option: any) => {
    dispatch(
      setClientSelected({
        clientCode: value,
        clientName: option.label,
        clientId: option.id,
        userCode: option.code
      })
    );
  };

  const handleLoginClient = () => {
    dispatch(
      loginStart({
        ...dataLogin,
        clientCode: clientSelected.clientCode,
        history: history,
        clientId: clientSelected.clientId,
        otp: otp,
      })
    );
  };

  const sendOTPagain = () => {
    dispatch(
      loginStart({
        ...dataLogin,
        history: history,
      })
    );
  };

  useEffect(() => {
    setDataSelect(
      dataModalClient.map((item: ClientType) => ({
        value: item.clientCode,
        label: item.clientName,
        id: item.clientId,
        code: item.userCode,
      }))
    );
  }, [dataModalClient]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && otp.length === 6 && !loadingButtonLogin) {
      handleLoginClient();
    }
    if (e.key === '+' || e.key === '-') {
      return e.preventDefault();
    }
  };

  return showModalClient ? (
    <CModal closeIcon={<CloseIcon />} centered footer={null} open={showModalClient} maskClosable={false} onCancel={handleClose}>
      {dataSelect.length > 1 && (
        <>
          <TitleOtp>
            <FormattedMessage id="auth.chooseClient" />
          </TitleOtp>
          <StyledFormVerify layout="vertical" labelAlign="left" autoComplete="off">
            <Form.Item
              name="clientCode"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={'validator.requireField'} />,
                },
              ]}
              initialValue={clientSelected.clientCode}
            >
              <Select className="clientSelect" options={dataSelect} onChange={handleChange} />
            </Form.Item>
          </StyledFormVerify>
        </>
      )}
      <div>
        <TitleOtp>
          <FormattedMessage id="auth.titleOtp" />
        </TitleOtp>
        <LabelOTP>
          <FormattedMessage id="auth.labelOtp" /> {dataLogin.username?.replace(/(.{2})(.*)(?=@)/, '$1***')}
        </LabelOTP>
        <LabelOTP>
          <FormattedMessage id="auth.timeCount" /> <TimeCountDown />
        </LabelOTP>
        <InputOtp
          renderInput={props => <input {...props} placeholder="-" pattern="[0-9]" onKeyDown={handleKeyDown} type="number" />}
          onChange={function (otp: string): void {
            dispatch(setOtpCode(otp));
          }}
          value={otp}
        />
        {fieldErrors.length > 0 && <ErrorDetail>{fieldErrors[0].message}</ErrorDetail>}
        <LabelOTP>
          <FormattedMessage id="auth.checkCode" />
          &nbsp;
          <ButtonRegister onClick={sendOTPagain}>
            <FormattedMessage id="auth.sendOtpAgain" />
          </ButtonRegister>
        </LabelOTP>
      </div>
      <WrapperButton>
        <Button typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleClose}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          sizeCustom={TypeSizeCustom.Medium}
          typeCustom={TypeCustom.Primary}
          onClick={handleLoginClient}
          loading={loadingButtonLogin}
          disabledCustom={clientSelected.clientCode && clientSelected.clientId && otp.length === 6 ? false : true}
        >
          <FormattedMessage id="common.confirm" />
        </Button>
      </WrapperButton>
    </CModal>
  ) : (
    <></>
  );
};

export default ModalClient;
