import React, { FC } from 'react';
import { Select, SelectProps, Form } from 'antd';
import { StyledWrapSelect } from './styles';

interface Props extends SelectProps {
  children?: React.ReactNode;
}

const CSelect: FC<Props> = React.memo(({ id, onBlur, children, ...rest }) => {
  const form = Form?.useFormInstance();
  const field = id as string;
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    } else {
      form && form?.validateFields([field]);
    }
  };
  return (
    <StyledWrapSelect>
      <Select onBlur={handleBlur} {...rest}>
        {children}
      </Select>
    </StyledWrapSelect>
  );
});

export default CSelect;
