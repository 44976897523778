import { useSelector } from 'react-redux';
import { MapRow, MapUserInfo, UserInfoItem, UserInfoWrapper } from 'modules/mapMonitor/components/style';
import Avatar from 'assets/imgs/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faClock } from '@fortawesome/free-solid-svg-icons';
import { infoUserSelector, lessonDetailSelector } from 'modules/monitorHistory/selector';
import { CardMapWrapper } from './style';

const UserInfo = () => {
  const infoUser = useSelector(infoUserSelector);
  const lessonDetail = useSelector(lessonDetailSelector);

  return (
    <CardMapWrapper>
      <MapRow>
        <img alt="avatar" src={Avatar} />
        <MapUserInfo>
          <div>{lessonDetail.studentName.split('_')[0] ?? ''}</div>
          <UserInfoWrapper>
            <UserInfoItem>
              <FontAwesomeIcon icon={faRoad} size="sm" />
              <div>{infoUser.distance}km</div>
            </UserInfoItem>
            <UserInfoItem>
              <FontAwesomeIcon icon={faClock} size="sm" />
              <div>{infoUser.time}</div>
            </UserInfoItem>
          </UserInfoWrapper>
        </MapUserInfo>
      </MapRow>
    </CardMapWrapper>
  );
};

export default UserInfo;
