import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AuthState } from 'modules/auth/redux';

export const authSelector = (state: RootState) => state.auth;

export const isAuthenticatedSelector = createSelector(authSelector, (state: AuthState) => state.isAuthenticated);
export const clientIdSelector = createSelector(authSelector, (state: AuthState) => state.clientId);
export const usernameSelector = createSelector(authSelector, (state: AuthState) => state.username);
export const dataSignUpdSelector = createSelector(authSelector, (state: AuthState) => state.dataSignUp);
export const timeCountDownOtpSelector = createSelector(authSelector, (state: AuthState) => state.timeCountDownOtp);
export const loadingButtonLoginSelector = createSelector(authSelector, (state: AuthState) => state.loadingButtonLogin);
export const dataLoginSelector = createSelector(authSelector, (state: AuthState) => state.dataLogin);
export const showModalClientSelector = createSelector(authSelector, (state: AuthState) => state.showModalClient);
export const dataModalClientSelector = createSelector(authSelector, (state: AuthState) => state.dataModalClient);
export const clientSelectedSelector = createSelector(authSelector, (state: AuthState) => state.clientSelected);
export const otpCodeSelector = createSelector(authSelector, (state: AuthState) => state.otpCode);
export const errDetailSelector = createSelector(authSelector, (state: AuthState) => state.errDetail);
export const fieldErrorsSelector = createSelector(authSelector, (state: AuthState) => state.fieldErrors);
export const allParamSelector = createSelector(authSelector, (state: AuthState) => state.allParam);
export const managementBaseSelector = createSelector(authSelector, (state: AuthState) => state.managementBase);
