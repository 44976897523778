import { RouterConfig } from './AppRoute';
// import categorieRoutes from 'modules/dashboard/routes';
// import report from 'modules/report/routes';
import account_manager from 'modules/accountManager/routes';
import role from 'modules/role/routes';
import userGroupManager from 'modules/userGroupManager/routes';
import instructorManagement from 'modules/instructorManagement/routes';
import training from 'modules/trainingFacilities/routes';
import course from 'modules/course/routes';
import student from 'modules/studentManagement/routes';
import mapMonitor from 'modules/mapMonitor/routes';
// import mapRealTime from 'modules/mapRealTime/routes';
import managementAgency from 'modules/managementAgency/routes';
import device from 'modules/device/routes';
import vehicleManagement from 'modules/vehicleManagement/routes';
import studentReport from 'modules/studentReport/routes';
import vehicleReport from 'modules/vehicleReport/routes';
import practiceReport from 'modules/practiceReport/routes';
import monitorHistory from 'modules/monitorHistory/routes';
import dashboard from 'modules/dashboard/routes';

export const routes: RouterConfig[] = [
  // ...categorieRoutes,
  // ...report,
  ...account_manager,
  ...role,
  ...managementAgency,
  ...userGroupManager,
  ...training,
  ...course,
  ...student,
  ...mapMonitor,
  ...instructorManagement,
  ...device,
  ...vehicleManagement,
  ...studentReport,
  ...vehicleReport,
  ...practiceReport,
  ...monitorHistory,
  ...dashboard,
];
