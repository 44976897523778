import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { UserGroupManagerState } from 'modules/userGroupManager/redux';

export const userGroupManagerSelector = (state: RootState) => state.userGroupManager;

export const paramsSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.params);
export const listRoleSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.listRole);
export const listRoleStatus0Selector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.listRoleStatus0);
export const listGroupCodeSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.listGroupCode);
export const loadingTableSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.loadingTable);
export const dataTableSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.data);
export const totalElementSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.totalElements);
export const typeModalSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.typeModal);
export const itemEditSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.itemEdit);
export const listUserSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.listUser);
export const listUserStatus0Selector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.listUserStatus0);
export const selectedRowKeysSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.selectedRowKeys);
export const fieldErrorsSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.fieldErrors);
export const valueSearchSelector = createSelector(userGroupManagerSelector, (state: UserGroupManagerState) => state.valueSearch);
