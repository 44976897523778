import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { LeftMenuState } from 'components/layouts/redux/leftMenu';

export const leftMenuSelector = (state: RootState) => state.layouts.leftMenu;

export const loadingMenuSelect = createSelector(leftMenuSelector, (state: LeftMenuState) => state.loadingMenu);
export const menuItemSelect = createSelector(leftMenuSelector, (state: LeftMenuState) => state.menuItem);
export const listUrlActiveSelect = createSelector(leftMenuSelector, (state: LeftMenuState) => state.listUrlActive);
export const collapsedSelect = createSelector(leftMenuSelector, (state: LeftMenuState) => state.collapsed);
