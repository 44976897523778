export type ModelStatus = 0 | 1;

export interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  unpaged: boolean;
  paged: boolean;
}

export interface Page<T> {
  content: T[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
  sort: Sort;
  last: boolean;
  first: boolean;
  empty: boolean;
}

export interface PageRequest {
  page: number;
  size: number;
  q?: string;
}

export interface FieldErrorsItem {
  field: string;
  message: string;
}

export const DefaultSort = {
  unsorted: true,
  sorted: false,
  empty: true
}

export const DefaultPageable = {
  sort: DefaultSort,
  offset: 0,
  pageSize: 0,
  pageNumber: 0,
  unpaged: true,
  paged: false
}

export const DefaultPage = {
  content: [],
  pageable: DefaultPageable,
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
  size: 0,
  number: 0,
  sort: DefaultSort,
  last: false,
  first: true,
  empty: true
}
