import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirm from 'components/commons/modalConfirm';
import { courseManagerSelector, openFilterSelector } from '../selector';
import { WrapperActionTable } from 'modules/role/page/style';
import {
  deleteCourse,
  getDetailCourse,
  getListCourse,
  setParams,
  setQsValue,
  setShowModal,
  setTypeModal,
  getListCourseParam,
  setParamsLessons,
} from '../redux';
import AddEditViewCourse from './AddEditViewCourse';
import { ParamsPayloadType, ResCourse } from '../type';
import includes from 'lodash/includes';
import { useRolesByRouter } from 'helper/cusHooks';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { ActionType } from 'helper/constants';
import CTable from 'components/commons/Table';
import CustomSearch from 'components/commons/Search';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import { MenuProps } from 'rc-menu';
import { RowHeader, Text, WrapperButton } from 'components/commons/Template/style';
import moment from 'moment';
import { CReloadButton } from 'components/commons/ReloadButton';

const Grid = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const openFilter = useSelector(openFilterSelector);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const dispatch = useDispatch();
  const { listCourse, loadingTable, params, showModal, typeModal, qsValue } = useSelector(courseManagerSelector);
  const listRoles = useRolesByRouter();
  const intl = useIntl();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: ResCourse) => ({
      disabled: moment(record?.startDate).isBefore(moment()), // Column configuration not to be checked
      name: record.id,
    }),
  };
  const renderMenuItemsMore = (record: ResCourse): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => handleEditCourse(record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteCourse(record)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRoles, item?.key));
  };
  const columns: ColumnsType<ResCourse> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'course.courseCode' }),
      dataIndex: 'code',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'course.courseName' }),
      dataIndex: 'name',
      width: 150,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'course.tranningNetwork' }),
      dataIndex: 'licenseClass',
      width: 130,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.startTime' }),
      dataIndex: 'startDate',
      align: 'left',
      width: 130,
      render(value, record, index) {
        return <Text>{moment(value).format('DD/MM/YYYY')}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.endTime' }),
      dataIndex: 'endDate',
      align: 'left',
      width: 130,
      render(value, record, index) {
        return <Text>{moment(value).format('DD/MM/YYYY')}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'left',
      className: 'table-course-action',
      fixed: 'right',
      width: 120,
      render(value, record, index) {
        const isCourseProgressing = moment(record?.startDate).isBefore(moment());
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewCourse(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            {!isCourseProgressing && (
              <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
                <IconMore className="iconMore" />
              </Dropdown>
            )}
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleSetQsValue = (value: string) => {
    dispatch(setQsValue(value));
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };

  const handleAddCourse = () => {
    handleSetTypeModal(ActionType.ADD);
    dispatch(setShowModal(true));
  };

  const handleViewCourse = (itemEdit: any) => {
    handleSetTypeModal(ActionType.VIEW);
    dispatch(
      setParamsLessons({
        page: 0,
        size: 10,
        id: itemEdit?.id,
      })
    );
    dispatch(getDetailCourse(itemEdit?.id));
  };

  const handleEditCourse = (itemEdit: any) => {
    handleSetTypeModal(ActionType.EDIT);
    dispatch(getDetailCourse(itemEdit?.id));
  };

  const handleDeleteCourse = (itemEdit: any) => {
    if (itemEdit?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteCourse([itemEdit?.id]));
        },
      });
    }
  };

  const handleDeleteMutiCourse = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteCourse(selectedRowKeys));
        setSelectedRowKeys([]);
      },
    });
  };

  const getListUserByPr = (params: ParamsPayloadType) => {
    dispatch(getListCourse(params));
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterCoursesManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperCoursesManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163;
  };

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        page: 0,
        q: value,
      })
    );
  };

  const handleRefresh = () => {
    handleSetQsValue('');
    dispatch(setParams({ page: 0, size: 10, q: '' }));
  };

  useEffect(() => {
    setTimeout(changeHeightTable, 500);
  }, [openFilter]);

  useEffect(() => {
    getListUserByPr(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    dispatch(getListCourseParam());
    return () => {
      dispatch(setParams({ page: 0, size: 10, q: '' }));
      handleSetQsValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'course.tooltipQS' })}
            onSearch={handleQuickSearch}
            value={qsValue}
            setValue={handleSetQsValue}
          />
          <CReloadButton onClick={handleRefresh} />
        </WrapperButton>
        <WrapperButton>
          {includes(listRoles, actionsType.DELETE) && (
            <CButton
              typeCustom={TypeCustom.Secondary}
              sizeCustom={TypeSizeCustom.Medium}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMutiCourse}
            >
              <FormattedMessage id="common.delete" />
            </CButton>
          )}
          {includes(listRoles, actionsType.CREATE) && (
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddCourse}>
              <FormattedMessage id="common.add" />
            </CButton>
          )}
        </WrapperButton>
      </RowHeader>
      <Row>
        <CTable
          rowSelection={rowSelection}
          loading={loadingTable}
          columns={columns}
          rowKey={'id'}
          dataSource={listCourse?.content}
          style={{ width: '100%' }}
          scroll={{ y: changeHeightTable() }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            current: params.page + 1,
            pageSize: params.size,
            size: 'small',
            total: listCourse?.totalElements,
            showQuickJumper: true,
            showTotal: TotalMessage,
            locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
          }}
          onChange={handleChangeTable}
        />
      </Row>
      {showModal && <AddEditViewCourse type={typeModal} />}
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
