/* eslint-disable react-hooks/rules-of-hooks */
import { Col, Form, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { managementSelector } from '../selector';
import { createManagementStart, deleteManagement, getListManagementDefault, setItemEdit, setOpenModalAddEdit, setTypeModal, updateManagement } from '../redux';
import { StyledModalAddEdit, StyledTitleModal } from '../page/style';
import { isEmpty } from 'helper/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalConfirm from 'components/commons/modalConfirm';
import { useEffectOnce } from 'react-use';
import { ActionType, RegValidEmail } from 'helper/constants';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { useRolesByRouter } from 'helper/cusHooks';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import CInput from 'components/commons/Input';
import { RowButton } from 'components/commons/Template/style';
import { FieldErrorsType } from '../type';
import isNumeric from "validator/lib/isNumeric";
import CTextArea from "../../../components/commons/TextArea";

const AddEditForm = () => {
  const listRoles = useRolesByRouter();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showModalAddEdit, loadingButtonSave, loadingModal, itemEdit, typeModal } = useSelector(managementSelector);
  const [form] = Form.useForm<{ apellido_empleado: string }>();

  const handleDeleteItem = () => {
    if (itemEdit?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteManagement([itemEdit?.id || '']));
          handleClose();
        },
      });
    }
  };
  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',

        handleConfirm: () => {
          dispatch(setOpenModalAddEdit(false));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        status: 0,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setOpenModalAddEdit(false));
          },
        });
      } else {
        dispatch(setOpenModalAddEdit(false));
      }
      return;
    }
    dispatch(setOpenModalAddEdit(false));
  };
  const getListDefault = () => {
    dispatch(getListManagementDefault());
  };
  const handleFinishForm = (values: any) => {
    if (typeModal === ActionType.ADD) {
      dispatch(
        createManagementStart({
          data: values,
          setFieldError: setFieldError,
        })
      );
    }
    if (typeModal === ActionType.EDIT && itemEdit?.id) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(
            updateManagement({
              ...values,
              id: itemEdit?.id,
              setFieldError: setFieldError,
            })
          );
        },
      });
    }
  };
  const handleClose = () => {
    dispatch(setOpenModalAddEdit(false));
  };
  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
  };
  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = form.getFieldValue(field);
      form.setFieldValue(field, value.trim());
      form.validateFields([field]);
    }, 0);
  };
  const renderTitle = () => {
    switch (typeModal) {
      case ActionType.ADD:
        return <FormattedMessage id="common.add" />;
      case ActionType.EDIT:
        return <FormattedMessage id="common.edit" />;
      case ActionType.VIEW:
        return <FormattedMessage id="common.viewDetail" />;
      default:
        return '';
    }
  };
  const setFieldError = (fieldErrors: FieldErrorsType[]) => {
    form.setFields(
      fieldErrors.map((item: FieldErrorsType) => ({
        name: item.field,
        errors: [item.message],
      }))
    );
  };

  useEffectOnce(() => {
    getListDefault();
    return () => {
      form.resetFields();
      dispatch(setItemEdit(null));
    };
  });
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEdit]);

  return (
    <StyledModalAddEdit
      closable
      closeIcon={<CloseIcon />}
      open={showModalAddEdit}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={loadingModal}>
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 20 }} labelAlign="left" onFinish={handleFinishForm}>
          <StyledTitleModal>{renderTitle()}</StyledTitleModal>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'managementAgency.name' })}
                name="name"
                initialValue={itemEdit?.name || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'managementAgency.name' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput
                  maxLength={255}
                  onPaste={(e: any) => {
                    handlePates(e, 'name');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'managementAgency.foundedYear' })}
                name="foundedYear"
                initialValue={itemEdit?.foundedYear || ''}
                rules={[]}
              >
                <CInput
                  maxLength={4}
                  onPaste={(e: any) => {
                    handlePates(e, 'foundedYear');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'managementAgency.address' })}
                name="address"
                initialValue={itemEdit?.address || ''}
                rules={[]}
              >
                <CTextArea
                  rows={5}
                  maxLength={255}
                  onPaste={(e: any) => {
                    handlePates(e, 'address');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'managementAgency.representative' })}
                name="representative"
                initialValue={itemEdit?.representative || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'managementAgency.representative' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
              >
                <CInput
                  maxLength={255}
                  onPaste={(e: any) => {
                    handlePates(e, 'representative');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'managementAgency.email' })}
                name="email"
                initialValue={!itemEdit?.email ? null : itemEdit?.email}
                rules={[
                  {
                    validator: (_, value) =>
                      !value || RegValidEmail.test(value)
                        ? Promise.resolve()
                        : Promise.reject(<FormattedMessage id="validator.requireEmail" />),
                  },
                ]}
              >
                <CInput
                  maxLength={255}
                  onPaste={(e: any) => {
                    handlePates(e, 'email');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'common.phoneNumber' })}
                name="phoneNumber"
                initialValue={itemEdit?.phoneNumber || ''}
                rules={[
                  {
                    min: 10,
                    message: intl.formatMessage({ id: 'common.phoneNumber' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' }),
                  },
                  {
                    validator: (_, value) =>
                      !value || isNumeric(value) || value?.length < 10
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'common.phoneNumber' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        ),
                  },
                ]}
              >
                <CInput
                  maxLength={11}
                  onPaste={(e: any) => {
                    handlePates(e, 'phoneNumber');
                  }}
                  disabled={typeModal === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'managementAgency.signInEmail' })}
                name="signInEmail"
                initialValue={itemEdit?.signInEmail || ''}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'managementAgency.signInEmail' }) + intl.formatMessage({ id: 'validator.require' }),
                  },
                  {
                    validator: (_, value) =>
                      !value || RegValidEmail.test(value)
                        ? Promise.resolve()
                        : Promise.reject(<FormattedMessage id="validator.requireEmail" />),
                  },
                ]}
              >
                <CInput
                  maxLength={255}
                  onPaste={(e: any) => {
                    handlePates(e, 'signInEmail');
                  }}
                  disabled={typeModal === ActionType.VIEW || typeModal === ActionType.EDIT}
                />
              </Form.Item>
            </Col>
          </Row>
          <RowButton>
            {typeModal === ActionType.VIEW ? (
              <>
                {includes(listRoles, actionsType.DELETE) && (
                  <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteItem}>
                    <FormattedMessage id="common.delete" />
                  </CButton>
                )}
                {includes(listRoles, actionsType.UPDATE) && (
                  <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} onClick={handleChangeEdit}>
                    <FormattedMessage id="common.edit" />
                  </CButton>
                )}
              </>
            ) : (
              <>
                <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                  {intl.formatMessage({ id: 'common.cancel' })}
                </CButton>

                <CButton
                  typeCustom={TypeCustom.Primary}
                  sizeCustom={TypeSizeCustom.Medium}
                  htmlType="submit"
                  loading={loadingButtonSave}
                >
                  <FormattedMessage id="common.save" />
                </CButton>
              </>
            )}
          </RowButton>
        </Form>
      </Spin>
    </StyledModalAddEdit>
  );
};

export default AddEditForm;
