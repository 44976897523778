import { all, put, takeLatest } from 'redux-saga/effects';
import {
  confirmPasswordOTP,
  confirmPasswordOTPSuccess,
  getEmailConfirm,
  getEmailConfirmFail,
  getEmailConfirmSuccess,
  getOtpChangePass,
  getOtpChangePassFail,
  getOtpChangePassSuccess,
  setFieldErrors,
  verifyToken,
  verifyTokenFail,
  verifyTokenSuccess,
} from '../redux';
import { SentOTPResponse, confirmPassword, emailConfirm, sentOTP, verifyTokenEmail } from '../services';
import { NotificationError, NotificationSuccess } from 'components/notifications';
import paths from 'helper/pathRoutes';
import { setTimeCountDownOtp } from 'modules/auth/redux';
import moment from 'moment';

function* getEmailConfirmForgot(action: ReturnType<typeof getEmailConfirm>): any {
  try {
    const res = yield emailConfirm(action.payload.data);
    NotificationSuccess('auth.checkEmail');
    yield put(getEmailConfirmSuccess(res));
  } catch (e: any) {
    yield put(getEmailConfirmFail());
    if (e?.detail) {
      action.payload.form.setFields([
        {
          name: 'email',
          errors: [e.detail],
        },
      ]);
      return;
    }
    NotificationError(e);
  }
}

function* verifyTokenWorker(action: ReturnType<typeof verifyToken>): any {
  try {
    const res = yield verifyTokenEmail(action.payload.token);
    yield put(verifyTokenSuccess(res));
    action.payload.history?.push(paths.login);
  } catch (e: any) {
    yield put(verifyTokenFail());
    action.payload.history?.push(paths.login);
    NotificationError(e);
  }
}

function* getOTP(action: ReturnType<typeof getOtpChangePass>) {
  try {
    const res: SentOTPResponse = yield sentOTP(action.payload.email);
    if (action.payload.dataForm) {
      yield put(getOtpChangePassSuccess(action.payload.dataForm));
    }

    yield put(setTimeCountDownOtp(moment().add(res.otpTime - 10, 'seconds')));
  } catch (e: any) {
    yield put(getOtpChangePassFail());
    NotificationError(e);
  }
}

function* confirmPass(action: ReturnType<typeof confirmPasswordOTP>) {
  try {
    yield confirmPassword({
      username: action.payload.username,
      password: action.payload.password,
      clientCode: action.payload.clientCode,
      otp: action.payload.otp,
    });
    yield put(confirmPasswordOTPSuccess());
    NotificationSuccess('auth.recoveryPassSuccess');
  } catch (err: any) {
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }

    NotificationError(err);
  }
}

export default function* forgotPassSaga(): any {
  return all([
    yield takeLatest(getEmailConfirm, getEmailConfirmForgot),
    yield takeLatest(verifyToken, verifyTokenWorker),
    yield takeLatest(getOtpChangePass, getOTP),
    yield takeLatest(confirmPasswordOTP, confirmPass),
  ]);
}
