import { Col, Image, Row, Spin, Tooltip, Table } from 'antd';
import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { reportStudentSelector } from '../selector';
import { exportReport, getDetailLesson, setShowModal } from '../redux';
import { StyledTitleModal } from 'modules/role/page/style';
import { useIntl } from 'react-intl';
import { StyledModalDetail, StyledTitle } from '../page/styles';
import moment from 'moment';
import Avatar from 'assets/imgs/AvartaError.png';
import CTable from 'components/commons/Table';
import { RowButton, Text, TextLink } from 'components/commons/Template/style';
import { ColumnsType } from 'antd/es/table';
import { LessonType } from '../type';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import ModalDetailLesson from 'modules/monitorHistory/components/ModalDetail';
import { showModalSelector } from 'modules/monitorHistory/selector';

const ModalDetail = () => {
  const { showModal, loadingModal, itemView, loadingBtnExport } = useSelector(reportStudentSelector);
  const showModalLesson = useSelector(showModalSelector);

  const dispatch = useDispatch();
  const intl = useIntl();
  const columns: ColumnsType<LessonType> = [
    {
      title: 'STT',
      align: 'left',
      fixed: 'left',
      dataIndex: 'stt',
      key: 'stt',
      width: 60,
      render(value, record, index) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'course.sessionTraining' }),
      align: 'left',
      dataIndex: 'code',
      key: 'code',
      width: 130,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <TextLink onClick={() => handleViewLesson(record)}>{value}</TextLink>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleManagement.licensePlate' }),
      align: 'left',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      width: 110,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'studentReport.dateTraining' }),
      align: 'left',
      width: 200,
      key: 'dateTraining',
      render(value, record) {
        return (
          <Tooltip
            title={
              <span>
                {record.startDate ? moment(record.startDate).format('DD/MM/YYYY HH:mm:ss') : ''} -{' '}
                {record.endDate ? moment(record.endDate).format('DD/MM/YYYY HH:mm:ss') : ''}
              </span>
            }
            placement="topLeft"
          >
            <Text>
              {record.startDate ? moment(record.startDate).format('DD/MM/YYYY') : ''} -{' '}
              {record.endDate ? moment(record.endDate).format('DD/MM/YYYY') : ''}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'student.hours' }) + ' (h)',
      align: 'left',
      dataIndex: 'duration',
      key: 'duration',
      width: 100,
      render(value, record, index) {
        const fmtVal = moment.duration(value, 'milliseconds').asHours().toFixed(2);
        return (
          <Tooltip title={fmtVal} placement="topLeft">
            <Text>{fmtVal}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'student.distance' }) + ' (Km)',
      align: 'left',
      dataIndex: 'distance',
      key: 'distance',
      width: 165,
      render(value, record, index) {
        const fmtVal = (value / 1000).toFixed(2);
        return (
          <Tooltip title={fmtVal} placement="topLeft">
            <Text>{fmtVal}</Text>
          </Tooltip>
        );
      },
    },
  ];

  const handleViewLesson = (record: LessonType) => {
    dispatch(getDetailLesson(record.id));
  };
  const handleClose = () => {
    dispatch(setShowModal(false));
  };

  const handleExportRp = () => {
    dispatch(
      exportReport({
        idCourse: itemView.idCourse,
        idStudent: itemView.idStudent,
      })
    );
  };
  return (
    <StyledModalDetail
      width={900}
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={loadingModal}>
        <StyledTitleModal style={{ fontSize: 20 }}>{intl.formatMessage({ id: 'studentReport.viewDetail' })}</StyledTitleModal>
        <StyledTitle>{intl.formatMessage({ id: 'studentReport.studentInfo' })}</StyledTitle>
        <Row className="rowInfo">
          <Col span={19}>
            <Row>
              <Col span={6}>
                <p>{intl.formatMessage({ id: 'common.name' })}</p>
                <p>{intl.formatMessage({ id: 'studentReport.studentCode' })}</p>
                <p>{intl.formatMessage({ id: 'common.dateBirth' })}</p>
                <p>{intl.formatMessage({ id: 'course.courseCode' })}</p>
                <p>{intl.formatMessage({ id: 'course.tranningLevel' })}</p>
                <p>{intl.formatMessage({ id: 'studentReport.trainingFacility' })}</p>
              </Col>
              <Col span={18}>
                <p>{itemView.fullName}</p>
                <p>{itemView.citizenIdCardNo}</p>
                <p>{itemView.birthday ? moment(itemView.birthday, 'YYYY/MM/DD').format('DD/MM/YYYY') : '\u00A0'}</p>
                <p>{itemView.code}</p>
                <p>{itemView.licenseClass}</p>
                <p>{itemView.schoolName}</p>
              </Col>
            </Row>
          </Col>
          <Col span={5} style={{ textAlign: 'right' }}>
            <Image src={itemView.faceImage} className="imgStudent" fallback={Avatar} />
          </Col>
        </Row>
        <StyledTitle>{intl.formatMessage({ id: 'studentReport.lessonInfo' })}</StyledTitle>
        <CTable
          columns={columns}
          dataSource={itemView.lessons}
          style={{ width: '100%' }}
          pagination={false}
          scroll={{ y: 350 }}
          summary={() =>
            itemView.lessons?.length ? (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={4} index={0}>
                    <b>{intl.formatMessage({ id: 'studentReport.total' })}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1} index={1}>
                    <b>{moment.duration((itemView.durationTotal || 0), 'milliseconds').asHours().toFixed(2)}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1} index={2}>
                    <b>{((itemView.distanceTotal || 0) / 1000).toFixed(2)}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            ) : (
              <></>
            )
          }
          rowKey={'stt'}
        />
        <RowButton style={{ marginTop: 32 }}>
          <CButton typeCustom={TypeCustom.Action} sizeCustom={TypeSizeCustom.Medium} onClick={handleClose}>
            {intl.formatMessage({ id: 'common.close' })}
          </CButton>

          <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} onClick={handleExportRp} loading={loadingBtnExport}>
            {intl.formatMessage({ id: 'report.export' })}
          </CButton>
        </RowButton>
      </Spin>
      {showModalLesson && <ModalDetailLesson />}
    </StyledModalDetail>
  );
};

export default ModalDetail;
