import styled from 'styled-components/macro';

export const InfoStyled = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid rgb(231, 231, 231);
  padding: 12px;
  height: 50px;
  border-radius: 12px;
`;

export const TitleDetail = styled.div`
  font-size: 16px;
  margin-top: -3px;
  font-weight: 600;
`;

export const TitleInfo = styled.span`
  font-weight: bold;
`;
