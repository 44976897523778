import { NotificationError, NotificationSuccess } from 'components/notifications';
import {
  deleteAddVehicle,
  getDetailVehicleService,
  getListVehicle,
  getParamDevice,
  postAddVehicle,
  putAddVehicle,
} from 'modules/vehicleManagement/api';
import { DeviceType, GetListVehicleRequest, GetListVehicleResponse, VehicleItem } from 'modules/vehicleManagement/api/types';
import { all, put, select, spawn, takeLatest } from 'redux-saga/effects';
import {
  getListUserFail,
  getListVehicleFull,
  getListVehicleSuccess,
  updateSearch,
  createVehicle,
  editVehicle,
  setTypeModal,
  deleteListVehicle,
  setFieldErrors,
  getDetailVehicle,
  getDetailVehicleSuccess,
  startGetListDevice,
  getListDeviceSuccess,
  createVehicleOk,
  createVehicleFail,
  editVehicleOk,
  editVehicleFail,
} from 'modules/vehicleManagement/redux/index';
import { paramsSelector } from '../selector';
import { clientIdSelector } from 'modules/auth/selectors';

function* getListUserByParams() {
  try {
    const params: GetListVehicleRequest = yield select(paramsSelector);
    const response: GetListVehicleResponse = yield getListVehicle(params);

    yield put(getListVehicleSuccess(response));
  } catch (err: any) {
    NotificationError(err);
    yield put(getListUserFail());
  }
}

function* getDetailVehicleById(action: ReturnType<typeof getDetailVehicle>) {
  try {
    const response: VehicleItem = yield getDetailVehicleService(action.payload);

    yield put(getDetailVehicleSuccess(response));
  } catch (err: any) {
    NotificationError(err);
    yield put(getListUserFail());
  }
}

function* getListDevice() {
  try {
    const response: DeviceType = yield getParamDevice();
    yield put(getListDeviceSuccess(response));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* createVehicleManagement(action: ReturnType<typeof createVehicle>) {
  try {
    const params = { ...action.payload };
    yield postAddVehicle(params);
    NotificationSuccess('common.addSuccess', { value: 'vehicleManagement.title' });
    yield put(createVehicleOk())
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
  } catch (err: any) {
    yield put(createVehicleFail())
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}
function* editVehicleManagement(action: ReturnType<typeof editVehicle>) {
  try {
    const clientId: string = yield select(clientIdSelector);
    const params = { ...action.payload, clientId };
    yield putAddVehicle(params);
    NotificationSuccess('common.updateSuccess', { value: 'vehicleManagement.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
    yield put(editVehicleOk())
  } catch (err: any) {
    yield put(editVehicleFail())

    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}
function* deleteListVehicleManagement(action: ReturnType<typeof deleteListVehicle>) {
  try {
    yield deleteAddVehicle(action.payload);
    NotificationSuccess('common.deleteSuccess', { value: 'vehicleManagement.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
  } catch (err: any) {
    NotificationError(err);
  }
}

export default function* vehicleManagementSaga() {
  yield all([
    takeLatest(getListVehicleFull, getListUserByParams),
    takeLatest(startGetListDevice, getListDevice),
    takeLatest(getDetailVehicle, getDetailVehicleById),
    takeLatest(updateSearch, getListUserByParams),
    takeLatest(createVehicle, createVehicleManagement),
    takeLatest(editVehicle, editVehicleManagement),
    takeLatest(deleteListVehicle, deleteListVehicleManagement),
  ]);
}
