import HttpRequest from 'core/http/singleton/auth';
import { PayloadConfirmPass } from '../type';
export interface SentOTPResponse {
  otpTime: number;
}

export const emailConfirm = async (email: string): Promise<any> => {
  const res = await HttpRequest.post<any>(`api/v1/users/forgot-password`, { email });
  return res.data;
};

export const verifyTokenEmail = async (token: string): Promise<any> => {
  const res = await HttpRequest.post<any>(`api/v1/users/verify-token?token=${token}`);
  return res.data;
};

export const sentOTP = async (email: string): Promise<SentOTPResponse> => {
  const res = await HttpRequest.post<any>(`api/v1/users/send-otp`, { email });
  return res.data;
};

export const confirmPassword = async (data: PayloadConfirmPass): Promise<any> => {
  const res = await HttpRequest.post<any>(`api/v1/users/confirm-password`, data);
  return res.data;
};
