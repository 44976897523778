import { all, put, takeLatest } from 'redux-saga/effects';
import { confirmTokenSignUp } from '../services';
import { confirmToken, setLoading } from '../redux';
import { NotificationError, NotificationSuccess } from 'components/notifications';

function* verifyAccount(action: ReturnType<typeof confirmToken>): any {
  try {
    yield confirmTokenSignUp(action.payload.token);
    yield put(setLoading(false));
    NotificationSuccess('auth.signUpSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(setLoading(false));
  }
}

export default function* ssoSaga(): any {
  return all([yield takeLatest(confirmToken, verifyAccount)]);
}
