import Body from '../components/Body';
import Header from '../components/Header';
import { Wrapper } from './style';

const InstructorManagementPage = () => {
  return (
    <Wrapper id="wrapperUserGroup">
      <Header />
      <Body />
    </Wrapper>
  );
};

export default InstructorManagementPage;
