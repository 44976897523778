import React from 'react';
import { Col, Layout, Row } from 'antd';
import ModalChangePassword from './components/ModalChangePassword';
import { HeaderAccount, StyledLayout } from './styles';
import LeftMenu from './components/LeftMenu';
import Profile from './components/Profile';
import { useSelector } from 'react-redux';
import LeftHeader from './components/LeftHeader';
import { themeSelector } from 'styles/selector';

const { Header, Content } = Layout;

interface Props {
  children: any;
}

const LayoutDashboard = (props: Props) => {
  const { children } = props;
  const theme = useSelector(themeSelector);

  return (
    <StyledLayout>
      <LeftMenu />
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: theme.menuBackground }}>
          <Row>
            <Col span={7} style={{ height: '56px' }}>
              <LeftHeader />
            </Col>
            <Col style={{ position: 'absolute', right: 0 }} span={10}>
              <HeaderAccount>
                <Profile />
              </HeaderAccount>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            padding: '0 36px 36px',
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
      <ModalChangePassword />
    </StyledLayout>
  );
};

export default LayoutDashboard;
