import { BodyFleetView } from '../../style';
import Filter from './Filter';
import ListCard from './ListCard';
import PaginationFleetView from './PaginationFleetView';

function Body() {
  return (
    <BodyFleetView>
      <Filter />
      <ListCard />
      <PaginationFleetView />
    </BodyFleetView>
  );
}

export default Body;
