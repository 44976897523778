import { all, put, select, takeLatest, spawn } from 'redux-saga/effects';
import { getAllParam, handleConfirmOTP, handleLogin, handleSignUp } from '../services';
import {
  loginSuccess,
  loginFail,
  loginStart,
  signUp,
  signUpFail,
  signUpSuccess,
  setDataModalClient,
  setShowModalClient,
  setDataLogin,
  confirmOTPSuccess,
  confirmOTPFail,
  confirmOTP,
  setClientSelected,
  setClientId,
  setUsername,
  setTimeCountDownOtp,
  setShowModalRegisterSuccess,
  setOtpCode,
  setErrDetail,
  setFieldErrors,
  setAllParam,
} from '../redux';
import Storage from 'helper/storage';
import paths from 'helper/pathRoutes';
import { NotificationError, NotificationSuccess } from 'components/notifications';
import { additionalActionType, AllParamResponse, LoginResponse } from 'modules/auth/services/types';
import { DataSignUpType } from '../types';
import { dataSignUpdSelector } from '../selectors';
import { setModalChangePass } from 'components/layouts/redux/changePass';
import LogRocket from 'logrocket';
import moment from 'moment';
import { setIsNotifyService } from 'components/layouts/redux/header';
import { changeLanguage } from 'languages/redux';

function* clientLogin(data: ReturnType<typeof loginStart>) {
  try {
    const rs: LoginResponse = yield handleLogin({
      username: data?.payload?.username,
      password: data?.payload?.password,
      clientCode: data?.payload?.clientCode,
      otp: data.payload.otp,
    });
    if (rs.additionalActionCode === additionalActionType.REQUIRE_PASSWORD_CHANGE) {
      yield put(setModalChangePass(true));
      yield put(setUsername(data?.payload?.username!));
      yield put(setClientId(data?.payload?.clientId!));
      yield put(loginFail());
      yield put(setShowModalClient(false));
      return;
    }
    if (rs?.clientList) {
      yield put(loginFail());
      yield put(setDataModalClient(rs.clientList));

      yield put(setClientSelected(rs.clientList[0]));

      yield put(
        setDataLogin({
          username: data.payload.username,
          password: data.payload.password,
        })
      );
      yield put(setShowModalClient(true));
      yield put(setTimeCountDownOtp(moment().add(rs.otpTime - 10, 'seconds')));
      yield put(setOtpCode(''));
      NotificationSuccess('common.checkEmailOTP');
      return;
    }

    yield Storage.setAccessToken(rs.accessToken);
    yield Storage.setRefreshToken(rs.refreshToken);
    yield put(
      loginSuccess({
        username: data.payload.username,
        clientId: data.payload?.clientId || '',
        managementBase: rs?.parentInfo,
      })
    );

    LogRocket.identify(data.payload.username, {
      name: data.payload.username,
      email: data.payload.username,
    });
    yield spawn(gettAllParamWorker);

    // handle display notify service
    yield put(setIsNotifyService(true));

    data?.payload?.history.push(paths.dashboard);
  } catch (err: any) {
    yield put(loginFail());
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }

    if (err.detail) {
      yield put(setErrDetail(err.detail));
      return;
    }

    NotificationError(err);
  }
}

function* gettAllParamWorker(): any {
  try {
    const res: AllParamResponse = yield getAllParam();
    yield put(setAllParam(res));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* clientSignUp(action: ReturnType<typeof signUp>): any {
  try {
    const params = action.payload;
    yield handleSignUp(params);
    yield put(signUpSuccess());
    yield put(setShowModalRegisterSuccess(true));
  } catch (err: any) {
    yield put(signUpFail());
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}

function* clientConfirmOTP(data: ReturnType<typeof confirmOTP>): any {
  try {
    yield handleConfirmOTP({
      otp: data?.payload?.otp,
    });
    const dataSignUp: DataSignUpType = yield select(dataSignUpdSelector);
    yield put(
      loginStart({
        username: dataSignUp?.users?.[0]?.email,
        password: dataSignUp?.users?.[0]?.password,
        history: data?.payload?.history,
      })
    );
    yield put(confirmOTPSuccess());
  } catch (e: any) {
    NotificationError(e);
    yield put(confirmOTPFail());
  }
}

export default function* authSaga(): any {
  return all([
    yield takeLatest(loginStart, clientLogin),
    yield takeLatest(signUp, clientSignUp),
    yield takeLatest(confirmOTP, clientConfirmOTP),
    yield takeLatest(changeLanguage, gettAllParamWorker),
  ]);
}
