import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PayloadChangPassType } from '../type';

export interface ChangPassState {
  showModalChangePass: boolean;
  loadingButton: boolean;
}

const initialState: ChangPassState = {
  showModalChangePass: false,
  loadingButton: false,
};

const changPassSlice = createSlice({
  name: 'changPassSlice',
  initialState,
  reducers: {
    setModalChangePass: (state, action: PayloadAction<boolean>) => {
      state.showModalChangePass = action.payload;
    },
    changePass: (state, action: PayloadAction<{ data: PayloadChangPassType; setFieldError: any }>) => {
      state.loadingButton = true;
    },
    changePassSuccess: state => {
      state.loadingButton = false;
      state.showModalChangePass = false;
    },
    changePassFail: state => {
      state.loadingButton = false;
    },
  },
});

export const { setModalChangePass, changePass, changePassFail, changePassSuccess } = changPassSlice.actions;

export default changPassSlice.reducer;
