import { Button } from 'antd';
import styled from 'styled-components/macro';
import { TypeCustom, TypeSizeCustom } from '.';

function getHeightButton(value: TypeSizeCustom) {
  switch (value) {
    case TypeSizeCustom.Medium:
      return `36px !important`;
    case TypeSizeCustom.Small:
      return `20px !important`;
    default:
      return `36px`;
  }
}
function getBackgroundButton(value: TypeCustom, hover?: boolean) {
  switch (value) {
    case TypeCustom.Primary:
      return (hover ? '#003971' : '#004C98') + '!important';
    case TypeCustom.Secondary:
      return (hover ? '#EBF1FF' : '#F5F5F5') + '!important';
    case TypeCustom.Action:
      return 'transparent';
    default:
      return 'transparent';
  }
}

function getColorTextButton(value: TypeCustom, hover?: boolean) {
  switch (value) {
    case TypeCustom.Primary:
      return '#FFFFFF !important';
    case TypeCustom.Secondary:
      return (hover ? '#004C98' : '#616774') + '!important';
    case TypeCustom.Action:
      return (hover ? '#004C98' : '#616774') + '!important';
    default:
      return hover ? '#004C98' : '#616774';
  }
}

function getBorderButton(value: TypeCustom, hover?: boolean) {
  switch (value) {
    case TypeCustom.Primary:
      return 'none;';
    case TypeCustom.Secondary:
      return 'none;';
    case TypeCustom.Action:
      return (hover ? '1px solid #004C98' : '1px solid #DCDCDC') + '!important';
    default:
      return 'none;';
  }
}

export const StyledButton = styled(Button)<{
  $disabledCustom?: boolean;
  $sizeCustom?: TypeSizeCustom;
  $typeCustom?: TypeCustom;
  $activeCustom?: boolean;
}>`
  ${({ $disabledCustom }) => ($disabledCustom ? `opacity: 0.5; cursor: not-allowed;` : null)}
  ${({ $sizeCustom }) => ($sizeCustom ? `height: ${getHeightButton($sizeCustom)};` : null)}
  ${({ $typeCustom, $activeCustom }) => ($typeCustom ? `color: ${getColorTextButton($typeCustom, $activeCustom)};` : null)}
  ${({ $typeCustom, $activeCustom }) => ($typeCustom ? `background: ${getBackgroundButton($typeCustom, $activeCustom)};` : null)}
  ${({ $typeCustom, $activeCustom }) => ($typeCustom ? `border: ${getBorderButton($typeCustom, $activeCustom)};` : null)}
  &:hover {
    ${({ $typeCustom }) => ($typeCustom ? `color: ${getColorTextButton($typeCustom, true)};` : null)}
    ${({ $typeCustom }) => ($typeCustom ? `background: ${getBackgroundButton($typeCustom, true)};` : null)}
    ${({ $typeCustom }) => ($typeCustom ? `border: ${getBorderButton($typeCustom, true)};` : null)}
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: 8px;
`;
