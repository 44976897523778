import { RouterConfig } from '../../router/AppRoute';
import paths from '../../helper/pathRoutes';
import VehicleReportPage from './page';

const routes: RouterConfig[] = [
  {
    path: paths.vehicleReport,
    page: VehicleReportPage,
  },
];

export default routes;
