import { NotificationSuccess } from 'components/notifications/index';
import { NotificationError } from 'components/notifications';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getListRole,
  getListRoleFail,
  getListRoleFailDefault,
  getListRoleSuccess,
  getListRoleSuccessDefault,
  getListRoleDefault,
  createRoleStart,
  createRoleSuccess,
  getDetailRoleSuccess,
  getDetailRole,
  updateRoleSuccess,
  updateRole,
  deleteRoleSuccess,
  deleteRoleFail,
  deleteRole,
  createRoleFail,
  deleteMutiRoleSuccess,
  deleteMutiRoleFail,
  deleteMutiRole,
  updateRoleFail,
} from '../redux/index';
import {
  createRole,
  deleteMutiRoleById,
  deleteRoleById,
  getDetailRoleById,
  getListRoleByQS,
  getListRolesDefault,
  updateRoleById,
} from '../services';
import { ParamsType, RoleObjectType } from '../type';
import { paramsSelector } from '../selector';
import { clientIdSelector } from 'modules/auth/selectors';

function* getListRoleByQuickSearch(action: ReturnType<typeof getListRole>): any {
  try {
    const res = yield getListRoleByQS(action?.payload);
    yield put(getListRoleSuccess(res));
  } catch (e: any) {
    yield put(getListRoleFail());
    NotificationError(e);
  }
}

function* getRolesDefault(): any {
  try {
    const res = yield getListRolesDefault();
    yield put(getListRoleSuccessDefault(res));
  } catch (e: any) {
    yield put(getListRoleFailDefault());
    NotificationError(e);
  }
}

function* handleCreateRole(action: ReturnType<typeof createRoleStart>) {
  try {
    const clientId: string = yield select(clientIdSelector);

    yield createRole({
      ...action.payload.data,
      clientId: clientId,
    });
    const params: ParamsType = yield select(paramsSelector);

    yield put(createRoleSuccess());
    yield put(getListRole(params));
    NotificationSuccess('role.addSuccess');
  } catch (e: any) {
    if (e?.fieldErrors) {
      action.payload.setFieldError(e?.fieldErrors);
    } else {
      NotificationError(e);
    }
    yield put(createRoleFail());
  }
}

function* handleViewRole(action: ReturnType<typeof getDetailRole>) {
  try {
    const res: RoleObjectType = yield getDetailRoleById(action?.payload);
    yield put(getDetailRoleSuccess(res));
  } catch (e: any) {
    NotificationError(e);
  }
}

function* handleUpdateRole(action: ReturnType<typeof updateRole>) {
  try {
    const clientId: string = yield select(clientIdSelector);

    yield updateRoleById({ ...action?.payload, clientId: clientId });
    const params: ParamsType = yield select(paramsSelector);
    yield put(updateRoleSuccess());
    yield put(getListRole(params));
    NotificationSuccess('role.updateSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(updateRoleFail());
  }
}

function* handleDeleteRole(action: ReturnType<typeof deleteRole>) {
  try {
    yield deleteRoleById(action?.payload);
    const params: ParamsType = yield select(paramsSelector);
    yield put(deleteRoleSuccess());
    yield put(getListRole(params));
    NotificationSuccess('role.deleteSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteRoleFail());
  }
}

function* handleDeleteMutiRole(action: ReturnType<typeof deleteMutiRole>) {
  try {
    yield deleteMutiRoleById(action?.payload);
    const params: ParamsType = yield select(paramsSelector);
    yield put(deleteMutiRoleSuccess());
    yield put(getListRole(params));
    NotificationSuccess('common.deleteSuccess', { value: 'role.title' });
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteMutiRoleFail());
  }
}

export default function* authSaga(): any {
  return all([
    yield takeLatest(getListRole, getListRoleByQuickSearch),
    yield takeLatest(getListRoleDefault, getRolesDefault),
    yield takeLatest(createRoleStart, handleCreateRole),
    yield takeLatest(getDetailRole, handleViewRole),
    yield takeLatest(updateRole, handleUpdateRole),
    yield takeLatest(deleteRole, handleDeleteRole),
    yield takeLatest(deleteMutiRole, handleDeleteMutiRole),
  ]);
}
