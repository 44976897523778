import { Button, Result } from 'antd';
import paths from 'helper/pathRoutes';
import React from 'react';
import { Link } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';

const NotFoundPage = () => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={resetBoundary}>
            <Link to={paths.liveMonitoring}>Home</Link>
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
