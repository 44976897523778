import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ParamsType, ParamsReportItem, PracticeReportItem, TypeTF, TypeItemView, dataItemViewDefault, CourseType, LicenseType, } from '../type';
import moment from 'moment';
import { DefaultPage, Page } from 'types';

export interface PracticeReportState {
  loading: boolean;
  data: Page<PracticeReportItem>;
  showFilter: boolean;
  params: ParamsType;
  loadingSelect: boolean;
  loadingBtnExport: boolean;
  listTF: TypeTF[];
  showModal: boolean;
  loadingModal: boolean;
  itemView: TypeItemView;
  listCourse: CourseType[];
  listLicense: LicenseType[];
}

const initialState: PracticeReportState = {
  loading: false,
  data: DefaultPage,
  showFilter: false,
  params: {
    page: 0,
    size: 10,
    endDate: moment().format('YYYY-MM-DD'),
    startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    clientId: '',
  },
  loadingSelect: false,
  loadingBtnExport: false,
  listTF: [],
  showModal: false,
  loadingModal: false,
  itemView: dataItemViewDefault,
  listCourse: [],
  listLicense: [],
};

const practiceReportSlice = createSlice({
  name: 'practiceReportSlice',
  initialState,
  reducers: {
    getListReportItem: (state, action: PayloadAction<ParamsType>) => {
      state.loading = true;
    },
    getListReportItemSuccess: (state, action: PayloadAction<Page<PracticeReportItem>>) => {
      state.loading = false;
      state.data = action.payload;
    },
    getListReportItemFail: state => {
      state.loading = false;
    },
    setParams: (state, action: PayloadAction<ParamsType>) => {
      state.params = action.payload;
    },
    setShowFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilter = action.payload;
    },

    getListCourse: (state, action: PayloadAction<string>) => {
      state.loadingSelect = true;
    },
    getListCourseSuccess: (state, action: PayloadAction<CourseType[]>) => {
      state.loadingSelect = false;
      state.listCourse = action.payload;
    },
    getListCourseFail: state => {
      state.loadingSelect = false;
    },

    exportReport: (state, action: PayloadAction<ParamsReportItem>) => {
      state.loadingBtnExport = true;
    },

    exportReportSuccess: state => {
      state.loadingBtnExport = false;
    },

    exportReportFail: state => {
      state.loadingBtnExport = false;
    },

    getListTF: state => {},

    getListTFSuccess: (state, action: PayloadAction<TypeTF[]>) => {
      state.listTF = action.payload;
    },

    getListTFFail: state => {},

    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },

    getDetail: (state, action: PayloadAction<ParamsReportItem>) => {
      state.loadingModal = true;
    },
    getDetailSuccess: (state, action: PayloadAction<TypeItemView>) => {
      state.loadingModal = false;
      state.itemView = action.payload;
    },
    getDetailFail: state => {
      state.loadingModal = false;
    },
    startGetListLicense: () => {},
    getListLicenseSuccess: (state, action: PayloadAction<LicenseType[]>) => {
      state.listLicense = action.payload;
    },
  },
});

export const {
  getListReportItem,
  getListReportItemSuccess,
  getListReportItemFail,
  setParams,
  setShowFilter,
  getListCourse,
  getListCourseFail,
  getListCourseSuccess,
  exportReport,
  exportReportFail,
  exportReportSuccess,
  getListTF,
  getListTFFail,
  getListTFSuccess,
  getDetail,
  getDetailFail,
  getDetailSuccess,
  setShowModal,
  startGetListLicense,
  getListLicenseSuccess,
} = practiceReportSlice.actions;

export default practiceReportSlice.reducer;
