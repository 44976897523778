import { APP_CODE } from 'AppConfig';

export const ACCESS_TOKEN_KEY = `${APP_CODE}:access_token`;
export const REFRESH_ACCESS_TOKEN_KEY = `${APP_CODE}:refresh_token`;
export const USERNAME = 'username';

export enum ActionType {
  ADD = 'ADD',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum StatusType {
  CANCEL = 0,
  PENDDING = 2,
  SUCCESS = 1,
  FAIL = 3,
}

export enum ActionMove {
  TOP = 'TOP',
  UP = 'UP',
  DOWN = 'DOWN',
  BOTTOM = 'BOTTOM',
}

export enum ModelStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum FileType {
  TEMPLATE = 'template',
  ACCESS_PERSON = 'accessPerson',
}

export const FileTemplateName = 'Them_danh_sach_nguoi_truy_cap.xlsx';
export const MaxFileUpload = 5 * 1024 * 1024;
export const userTypeSignUp = 'owner';

export const RegValidPass = '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]';
// eslint-disable-next-line no-useless-escape
export const RegValidStringEnglish = /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;

export const RegValidEmail = /[a-zA-Z0-9][\w-\.]+@[a-zA-Z0-9][\w-\.]+(\.[a-zA-Z0-9]+)$/;

export const RegValidIdNumber = /^[0-9]{12}$/;
export const RegValidPhoneNumber = /^[0-9]{10,11}$/;

export enum TypeUser {
  TYPE1 = 1, //chua dang ky goi dich vu
  TYPE2 = 2, // dang ky goi dung thu, chua gan thiet bi
  TYPE3 = 3, // dang ky goi dung thu, da gan thiet bi
  TYPE4 = 4, // dang ky goi tra phi, chua gan thiet bi
  TYPE5 = 5, // dang ky goi tra phi, da gan thiet bi
  TYPE6 = 6, // da het han
}

export enum TypeSyncing {
  NOT_SYNC = 0,
  SYNCING = 1,
  SYNC_SUCCESS = 2,
  SYNC_FAIL = 3,
  NO_DATA = -1,
}

export enum StatusTrainingType {
  ALL = '3',
  ACTIVE = '1',
  INACTIVE = '0',
}

export enum UnitType {
  CUC = 1,
  SO = 2,
  CSDT = 3,
}

export const DateFormat = 'DD/MM/YYYY';

export const optionsCompressedFile = {
  maxSizeMB: 0.1,
  useWebWorker: true,
  maxWidthOrHeight: 500,
};
