import { Polyline } from '@react-google-maps/api';
import { runOffset } from 'modules/mapMonitor/redux';
import { dataTracksSelector, offsetSelector } from 'modules/mapMonitor/selector';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

const Poly = () => {
  const dispatch = useDispatch();
  const offset: number = useSelector(offsetSelector);

  const dataTracks = useSelector(dataTracksSelector);

  useEffectOnce(() => {
    dispatch(runOffset());
  });

  if (dataTracks.length)
    return (
      <Polyline
        path={dataTracks}
        options={{
          strokeColor: '#003D7A',
          strokeOpacity: 1,
          strokeWeight: 4,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillOpacity: 1,
                strokeColor: '#003D7A',
                fillColor: '#FFF',
                scale: 6,
              },
              offset: `${offset}%`,
            },
          ],
        }}
      />
    );
  else return null;
};

export default Poly;
