import React from 'react';
import { Wrapper } from './styles';
import Grid from '../components/Grid';
import { FormattedMessage } from 'react-intl';
import { TitleHeader } from 'components/commons/Template/style';

const AccountPage = () => {
  return (
    <Wrapper id="wrapperAccountsManager">
      <TitleHeader id="filterAccountsManager">
        <FormattedMessage id="layout.leftMenu.accountManager" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default AccountPage;
