import { Wrapper } from 'modules/auth/pages/ModalLogin/styles';
import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import ImgBackground from 'modules/auth/asset/imgs/login-background.png';
import { useDispatch } from 'react-redux';
import { verifyToken } from '../redux';
import { useQuery } from 'modules/sso/page';

const ForgotPasswordPage = () => {
  let query = useQuery();
  const history = useHistory();
  const token = query.get('token');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;
    dispatch(
      verifyToken({
        token: token,
        history: history,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Wrapper
      style={{
        background: `url(${ImgBackground}) center fixed no-repeat `,
      }}
    ></Wrapper>
  );
};

export default withRouter(ForgotPasswordPage);
