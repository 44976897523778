import React from 'react';
import { Wrapper } from './styles';
import { TitleHeader } from 'components/commons/Template/style';
import { FormattedMessage } from 'react-intl';
import Header from '../components/Header';
import Grid from '../components/Grid';

const StudentReportPage = () => {
  return (
    <Wrapper id="wrapperReportManager">
      <TitleHeader>
        <FormattedMessage id="studentReport.title" />
      </TitleHeader>
      <Header />
      <Grid />
    </Wrapper>
  );
};

export default StudentReportPage;
