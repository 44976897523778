import { TitleHeader } from 'components/commons/Template/style';
import { FormattedMessage } from 'react-intl';
import { Wrapper } from './styles';
import Grid from '../components/Grid';
import SearchForm from '../components/SearchForm';
import React from 'react';


const VehicleReportPage = () => {
  return (
    <Wrapper id="wrapperStudentsManager">
      <TitleHeader id="filterStudentsManager">
        <FormattedMessage id="layout.leftMenu.vehicleReport" />
      </TitleHeader>
      <SearchForm/>
      <Grid />
    </Wrapper>
  );
};

export default VehicleReportPage;
