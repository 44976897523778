import { notification } from 'antd';
import { Language } from 'languages/redux';
import language from 'languages/translate';
import { createIntl, createIntlCache } from 'react-intl';
import { store } from 'redux/store';
export interface ErrMessage {
  detail: string;
  message: string;
  title: string;
}

export const NotificationSuccess = (message: string, values?: any) => {
  const { type } = store.getState().language;
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: type,
      messages: language[Language[type]],
    },
    cache
  );
  if (values) {
    Object.keys(values).forEach(function (key, _) {
      values[key] = intl?.formatMessage({ id: values[key] });
    });
  }

  notification.open({
    type: 'success',
    message: intl?.formatMessage({ id: message }, values),
    placement: 'topRight',
    style: {
      borderLeft: '5px solid green',
    },
  });
};

export const NotificationError = (data: ErrMessage) => {
  notification.open({
    type: 'error',
    message: data.detail ?? data.title ?? data.message,
    placement: 'topRight',
    style: {
      borderLeft: '5px solid red',
    },
  });
};

export const NotificationErrorCustom = (message: string) => {
  const { type } = store.getState().language;
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: type,
      messages: language[Language[type]],
    },
    cache
  );

  notification.open({
    type: 'error',
    message: intl?.formatMessage({ id: message }),
    placement: 'topRight',
    style: {
      borderLeft: '5px solid red',
    },
  });
};
