import styled from 'styled-components/macro';

export const WindowWrapper = styled('div')`
  .gm-style-iw button {
    display: none !important;
  }
`;

export const TimeWrapper = styled('div')`
  display: flex;
  gap: 20px;
  > div {
    display: flex;
    gap: 4px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  margin-right: 4px;
`;

export const TimeContent = styled.div`
  display: flex;
`;
