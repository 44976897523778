import styled from 'styled-components/macro';
import CButton from 'components/commons/Button';
import Step from 'components/commons/Step';
import { BtnTabType } from '../constant';

export const DetailContainer = styled.div`
  min-width: 350px;
  padding-right: 10px;
  .trip-range-picker {
    width: 233px;
  }
  .trip-row-event {
    margin-bottom: 12px;
    .ant-select {
      width: 233px;
    }
  }
  .trip-row-event > div:first-child {
    width: 72px;
  }
  .trip-row-event > div:last-child {
    width: 233px;
  }
`;

export const DepartmentSort = styled.div`
  background-color: ${({ theme }) => theme.menuBackground};
  padding: 10px;
  border-radius: 8px;
`;

export const ItemSort = styled.div`
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-select {
    width: 70%;
  }
`;

export const ItemText = styled.div`
  gap: 4px;
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 6px;
`;

export const Label = styled.div`
  white-space: pre;
  word-break: break-word;
  overflow: hidden;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.titleDefault};
`;

export const Content = styled.div`
  white-space: pre;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #727880;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const LabelRequire = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.titleDefault};
  ::after {
    display: inline-block;
    margin-inline-end: 4px;
    margin-inline-start: 2px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    position: absolute;
  }
`;

export const BodyFleetView = styled.div`
  position: relative;
  gap: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.menuBackground};
  padding: 10px;
  border-radius: 8px;
  height: calc(100% - 66px);
`;

export const Xhnum = styled.div`
  min-width: 100px;
  font-size: 16px;
  color: #1b1d1f;
  font-weight: 500;
  align-items: center;
  line-height: 25px;
`;

export const NumSpeedDevcie = styled.div`
  color: #727880;
  background-color: #eee;
  padding: 8px 12px;
  border-radius: 16px;
  margin-left: 10px;
  font-size: 12px;
`;

export const NumDetailSpeed = styled.div`
  display: flex;
`;

export const DiriverSvg = styled.div`
  display: flex;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DiriverName = styled.div`
  color: #727880;
`;

export const Num = styled.div`
  font-size: 14px;
  color: #727880;
  padding-left: 4px;
  padding-right: 10px;
`;

export const IconBottomItem = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

export const BottomLeft = styled.div`
  display: flex;
`;

export const StyledBtnTab = styled(CButton)`
  max-width: 200px;

  span {
    max-width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    word-break: break-all;
    overflow: hidden;
    text-wrap: wrap;
  }
`;

export const StyledBtnTabCustom = styled(CButton)`
  width: 50px !important;

  span {
    max-width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    word-break: break-all;
    overflow: hidden;
    text-wrap: wrap;
  }
`;

export const RowButton = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-evenly;
  margin-bottom: 10px;
  .trip-icon-down {
    margin-bottom: 22px;
  }
  padding: 10px;
`;

export const TitleTripStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const NameTitleTripStyled = styled.div`
  font-weight: 600;
`;

export const InforTitleTripStyled = styled.div`
  color: ${({ theme }) => theme.menuDisableDark};
  font-size: 12px;
`;

export const ListStepStyled = styled(Step)`
  position: relative;

  .ant-steps-icon-dot {
    top: 12px;
  }

  .ant-steps-item-tail {
    margin-inline-start: 6px !important;
  }

  .ant-steps-item-title {
    font-size: 12px !important;
    color: ${({ theme }) => theme.menuDisableDark} !important;
  }

  .ant-steps-item-icon {
    margin-inline-start: 0px !important;
    width: 12px !important;
    height: 12px !important;
  }

  .ant-steps-icon-dot {
    width: 12px !important;
    height: 12px !important;
  }

  .ant-steps-item-container:last-child {
    width: 12px !important;
    .ant-steps-item-content .ant-steps-item-title {
      width: 44px;
      margin-left: -7px;
    }
  }

  .ant-steps-item-content {
    width: 30px !important;
  }
`;

export const DetailStepStyled = styled(Step)`
  .ant-steps-item-content {
    position: relative;
  }
  .ant-steps-item-title {
    width: 100%;
    font-size: 13px !important;
    line-height: 24px !important;
    color: ${({ theme }) => theme.titleDefault} !important;
  }

  .ant-steps-item-description {
    font-size: 13px !important;
    color: ${({ theme }) => theme.titleDefault} !important;
  }

  .ant-steps-icon-dot {
    width: 8px !important;
    height: 8px !important;
  }

  .ant-steps-item-subtitle {
    position: absolute;
    right: 0;
    height: calc(100% + 14px);
    span {
      height: 100%;
    }
  }

  .event-play-video {
    color: ${({ theme }) => theme.primary};
    font-size: 23px;
  }
`;

export const CardTripStyled = styled.div`
  display: flex;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px dashed #c6c8cb;
  border-radius: 16px;
  padding: 12px;
  border-style: dotted;
  .ant-col:last-child {
    color: ${({ theme }) => theme.menuDisableDark};
  }
  margin: 12px 0 12px 0;
  gap: 12px;
  img {
    border-radius: 8px;
  }
`;

export const RowTrip = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 12px;
  justify-content: space-evenly;
  .trip-icon-down {
    margin-bottom: 22px;
    cursor: pointer;
  }
  .trip-icon-info {
    position: absolute;
    font-size: 24px !important;
    color: #c671ca !important;
    background-color: white;
    top: 4px;
  }
  .ant-badge-count {
    min-width: 18px !important;
    height: 18px !important;
    font-size: 11px !important;
    line-height: 18px !important;
  }
`;

export const CardEventTrip = styled.div`
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  padding: 16px 24px;
  :hover {
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
  .trip-card-event--time {
    margin-bottom: 4px;
  }
  .event-play-video {
    color: ${({ theme }) => theme.primary};
    font-size: 23px;
  }
  cursor: pointer;
  margin-bottom: 12px;
`;

export const MapTripStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .map-marker--label {
    font-size: 12px !important;
    margin-bottom: 55px;
    border: 1px solid black;
    border-radius: 4px;
    padding: 4px;
    font-weight: 600;
    background-color: white;
  }
`;

export const CardMapWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 12px 18px;
  background-color: white;
  width: 83%;
  height: 68px;
  z-index: 99;
  gap: 12px;
  justify-content: space-between;
`;

export const MapRow = styled.div`
  display: flex;
  gap: 12px;
  img {
    width: 43px;
    border-radius: 8px;
  }
  height: 43px;
`;

export const MapUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const CardMap = styled.div`
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  padding: 12px 16px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
  div {
    margin-left: 6px;
  }
`;

export const ListCarItemBox = styled.div`
  height: 100%;
  padding: 0 10px 10px 10px;
  > div {
    margin-bottom: 12px;
  }
`;

export const BodyDetailSession = styled('div')<{ $tab: string }>`
  position: relative;
  gap: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.menuBackground};
  border-radius: 8px;
  height: calc(100% - 5px);
  .ant-spin-nested-loading {
    height: ${({ $tab }) => ($tab === BtnTabType.trip ? 'calc(100% - 64px)' : 'calc(100% - 86px)')};
    /* .ant-spin-container {
      height: 100%;
    } */
    overflow-y: auto;
    margin-bottom: 10px;

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar {
      width: 4px;
      /* background-color: #f5f5f5; */
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: rgb(94, 102, 115);
    }
  }
`;

export const EventNoData = styled('div')<{ $isNotDisplay?: any }>`
  margin-top: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.titleDefault};
  text-align: center;
  font-family: Inter;
  img {
    width: 115px;
    margin-bottom: 8px;
  }
  display: ${({ $isNotDisplay }) => (!$isNotDisplay ? 'block' : 'none')};
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 12px;
  }
`;

export const UserInfoItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.menuDisableDark};
  svg {
    margin-right: 4px;
    /* color: ${({ theme }) => theme.menuDisableDark}; */
  }
`;

export const CardEventWrapper = styled.div``;

export const EventDate = styled.div`
  width: 100%;
  text-align: center;
  > div {
    text-align: center;
    font-size: 0.75em;
    display: inline-block;
    color: #fff;
    padding: 8px 32px;
    margin: 12px 0;
    background: #99a0a9;
    border-radius: 32px;
  }
`;
