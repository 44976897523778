import { all, put, takeLatest } from 'redux-saga/effects';
import { NotificationError } from 'components/notifications';
import { apiGetSubscribers } from '../services';
import {

  getSubscribers,
  getSubscribersFail,
  getSubscribersOk,
} from '../redux/header';





function* handleGetSubscriber(): any {
  try {
    const res = yield apiGetSubscribers();
    yield put(getSubscribersOk(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getSubscribersFail());
  }
}


export default function* headerSaga(): any {
  return all([
    yield takeLatest(getSubscribers, handleGetSubscriber),
  ]);
}
