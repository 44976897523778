import HttpRequest from 'core/http/singleton/auth';
import { DataPayloadCreateCourse, ParamsPayloadType,DataPayloadCourseLesson } from '../type';

export const createCourseService = async (data: DataPayloadCreateCourse): Promise<any> => {
  const res = await HttpRequest.post<any>('api/course', data);
  return res.data;
};

export const getListCourseByParamsService = async (params: ParamsPayloadType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/course', params);
  return res.data;
};

export const getDetailCourseService = async (id: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/course/${id}`);
  return res.data;
};

export const updateCourseService = async (data: DataPayloadCreateCourse): Promise<any> => {
  const res = await HttpRequest.put<any>('api/course', data);
  return res.data;
};

export const deleteCourseService = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>('api/course', {
    data: data,
  });
  return res.data;
};

export const getListCourseParamService = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/course/param');
  return res.data;
};

export const getListCourseLessonService = async (params: DataPayloadCourseLesson): Promise<any> => {
  const {id, page, size} = params;
  const res = await HttpRequest.get<any>(`api/course/lesson/${id}`, { page, size });
  return res.data;
};
