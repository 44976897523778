import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  createCourse,
  createCourseFail,
  createCourseSuccess,
  getListCourse,
  getListCourseFail,
  getListCourseSuccess,
  getDetailCourse,
  getDetailCourseFail,
  getDetailCourseSuccess,
  updateCourse,
  updateCourseSuccess,
  updateCourseFail,
  deleteCourse,
  deleteCourseFail,
  deleteCourseSuccess,
  getListCourseParam,
  getListCourseParamSuccess,
  getListCourseParamFail,
  getDetailLessons,
  getDetailLessonsSuccess,
  getDetailLessonsFail,
} from '../redux';
import {
  createCourseService,
  deleteCourseService,
  getDetailCourseService,
  getListCourseByParamsService,
  getListCourseParamService,
  updateCourseService,
  getListCourseLessonService,
} from '../services';
import { NotificationError, NotificationSuccess } from 'components/notifications';
import { paramsSelector, paramsLessonSelector } from '../selector';

function* handleCreateCourse(action: ReturnType<typeof createCourse>): any {
  try {
    yield createCourseService(action?.payload);
    yield put(createCourseSuccess());
    const params = yield select(paramsSelector);
    yield put(getListCourse(params));
    NotificationSuccess('course.addSuccess');
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(createCourseFail());
  }
}

function* handleUpdateCourse(action: ReturnType<typeof updateCourse>): any {
  try {
    yield updateCourseService(action?.payload);
    yield put(updateCourseSuccess());
    const params = yield select(paramsSelector);
    yield put(getListCourse(params));
    NotificationSuccess('course.updateSuccess');
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(updateCourseFail());
  }
}

function* handleGetListCourse(action: ReturnType<typeof getListCourse>): any {
  try {
    const res = yield getListCourseByParamsService(action?.payload);
    yield put(getListCourseSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListCourseFail());
  }
}

function* handleGetDetailCourse(action: ReturnType<typeof getDetailCourse>): any {
  try {
    const paramsLesson = yield select(paramsLessonSelector);
    const res = yield getDetailCourseService(action.payload);
    const resLesson = yield getListCourseLessonService({ ...paramsLesson, id: action.payload });
    yield put(getDetailCourseSuccess(res));
    yield put(getDetailLessonsSuccess(resLesson));
  } catch (e: any) {
    NotificationError(e);
    yield put(getDetailCourseFail());
  }
}

function* handleDeleteCourse(action: ReturnType<typeof deleteCourse>): any {
  try {
    yield deleteCourseService(action.payload);
    yield put(deleteCourseSuccess());
    NotificationSuccess('course.deleteSuccess');
    const params = yield select(paramsSelector);
    yield put(getListCourse(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteCourseFail());
  }
}

function* handleGetListCourseParam(action: ReturnType<typeof getListCourseParam>): any {
  try {
    const res = yield getListCourseParamService();
    yield put(getListCourseParamSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListCourseParamFail());
  }
}

function* handleGetDetailLessons(): any {
  try {
    const paramsLesson = yield select(paramsLessonSelector);
    const res = yield getListCourseLessonService(paramsLesson);
    yield put(getDetailLessonsSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getDetailLessonsFail());
  }
}

export default function* courseManagerSaga(): any {
  return all([
    yield takeLatest(createCourse, handleCreateCourse),
    yield takeLatest(getListCourse, handleGetListCourse),
    yield takeLatest(getDetailCourse, handleGetDetailCourse),
    yield takeLatest(updateCourse, handleUpdateCourse),
    yield takeLatest(deleteCourse, handleDeleteCourse),
    yield takeLatest(getListCourseParam, handleGetListCourseParam),
    yield takeLatest(getDetailLessons, handleGetDetailLessons),
  ]);
}
