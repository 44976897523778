import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { InfoStyled, TitleInfo } from './style';
import { Col, Row } from 'antd';
import { lessonDetailSelector } from 'modules/monitorHistory/selector';

const DetailInfo = () => {
  const intl = useIntl();
  const lessonDetail = useSelector(lessonDetailSelector);

  return (
    <InfoStyled>
      <Row gutter={[5, 5]}>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'historyMonitor.licensePlate' })}</TitleInfo>
          <span>{lessonDetail.licensePlate}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'course.sessionTraining' })}</TitleInfo>
          <span>: &nbsp;</span>
          <span>{lessonDetail.drivingLessonCode}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'vehicleReport.time' })}</TitleInfo>
          <span>: &nbsp;</span>
          <span>
            {lessonDetail.startTime ? moment(lessonDetail.startTime).format('DD/MM/YYYY HH:mm') : ''} -{' '}
            {lessonDetail.endTime ? moment(lessonDetail.endTime).format('DD/MM/YYYY HH:mm') : ''}
          </span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'course.courseCode' })}</TitleInfo>
          <span>: &nbsp;</span>
          <span>{lessonDetail.courseCode}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'mapMonitor.teacher' })}</TitleInfo>
          <span>{lessonDetail.instructorName}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'mapMonitor.studentName' })}</TitleInfo>
          <span>{lessonDetail.studentName}</span>
        </Col>
      </Row>
    </InfoStyled>
  );
};

export default DetailInfo;
