import { Input } from 'antd';
import styled from 'styled-components/macro';

export const StyledInput = styled(Input)`
  height: 36px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  &:disabled {
    color: #2d394b;
  }
`;
