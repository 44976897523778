import { all, put, select, takeLatest } from 'redux-saga/effects';
import { NotificationError } from '../../../components/notifications';
import {
  downloadFile,
  downloadFileFail,
  downloadFileSuccess,
  getDataDetail,
  getDataDetailFail,
  getDataDetailSuccess,
  getDetailLesson,
  getDetailLessonFail,
  getDetailLessonSuccess,
  getListLicensePlate,
  getListLicensePlateFail,
  getListLicensePlateSuccess,
  getListTF,
  getListTFFail,
  getListTFSuccess,
  getListVehicle,
  getListVehicleFail,
  getListVehicleSuccess,
} from '../redux';
import { downLoadFileApi, getDetailVehicleApi, getListLicensePlateApi, getListTrainingFacility, getListVehicleByParams } from '../service';
import { ManagementBaseType } from '../../auth/types';
import { managementBaseSelector } from '../../auth/selectors';
import moment from 'moment';
import { getDetailLessonApi } from 'modules/studentReport/service';
import { getDetailHistoryStart, setTypeModal } from 'modules/monitorHistory/redux';
import { ActionType } from 'helper/constants';

function* handleGetListVehicle(action: ReturnType<typeof getListVehicle>): any {
  try {
    const res = yield getListVehicleByParams(action?.payload);
    yield put(getListVehicleSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListVehicleFail());
  }
}

function* handleGetDetailVehicle(action: ReturnType<typeof getDataDetail>): any {
  try {
    const res = yield getDetailVehicleApi(action.payload.id, { startTime: action.payload.startDate, endTime: action.payload.endDate });
    yield put(getDataDetailSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getDataDetailFail());
  }
}

function* handleGetListTF(): any {
  try {
    const { clientType }: ManagementBaseType = yield select(managementBaseSelector);
    const res = yield getListTrainingFacility({
      clientType: clientType,
    });
    yield put(getListTFSuccess(res));
  } catch (e: any) {
    yield put(getListTFFail());
    NotificationError(e);
  }
}

function* handleGetListLicensePlate(action: ReturnType<typeof getListLicensePlate>): any {
  try {
    const res = yield getListLicensePlateApi(action.payload);
    yield put(getListLicensePlateSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListLicensePlateFail());
  }
}

function* handleDownloadFile(action: ReturnType<typeof downloadFile>): any {
  try {
    const res = yield downLoadFileApi(action.payload.id, { startTime: action.payload.startDate, endTime: action.payload.endDate });
    const blob = new Blob([res], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = `Bao_cao_qua_trinh_dao_tao_cua_xe_tap_lai_${moment().format('YYYYMMDD')}`;
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
    yield put(downloadFileSuccess());
  } catch (e: any) {
    yield put(downloadFileFail());
    NotificationError(e);
  }
}

function* handleGetDetailLesson(action: ReturnType<typeof getDetailLesson>): any {
  try {
    const res = yield getDetailLessonApi(action.payload);
    yield put(getDetailLessonSuccess());
    yield put(setTypeModal(ActionType.VIEW));
    yield put(getDetailHistoryStart(res));
  } catch (e: any) {
    yield put(getDetailLessonFail());
    NotificationError(e);
  }
}

export default function* vehicleReportSaga(): any {
  return all([
    yield takeLatest(getListVehicle, handleGetListVehicle),
    yield takeLatest(getDataDetail, handleGetDetailVehicle),
    yield takeLatest(getListTF, handleGetListTF),
    yield takeLatest(getListLicensePlate, handleGetListLicensePlate),
    yield takeLatest(downloadFile, handleDownloadFile),
    yield takeLatest(getDetailLesson, handleGetDetailLesson),
  ]);
}
