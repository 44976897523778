import { ManagementBaseType } from '../types';

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  clientList: ClientType[];
  additionalActionCode: additionalActionType;
  otpTime: number;
  parentInfo?: ManagementBaseType;
}

export enum additionalActionType {
  REQUIRE_CLIENT_SELECTION = 'REQUIRE_CLIENT_SELECTION',
  REQUIRE_PASSWORD_CHANGE = 'REQUIRE_PASSWORD_CHANGE',
}
export interface ClientType {
  clientCode: string;
  clientId: string;
  clientName: string;
  userCode: string;
}

export interface AllParamResponse {
  PERSON_SEX: ParamItem[];
  DOOR_TYPE: ParamItem[];
  TRAINING_TYPE: ParamItem[];
  OTP_DEVICE: ParamItem[];
}
export interface ParamItem {
  label: string;
  value: string;
}
