import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  createTC,
  createTCSuccess,
  getListTC,
  createTCFail,
  updateTC,
  updateTCSuccess,
  getListTCSuccess,
  getListTCFail,
  getDetailTC,
  getDetailTCSuccess,
  getDetailTCFail,
  deleteTC,
  deleteTCSuccess,
  deleteTCFail,
  updateTCFail,
  getListAgency,
  getListAgencySuccess,
} from '../redux';
import {
  createTrainingFacilityApi,
  deleteTrainingFacilityApi,
  getDetailTrainingFacilityApi,
  getListTrainingFacilityApi,
  updateTrainingFacilityApi,
  getListAgencyApi,
} from '../services';
import { NotificationError, NotificationSuccess } from 'components/notifications';
import { paramsSelector } from '../selector';

function* handleCreateTC(action: ReturnType<typeof createTC>): any {
  try {
    yield createTrainingFacilityApi(action?.payload.data);
    yield put(createTCSuccess());
    const params = yield select(paramsSelector);
    yield put(getListTC(params));
    NotificationSuccess('common.addSuccess', { value: 'trainingFacility.span' });
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(createTCFail());
  }
}

function* handleUpdateTC(action: ReturnType<typeof updateTC>): any {
  try {
    yield updateTrainingFacilityApi(action?.payload.data);
    yield put(updateTCSuccess());
    const params = yield select(paramsSelector);
    yield put(getListTC(params));
    NotificationSuccess('common.updateSuccess', { value: 'trainingFacility.span' });
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(updateTCFail());
  }
}

function* handleGetListUser(action: ReturnType<typeof getListTC>): any {
  try {
    const res = yield getListTrainingFacilityApi(action?.payload);
    yield put(getListTCSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListTCFail());
  }
}

function* handleGetListAgency(action: ReturnType<typeof getListAgency>): any {
  try {
    const res = yield getListAgencyApi();
    yield put(getListAgencySuccess(res));
  } catch (e: any) {
    NotificationError(e);
  }
}

function* handleGetDetailUser(action: ReturnType<typeof getDetailTC>): any {
  try {
    const res = yield getDetailTrainingFacilityApi(action.payload);
    yield put(getDetailTCSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getDetailTCFail());
  }
}

function* handleDeleteUser(action: ReturnType<typeof deleteTC>): any {
  try {
    yield deleteTrainingFacilityApi(action.payload);
    yield put(deleteTCSuccess());
    NotificationSuccess('common.deleteSuccess', { value: 'trainingFacility.span' });
    const params = yield select(paramsSelector);
    yield put(getListTC(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteTCFail());
  }
}

export default function* trainingFacilityManagerSaga(): any {
  return all([
    yield takeLatest(createTC, handleCreateTC),
    yield takeLatest(getListTC, handleGetListUser),
    yield takeLatest(getDetailTC, handleGetDetailUser),
    yield takeLatest(updateTC, handleUpdateTC),
    yield takeLatest(deleteTC, handleDeleteUser),
    yield takeLatest(getListAgency, handleGetListAgency),
  ]);
}
