import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import flatMap from 'lodash/flatMap';
import concat from 'lodash/concat';
import map from 'lodash/map';

export enum actionsType {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  MONITOR = 'MONITOR',
}

export interface MenuItem {
  code: string;
  uri: string | null;
  actions: actionsType[];
  items: MenuItem[];
}

export interface LeftMenuState {
  loadingMenu: boolean;
  menuItem: MenuItem[];
  listUrlActive: (string | null)[];
  collapsed: boolean;
}

const initialState: LeftMenuState = {
  loadingMenu: false,
  menuItem: [],
  listUrlActive: [],
  collapsed: false,
};

const leftMenuSlice = createSlice({
  name: 'leftMenuSlice',
  initialState,
  reducers: {
    getMenuStart: state => {
      state.loadingMenu = true;
    },
    getMenuSuccess: (state, action: PayloadAction<MenuItem[]>) => {
      state.menuItem = action.payload;
      const arrMenuItem = flatMap(action.payload, ({ items }) => map(items, items => items));
      const arrUrl = concat(action.payload, arrMenuItem).map(item => item.uri);
      state.listUrlActive = arrUrl;
      state.loadingMenu = false;
    },
    getMenuFail: state => {
      state.loadingMenu = false;
    },
    setCollapsed: state => {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { getMenuStart, getMenuSuccess, getMenuFail, setCollapsed } = leftMenuSlice.actions;

export default leftMenuSlice.reducer;
