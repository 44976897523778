export interface ReportItemType {
  areaName: string;
  accessTime: string;
  cardNo: string;
  name: string;
  object?: string | null;
  areaId: string;
  accessLogId: string;
}

export interface ParamsType {
  startDate?: string;
  endDate?: string;
  fullName?: string;
  citizenIdCardNo?: string;
  courseCode?: string;
  page: number;
  size: number;
  clientId?: string;
}

export interface ParamsExportType {
  startDate?: string;
  endDate?: string;
  name?: string;
  cardNo?: string;
  areaName?: string;
  size?: number;
}

export interface ParamsGetListAreaType {
  name: string;
}

export interface AreaItemType {
  id: string;
  name: string;
  code: string;
}

export interface ParamsTF {
  clientType: number;
}

export interface ParamsReportItem {
  idStudent: string;
  idCourse: string;
}

export interface StudentReportItem {
  studentId: string;
  fullName: string;
  citizenIdCardNo: string;
  birthday: string;
  code: string;
  licenseClass: string;
  courseId: string;
}

export interface TypeTF {
  children: null | TypeTF[];
  id: string;
  name: string;
  parentId: null | string;
  disabled?: boolean;
}

export interface LessonType {
  stt: number;
  code: string;
  licensePlate: string;
  startDate: string;
  endDate: string;
  id: string;
  duration: null | string;
  distance: null | string;
}

export interface TypeItemView {
  fullName: string;
  citizenIdCardNo: string;
  birthday: string;
  code: string;
  licenseClass: string;
  schoolName: string;
  faceImage: string;
  durationTotal: number;
  distanceTotal: number;
  lessons: LessonType[];
  idCourse: string;
  idStudent: string;
}

export interface CourseType {
  id: string;
  code: string;
  name: string;
  startDate: string;
  endDate: string;
  licenseClass: string;
}

export const dataItemViewDefault = {
  fullName: '',
  citizenIdCardNo: '',
  birthday: '',
  code: '',
  licenseClass: '',
  schoolName: '',
  faceImage: '',
  durationTotal: 0,
  distanceTotal: 0,
  lessons: [],
  idCourse: '',
  idStudent: '',
};
