import React from 'react';
import { Pie, PieConfig } from '@ant-design/plots';

const ChartStudentDonut = () => {
  const data = [
    {
      type: 'Hà Nội',
      value: 16.5,
    },
    {
      type: 'Nam Định',
      value: 33,
    },
    {
      type: 'TP Hồ Chí Minh',
      value: 36.1,
    },
    {
      type: 'Đà Nẵng',
      value: 14,
    },
  ];
  const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },

    color: ({ type }) => {
      switch (type) {
        case 'Hà Nội':
          return '#A155B9';
        case 'Đà Nẵng':
          return '#00B4D8';
        case 'Nam Định':
          return '#F765A3';
        case 'TP Hồ Chí Minh':
          return '#165BAA';

        default:
          return 'black';
      }
    },

    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#165baa',
        },
        content: '100%',
      },
    },
    legend: false,
  };
  return <Pie {...config} style={{ height: '35vh' }} />;
};

export default ChartStudentDonut;
