import { ReactComponent as IconReload } from 'assets/imgs/IconReload.svg';
import styled from 'styled-components/macro';

import React from 'react';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

interface Props {
  onClick: any;
}

export const CReloadButton = (props: Props) => {
  const intl = useIntl();
  return (
    <Tooltip title={intl.formatMessage({ id: 'common.refresh' })}>
      <ReloadIcon onClick={props.onClick} />
    </Tooltip>
  );
};

const ReloadIcon = styled(IconReload)`
  cursor: pointer;
  transition: all 0.25s linear;
  &:hover {
    opacity: 0.7;
  }
`;
