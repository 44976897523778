import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetListUserRequest,
  ContentItem,
  roleItem,
  GroupCodeItem,
  GetListUserResponse,
  UserItem,
  ListId,
  ItemEdit,
} from 'modules/userGroupManager/api/types';
import { ActionType } from 'helper/constants';
import { fieldErrorsItem } from 'modules/auth/redux/type';

export interface UserGroupManagerState {
  params: GetListUserRequest;
  loadingTable: boolean;
  data: ContentItem[];
  totalElements: number;
  listRole: roleItem[];
  listRoleStatus0: roleItem[];
  listGroupCode: GroupCodeItem[];
  typeModal: ActionType | null;
  itemEdit: ItemEdit;
  listUser: UserItem[];
  listUserStatus0: UserItem[];
  selectedRowKeys: React.Key[];
  fieldErrors: fieldErrorsItem[];
  valueSearch: string;
}

const initialState: UserGroupManagerState = {
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  loadingTable: false,
  data: [],
  totalElements: 0,
  listRole: [],
  listRoleStatus0: [],
  listGroupCode: [],
  typeModal: null,
  itemEdit: {
    id: null,
    groupName: '',
    clientId: '',
    status: 1,
    groupCode: '',
    roles: [],
    user: [],
  },
  listUser: [],
  listUserStatus0: [],
  selectedRowKeys: [],
  fieldErrors: [],
  valueSearch: '',
};

const userGroupManagerSlice = createSlice({
  name: 'userGroupManagerSlice',
  initialState,
  reducers: {
    resetSearch: state => {
      state.params = initialState.params;
      state.selectedRowKeys = initialState.selectedRowKeys;
      state.valueSearch = initialState.valueSearch;
    },
    updateSearch: (state, action: PayloadAction<object>) => {
      state.params = { ...state.params, ...action.payload } || {};
      state.loadingTable = true;
    },
    getListUserSuccess: (state, action: PayloadAction<GetListUserResponse>) => {
      state.data = action.payload.content;
      state.totalElements = action.payload.totalElements;
      state.loadingTable = initialState.loadingTable;
    },
    getListUserFail: state => {
      state.data = initialState.data;
      state.loadingTable = initialState.loadingTable;
    },
    getListRoleSuccess: (state, action: PayloadAction<roleItem[]>) => {
      state.listRole = action.payload;
    },
    pushListRoleStatus0: (state, action: PayloadAction<roleItem[]>) => {
      state.listRoleStatus0 = action.payload;
    },
    getGroupCodeSuccess: (state, action: PayloadAction<GroupCodeItem[]>) => {
      state.listGroupCode = action.payload;
    },
    getListFilterGroup: () => {},
    getListUserGroup: state => {
      state.loadingTable = true;
    },
    setTypeModal: (state, action: PayloadAction<ActionType | null>) => {
      if (action.payload === null) {
        state.itemEdit = initialState.itemEdit;
        state.listRoleStatus0 = [];
        state.listUserStatus0 = [];
      }
      state.typeModal = action.payload;
      state.fieldErrors = initialState.fieldErrors;
    },
    updateItemEdit: (state, action: PayloadAction<object>) => {
      state.itemEdit = { ...state.itemEdit, ...action.payload } || {};
    },
    startGetListUserName: () => {},
    getListUserNameSuccess: (state, action: PayloadAction<UserItem[]>) => {
      state.listUser = action.payload;
    },
    pushListUserNameStatus0: (state, action: PayloadAction<UserItem[]>) => {
      state.listUserStatus0 = action.payload;
    },
    createUserGroupStart: (state, action: PayloadAction<ItemEdit>) => {},
    editUserGroupStart: (state, action: PayloadAction<ItemEdit>) => {},
    deleteListItem: (state, action: PayloadAction<ListId>) => {},
    setSelectedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
      state.selectedRowKeys = action.payload;
    },
    setFieldErrors: (state, action: PayloadAction<fieldErrorsItem[]>) => {
      state.fieldErrors = action.payload;
    },
    setValueSearch: (state, action: PayloadAction<string>) => {
      state.valueSearch = action.payload;
    },
  },
});

export const {
  resetSearch,
  updateSearch,
  getListUserSuccess,
  getListUserFail,
  getListRoleSuccess,
  pushListRoleStatus0,
  getListFilterGroup,
  getGroupCodeSuccess,
  getListUserGroup,
  setTypeModal,
  updateItemEdit,
  startGetListUserName,
  getListUserNameSuccess,
  pushListUserNameStatus0,
  createUserGroupStart,
  editUserGroupStart,
  deleteListItem,
  setSelectedRowKeys,
  setFieldErrors,
  setValueSearch,
} = userGroupManagerSlice.actions;

export default userGroupManagerSlice.reducer;
