import { Route, RouteProps, useHistory } from 'react-router-dom';
import Storage from 'helper/storage';
import React, { useEffect } from 'react';
import { isAuthenticatedSelector } from 'modules/auth/selectors';
import { listUrlActiveSelect } from 'components/layouts/selector/leftMenuSelector';
import includes from 'lodash/includes';
import paths from 'helper/pathRoutes';
import { useSelector } from 'react-redux';

export interface RouterConfig extends RouteProps {
  path: string;
  page: React.ElementType;
}

const AppRoute: React.FC<RouterConfig> = (props: RouterConfig) => {
  const { page: Component, ...rest } = props;

  const history = useHistory();

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const listUrlActive = useSelector(listUrlActiveSelect);

  const token = Storage.getAccessToken();

  useEffect(() => {
    //Check Auth
    if (!isAuthenticated || !token) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token]);

  return (
    <Route
      exact
      {...rest}
      render={appProps => {
        return !isAuthenticated || !token || !includes([...listUrlActive, paths.profile], props.path) ? null : ( //check chưa đăng nhập sẽ trả về null
          <Component {...appProps} {...rest} />
        );
      }}
    />
  );
};

export default AppRoute;
