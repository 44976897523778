import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OptionSelectBox,
  ParamsDefault,
  ParamsPayloadType,
  TypeTF,
  VehicleReport,
  VehicleReportDefault,
  VehicleReportDetail,
} from '../type';
import { DefaultPage, Page } from '../../../types';

export interface VehicleReportState {
  showModal: boolean;
  loadingTable: boolean;
  loadingBtnModal: boolean;
  loadingDownFile: boolean;
  params: ParamsPayloadType;
  listLicensePlate: OptionSelectBox[];
  listTF: TypeTF[];
  idDetail: string;
  startDateDetail: string;
  endDateDetail: string;
  dataGrid: Page<VehicleReport>;
  dataDetail: VehicleReportDetail;
  loadingModal: boolean;
}

const initialState: VehicleReportState = {
  showModal: false,
  loadingTable: false,
  loadingBtnModal: false,
  loadingDownFile: false,
  params: ParamsDefault,
  listLicensePlate: [],
  listTF: [],
  idDetail: '',
  startDateDetail: '',
  endDateDetail: '',
  dataGrid: DefaultPage,
  dataDetail: VehicleReportDefault,
  loadingModal: false,
};

const vehicleReportSlice = createSlice({
  name: 'vehicleReportSlice',
  initialState,
  reducers: {
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setParams: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.params = action.payload;
    },
    setStartDateDetail: (state, action: PayloadAction<any>) => {
      state.startDateDetail = action.payload;
    },
    setEndDateDetail: (state, action: PayloadAction<any>) => {
      state.endDateDetail = action.payload;
    },
    setIdDetail: (state, action: PayloadAction<string>) => {
      state.idDetail = action.payload;
    },
    setDataDetail: (state, action: PayloadAction<any>) => {
      state.dataDetail = action.payload;
    },
    getDataDetail: (state, action: PayloadAction<any>) => {
      state.loadingModal = true;
      state.showModal = true;
    },

    getDataDetailSuccess: (state, action: PayloadAction<any>) => {
      state.dataDetail = action.payload;
      state.loadingModal = false;
    },

    getDataDetailFail: state => {
      state.loadingModal = false;
      state.showModal = false;
    },

    getListVehicle: (state, action: PayloadAction<any>) => {
      state.loadingTable = true;
    },

    getListVehicleSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.dataGrid = action.payload;
    },
    getListVehicleFail: state => {
      state.loadingTable = false;
    },

    getListTF: state => {},

    getListTFSuccess: (state, action: PayloadAction<TypeTF[]>) => {
      state.listTF = action.payload;
    },

    getListTFFail: state => {},

    getListLicensePlate: (state, action: PayloadAction<any>) => {
      // state.loading = true;
    },

    getListLicensePlateSuccess: (state, action: PayloadAction<any>) => {
      state.listLicensePlate = action.payload.map((item: any) => {
        return { value: item, label: item };
      });
    },

    getListLicensePlateFail: state => {},

    resetListLicensePlate: state => {
      state.listLicensePlate = [];
    },

    downloadFile: (state, action: PayloadAction<any>) => {
      state.loadingDownFile = true;
    },
    downloadFileSuccess: state => {
      state.loadingDownFile = false;
    },
    downloadFileFail: state => {
      state.loadingDownFile = false;
    },
    getDetailLesson: (state, action: PayloadAction<string>) => {
      state.loadingModal = true;
    },
    getDetailLessonSuccess: state => {
      state.loadingModal = false;
    },
    getDetailLessonFail: state => {
      state.loadingModal = false;
    },
  },
});

export const {
  setShowModal,
  setParams,
  setDataDetail,
  getDataDetail,
  getDataDetailSuccess,
  getDataDetailFail,
  getListVehicle,
  getListVehicleSuccess,
  getListVehicleFail,
  getListTF,
  getListTFSuccess,
  getListTFFail,
  getListLicensePlate,
  getListLicensePlateSuccess,
  getListLicensePlateFail,
  setStartDateDetail,
  setEndDateDetail,
  resetListLicensePlate,
  downloadFile,
  downloadFileSuccess,
  downloadFileFail,
  setIdDetail,
  getDetailLesson,
  getDetailLessonFail,
  getDetailLessonSuccess,
} = vehicleReportSlice.actions;

export default vehicleReportSlice.reducer;
