import HttpRequest from 'core/http/singleton/auth';
import { ParamsLicensePlate, ParamsPayloadType, ParamsTF, PayloadTime } from '../type';

export const getListVehicleByParams = async (params: ParamsPayloadType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/reports/vehicle', params);
  return res.data;
};

export const getDetailVehicleApi = async (vehicleId: string, params: PayloadTime): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/vehicle/${vehicleId}`, params);
  return res.data;
};

export const getListLicensePlateApi = async (params: ParamsLicensePlate): Promise<any> => {
  const res = await HttpRequest.get<any>('api/reports/param/licensePlate', params);
  return res.data;
};

export const getListTrainingFacility = async (params: ParamsTF): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/lesson-view/tree-client', params);
  return res.data;
};

export const downLoadFileApi = async (vehicleId: string, params: PayloadTime): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/reports/vehicle/export/${vehicleId}`, params, { responseType: 'blob' });
  return res.data;
};
