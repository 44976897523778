import {FormattedMessage} from "react-intl";
import React from "react";
import Grid from "../../../modules/role/components/Grid";

export const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};
export default Grid;
