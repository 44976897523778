import { Col, Form } from 'antd';
import CDatePicker from 'components/commons/DatePicker';
import CSelect from 'components/commons/Select';
import CSwitch from 'components/commons/Switch';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledInstructorForm = styled(Form)`
  .ant-form-item .ant-form-item-label {
    > label::after {
      display: none;
    }
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      right: 0;
    }
  }
  span.anticon.anticon-upload {
    width: 20px;
    margin-top: 20px;
    svg {
      font-size: 20px;
    }
  }

  .ant-upload-wrapper{
    &.ant-upload-picture-card-wrapper,
    &.ant-upload-picture-circle-wrapper {
      .ant-upload.ant-upload-select >.ant-upload {
        flex-direction: column;
      }
      .ant-upload.ant-upload-select {
        width: 125px;
        height: 125px;
        margin-bottom: 0;
      }
    }
  }

  .img-wrap {
    width: 125px;
    height: 125px;
    position: relative;
    display: block;
    align-items: center;
    .close-icon {
      position: absolute;
      top: 4px;
      right: -4px;
      transform: translateX(100%);
      cursor: pointer;
      color: ${({ theme }) => theme.statusRed};
      background-color: white;
      border-radius: 50%;
      svg {
        font-size: 18px;
      }
    }
  }

  .ant-image {
    width: 100%;
    height: 100%;
    .ant-image-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const StyledCSelect = styled(CSelect)`
  min-width: 130px;
`;

export const DatePickerStyle = styled(CDatePicker)`
  width: 100%;
`;

export const ColStyle = styled(Col)`
  .portrait-image .ant-form-item-label {
    order: 1;
  }
`;

export const CSwitchStyle = styled(CSwitch)`
  .ant-switch-inner {
    .ant-switch-inner-checked,
    span.ant-switch-inner-unchecked {
      position: absolute;
      right: -12px;
      transform: translateX(100%);
      display: inline-flex;
      align-items: center;
      height: 22px;
      font-size: 14px;
      width: max-content;
      font-family: Inter;
      margin: 0;
      margin-inline-end: 0 !important;
    }
    .ant-switch-inner-checked {
      color: rgb(101 174 89);
    }
    .ant-switch-inner-unchecked {
      color: rgba(0, 0, 0, 0.88);
    }
  }

  &:not(.ant-switch-checked) .ant-switch-inner .ant-switch-inner-checked,
  &.ant-switch-checked .ant-switch-inner .ant-switch-inner-unchecked {
    opacity: 0;
    transition: all 0.5 ease-in-out;
  }
`;
