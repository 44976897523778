import notification from 'antd/es/notification';
import { APP_CODE, SERVER_API_URL } from 'AppConfig';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import Storage from 'helper/storage';
import { LanguageType } from 'languages/redux';
import { logout } from 'modules/auth/redux';
import { loginUrl, refershTokenUrl } from 'modules/auth/services';
import { store } from 'redux/store';

import { v4 as uuidv4 } from 'uuid';
enum LogType {
  REQUEST = 'req',
  RESPONSE = 'res',
  ERROR = 'err',
}
export const API_REQUEST_TIMEOUT = 20000; // 20s
export const RESPONSE_CODE = {
  TOKEN_EXPIRED: 401,
};

const log = (...params: any) => {
  if (process.env.NODE_ENV === `development`) {
    console.warn(...params);
  }
};

const requestLog = (method: string = '', url: string = '', data: any, type: LogType, baseURL: string) => {
  const tag = type === LogType.REQUEST || type === LogType.RESPONSE ? method : LogType.ERROR;
  const colors = {
    [LogType.REQUEST]: 'blue',
    [LogType.RESPONSE]: 'green',
    [LogType.ERROR]: 'red',
  };
  const icons = {
    [LogType.REQUEST]: '>>>',
    [LogType.RESPONSE]: '<<<',
    [LogType.ERROR]: 'xxx',
  };

  log(
    `%c${icons[type]} [${tag.toUpperCase()}] | %c${url.replace(baseURL, '')} \n`,
    `color: ${colors[type]}; font-weight: bold`,
    'color: violet; font-weight: bold',
    data
  );
};

const headers = {
  'Content-Type': 'application/json',
  'App-Code': APP_CODE,
};

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers,
      timeout: API_REQUEST_TIMEOUT,
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.request.use(this._handleRequest, this._handleRequestError);

    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  private _handleRequest = (req: AxiosRequestConfig) => {
    const typeLanguage = store.getState().language.type;

    const token = Storage.getAccessToken() || '';
    if (token && req.url !== loginUrl) {
      req.headers['Authorization'] = `Bearer ${token}`;
    }
    req.headers['x-client-request-id'] = uuidv4();
    req.headers['Accept-Language'] = typeLanguage === LanguageType.VI ? 'vi-VN' : 'en-US ';
    req.headers['Accept'] = '*';

    requestLog(req.method, req.url, req, LogType.REQUEST, req.baseURL || '');
    return req;
  };

  private _handleRequestError = (error: any) => {
    log('request.error', error?.response?.data);
    return Promise.reject(error);
  };

  private _handleResponse = (response: AxiosResponse) => {
    const {
      config: { method, url, baseURL },
    } = response;
    requestLog(method, url, response, LogType.RESPONSE, baseURL || '');
    return response;
  };

  protected _handleError = (error: any) => {
    console.error(error);
    const httpCode = error?.response?.status;
    const config = error?.response?.config;
    const errorData = {
      title: 'Error',
      detail: 'Error',
    };

    // Handle some special http errors
    if ([403, 404, 500, 501, 502, 503, 504].includes(httpCode) || httpCode === undefined) {
      return Promise.reject(error?.response?.data || errorData);
    }

    if (httpCode === RESPONSE_CODE.TOKEN_EXPIRED && config?.url !== loginUrl && config?.url !== refershTokenUrl) {
      const refreshToken = Storage.getRefreshToken();

      if (!refreshToken) {
        notification.destroy();
        return window.location.reload();
      }
      return this.instance
        .post(
          refershTokenUrl,
          {
            refreshToken: refreshToken,
            rememberMe: true,
          },
          { baseURL: SERVER_API_URL }
        )
        .then(token => {
          Storage.setAccessToken(token?.data?.accessToken);
          // Storage.setRefreshToken(token?.data?.refreshToken);

          return new Promise((resolve, reject) => {
            axios
              .request({
                ...config,
                headers: {
                  ...config?.headers,
                  Authorization: `Bearer ${token?.data?.accessToken}`,
                },
              })
              .then((response: any) => {
                resolve(response);
              })
              .catch((error: any) => {
                console.error(error);
                reject(error);
              });
          });
        })
        .catch(error => {
          store.dispatch(logout());
          const errorExpired = {
            title: 'Error',
            detail: 'Expired',
          };
          return Promise.reject(error?.response?.data || error || errorExpired);
        });
    }
    return Promise.reject(error?.response?.data || errorData);
  };

  public get = <T>(url: string, params = {}, config: AxiosRequestConfig = {}): AxiosPromise<T> =>
    this.instance.get<T>(url, { params, ...config });

  public post = <T>(url: string, data: any = {}, config: AxiosRequestConfig = {}) => this.instance.post<T>(url, data, { ...config });

  public put = <T>(url: string, data: any = {}, config: AxiosRequestConfig = {}) => this.instance.put<T>(url, data, { ...config });

  public patch = <T>(url: string, data: any = {}, config: AxiosRequestConfig = {}) => this.instance.patch<T>(url, data, { ...config });

  public delete = <T>(url: string, config: AxiosRequestConfig = {}) => this.instance.delete<T>(url, { ...config });
}

export default HttpClient;
