import {
  DetailContainer,
  BodyDetailSession,
  StyledBtnTab,
  RowButton,
  StyledBtnTabCustom,
  TitleTripStyled,
  NameTitleTripStyled,
  InforTitleTripStyled,
  ListStepStyled,
  DetailStepStyled,
  CardTripStyled,
  RowTrip,
  ListCarItemBox,
  EventNoData,
} from './style';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailSessionStart,
  getListFleetStart,
  setBtnTab,
  setIsViewDetail,
  getDetailTripStart,
  setShowVideoDetail,
  setPlayDetailUrl,
  setIndexActiveEvent,
  setIndexActiveTrip,
} from '../redux';
import { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import {
  btnTabSelector,
  eventsSelector,
  tripsSelector,
  loadingDetailSelector,
  indexActiveTripSelector,
  indexActiveEventSelector,
} from '../selector';
import { ArrowLeftOutlined, DownOutlined, ExclamationCircleFilled, RightOutlined, YoutubeOutlined } from '@ant-design/icons';
import { BtnTabType } from '../constant';
import { Badge, Col, Row, Spin, StepsProps } from 'antd';
import moment from 'moment';
import { CarItemBox } from './ListFleet/Body/style';
import EventTrip from './EventTrip';
import NoData from 'assets/imgs/NoData.svg';
import Vinfast from 'assets/imgs/Vinfast.png';
import { EventItem } from '../api/types';
interface DetailStepType {
  title: string;
  description: string;
  subTitle?: React.ReactNode;
}

function DetailSession() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const btnTab = useSelector(btnTabSelector);
  const trips = useSelector(tripsSelector);
  const events = useSelector(eventsSelector);
  const loadingDetail = useSelector(loadingDetailSelector);
  const indexActiveTrip = useSelector(indexActiveTripSelector);
  const indexActiveEvent: number = useSelector(indexActiveEventSelector);
  const [itemsDetailStep, setItemsDetailStep] = useState<DetailStepType[]>([]);

  useEffect(() => {
    return () => {
      dispatch(setBtnTab(BtnTabType.trip));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let stepMiddle: any[] = [];
    if (events.length) {
      stepMiddle = events?.map((item: EventItem, index: number) => ({
        title: intl.formatMessage({ id: `events.${item?.eventType}` }),
        description: item.gps?.time ? moment(item.gps.time).format('HH:mm') : '',
        subTitle: item.gps?.coordinate ? (
          <RightOutlined onClick={() => handleShowEvent(index)} />
        ) : (
          <YoutubeOutlined className="event-play-video" onClick={() => handlePlayVideo(item)} />
        ),
      }));
    }
    setItemsDetailStep([...stepMiddle]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips, events, intl, indexActiveEvent]);

  useEffectOnce(() => {
    dispatch(getListFleetStart());
  });

  const handleShowEvent = (indexActive: number) => {
    if (indexActive === indexActiveEvent) {
      dispatch(setIndexActiveEvent(-1));
    } else {
      dispatch(setIndexActiveEvent(indexActive));
    }
  };

  const handleViewDetail = (type: boolean) => {
    dispatch(setIsViewDetail(type));
  };

  const handleShowCollapseTrip = (trip: any, indexActive: any) => {
    if (indexActive === indexActiveTrip) {
      dispatch(setIndexActiveTrip(-1));
    } else {
      dispatch(setIndexActiveTrip(indexActive));
    }
    if (!trip.eventCount) return;
    dispatch(getDetailTripStart({ tripId: trip?.tripId }));
  };

  const changeBtnTab = (key: string) => {
    if (key !== btnTab) {
      dispatch(setBtnTab(key));
    } else return;
    if (key === BtnTabType.prev) {
      handleViewDetail(false);
    }
    if (key === BtnTabType.trip) {
      dispatch(
        getDetailSessionStart({
          fromTime: Number(moment().startOf('day').format('x')),
          toTime: Number(moment().format('x')),
        })
      );
    }
  };

  const customDot: StepsProps['progressDot'] = (dot, { status, index }) => <>{dot}</>;

  const handlePlayVideo = (value: any) => {
    dispatch(setShowVideoDetail(true));
    dispatch(setPlayDetailUrl(value.mp4Url));
  };

  return (
    <DetailContainer>
      <BodyDetailSession $tab={btnTab}>
        <RowButton>
          <StyledBtnTab
            key="event"
            sizeCustom={TypeSizeCustom.Medium}
            typeCustom={TypeCustom.Secondary}
            activeCustom={btnTab === 'event'}
            onClick={() => changeBtnTab('event')}
            title={intl.formatMessage({ id: 'liveMonitor.event' })}
          >
            {intl.formatMessage({ id: 'liveMonitor.event' })}
          </StyledBtnTab>
          <StyledBtnTab
            key="trip"
            sizeCustom={TypeSizeCustom.Medium}
            typeCustom={TypeCustom.Secondary}
            activeCustom={btnTab === 'trip'}
            onClick={() => changeBtnTab('trip')}
            title={intl.formatMessage({ id: 'liveMonitor.trip' })}
          >
            {intl.formatMessage({ id: 'liveMonitor.trip' })}
          </StyledBtnTab>
          <StyledBtnTabCustom
            key="prev"
            sizeCustom={TypeSizeCustom.Medium}
            typeCustom={TypeCustom.Secondary}
            activeCustom={btnTab === 'prev'}
            onClick={() => changeBtnTab('prev')}
            icon={<ArrowLeftOutlined />}
          ></StyledBtnTabCustom>
        </RowButton>
        <Spin spinning={loadingDetail}>
          {btnTab === BtnTabType.trip && (
            <>
              <ListCarItemBox>
                {trips.map((trip, index) => (
                  <CarItemBox key={index}>
                    <TitleTripStyled>
                      <NameTitleTripStyled>Trip #{Math.floor(Math.random() * 1000)}</NameTitleTripStyled>
                      <InforTitleTripStyled>
                        {moment.duration(trip?.time, 'milliseconds').minutes()} {intl.formatMessage({ id: 'liveMonitor.minutes' })} | {trip?.distance / 1000 ?? ''} Km
                      </InforTitleTripStyled>
                    </TitleTripStyled>
                    <RowTrip onClick={() => handleShowCollapseTrip(trip, index)}>
                      <ListStepStyled
                        current={1}
                        progressDot={customDot}
                        items={[
                          {
                            title: trip?.drivingTime ? moment(trip?.drivingTime).format('HH:mm') : '',
                          },
                          {
                            title: trip?.parkingTime
                              ? moment(trip?.parkingTime).format('HH:mm')
                              : intl.formatMessage({ id: 'liveMonitor.now' }),
                          },
                        ]}
                      />
                      {trip?.eventCount !== 0 && (
                        <Badge count={trip?.eventCount} className="trip-icon-info">
                          <ExclamationCircleFilled />
                        </Badge>
                      )}
                      {index === indexActiveTrip ? (
                        <DownOutlined className="trip-icon-down" onClick={() => handleShowCollapseTrip(trip, index)} />
                      ) : (
                        <RightOutlined className="trip-icon-down" onClick={() => handleShowCollapseTrip(trip, index)} />
                      )}
                    </RowTrip>
                    <CardTripStyled style={{ display: index === indexActiveTrip ? 'flex' : 'none' }}>
                      <img width={48} height={48} alt="trip" src={Vinfast} />
                      <Row>
                        <Col span={24}>Vinfast Lux A2.0</Col>
                        <Col span={24}>Sedan28 </Col>
                      </Row>
                    </CardTripStyled>
                    <DetailStepStyled
                      style={{ display: index === indexActiveTrip ? 'block' : 'none' }}
                      progressDot={customDot}
                      direction="vertical"
                      items={[
                        {
                          title: intl.formatMessage({ id: 'liveMonitor.droveCar' }, { value: trip?.studentName }),
                          description: trip?.drivingTime ? moment(trip?.drivingTime).format('HH:mm') : '',
                        },
                        ...itemsDetailStep,
                        {
                          title: trip?.parkingTime
                            ? intl.formatMessage({ id: 'liveMonitor.parkedCar' }, { value: trip?.studentName })
                            : intl.formatMessage({ id: 'liveMonitor.drivingCar' }, { value: trip?.studentName }),
                          description: trip?.parkingTime
                            ? moment(trip?.parkingTime).format('HH:mm')
                            : intl.formatMessage({ id: 'liveMonitor.now' }),
                        },
                      ]}
                    />
                  </CarItemBox>
                ))}
                <EventNoData $isNotDisplay={trips.length}>
                  <img src={NoData} alt="no data" />
                  <div>{intl.formatMessage({ id: 'common.noData' })}</div>
                </EventNoData>
              </ListCarItemBox>
            </>
          )}
          {btnTab === BtnTabType.event && <EventTrip />}
        </Spin>
      </BodyDetailSession>
    </DetailContainer>
  );
}

export default DetailSession;
