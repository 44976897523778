import HttpRequest from 'core/http/singleton/auth';
import { MenuItem } from '../redux/leftMenu';
import { PayloadChangPassType } from 'components/layouts/type';

export const clientChangePass = async (data: PayloadChangPassType): Promise<any> => {
  const res = await HttpRequest.post<any>('api/v1/users/change-password', data);
  return res.data;
};
export const clientChangePassRequest = async (data: PayloadChangPassType): Promise<any> => {
  const res = await HttpRequest.post<any>('api/v1/users/change-password/request', data);
  return res.data;
};
export const apiGetMenu = async (): Promise<MenuItem[]> => {
  const res = await HttpRequest.get<MenuItem[]>('api/menu');
  return res.data;
};


export const apiGetSubscribers = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/service/service-package-active');
  return res.data;
};

