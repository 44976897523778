import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DataPayloadCreateCourse,
  DataPayloadUpdateCourse,
  ParamsPayloadType,
  DataPayloadCourseLesson,
  ResListLesson,
  instructorsItem,
  studentsItem,
} from '../type';

export interface CourseManagerState {
  params: ParamsPayloadType;
  paramsLesson: DataPayloadCourseLesson;
  listCourse: any;
  loadingTable: boolean;
  loadingBtnModal: boolean;
  showModal: boolean;
  loadingModal: boolean;
  itemData: any;
  openFilter: boolean;
  typeModal: string;
  qsValue: string;
  instructors: instructorsItem[];
  licenses: ResListLesson;
  students: studentsItem[];
  lessons: ResListLesson;
  loadingLessons: boolean;
  instructorsWorking0: instructorsItem[];
}

const initialState: CourseManagerState = {
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  paramsLesson: {
    page: 0,
    size: 10,
    id: '',
  },
  listCourse: {},
  loadingTable: false,
  loadingBtnModal: false,
  showModal: false,
  loadingModal: false,
  itemData: {},
  openFilter: false,
  typeModal: '',
  qsValue: '',
  instructors: [],
  licenses: {} as any,
  students: [],
  lessons: {} as any,
  loadingLessons: false,
  instructorsWorking0: [],
};

const courseManagerSlice = createSlice({
  name: 'courseManagerSlice',
  initialState,
  reducers: {
    setOpenFilter: (state, action: PayloadAction<boolean>) => {
      state.openFilter = action.payload;
    },
    getListCourse: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.loadingTable = true;
    },
    getListCourseSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.listCourse = action.payload;
    },
    getListCourseFail: state => {
      state.loadingTable = false;
    },

    setParams: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.params = action.payload;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },

    createCourse: (state, action: PayloadAction<DataPayloadCreateCourse>) => {
      state.loadingBtnModal = true;
    },

    createCourseSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },

    createCourseFail: state => {
      state.loadingBtnModal = false;
    },

    getDetailCourse: (state, action: PayloadAction<string>) => {
      state.loadingBtnModal = true;
    },

    getDetailCourseSuccess: (state, action: PayloadAction<any>) => {
      state.loadingBtnModal = false;
      state.itemData = action.payload;
      state.showModal = true;
      state.instructorsWorking0 = action.payload.instructors?.filter((item: instructorsItem) => item.isWorking === 0);
    },

    setInstructorsWorking0: (state, action: PayloadAction<instructorsItem[]>) => {
      state.instructorsWorking0 = action.payload;
    },

    getDetailCourseFail: state => {
      state.loadingBtnModal = false;
    },

    setDataItem: (state, action: PayloadAction<any>) => {
      state.itemData = action.payload;
    },
    updateCourse: (state, action: PayloadAction<DataPayloadUpdateCourse>) => {
      state.loadingBtnModal = true;
    },
    updateCourseSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },
    updateCourseFail: state => {
      state.loadingBtnModal = false;
    },
    deleteCourse: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteCourseSuccess: state => {
      state.loadingTable = false;
    },
    deleteCourseFail: state => {
      state.loadingTable = false;
    },
    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
    getListCourseParam: state => {
      state.loadingTable = true;
    },
    getListCourseParamSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.instructors = action.payload.instructors;
      state.licenses = action.payload.licenses;
      state.students = action.payload.students;
    },
    getListCourseParamFail: state => {
      state.loadingTable = false;
    },
    getDetailLessons: state => {
      state.loadingLessons = true;
    },
    getDetailLessonsSuccess: (state, action: PayloadAction<ResListLesson>) => {
      state.loadingLessons = false;
      state.lessons = action.payload;
    },
    getDetailLessonsFail: state => {
      state.loadingLessons = false;
    },
    setParamsLessons: (state, action: PayloadAction<DataPayloadCourseLesson>) => {
      state.paramsLesson = action.payload;
    },
  },
});

export const {
  createCourse,
  createCourseFail,
  createCourseSuccess,
  getListCourse,
  getListCourseFail,
  getListCourseSuccess,
  setParams,
  setShowModal,
  getDetailCourse,
  getDetailCourseFail,
  getDetailCourseSuccess,
  setDataItem,
  updateCourse,
  updateCourseFail,
  updateCourseSuccess,
  deleteCourse,
  deleteCourseFail,
  deleteCourseSuccess,
  setOpenFilter,
  setTypeModal,
  setQsValue,
  getListCourseParam,
  getListCourseParamSuccess,
  getListCourseParamFail,
  getDetailLessons,
  getDetailLessonsSuccess,
  getDetailLessonsFail,
  setParamsLessons,
  setInstructorsWorking0,
} = courseManagerSlice.actions;

export default courseManagerSlice.reducer;
