import { Col, Form, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deviceManagerSelector } from '../selector';
import { setShowOtpSection } from '../redux';
import { LinkBtn } from 'components/commons/Link/styles';
import { FormInstance } from 'antd/lib/form/Form';
import CModal from 'components/commons/Modal';
import TimeCountDown from './TimeCountDown';

interface Iprops {
  formDevice: FormInstance;
}

const AuthenOtp = (props: Iprops) => {
  const { formDevice } = props;
  const dispatch = useDispatch();
  const { showOtpSection, otpDevice } = useSelector(deviceManagerSelector);
  const [form] = Form.useForm();

  const handleCancel = () => {
    dispatch(setShowOtpSection(false));
  };

  const onFinish = () => {};

  return (
    <CModal open={showOtpSection} closable maskClosable={false} onCancel={handleCancel} footer={null}>
      <Form form={form} labelAlign="left" layout="vertical" onFinish={onFinish}>
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <span style={{ fontSize: '1rem' }}>
              <FormattedMessage id="device.otpGuideText" />
            </span>
            :&nbsp;
            <span
              style={{
                fontSize: '1.2rem',
                color: 'navy',
                fontWeight: 'bold',
              }}
            >
              {otpDevice}
            </span>
          </Col>
        </Row>
        <Row gutter={[20, 10]} style={{ marginTop: '1rem' }}>
          <Col span={24}>
            <span>
              <FormattedMessage id="device.timeleft" />
            </span>
            :&nbsp;
            <TimeCountDown />
            &nbsp;&nbsp;&nbsp;
            <LinkBtn
              style={{
                color: 'navy',
                textDecoration: 'underline',
              }}
              onClick={formDevice.submit}
            >
              <FormattedMessage id="device.resendOtpBtn" />
            </LinkBtn>
          </Col>
        </Row>
      </Form>
    </CModal>
  );
};
export default AuthenOtp;
