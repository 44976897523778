import { Form, Spin } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingModalSelector, showModalSelector } from '../../selector';
import { setDataItem, setShowModal } from '../../redux';
import { StyledModal } from '../../page/styles';
import { useEffectOnce } from 'react-use';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { LaneColor, Row, StyledTitleModal, Wrapper } from './style';
import DetailInfo from './DetailInfo';
import DetailPlayVideo from './DetailPlayVideo';
import DetailSession from './DetailSession';
import Map from './GoogleMap';
import ModalPlayVideo from './PlayVideo';
import { useIntl } from 'react-intl';

const ModalDetail = () => {
  const showModal = useSelector(showModalSelector);
  const loadingModal = useSelector(loadingModalSelector);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };

  useEffectOnce(() => {
    return () => {
      dispatch(setDataItem(null));
      form.resetFields();
    };
  });

  return (
    <StyledModal
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
      width={'calc(100% - 332px)'}
    >
      <Spin spinning={loadingModal}>
        <StyledTitleModal>{intl.formatMessage({ id: 'common.viewDetail' })}</StyledTitleModal>
        <Row>
          <DetailInfo />
          <Wrapper>
            <DetailSession />
            <LaneColor />
            <Map />
            <ModalPlayVideo />
            <DetailPlayVideo />
          </Wrapper>
        </Row>
      </Spin>
    </StyledModal>
  );
};
export default ModalDetail;
