import HttpRequest from 'core/http/singleton/auth';
import { DataPayloadCreateUser, GetListGroupsParams, ParamsDetailUserType, ParamsPayloadType } from '../type';

export const createUser = async (data: DataPayloadCreateUser): Promise<any> => {
  const res = await HttpRequest.post<any>('api/v1/users/create', data);
  return res.data;
};

export const getListUserByParams = async (params: ParamsPayloadType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/users', params);
  return res.data;
};

export const getDetailUser = async (params: ParamsDetailUserType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/users/detail', params);
  return res.data;
};

export const updateUser = async (data: DataPayloadCreateUser): Promise<any> => {
  const res = await HttpRequest.put<any>('api/v1/users', data);
  return res.data;
};

export const deleteUser = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>('api/v1/users', {
    data: data,
  });
  return res.data;
};

export const getListUserGroupByPr = async (params: GetListGroupsParams): Promise<any> => {
  const res = await HttpRequest.get<any>('api/groups/get-suggest-by-group-code', params);
  return res.data;
};
