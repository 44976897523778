import { NotificationError, NotificationSuccess } from 'components/notifications';
import {
  deleteAddInstructor,
  getDetailInstructorService,
  getListInstructor,
  getDrivingLicense,
  postAddInstructor,
  putAddInstructor
} from 'modules/instructorManagement/api';
import {
  AllParamResponse,
  GetListInstructorRequest,
  GetListInstructorResponse,
  InstructorItem,
  LicenseType
} from 'modules/instructorManagement/api/types';
import { all, put, select, spawn, takeLatest } from 'redux-saga/effects';
import {
  getListUserFail,
  getListInstructorFull,
  getListInstructorSuccess,
  updateSearch,
  createInstructor,
  editInstructor,
  setTypeModal,
  deleteListInstructor,
  setFieldErrors,
  getDetailInstructor,
  getDetailInstructorSuccess,
  startGetListLicense,
  getListLicenseSuccess,
  setAllParam,
  createInstructorOk,
  createInstructorFail,
  editInstructorOk,
  editInstructorFail,
  setImageUrl,
} from 'modules/instructorManagement/redux/index';
import { paramsSelector } from '../selector';
import { getAllParam } from 'modules/auth/services';

function* getListUserByParams() {
  try {
    const params: GetListInstructorRequest = yield select(paramsSelector);
    const response: GetListInstructorResponse = yield getListInstructor(params);

    yield put(getListInstructorSuccess(response));
  } catch (err: any) {
    NotificationError(err);
    yield put(getListUserFail());
  }
}

function* getDetailInstructorById(action: ReturnType<typeof getDetailInstructor>) {
  try {
    const response: InstructorItem = yield getDetailInstructorService(action.payload);
    yield put(getDetailInstructorSuccess(response));
    if (response.faceImage) {
      yield put(setImageUrl(response.faceImageUrl));
    }
  } catch (err: any) {
    NotificationError(err);
    yield put(getListUserFail());
  }
}

function* getListLicense() {
  try {
    const response: LicenseType[] = yield getDrivingLicense();
    yield put(getListLicenseSuccess(response));
    yield spawn(getAllParamWorker);
  } catch (err: any) {
    NotificationError(err);
  }
}

function* getAllParamWorker(): any {
  try {
    const res: AllParamResponse = yield getAllParam();
    yield put(setAllParam(res));
  } catch (err: any) {
    NotificationError(err);
  }
}

function* createInstructorManagement(action: ReturnType<typeof createInstructor>) {
  try {
    yield postAddInstructor(action.payload);
    NotificationSuccess('common.addSuccess', { value: 'instructorManagement.title' });
    yield put(createInstructorOk());
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
  } catch (err: any) {
    yield put(createInstructorFail());
    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}
function* editInstructorManagement(action: ReturnType<typeof editInstructor>) {
  try {
    yield putAddInstructor(action.payload);
    NotificationSuccess('common.updateSuccess', { value: 'instructorManagement.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
    yield put(editInstructorOk());
  } catch (err: any) {
    yield put(editInstructorFail());

    if (err.fieldErrors) {
      yield put(setFieldErrors(err.fieldErrors));
      return;
    }
    NotificationError(err);
  }
}
function* deleteListInstructorManagement(action: ReturnType<typeof deleteListInstructor>) {
  try {
    yield deleteAddInstructor(action.payload);
    NotificationSuccess('common.deleteSuccess', { value: 'instructorManagement.title' });
    yield put(setTypeModal(null));
    yield spawn(getListUserByParams);
  } catch (err: any) {
    NotificationError(err);
  }
}

export default function* instructorManagementSaga() {
  yield all([
    takeLatest(getListInstructorFull, getListUserByParams),
    takeLatest(startGetListLicense, getListLicense),
    takeLatest(getDetailInstructor, getDetailInstructorById),
    takeLatest(updateSearch, getListUserByParams),
    takeLatest(createInstructor, createInstructorManagement),
    takeLatest(editInstructor, editInstructorManagement),
    takeLatest(deleteListInstructor, deleteListInstructorManagement)
  ]);
}
