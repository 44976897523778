import { ParamsType, ParamsExportType } from '../type';
import HttpRequest from 'core/http/singleton/auth';

export const getListReportByPr = async (params: ParamsType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/report/search', params);
  return res.data;
};

export const exportListReportByPr = async (params: ParamsExportType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/v1/report/export', params, { responseType: 'blob' });
  return res.data;
};

export const getListArea = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/areas/suggest-by-name');
  return res.data;
};
