import React from 'react';
import { Wrapper } from './styles';
import Grid from '../components/Grid';
import { FormattedMessage } from 'react-intl';
import { TitleHeader } from 'components/commons/Template/style';

const DevicePage = () => {
  return (
    <Wrapper id="wrapperDevicesManager">
      <TitleHeader id="filterDevicesManager">
        <FormattedMessage id="layout.leftMenu.device" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default DevicePage;
