import { Form } from 'antd';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledVehicleForm = styled(Form)`
  .ant-form-item .ant-form-item-label {
    > label::after {
      display: none;
    }
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      right: 0;
    }
  }
`;
