const paths = {
  login: '/login',
  signUp: '/sign-up',
  report: '/report',
  payment: '/payment',
  user_group_manager: '/user-group-manager',
  account_manager: '/account-manager',
  management_agency: '/management-agency',
  role: '/role',
  sso: '/sso',
  forgotPassword: '/forgot-password',
  profile: '/profile',
  course_manager: '/course',
  teacher_manager: '/instructor',
  liveMonitoring: '/live-monitoring',
  monitoringHistory: '/monitoring-history',
  student: '/student',
  trainingFacilities: '/training-facility',
  studentReport: '/student-report',
  vehicleReport: '/vehicle-report',
  practiceReport: '/practice-report',
  device: '/device',
  vehicle_manager: '/vehicle',
  dashboard: '/',
};

export default paths;
