import { Form, TabsProps } from 'antd';
import { ActionType } from 'helper/constants';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createStudent, deleteStudent, setDataDetail, setImgUrl, setShowModal, setTypeModal, updateStudent } from '../redux';
import { StyledModal } from '../page/styles';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { filePhotoSelector, studentManagerSelector } from '../selector';
import { StyledTitleModal } from 'modules/role/page/style';
import { RowButton } from 'components/commons/Template/style';
import CButton, { TypeCustom, TypeSizeCustom } from '../../../components/commons/Button';
import CTabs from '../../../components/commons/Tabs';
import PersonalInfo from './PersonalInfo';
import TrainingInfo from './TrainingInfo';
import ModalConfirm from 'components/commons/modalConfirm';
import { isEmpty } from '../../../helper/utils';

const AddEditViewStudent = () => {
  const { showModal, typeModal, dataDetail, loadingBtnModal } = useSelector(studentManagerSelector);
  const filePhoto = useSelector(filePhotoSelector);

  const dispatch = useDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();

  const renderTitle = () => {
    switch (typeModal) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleCancel = () => {
    dispatch(setImgUrl(''));
    dispatch(setDataDetail(null));
    dispatch(setShowModal(false));
  };

  const onFinish = async (values: any) => {
    const formData = new FormData();
    if (typeModal === ActionType.ADD) {
      formData.set('file', (filePhoto as File));
      const data = {
        ...values,
        birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : ''
      };
      formData.set('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));
      dispatch(createStudent(formData)
      );
      return;
    }
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          formData.set('file', (filePhoto as File));
          const data = {
            ...values,
            id: dataDetail?.id,
            birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : ''
          };
          formData.set('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));
          dispatch(updateStudent(formData));
        },
      });
    }
  };

  const onClearData = () => {
    dispatch(setImgUrl(''));
    dispatch(setDataDetail({}));
    dispatch(setShowModal(false));
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',
        handleConfirm: () => {
          onClearData();
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        gender: null,
        trainingType: null,
        licenseClass: null,
        courseIds: !values.courseIds.length ? null : values.courseIds,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setShowModal(false));
          },
        });
      } else {
        dispatch(setShowModal(false));
      }
      return;
    }
    onClearData();
  };
  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
  };

  const handleDeleteStudent = () => {
    if (dataDetail?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteStudent([dataDetail?.id]));
          handleCancel();
        },
      });
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'personal',
      label: intl.formatMessage({ id: 'student.tab1' }),
      children: <PersonalInfo form={form} />,
    },
    {
      key: 'training',
      label: intl.formatMessage({ id: 'student.tab2' }),
      children: <TrainingInfo />,
    },
  ];

  return (
    <StyledModal
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleCancel}
      width={1000}
      footer={null}
    >
      <Form form={form} layout={'vertical'} labelAlign="left" onFinish={onFinish}>
        <StyledTitleModal>{renderTitle()}</StyledTitleModal>
        {typeModal === ActionType.VIEW ? <CTabs defaultActiveKey="area" items={items} /> : <PersonalInfo form={form} />}
        <RowButton>
          {typeModal === ActionType.VIEW ? (
            <>
              {!dataDetail?.courseStarted && (
                <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteStudent}>
                  <FormattedMessage id="common.delete" />
                </CButton>
              )}
              <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} onClick={handleChangeEdit}>
                <FormattedMessage id="common.edit" />
              </CButton>
            </>
          ) : (
            <>
              <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </CButton>

              <CButton sizeCustom={TypeSizeCustom.Medium} loading={loadingBtnModal} typeCustom={TypeCustom.Primary} htmlType="submit">
                <FormattedMessage id="common.save" />
              </CButton>
            </>
          )}
        </RowButton>
      </Form>
    </StyledModal>
  );
};
export default AddEditViewStudent;
