import CModal from 'components/commons/Modal';
import { setShowVideoDetail } from 'modules/mapMonitor/redux';
import { playDetailUrlSelector, showVideoDetailSelector } from 'modules/mapMonitor/selector';
import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';

const DetailPlayVideo = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const showVideoDetail = useSelector(showVideoDetailSelector);
  const playDetailUrl = useSelector(playDetailUrlSelector);
  const handleCancel = () => {
    dispatch(setShowVideoDetail(false));
  };

  return (
    <CModal
      open={showVideoDetail}
      title={intl.formatMessage({ id: 'liveMonitor.playVideo' })}
      closable
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
    >
      {/* <Spin spinning={loadingVideo} tip="Loading" size="large"> */}
      <ReactPlayer width="100%" url={playDetailUrl} muted playing={true} controls />
      {/* </Spin> */}
    </CModal>
  );
};

export default DetailPlayVideo;
