import authRecuder from 'modules/auth/redux';
import roleReducer from 'modules/role/redux';
import { combineReducers } from 'redux';
import languageRecuder from 'languages/redux';
import userGroupManagerReducer from 'modules/userGroupManager/redux';
import layoutsReducer from 'components/layouts/redux';
import forgotPassReducer from 'modules/forgotPass/redux';
import accountManagerReducer from 'modules/accountManager/redux';
import themeReducer from '../styles';
import reportReducer from 'modules/report/redux';
import ssoReducer from 'modules/sso/redux';
import instructorManagementReducer from 'modules/instructorManagement/redux';
import trainingFacilities from 'modules/trainingFacilities/redux';
import courseReducer from 'modules/course/redux';
import studentManagerReducer from 'modules/studentManagement/redux';
import mapMonitorReducer from 'modules/mapMonitor/redux';
import managementReducer from 'modules/managementAgency/redux';
import deviceReducer from 'modules/device/redux';
import vehicleManagementReducer from 'modules/vehicleManagement/redux';
import studentReport from 'modules/studentReport/redux';
import vehicleReportReducer from '../modules/vehicleReport/redux';
import practiceReport from 'modules/practiceReport/redux';
import monitorHistory from 'modules/monitorHistory/redux';

export default combineReducers({
  language: languageRecuder,
  auth: authRecuder,
  role: roleReducer,
  management: managementReducer,
  userGroupManager: userGroupManagerReducer,
  layouts: layoutsReducer,
  forgotPass: forgotPassReducer,
  accountManager: accountManagerReducer,
  theme: themeReducer,
  report: reportReducer,
  sso: ssoReducer,
  instructorManagement: instructorManagementReducer,
  trainingFacilities: trainingFacilities,
  course: courseReducer,
  mapMonitor: mapMonitorReducer,
  studentManager: studentManagerReducer,
  device: deviceReducer,
  vehicleManagement: vehicleManagementReducer,
  studentReport: studentReport,
  vehicleReport: vehicleReportReducer,
  practiceReport: practiceReport,
  monitorHistory: monitorHistory,
});
