import { Form } from 'antd';
import React, { useEffect } from 'react';
import { ModalAntd } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { changePassSelect } from 'components/layouts/selector/changPassSelector';
import { changePass, setModalChangePass } from 'components/layouts/redux/changePass';
import { FormattedMessage, useIntl } from 'react-intl';
import { clientIdSelector, usernameSelector } from 'modules/auth/selectors';
import { useLocation } from 'react-router-dom';
import { TitleOtp } from 'modules/auth/pages/ModalLogin/styles';
import { RegValidPass } from 'helper/constants';
import { FieldErrorsType } from 'modules/role/type';
import { isEmpty } from 'helper/utils';
import ModalConfirm from 'components/commons/modalConfirm';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CInputPassword from 'components/commons/InputPass';
const ModalChangePassword = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loadingButton, showModalChangePass } = useSelector(changePassSelect);
  const clientId = useSelector(clientIdSelector);
  const username = useSelector(usernameSelector);
  const [form] = Form.useForm();
  const location = useLocation();
  const handleCancel = () => {
    dispatch(setModalChangePass(false));
  };
  const onFinish = (values: any) => {
    dispatch(
      changePass({
        data: {
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
          clientId: clientId,
          username: username,
          location: location,
        },
        setFieldError,
      })
    );
  };

  const handleClose = () => {
    const values = form.getFieldsValue();
    const isValuesEmpty = isEmpty(values);
    if (!isValuesEmpty) {
      ModalConfirm({
        title: 'common.confirmCancelAction',
        handleConfirm: () => {
          handleCancel();
        },
      });
    } else {
      handleCancel();
    }
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalChangePass]);

  const setFieldError = (fieldErrors: FieldErrorsType[]) => {
    form.setFields(
      fieldErrors.map((item: FieldErrorsType) => ({
        name: item.field,
        errors: [intl.formatMessage({ id: item.message })],
      }))
    );
  };

  const handleBlur = (e: any, field: string) => {
    form.validateFields([field]);
  };

  return showModalChangePass ? (
    <ModalAntd maskClosable={false} open={showModalChangePass} onCancel={handleCancel} footer={null}>
      <TitleOtp>
        <FormattedMessage id="auth.changePass" />
      </TitleOtp>
      <Form form={form} onFinish={onFinish} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
        <Form.Item
          label={<FormattedMessage id="auth.oldPass" />}
          name="oldPassword"
          labelAlign="left"
          rules={[
            { required: true, message: intl.formatMessage({ id: 'auth.oldPass' }) + intl.formatMessage({ id: 'validator.require' }) },
          ]}
        >
          <CInputPassword
            onBlur={(e: any) => {
              handleBlur(e, 'oldPassword');
            }}
            maxLength={30}
            placeholder={intl.formatMessage({ id: 'auth.placeholderChangePass' })}
          />
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id="auth.newPass" />}
          name="newPassword"
          labelAlign="left"
          rules={[
            { required: true, message: intl.formatMessage({ id: 'auth.newPass' }) + intl.formatMessage({ id: 'validator.require' }) },
            { min: 8, message: intl.formatMessage({ id: 'validator.passMin8' }) },
            {
              pattern: new RegExp(RegValidPass),
              message: intl.formatMessage({ id: 'validator.validPass' }),
            },
          ]}
        >
          <CInputPassword
            onBlur={(e: any) => {
              handleBlur(e, 'newPassword');
            }}
            maxLength={30}
            placeholder={intl.formatMessage({ id: 'auth.placeholderNewPass' })}
          />
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id="validator.requireConfirmNewPass" />}
          name="rePassword"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'validator.requireConfirmNewPass' }) + intl.formatMessage({ id: 'validator.require' }),
            },
            ({ getFieldValue }: any) => ({
              validator(rule: any, value: any) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(<FormattedMessage id="validator.confirmPassFail" />);
              },
            }),
          ]}
        >
          <CInputPassword
            onBlur={(e: any) => {
              handleBlur(e, 'rePassword');
            }}
            maxLength={30}
            placeholder={intl.formatMessage({ id: 'auth.placeholderConfirmNewPass' })}
          />
        </Form.Item>
        <div className="btnWrap">
          <CButton onClick={handleClose} sizeCustom={TypeSizeCustom.Medium}>
            <FormattedMessage id="common.cancel" />
          </CButton>
          <CButton htmlType="submit" loading={loadingButton} typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium}>
            <FormattedMessage id="common.save" />
          </CButton>
        </div>
      </Form>
    </ModalAntd>
  ) : (
    <></>
  );
};

export default ModalChangePassword;
