import { Col, Form, Row, Spin } from 'antd';
import { ActionType, RegValidEmail, UnitType } from 'helper/constants';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { itemDataSelector, trainingFacilitySelector } from '../selector';
import { createTC, setDataItem, setShowModal, setTypeModal, updateTC, deleteTC } from '../redux';
import { StyledModal } from '../page/styles';
import { useEffectOnce } from 'react-use';
import { isEmpty } from 'helper/utils';
import ModalConfirm from 'components/commons/modalConfirm';
import CInput from 'components/commons/Input';
import { StyledTitleModal, TagActive } from 'modules/role/page/style';
import { themes } from 'styles';
import { useRolesByRouter } from 'helper/cusHooks';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { includes } from 'lodash';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { RowButton } from 'components/commons/Template/style';
import CSwitch from 'components/commons/Switch';
import isNumeric from 'validator/lib/isNumeric';
import CSelect from 'components/commons/Select';
import { managementBaseSelector } from 'modules/auth/selectors';
import { CsdtType } from 'modules/auth/types';
import CTextArea from 'components/commons/TextArea';
interface Iprops {
  type: string;
}

const ModalTraining = (props: Iprops) => {
  const { loadingBtnModal, showModal, typeModal, loadingModal, listAgency } = useSelector(trainingFacilitySelector);

  const { type } = props;
  const dispatch = useDispatch();
  const itemData = useSelector(itemDataSelector);
  const managementBase = useSelector(managementBaseSelector);
  const intl = useIntl();
  const listRoles = useRolesByRouter();
  const [form] = Form.useForm();

  const renderTitle = () => {
    switch (type) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };

  const setFields = (field: string, message: string) => {
    form.setFields([
      {
        name: field,
        errors: [message],
      },
    ]);
  };

  const onFinish = (values: any) => {
    const data = {
      ...values,
      address: values?.address ? values?.address : undefined,
      foundedYear: values?.foundedYear ? values?.foundedYear : undefined,
      phoneNumber: values?.phoneNumber ? values?.phoneNumber : undefined,
      email: values?.email ? values?.email : undefined,
      status: values?.status ? 1 : 0,
    };
    if (type === ActionType.ADD) {
      dispatch(
        createTC({
          data: data,
          setFields: setFields,
        })
      );
      return;
    }
    if (type === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(
            updateTC({
              data: {
                ...data,
                clientId: itemData?.clientId,
              },
              setFields: setFields,
            })
          );
        },
      });

      return;
    }
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',

        handleConfirm: () => {
          dispatch(setShowModal(false));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        status: 0,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setShowModal(false));
          },
        });
      } else {
        dispatch(setShowModal(false));
      }
      return;
    }
    dispatch(setShowModal(false));
  };
  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
  };

  const handleDeleteRole = () => {
    if (itemData?.clientId) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteTC([itemData?.clientId || '']));
          handleCancel();
        },
      });
    }
  };

  const handlePates = (e: any, field: string) => {
    setTimeout(() => {
      const value = form.getFieldValue(field);
      form.setFieldValue(field, value?.trim());
      form.validateFields([field]);
    }, 0);
  };

  useEffectOnce(() => {
    return () => {
      dispatch(setDataItem(null));
      form.resetFields();
    };
  });

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  return (
    <StyledModal closable closeIcon={<CloseIcon />} open={showModal} maskClosable={false} onCancel={handleCancel} footer={null}>
      <Spin spinning={loadingModal}>
        <Form form={form} labelAlign="left" onFinish={onFinish} layout="vertical">
          <StyledTitleModal>{renderTitle()}</StyledTitleModal>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                valuePropName="checked"
                initialValue={typeModal === ActionType.ADD ? 1 : itemData?.status}
                name="status"
                className="switchActive"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                label={typeModal !== ActionType.VIEW ? intl.formatMessage({ id: 'common.active' }) : ''}
              >
                {typeModal === ActionType.VIEW ? (
                  <>
                    <TagActive clr={itemData?.status ? themes.statusGreen : themes.menuDisableDark}>
                      <FormattedMessage id={itemData?.status ? 'common.active' : 'common.inactive'} />
                    </TagActive>
                  </>
                ) : (
                  <CSwitch disabled={typeModal !== ActionType.EDIT} />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'trainingFacility.agency' })}
                name="parentId"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'trainingFacility.agency' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
                initialValue={managementBase?.clientType === UnitType.SO ? managementBase.parentList?.[0]?.clientId : itemData?.parentId}
              >
                <CSelect
                  options={(managementBase?.clientType === UnitType.SO ? managementBase?.parentList : listAgency).map((item: CsdtType) => ({
                    label: item.clientName,
                    value: item.clientId,
                  }))}
                  filterOption={(input: any, option: any) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                  showSearch
                  allowClear
                  disabled={type === ActionType.VIEW || managementBase?.clientType !== UnitType.CUC}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({ id: 'trainingFacility.nameFacility' })}
                name="clientName"
                rules={[
                  {
                    required: true,
                    message:
                      intl.formatMessage({ id: 'trainingFacility.nameFacility' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
                initialValue={itemData?.clientName}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'clientName');
                  }}
                  disabled={type === ActionType.VIEW}
                  maxLength={255}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={intl.formatMessage({ id: 'common.address' })} name="address" initialValue={itemData?.address}>
                <CTextArea
                  onPaste={(e: any) => {
                    handlePates(e, 'address');
                  }}
                  maxLength={255}
                  disabled={type === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage({ id: 'trainingFacility.person' })}
                name="representative"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'trainingFacility.person' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
                initialValue={itemData?.representative}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'representative');
                  }}
                  disabled={type === ActionType.VIEW}
                  maxLength={255}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage({ id: 'trainingFacility.foundYear' })}
                name="foundedYear"
                initialValue={itemData?.foundedYear?.toString()}
                rules={[
                  {
                    validator: (_, value) =>
                      !value || isNumeric(value)
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'trainingFacility.foundYear' }) +
                            ' ' +
                            intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        ),
                  },
                ]}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'foundedYear');
                  }}
                  disabled={type === ActionType.VIEW}
                  maxLength={4}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage({ id: 'profile.email' })}
                name="email"
                rules={[
                  {
                    validator: (_, value) =>
                      !value || RegValidEmail.test(value)
                        ? Promise.resolve()
                        : Promise.reject(new Error(intl.formatMessage({ id: 'validator.requireEmail' }))),
                  },
                ]}
                initialValue={itemData?.email}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'email');
                  }}
                  maxLength={255}
                  disabled={type === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    min: 10,
                    message: intl.formatMessage({ id: 'common.phoneNumber' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' }),
                  },
                  {
                    validator: (_, value) =>
                      !value || isNumeric(value) || value?.length < 10
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(
                            intl.formatMessage({ id: 'common.phoneNumber' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' })
                          )
                        ),
                  },
                ]}
                label={intl.formatMessage({ id: 'common.phoneNumber' })}
                name={'phoneNumber'}
                initialValue={itemData?.phoneNumber}
              >
                <CInput
                  maxLength={11}
                  onPaste={(e: any) => {
                    handlePates(e, 'phoneNumber');
                  }}
                  disabled={type === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={0}>
              <Form.Item
                label={'AccessKeyId'}
                name="accessKeyId"

                initialValue={itemData?.accessKeyId}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'accessKeyId');
                  }}
                  disabled={type === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={0}>
              <Form.Item
                label={'AccessKeySecret'}
                name="accessKeySecret"
                initialValue={itemData?.accessKeySecret}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'accessKeySecret');
                  }}
                  disabled={type === ActionType.VIEW}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage({ id: 'common.email' })}
                name="signInEmail"
                rules={[
                  {
                    validator: (_, value) =>
                      !value || RegValidEmail.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(intl.formatMessage({ id: 'common.email' }) + ' ' + intl.formatMessage({ id: 'validator.errFormat' }))
                        ),
                  },
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'common.email' }) + ' ' + intl.formatMessage({ id: 'validator.require' }),
                  },
                ]}
                initialValue={itemData?.signInEmail}
              >
                <CInput
                  onPaste={(e: any) => {
                    handlePates(e, 'signInEmail');
                  }}
                  maxLength={255}
                  disabled={type !== ActionType.ADD}
                />
              </Form.Item>
            </Col>
          </Row>
          <RowButton>
            {typeModal === ActionType.VIEW ? (
              <>
                {includes(listRoles, actionsType.DELETE) && (
                  <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteRole}>
                    <FormattedMessage id="common.delete" />
                  </CButton>
                )}
                {includes(listRoles, actionsType.UPDATE) && (
                  <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} onClick={handleChangeEdit}>
                    <FormattedMessage id="common.edit" />
                  </CButton>
                )}
              </>
            ) : (
              <>
                <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                  {intl.formatMessage({ id: 'common.cancel' })}
                </CButton>

                <CButton loading={loadingBtnModal} sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} htmlType="submit">
                  <FormattedMessage id="common.save" />
                </CButton>
              </>
            )}
          </RowButton>
        </Form>
      </Spin>
    </StyledModal>
  );
};
export default ModalTraining;
