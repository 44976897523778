import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fieldErrorsItem } from 'modules/auth/redux/type';
import { DataFormConfirmPass, PayloadConfirmPass, PayloadSendOTP, PayloadVerifyToken } from '../type';
export interface ForgotPassState {
  loadingButton: boolean;
  listClient: any[];
  showModalOTP: boolean;
  dataForm: DataFormConfirmPass;
  showModalInputEmail: boolean;
  showModalResetPass: boolean;
  fieldErrors: fieldErrorsItem[];
}

const initialState: ForgotPassState = {
  loadingButton: false,
  listClient: [],
  showModalOTP: false,
  dataForm: {
    username: '',
    password: '',
    clientCode: '',
  },
  showModalInputEmail: false,
  showModalResetPass: false,
  fieldErrors: [],
};

const forgotPassSlice = createSlice({
  name: 'forgotPassSlice',
  initialState,
  reducers: {
    getEmailConfirm: (
      state,
      action: PayloadAction<{
        data: string;
        form: any;
      }>
    ) => {
      state.loadingButton = true;
    },

    getEmailConfirmSuccess: state => {
      state.loadingButton = false;
      state.showModalInputEmail = false;
    },

    getEmailConfirmFail: state => {
      state.loadingButton = false;
    },

    verifyToken: (state, action: PayloadAction<PayloadVerifyToken>) => {
      state.loadingButton = true;
    },

    verifyTokenSuccess: (state, action: PayloadAction<any[]>) => {
      state.loadingButton = false;
      state.listClient = action.payload;
      state.showModalResetPass = true;
    },

    verifyTokenFail: state => {
      state.loadingButton = false;
    },

    setShowModalOTP: (state, action: PayloadAction<boolean>) => {
      state.showModalOTP = action.payload;
    },

    getOtpChangePass: (state, action: PayloadAction<PayloadSendOTP>) => {
      state.loadingButton = true;
      state.fieldErrors = initialState.fieldErrors;
    },

    getOtpChangePassSuccess: (state, action: PayloadAction<DataFormConfirmPass>) => {
      state.loadingButton = false;
      state.showModalOTP = true;
      state.dataForm = action.payload;
    },

    getOtpChangePassFail: state => {
      state.loadingButton = false;
    },
    confirmPasswordOTP: (state, action: PayloadAction<PayloadConfirmPass>) => {
      state.fieldErrors = initialState.fieldErrors;
    },

    confirmPasswordOTPSuccess: state => {
      state.showModalOTP = false;
      state.showModalResetPass = false;
    },

    confirmPasswordOTPFail: state => {},

    setShowModalInputEmail: (state, action: PayloadAction<boolean>) => {
      state.showModalInputEmail = action.payload;
    },
    setshowModalResetPass: (state, action: PayloadAction<boolean>) => {
      state.showModalResetPass = action.payload;
    },
    setFieldErrors: (state, action: PayloadAction<fieldErrorsItem[]>) => {
      state.fieldErrors = action.payload;
    },
  },
});

export const {
  getEmailConfirm,
  getEmailConfirmFail,
  getEmailConfirmSuccess,
  verifyToken,
  verifyTokenFail,
  verifyTokenSuccess,
  getOtpChangePass,
  getOtpChangePassFail,
  getOtpChangePassSuccess,
  setShowModalOTP,
  confirmPasswordOTP,
  confirmPasswordOTPFail,
  confirmPasswordOTPSuccess,
  setShowModalInputEmail,
  setshowModalResetPass,
  setFieldErrors,
} = forgotPassSlice.actions;

export default forgotPassSlice.reducer;
