import { Language } from 'languages/redux';
import language from 'languages/translate';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { createIntl, createIntlCache } from 'react-intl';
import { store } from 'redux/store';

export const flattenObject = (inputObj: { [key: string]: any }) => {
  const outputObj: { [key: string]: string } = {};

  for (const i in inputObj) {
    if (!inputObj.hasOwnProperty(i)) continue;

    if (typeof inputObj[i] === 'object') {
      const flatObject = flattenObject(inputObj[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        outputObj[i + '.' + x] = flatObject[x];
      }
    } else {
      outputObj[i] = inputObj[i];
    }
  }
  return outputObj;
};

//input string "hh:mm:ss" -> date
export const getCurrentDateWithTime = (time: string): Date => {
  const now = new Date();
  const [hours, minutes, seconds] = time.split(':').map(val => parseInt(val, 10));

  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);
};

export function isEmpty(object: any) {
  for (const property in object) {
    if (object[property]) {
      return false;
    }
  }
  return true;
}

export const isEmptyObject = (obj: any) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== '' && obj[key]?.length !== 0 && obj[key]) return false;
  }
  return true;
};

export function valueToText(value: any, options: DefaultOptionType[]) {
  return options.find(e => e.value === value)?.label;
}

export function findParent(items: any, id: any) {
  for (let item of items) {
    let res: any = item.id === id ? items : item.children && findParent(item.children, id);
    if (res) return res;
  }
}

export function removeNull(array: any) {
  return array.map((item: any) =>
    item.children.length > 0 ? { ...item, children: removeNull(item.children) } : { ...item, children: undefined }
  );
}

export const weekdays = (key: string) => {
  const { type } = store.getState().language;
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: type,
      messages: language[Language[type]],
    },
    cache
  );
  switch (key) {
    case 'MON':
      return intl?.formatMessage({ id: 'accessTime.t2' });
    case 'TUE':
      return intl?.formatMessage({ id: 'accessTime.t3' });
    case 'WED':
      return intl?.formatMessage({ id: 'accessTime.t4' });
    case 'THU':
      return intl?.formatMessage({ id: 'accessTime.t5' });
    case 'FIR':
      return intl?.formatMessage({ id: 'accessTime.t6' });
    case 'SAT':
      return intl?.formatMessage({ id: 'accessTime.t7' });
    case 'SUN':
      return intl?.formatMessage({ id: 'accessTime.cn' });
    default:
      break;
  }
};

export const fileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const removeVietNamese = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
};

export const chunkArray = (myArray: any, chunkSize: any) => {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];
  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
};
