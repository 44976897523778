import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AreaItemType, ParamsExportType, ParamsType, ReportItemType } from '../type';
import moment from 'moment';

export interface ReportState {
  loading: boolean;
  data: ReportItemType[];
  showFilter: boolean;
  params: ParamsType;
  listArea: AreaItemType[];
  loadingSelect: boolean;
  loadingBtnExport: boolean;
  total: number;
}

const initialState: ReportState = {
  loading: false,
  data: [],
  showFilter: false,
  params: {
    page: 0,
    size: 10,
    endDate: new Date(moment().utc().endOf('day').format()).toJSON(),
    startDate: new Date(moment().utc().startOf('day').format()).toJSON(),
  },
  listArea: [],
  loadingSelect: false,
  loadingBtnExport: false,
  total: 0,
};

const reportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    getListReportItem: (state, action: PayloadAction<ParamsType>) => {
      state.loading = true;
    },
    getListReportItemSuccess: (state, action: PayloadAction<ReportItemType[]>) => {
      state.loading = false;
      state.data = action.payload;
    },
    getListReportItemFail: state => {
      state.loading = false;
    },
    setParams: (state, action: PayloadAction<ParamsType>) => {
      state.params = action.payload;
    },
    setShowFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilter = action.payload;
    },

    getListAreaAction: state => {
      state.loadingSelect = true;
    },
    getListAreaActionSuccess: (state, action: PayloadAction<AreaItemType[]>) => {
      state.loadingSelect = false;
      state.listArea = action.payload;
    },
    getListAreaActionFail: state => {
      state.loadingSelect = false;
    },

    exportReport: (state, action: PayloadAction<ParamsExportType>) => {
      state.loadingBtnExport = true;
    },

    exportReportSuccess: state => {
      state.loadingBtnExport = false;
    },

    exportReportFail: state => {
      state.loadingBtnExport = false;
    },

    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
  },
});

export const {
  getListReportItem,
  getListReportItemSuccess,
  getListReportItemFail,
  setParams,
  setShowFilter,
  getListAreaAction,
  getListAreaActionFail,
  getListAreaActionSuccess,
  exportReport,
  exportReportFail,
  exportReportSuccess,
  setTotal,
} = reportSlice.actions;

export default reportSlice.reducer;
