import { InfoWindow } from '@react-google-maps/api';
import { setPlayDetailUrl, setShowVideoDetail } from 'modules/mapMonitor/redux';
import { useDispatch } from 'react-redux';
import { EventItem } from 'modules/mapMonitor/api/types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { videoIcon } from '../../../constant';
import { Body, Time, TimeDuration, Title, Wrapper } from '../style';

interface PropsType {
  event: EventItem;
}

const ModalEvent = (props: PropsType) => {
  const { event } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const handlePlayVideo = (value: any) => {
    dispatch(setShowVideoDetail(true));
    dispatch(setPlayDetailUrl(value.mp4Url));
  };

  return (
    <InfoWindow
      position={{
        lat: event.gps?.coordinate[1],
        lng: event.gps?.coordinate[0],
      }}
      options={{
        pixelOffset: new window.google.maps.Size(0, -36),
      }}
    >
      <Wrapper>
        <Body>
          <Title>{intl.formatMessage({ id: `events.${event?.eventType}` })}</Title>
          <Time>{moment(event.gps?.time).format('DD/MM/YYYY HH:mm:ss') ?? 0}</Time>
          <TimeDuration>
            {intl.formatMessage({ id: 'liveMonitor.timeDuration' })}
            <span>:</span> &nbsp;
            {event.duration / 1000}s
          </TimeDuration>
        </Body>
        <div onClick={() => handlePlayVideo(event)}>
          <img style={{ cursor: 'pointer' }} src={videoIcon} alt="video" />
        </div>
      </Wrapper>
    </InfoWindow>
  );
};

export default ModalEvent;
