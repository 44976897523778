import { createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { APP_CODE } from "AppConfig";

const Transform = createTransform(
  (inboundState: any, _) => {
    return inboundState;
  },
  (outBoundState: any, _) => {
    return outBoundState;
  }
);

export const persistConfig = {
  timeout: process.env.NODE_ENV === 'development' ? 0 : 3000,
  key: `${APP_CODE}:root`,
  storage,
  whitelist: [],
  transforms: [Transform],
};
