import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetListInstructorRequest,
  ContentItem,
  roleItem,
  GetListInstructorResponse,
  InstructorItem,
  ItemEdit,
  LicenseType,
  AllParamResponse,
  ResFile,
} from 'modules/instructorManagement/api/types';
import { ActionType } from 'helper/constants';
import { fieldErrorsItem } from 'modules/auth/redux/type';

export interface InstructorManagementState {
  params: GetListInstructorRequest;
  detailInstructor: any;
  loadingTable: boolean;
  data: ContentItem[];
  totalElements: number;
  listRole: roleItem[];
  listRoleStatus0: roleItem[];
  typeModal: ActionType | null;
  itemEdit: ItemEdit;
  listLicense: LicenseType[];
  listInstructorStatus0: InstructorItem[];
  selectedRowKeys: React.Key[];
  fieldErrors: fieldErrorsItem[];
  valueSearch: string;
  imageUrl: string;
  valueDetailId: string;
  loadingBtnModal: boolean;
  allParam: AllParamResponse;
  loadingModal: boolean;
  loadingFile: boolean;
  dataPhoto: ResFile;
  filePhoto: File | null;
}

const initialState: InstructorManagementState = {
  params: {
    page: 0,
    size: 10,
    q: '',
    status: 2,
  },
  detailInstructor: {},
  loadingTable: false,
  data: [],
  totalElements: 0,
  listRole: [],
  listRoleStatus0: [],
  typeModal: null,
  itemEdit: {
    isWorking: 0,
    id: null,
    fullName: '',
    citizenIdCardNo: '',
    courseStarted: false,
    gender: 1,
    birthday: '',
    phoneNo: '',
    address: '',
    email: '',
    driverLicenseNo: '',
    driverLicenseCategory: '',
    certificationNo: '',
    faceImage: '',
    faceImageUrl: '',
    user: [],
    isAccuracy: 0,
  },
  listLicense: [],
  listInstructorStatus0: [],
  selectedRowKeys: [],
  fieldErrors: [],
  valueSearch: '',
  imageUrl: '',
  valueDetailId: '',
  loadingBtnModal: false,
  allParam: {
    PERSON_SEX: [],
  },
  loadingModal: false,
  loadingFile: false,
  dataPhoto: {
    fileName: '',
    directory: '',
  },
  filePhoto: null,
};

const instructorManagementSlice = createSlice({
  name: 'instructorManagementSlice',
  initialState,
  reducers: {
    resetSearch: state => {
      state.params = initialState.params;
      state.selectedRowKeys = initialState.selectedRowKeys;
      state.valueSearch = initialState.valueSearch;
    },
    updateSearch: (state, action: PayloadAction<object>) => {
      state.params = { ...state.params, ...action.payload } || {};
      state.loadingTable = true;
    },
    getDetailInstructor: (state, action: PayloadAction<string>) => {
      state.loadingModal = true;
    },

    getDetailInstructorSuccess: (state, action: PayloadAction<any>) => {
      state.itemEdit = action.payload;
      state.loadingModal = false;
    },

    getDetailInstructorFail: state => {
      state.loadingModal = false;
    },

    getListInstructorSuccess: (state, action: PayloadAction<GetListInstructorResponse>) => {
      state.data = action.payload.content;
      state.loadingTable = initialState.loadingTable;
      state.totalElements = action.payload.totalElements;
    },

    getListUserFail: state => {
      state.data = initialState.data;
      state.loadingTable = initialState.loadingTable;
    },
    getListRoleSuccess: (state, action: PayloadAction<roleItem[]>) => {
      state.listRole = action.payload;
    },

    getListInstructorFull: state => {
      state.loadingTable = true;
    },
    setTypeModal: (state, action: PayloadAction<ActionType | null>) => {
      if (action.payload === null) {
        state.itemEdit = initialState.itemEdit;
        state.imageUrl = '';
      }
      state.typeModal = action.payload;
      state.fieldErrors = initialState.fieldErrors;
    },
    updateItemEdit: (state, action: PayloadAction<object>) => {
      state.itemEdit = { ...state.itemEdit, ...action.payload } || {};
    },
    startGetListLicense: () => {},
    getListLicenseSuccess: (state, action: PayloadAction<LicenseType[]>) => {
      state.listLicense = action.payload;
    },
    pushListInstructorNameStatus0: (state, action: PayloadAction<InstructorItem[]>) => {
      state.listInstructorStatus0 = action.payload;
    },
    createInstructor: (state, action: PayloadAction<FormData>) => {
      state.loadingBtnModal = true;
    },
    createInstructorOk: state => {
      state.loadingBtnModal = false;
    },
    createInstructorFail: state => {
      state.loadingBtnModal = false;
    },
    editInstructor: (state, action: PayloadAction<FormData>) => {
      state.loadingBtnModal = true;
    },
    editInstructorOk: state => {
      state.loadingBtnModal = false;
    },
    editInstructorFail: state => {
      state.loadingBtnModal = false;
    },
    deleteListInstructor: (state, action: PayloadAction<string[]>) => {},
    setSelectedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
      state.selectedRowKeys = action.payload;
    },
    setFieldErrors: (state, action: PayloadAction<fieldErrorsItem[]>) => {
      state.fieldErrors = action.payload;
    },
    setValueSearch: (state, action: PayloadAction<string>) => {
      state.valueSearch = action.payload;
    },
    setImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setAllParam: (state, action: PayloadAction<AllParamResponse>) => {
      state.allParam = action.payload;
    },

    // upload file
    uploadFile: (state, action: PayloadAction<any>) => {
      state.loadingFile = true;
    },
    uploadFileSuccess: (state, action: PayloadAction<ResFile>) => {
      state.dataPhoto = action.payload;
      state.loadingFile = false;
    },
    uploadFileFail: state => {
      state.loadingFile = false;
    },

    setFilePhoto: (state, action: PayloadAction<File>) => {
      state.filePhoto = action.payload;
    },
  },
});

export const {
  resetSearch,
  updateSearch,
  getListInstructorSuccess,
  getListUserFail,
  getListInstructorFull,
  setTypeModal,
  startGetListLicense,
  getListLicenseSuccess,
  createInstructor,
  editInstructor,
  deleteListInstructor,
  setSelectedRowKeys,
  setFieldErrors,
  setValueSearch,
  setImageUrl,
  getDetailInstructor,
  getDetailInstructorSuccess,
  setAllParam,
  createInstructorFail,
  createInstructorOk,
  editInstructorFail,
  editInstructorOk,
  setFilePhoto,
} = instructorManagementSlice.actions;

export default instructorManagementSlice.reducer;
