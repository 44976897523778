import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { InfoStyled, TitleDetail, TitleInfo } from './style';
import { fleetViewItemSelector } from 'modules/mapMonitor/selector';
import { Col, Row } from 'antd';
import numeral from 'numeral';

const DetailInfo = () => {
  const intl = useIntl();
  const fleetViewItem = useSelector(fleetViewItemSelector);

  return (
    <InfoStyled>
      <TitleDetail>
        {intl.formatMessage({ id: 'liveMonitor.titleMap' }, { car: fleetViewItem?.licensePlate, code: fleetViewItem?.courseCode })}
      </TitleDetail>
      <Row gutter={[5, 5]}>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'mapMonitor.studentName' })}</TitleInfo>
          <span>{`${fleetViewItem.studentName ?? ''}_${fleetViewItem.studentCitizenId ?? ''}`}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'liveMonitor.countTime' })}</TitleInfo>
          <span>{moment.utc(fleetViewItem.accumulatedTime).format('H[h] mm[m]')}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'liveMonitor.countDistance' })}</TitleInfo>
          <span>{numeral(fleetViewItem.accumulatedDistance / 1000).format('0.00')} Km</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'mapMonitor.teacher' })}</TitleInfo>
          <span>{`${fleetViewItem.instructorName ?? ''}_${fleetViewItem.instructorCitizenId ?? ''}`}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'course.sessionTraining' }) + ': '}</TitleInfo>
          <span>{fleetViewItem.code}</span>
        </Col>
        <Col span={8}>
          <TitleInfo>{intl.formatMessage({ id: 'mapMonitor.startTime' })}</TitleInfo>
          <span>{moment(fleetViewItem.startTime).format('DD/MM/YYYY H:mm')}</span>
        </Col>
      </Row>
    </InfoStyled>
  );
};

export default DetailInfo;
