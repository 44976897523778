import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMutiRole, deleteRole, getDetailRole, getListRole, setOpenModalAddEdit, setParams, setQsValue, setTypeModal } from '../redux';
import { roleSelector } from '../selector';
import { WrapperActionTable } from '../page/style';
import AddEditRole from './AddEditRole';
import ModalConfirm from 'components/commons/modalConfirm';
import { useRolesByRouter } from 'helper/cusHooks';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import CTable from 'components/commons/Table';
import { ReactComponent as IconMore } from 'assets/imgs/IconMore.svg';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import CustomSearch from 'components/commons/Search';
import { RowHeader, Text, WrapperButton } from 'components/commons/Template/style';
import moment from 'moment';
import { useEffectOnce } from 'react-use';
import { CReloadButton } from 'components/commons/ReloadButton';

interface DataType {
  key: React.Key;
  name: string;
  code: string;
  status: string;
}
const Grid = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { showModalAddEdit } = useSelector(roleSelector);
  const intl = useIntl();

  const listRoles = useRolesByRouter();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const dispatch = useDispatch();
  const { listRole, loadingTable, params, openFilter, qsValue } = useSelector(roleSelector);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderMenuItemsMore = (record: DataType): MenuProps['items'] => {
    return [
      {
        key: actionsType.UPDATE,
        label: (
          <Text onClick={() => handleEditRole(record)}>
            <FormattedMessage id={'common.edit'} />
          </Text>
        ),
      },
      {
        key: actionsType.DELETE,
        label: (
          <Text type="danger" onClick={() => handleDeleteRole(record)}>
            <FormattedMessage id={'common.delete'} />
          </Text>
        ),
      },
    ].filter((item: any) => includes(listRoles, item?.key));
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text disabled={record?.status ? false : true}>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'role.roleCode' }),
      dataIndex: 'roleCode',
      align: 'left',
      width: 200,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'role.roleName' }),
      dataIndex: 'roleName',
      align: 'left',
      width: 200,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.creator' }),
      dataIndex: 'createdBy',
      align: 'left',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.creationDate' }),
      dataIndex: 'createdDate',
      align: 'left',
      width: 120,
      render(value, record, index) {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.updater' }),
      dataIndex: 'lastModifiedBy',
      align: 'left',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.updatedDate' }),
      dataIndex: 'lastModifiedDate',
      align: 'left',
      width: 130,
      render(value, record, index) {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text disabled={record?.status ? false : true}>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'role.statusName' }),
      dataIndex: 'status',
      align: 'left',
      width: 150,
      render: value => {
        return (
          <Text type={value === 1 ? 'success' : undefined} disabled={value !== 1}>
            <FormattedMessage id={value ? 'common.active' : 'common.inactive'} />
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      width: 150,
      fixed: 'right',
      render(value, record, index) {
        return (
          <WrapperActionTable>
            {includes(listRoles, actionsType.READ) && (
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewRole(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
            )}
            <Dropdown menu={{ items: renderMenuItemsMore(record) }} placement="bottomCenter" trigger={['click']}>
              <IconMore className="iconMore" />
            </Dropdown>
          </WrapperActionTable>
        );
      },
    },
  ];

  const setValueQS = (value: string) => {
    dispatch(setQsValue(value));
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  const handleSetTypeModal = (value: string) => {
    dispatch(setTypeModal(value));
  };

  const handleAddRole = () => {
    handleSetTypeModal('ADD');
    dispatch(setOpenModalAddEdit(true));
  };

  const handleViewRole = (itemEdit: any) => {
    handleSetTypeModal('VIEW');
    dispatch(getDetailRole(itemEdit?.roleId));
  };

  const handleEditRole = (itemEdit: any) => {
    handleSetTypeModal('EDIT');
    dispatch(getDetailRole(itemEdit?.roleId));
  };

  const handleDeleteRole = (itemEdit: any) => {
    if (itemEdit?.roleId) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteRole(itemEdit?.roleId));
        },
      });
    }
  };

  const handleDeleteMutiRoles = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteMutiRole(selectedRowKeys));
        setSelectedRowKeys([]);
      },
    });
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterRolesManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperRolesManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163;
  };

  const handleQuickSearch = (value: string) => {
    dispatch(
      setParams({
        ...params,
        q: value,
        page: 0,
      })
    );
  };

  const handleRefresh = () => {
    dispatch(
      setParams({
        page: 0,
        size: 10,
        q: '',
      })
    );
    setValueQS('');
  };

  useEffect(() => {
    setTimeout(changeHeightTable, 500);
  }, [openFilter]);

  useEffect(() => {
    dispatch(getListRole(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, showModalAddEdit]);

  useEffectOnce(() => {
    return () => {
      dispatch(setParams({ page: 0, size: 10, q: '' }));
      setValueQS('');
    };
  });

  return (
    <div>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'role.tooltipQS' })}
            onSearch={handleQuickSearch}
            value={qsValue}
            setValue={setValueQS}
          />
          <CReloadButton onClick={handleRefresh} />
        </WrapperButton>
        <WrapperButton>
          {includes(listRoles, actionsType.DELETE) && (
            <CButton
              typeCustom={TypeCustom.Secondary}
              sizeCustom={TypeSizeCustom.Medium}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMutiRoles}
            >
              <FormattedMessage id="common.delete" />
            </CButton>
          )}
          {includes(listRoles, actionsType.CREATE) && (
            <CButton typeCustom={TypeCustom.Primary} sizeCustom={TypeSizeCustom.Medium} icon={<PlusOutlined />} onClick={handleAddRole}>
              <FormattedMessage id="common.add" />
            </CButton>
          )}
        </WrapperButton>
      </RowHeader>
      <Row>
        <CTable
          rowSelection={rowSelection}
          loading={loadingTable}
          columns={columns}
          scroll={{ y: changeHeightTable() }}
          rowKey={'roleId'}
          dataSource={listRole?.content}
          style={{ width: '100%' }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            current: params.page + 1,
            pageSize: params.size,
            total: listRole?.totalElements,
            size: 'small',
            showTotal: TotalMessage,
            showQuickJumper: true,
            locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
          }}
          onChange={handleChangeTable}
        />
      </Row>
      {showModalAddEdit && <AddEditRole />}
    </div>
  );
};

export const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};
export default Grid;
