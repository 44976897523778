import styled from 'styled-components/macro';

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const FooteBody = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CarItemBox = styled.div`
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  padding: 12px;
  :hover {
    border: 1px solid #003d7a;
    cursor: pointer;
  }
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const ListCardBox = styled.div`
  overflow: auto;
  height: calc(100% - 150px);
  .ant-spin-container {
    gap: 12px;
    display: flex;
    flex-direction: column;
    min-height: 50px;
  }
`;

export const LicensePlate = styled.div`
  display: flex;
  align-items: center;
`;

export const CarDriving = styled.div<{
  driving: boolean;
}>`
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  background-color: ${({ driving }) => (driving ? '#eaf6fc' : '#e6f9ef')};
  color: ${({ driving }) => (driving ? '#59b4d1' : '#7dc066')};
`;

export const ListFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
