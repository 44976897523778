import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataPayloadCreateUser, DataPayloadUpdateUser, ParamsDetailUserType, ParamsPayloadType, GetListGroupsParams, roleItem, groupItem} from '../type';

export interface AccountManagerState {
  params: ParamsPayloadType;
  listAccount: any;
  loadingTable: boolean;
  loadingBtnModal: boolean;
  showModal: boolean;
  loadingModal: boolean;
  itemData: any;
  listUserGroup: any[];
  listGroupStatus0: groupItem[];
  listRole: any[];
  listRoleStatus0: roleItem[];
  openFilter: boolean;
  typeModal: string;
  qsValue: string;
}

const initialState: AccountManagerState = {
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  listAccount: {},
  loadingTable: false,
  loadingBtnModal: false,
  showModal: false,
  loadingModal: false,
  itemData: {},
  listUserGroup: [],
  listGroupStatus0: [],
  listRole: [],
  listRoleStatus0: [],
  openFilter: false,
  typeModal: '',
  qsValue: '',
};

const accountManagerSlice = createSlice({
  name: 'accountManagerSlice',
  initialState,
  reducers: {
    setOpenFilter: (state, action: PayloadAction<boolean>) => {
      state.openFilter = action.payload;
    },
    getListAccount: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.loadingTable = true;
    },
    getListAccountSuccess: (state, action: PayloadAction<any>) => {
      state.loadingTable = false;
      state.listAccount = action.payload;
    },
    getListAccountFail: state => {
      state.loadingTable = false;
    },

    setParams: (state, action: PayloadAction<ParamsPayloadType>) => {
      state.params = action.payload;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.listRoleStatus0 = [];
        state.listGroupStatus0 = [];
      }
      state.showModal = action.payload;
    },

    createAccount: (state, action: PayloadAction<DataPayloadCreateUser>) => {
      state.loadingBtnModal = true;
    },

    createAccountSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },

    createAccountFail: state => {
      state.loadingBtnModal = false;
    },

    getDetailAccount: (state, action: PayloadAction<ParamsDetailUserType>) => {
      state.loadingBtnModal = true;
    },

    getDetailAccountSuccess: (state, action: PayloadAction<any>) => {
      state.loadingBtnModal = false;
      state.itemData = action.payload;
      state.showModal = true;
    },

    getDetailAccountFail: state => {
      state.loadingBtnModal = false;
    },

    setDataItem: (state, action: PayloadAction<any>) => {
      state.itemData = action.payload;
    },
    updateAccount: (state, action: PayloadAction<DataPayloadUpdateUser>) => {
      state.loadingBtnModal = true;
    },
    updateAccountSuccess: state => {
      state.loadingBtnModal = false;
      state.showModal = false;
    },
    updateAccountFail: state => {
      state.loadingBtnModal = false;
    },
    deleteAccount: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteAccountSuccess: state => {
      state.loadingTable = false;
    },
    deleteAccountFail: state => {
      state.loadingTable = false;
    },
    getListGroupUser: (state, action: PayloadAction<any>) => {},
    getListGroupUserSuccess: (state, action: PayloadAction<any[]>) => {
      state.listUserGroup = action.payload;
    },
    getListGroupUserFail: state => {},
    getListRoles: (state, action: PayloadAction<GetListGroupsParams>) => {},
    getListRolesSuccess: (state, action: PayloadAction<any[]>) => {
      state.listRole = action.payload;
    },
    getListRolesFail: state => {},
    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
    pushListRoleStatus0: (state, action: PayloadAction<roleItem[]>) => {
      state.listRoleStatus0 = action.payload;
    },
    pushListGroupStatus0: (state, action: PayloadAction<groupItem[]>) => {
      state.listGroupStatus0 = action.payload;
    },
  },
});

export const {
  createAccount,
  createAccountFail,
  createAccountSuccess,
  getListAccount,
  getListAccountFail,
  getListAccountSuccess,
  setParams,
  setShowModal,
  getDetailAccount,
  getDetailAccountFail,
  getDetailAccountSuccess,
  setDataItem,
  updateAccount,
  updateAccountFail,
  updateAccountSuccess,
  deleteAccount,
  deleteAccountFail,
  deleteAccountSuccess,
  getListGroupUser,
  getListGroupUserFail,
  getListGroupUserSuccess,
  getListRoles,
  getListRolesFail,
  getListRolesSuccess,
  setOpenFilter,
  setTypeModal,
  setQsValue,
  pushListRoleStatus0,
  pushListGroupStatus0,
} = accountManagerSlice.actions;

export default accountManagerSlice.reducer;
