import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DataCreateUpdateManagementType,
  ParamsType,
  ResListManagement,
  ManagementObjectType
} from '../type';

export interface ManagementState {
  loadingTable: boolean;
  loadingModal: boolean;
  loadingButtonSave: boolean;
  listManagement: ResListManagement;
  showModalAddEdit: boolean;
  params: ParamsType;
  itemEdit: ManagementObjectType | null;
  typeModal: string;
  openFilter: boolean;
  qsValue: string;
}

const initialState: ManagementState = {
  loadingTable: false,
  loadingModal: false,
  loadingButtonSave: false,
  listManagement: {
    content: [],
    pageable: '',
    last: false,
    totalPages: 0,
    totalElements: 0,
    first: false,
    sort: '',
    numberOfElements: 0,
    size: 0,
    number: 0,
    empty: false,
  },
  showModalAddEdit: false,
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  itemEdit: {
    id: '',
    name: '',
    representative: '',
    address: '',
    foundedYear: 0,
    email: '',
    phoneNumber: 0,
    signInEmail: '',
    setFieldError: '',
  },
  typeModal: '',
  openFilter: false,
  qsValue: '',
};

const managementSlice = createSlice({
  name: 'managementSlice',
  initialState,
  reducers: {
    //Action lấy danh sách management
    getListManagement: (state, action: PayloadAction<ParamsType>) => {
      state.loadingTable = true;
    },
    getListManagementSuccess: (state, action: PayloadAction<ResListManagement>) => {
      state.loadingTable = false;
      state.listManagement = action.payload;
    },
    getListManagementFail: state => {
      state.loadingTable = false;
    },

    //Action setParams
    setParams: (state, action: PayloadAction<ParamsType>) => {
      state.params = action.payload;
    },

    //Action show Modal
    setOpenModalAddEdit: (state, action: PayloadAction<boolean>) => {
      state.showModalAddEdit = action.payload;
    },

    //Action lấy danh sách Defautlt
    getListManagementDefault: state => {
      state.loadingModal = false;
    },
    getListManagementFailDefault: state => {
      state.loadingModal = false;
    },

    //Action tạo management
    createManagementStart: (state, action: PayloadAction<DataCreateUpdateManagementType>) => {
      state.loadingButtonSave = true;
    },
    createManagementSuccess: state => {
      state.loadingButtonSave = false;
      state.showModalAddEdit = false;
    },
    createManagementFail: state => {
      state.loadingButtonSave = false;
    },

    //setItem cho ModalAdd và ModalUpdate
    setItemEdit: (state, action: PayloadAction<ManagementObjectType | null>) => {
      state.itemEdit = action.payload;
    },

    //Get Detail một Management
    getDetailManagement: (state, action: PayloadAction<string>) => {
    },
    getDetailManagementSuccess: (state, action: PayloadAction<any>) => {
      state.itemEdit = action.payload;
      state.showModalAddEdit = true;
    },

    //Update management
    updateManagement: (state, action: PayloadAction<DataCreateUpdateManagementType>) => {
      state.loadingButtonSave = true;
    },
    updateManagementSuccess: state => {
      state.loadingButtonSave = false;
      state.showModalAddEdit = false;
    },
    updateManagementFail: state => {
      state.loadingButtonSave = false;
    },

    //DeleteRole
    deleteRole: (state, action: PayloadAction<string>) => {
      state.loadingTable = true;
    },
    deleteRoleSuccess: state => {
      state.loadingTable = false;
    },
    deleteRoleFail: state => {
      state.loadingTable = false;
    },

    //DeleteManagement
    deleteManagement: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteManagementSuccess: state => {
      state.loadingTable = false;
    },
    deleteManagementFail: state => {
      state.loadingTable = false;
    },

    //Delete MutiManagement
    deleteMutiManagement: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteMutiManagementSuccess: state => {
      state.loadingTable = false;
    },
    deleteMutiManagementFail: state => {
      state.loadingTable = false;
    },

    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
  },
});

export const {
  getListManagement,
  getListManagementSuccess,
  getListManagementFailDefault,
  getListManagementDefault,
  getListManagementFail,
  getDetailManagement,
  getDetailManagementSuccess,
  createManagementStart,
  createManagementSuccess,
  createManagementFail,
  updateManagement,
  deleteManagement,
  deleteManagementFail,
  deleteManagementSuccess,
  deleteMutiManagement,
  deleteMutiManagementFail,
  deleteMutiManagementSuccess,
  updateManagementSuccess,
  updateManagementFail,

  setOpenModalAddEdit,
  setItemEdit,
  setParams,
  setTypeModal,

  setQsValue,
} = managementSlice.actions;

export default managementSlice.reducer;
