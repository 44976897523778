import { CardEventTrip, EventNoData, ListCarItemBox } from 'modules/mapMonitor/components/style';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setIndexActiveEvent, setPlayDetailUrl, setShowVideoDetail } from '../../redux';
import { eventsSelector, indexActiveEventSelector } from '../../selector';
import { Col, Row } from 'antd';
import moment from 'moment';
import { RightOutlined } from '@ant-design/icons';
import NoData from 'assets/imgs/NoData.svg';
import { videoIcon } from 'modules/mapMonitor/constant';

function EventTrip() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const events = useSelector(eventsSelector);
  const indexActiveEvent: number = useSelector(indexActiveEventSelector);

  const handlePlayVideo = (value: any) => {
    dispatch(setShowVideoDetail(true));
    dispatch(setPlayDetailUrl(value.mp4Url));
  };

  const handleShowEvent = (indexActive: number) => {
    if (indexActive === indexActiveEvent) {
      dispatch(setIndexActiveEvent(-1));
    } else {
      dispatch(setIndexActiveEvent(indexActive));
    }
  };

  return (
    <ListCarItemBox>
      {events.length ? (
        events.map((event: any, index: number) => {
          return (
            <CardEventTrip key={index}>
              <Row justify={'space-between'} align={'middle'}>
                <Col>
                  <div className="trip-card-event--time">{moment(event.gpsSeq?.time).format('HH:mm') ?? 0}</div>
                  <div>{intl.formatMessage({ id: `events.${event?.eventType}` })}</div>
                </Col>
                <Col>
                  {event.gpsSeq.coordinate.length ? (
                    <RightOutlined onClick={() => handleShowEvent(index)} />
                  ) : (
                    <img src={videoIcon} alt="video" onClick={() => handlePlayVideo(event)} className="event-play-video" />
                  )}
                </Col>
              </Row>
            </CardEventTrip>
          );
        })
      ) : (
        <EventNoData>
          <img src={NoData} alt="no data" />
          <div>{intl.formatMessage({ id: 'common.noData' })}</div>
        </EventNoData>
      )}
    </ListCarItemBox>
  );
}

export default EventTrip;
