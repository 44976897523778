import moment from 'moment';

export interface ParamsType {
  page: number;
  size: number;
  q: string;
}

export interface ParamsPayloadType {
  page: number;
  size: number;
  clientId: string;
  startTime: string;
  endTime: string;
  licensePlate: string;
  classification: string;
}

export interface OptionSelectBox {
  value: string | number;
  label: string;
  isDisabled: boolean;
}

export interface TypeTF {
  children: null | TypeTF[];
  id: string;
  name: string;
  parentId: null | string;
}

export interface ParamsTF {
  clientType: number;
}

export interface ParamsLicensePlate {
  clientId: string;
}

export interface PayloadTime {
  startTime: string;
  endTime: string;
}

export interface VehicleReport {
  classification: string;
  id: string;
  licensePlate: string;
  ownerName: string;
}

export interface LessonVehicle {
  code: string;
  distance: number;
  duration: number;
  endTime: string;
  startTime: string;
  stt: number;
  studentCode: string;
  studentName: string;
  id: string;
}

export interface VehicleReportDetail {
  classification: string;
  endTime: string;
  lessonVehicle: LessonVehicle[];
  licensePlate: string;
  ownerName: string;
  schoolName: string;
  startTime: string;
  totalDistance: number;
  totalDuration: number;
}

export const VehicleReportDefault = {
  classification: '',
  endTime: '',
  lessonVehicle: [],
  licensePlate: '',
  ownerName: '',
  schoolName: '',
  startTime: '',
  totalDistance: 0,
  totalDuration: 0,
};

export const ParamsDefault = {
  page: 0,
  size: 10,
  endTime: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  startTime: moment().subtract(3, 'months').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  clientId: '',
  licensePlate: '',
  classification: '',
};
