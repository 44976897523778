import {
  BottomLeft,
  Content,
  DiriverName,
  DiriverSvg,
  IconBottomItem,
  ItemText,
  Label,
  Num,
  NumDetailSpeed,
  NumSpeedDevcie,
  Top,
  Xhnum,
} from '../../style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faClock, faEye, faCircleChevronDown, faVideo } from '@fortawesome/free-solid-svg-icons';
import Collapse from 'antd/es/collapse';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLiveStart, setIsViewDetail, getDetailSessionStart, setFleetViewItem } from 'modules/mapMonitor/redux';
import { CarItemBox, LicensePlate, CarDriving } from './style';
import { RecordsItem } from 'modules/mapMonitor/api/types';
import moment from 'moment';
import numeral from 'numeral';
import { FormattedMessage, useIntl } from 'react-intl';

function CarItem(prop: RecordsItem) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showCollapse, setShowCollapse] = useState(false);

  const handleViewDetail = () => {
    dispatch(getDetailSessionStart({ lessonId: prop.id, cameraSn: prop.cameraSn }));
    dispatch(setFleetViewItem(prop));
    dispatch(setIsViewDetail(true));
  };

  const handleViewSession = () => {
    dispatch(getDetailSessionStart({ lessonId: prop.id }));
  };

  const handleLiveStream = () => {
    dispatch(getLiveStart(prop.cameraSn));
  };

  return (
    <div>
      <CarItemBox>
        <Top>
          <LicensePlate>
            <Xhnum onClick={handleViewSession}>{prop.licensePlate}</Xhnum>
            <FontAwesomeIcon
              icon={faCircleChevronDown}
              flip={showCollapse ? 'vertical' : undefined}
              size="lg"
              onClick={() => setShowCollapse(!showCollapse)}
            />
          </LicensePlate>

          <NumDetailSpeed>
            <CarDriving driving={prop.mode !== 'parking'}>
              {prop.mode === 'parking'
                ? intl.formatMessage({ id: 'mapMonitor.parking' })
                : intl.formatMessage({ id: 'mapMonitor.driving' })}
            </CarDriving>
            <NumSpeedDevcie>{numeral(prop.currentSpeed).format('0.00')} km/h</NumSpeedDevcie>
          </NumDetailSpeed>
        </Top>

        <DiriverSvg>
          <DiriverName>({prop.courseCode})</DiriverName>
        </DiriverSvg>

        <Top>
          <BottomLeft>
            <IconBottomItem>
              <FontAwesomeIcon icon={faRoad} size="lg" />
              <Num>{numeral(prop.distance / 1000).format('0.00')}Km</Num>
            </IconBottomItem>
            <IconBottomItem>
              <FontAwesomeIcon icon={faClock} size="lg" />
              <Num>{moment.utc(prop.time).format('H[h] mm[m]')}</Num>
            </IconBottomItem>
          </BottomLeft>
          <BottomLeft>
            <IconBottomItem>
              <FontAwesomeIcon icon={faVideo} shake size="lg" onClick={handleLiveStream} />
              <Num />
              <FontAwesomeIcon icon={faEye} onClick={handleViewDetail} size="lg" />
            </IconBottomItem>
          </BottomLeft>
        </Top>

        <Collapse bordered={false} activeKey={[showCollapse ? '1' : '0']}>
          <Collapse.Panel showArrow={false} header="" key="1">
            <ItemText>
              <Label>
                <FormattedMessage id="mapMonitor.courseName" />
              </Label>
              <Content title={prop.courseName + ' (' + prop.courseCode + ')'}>{prop.courseName + ' (' + prop.courseCode + ')'}</Content>
            </ItemText>
            <ItemText>
              <Label>
                <FormattedMessage id="mapMonitor.teacher" />
              </Label>
              <Content title={prop.instructorName + '_' + prop.instructorCitizenId}>
                {prop.instructorName + '_' + prop.instructorCitizenId}
              </Content>
            </ItemText>
            <ItemText>
              <Label>
                <FormattedMessage id="mapMonitor.studentName" />
              </Label>
              <Content title={prop.studentName + '_' + prop.studentCitizenId}>{prop.studentName + '_' + prop.studentCitizenId}</Content>
            </ItemText>
            <ItemText>
              <Label>
                <FormattedMessage id="mapMonitor.startTime" />
              </Label>
              <Content>{moment(prop.startTime).format('DD/MM/YYYY H:mm')}</Content>
            </ItemText>
          </Collapse.Panel>
        </Collapse>
      </CarItemBox>
    </div>
  );
}

export default CarItem;
