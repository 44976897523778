import { Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import { StyledSpanUpdate, StyledTime, StyledWrapGrid, StyledWrapSpanUpdate, TitleChart } from '../page/styles';
import ChartLesson from './ChartLesson';
import ChartEvent from './ChartEvent';
import ChartStudentLine from './ChartStudentLine';
import ChartStudentDonut from './ChartStudentDonut';
import ChartSession from './CharSession';
import { ClockCircleOutlined } from '@ant-design/icons';

const Grid = () => {
  const dataGTVT = [
    {
      label: 'Hà Nội',
      value: 1,
    },
    {
      label: 'TP Hồ Chí Minh',
      value: 2,
    },
    {
      label: 'Đà Nẵng',
      value: 3,
    },
    {
      label: 'Quảng Ninh',
      value: 4,
    },
    {
      label: 'Nam Định',
      value: 5,
    },
  ];

  const optionSort = [
    {
      label: 'Cao tới thấp',
      value: 'desc',
    },
    {
      label: 'Thấp tới cao',
      value: 'asc',
    },
  ];
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');

  return (
    <StyledWrapGrid gutter={[16, 0]}>
      <Col style={{ paddingLeft: 2 }} span={16}>
        <div className="wrapChart">
          <TitleChart>Số lượng khóa học</TitleChart>
          <StyledWrapSpanUpdate>
            <StyledSpanUpdate>
              <span>Cập nhật lần cuối: 01/08/2023</span>
            </StyledSpanUpdate>
            <Select options={dataGTVT} placeholder="Chọn sở GTVT" style={{ width: 150 }} />
          </StyledWrapSpanUpdate>
          <ChartLesson />
        </div>
        <div className="wrapChart">
          <TitleChart>Số lượng sự kiện</TitleChart>
          <StyledWrapSpanUpdate>
            <StyledSpanUpdate>
              <span>Cập nhật lần cuối: 01/08/2023</span>
            </StyledSpanUpdate>
            <Select options={optionSort} value={sort} onChange={(value: any) => setSort(value)} style={{ width: 150 }} />
          </StyledWrapSpanUpdate>
          <ChartEvent sort={sort} />
        </div>
      </Col>
      <Col style={{ paddingRight: 2 }} span={8}>
        <Row gutter={[10, 0]} style={{ width: '100%' }}>
          <div className="wrapChart">
            <TitleChart>Số lượng học viên</TitleChart>
            <StyledWrapSpanUpdate>
              <StyledSpanUpdate>
                <span>Cập nhật lần cuối: 01/08/2023</span>
              </StyledSpanUpdate>
              <Select options={dataGTVT} placeholder="Chọn sở GTVT" style={{ width: 150 }} />
            </StyledWrapSpanUpdate>

            <ChartStudentLine />
            <TitleChart style={{ marginTop: 36 }}>Tỉ lệ</TitleChart>

            <ChartStudentDonut />
          </div>
        </Row>
      </Col>
      <Col span={24} style={{ paddingLeft: 2 }}>
        <div className="wrapChart">
          <TitleChart>Phiên đào tạo đang diễn ra</TitleChart>
          <StyledWrapSpanUpdate>
            <StyledSpanUpdate>
              <span>Cập nhật lần cuối: 01/08/2023</span>
            </StyledSpanUpdate>
            <StyledTime>
              <ClockCircleOutlined />
              10:00:00
            </StyledTime>
          </StyledWrapSpanUpdate>
          <ChartSession />
        </div>
      </Col>
    </StyledWrapGrid>
  );
};

export default Grid;
