import { Marker } from '@react-google-maps/api';
import { setIndexActiveEvent } from 'modules/mapMonitor/redux';
import { dataTracksSelector, eventsSelector, indexActiveEventSelector } from 'modules/mapMonitor/selector';
import { useDispatch, useSelector } from 'react-redux';
import PointStart from 'assets/imgs/PointStart.svg';
import PointEnd from 'assets/imgs/PointEnd.svg';
import { EventItem } from 'modules/mapMonitor/api/types';
import { eventIcon } from '../../../constant';
import ModalEvent from './ModalEvent';
import Polyline from './Polyline';

const PolylineMap = (props: any) => {
  const dispatch = useDispatch();

  const dataTracks = useSelector(dataTracksSelector);
  const events: EventItem[] = useSelector(eventsSelector);
  const indexActiveEvent: number = useSelector(indexActiveEventSelector);

  const handleClickEvent = (indexActive: number) => {
    if (indexActive === indexActiveEvent) {
      dispatch(setIndexActiveEvent(-1));
    } else {
      dispatch(setIndexActiveEvent(indexActive));
    }
  };

  return (
    <>
      {dataTracks.length && (
        <>
          <Marker
            icon={{
              url: PointStart,
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0), // origin
              anchor: new google.maps.Point(24, 18),
            }}
            position={{
              lat: dataTracks[0].lat,
              lng: dataTracks[0].lng,
            }}
          />
          <Marker
            icon={{
              url: PointEnd,
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0), // origin
              anchor: new google.maps.Point(24, 18),
            }}
            position={{
              lat: dataTracks[dataTracks.length - 1].lat,
              lng: dataTracks[dataTracks.length - 1].lng,
            }}
          />
          <Polyline />
        </>
      )}

      {events?.map((value: EventItem, index: number) => {
        return (
          <Marker
            icon={eventIcon}
            position={{
              lat: value.gps?.coordinate[1],
              lng: value.gps?.coordinate[0],
            }}
            key={index}
            onClick={() => handleClickEvent(index)}
          >
            {index === indexActiveEvent && <ModalEvent event={value} />}
          </Marker>
        );
      })}
    </>
  );
};

export default PolylineMap;
