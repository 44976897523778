import React, { FC } from 'react';
import { StepsProps } from 'antd';
import { StyledSteps } from './styles';

interface Props extends StepsProps {
  children?: React.ReactNode;
}

const CSteps: FC<Props> = React.memo(({ children, ...rest }) => {
  return <StyledSteps {...rest}>{children}</StyledSteps>;
});

export default CSteps;
