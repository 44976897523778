import React from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
const ChartLesson = () => {
  const data = [
    {
      city: 'Hà Nội',
      type: '1',
      value: 14500,
    },
    {
      city: 'Hà Nội',
      type: '2',
      value: 8500,
    },
    {
      city: 'Hà Nội',
      type: '3',
      value: 10000,
    },
    {
      city: 'Hà Nội',
      type: '4',
      value: 7000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '1',
      value: 9000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '2',
      value: 8500,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '3',
      value: 11000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '4',
      value: 6000,
    },
    {
      city: 'Đà Nẵng',
      type: '1',
      value: 16000,
    },
    {
      city: 'Đà Nẵng',
      type: '2',
      value: 5000,
    },
    {
      city: 'Đà Nẵng',
      type: '3',
      value: 6000,
    },
    {
      city: 'Đà Nẵng',
      type: '4',
      value: 10000,
    },
    {
      city: 'Quảng Ninh',
      type: '1',
      value: 14000,
    },
    {
      city: 'Quảng Ninh',
      type: '2',
      value: 9000,
    },
    {
      city: 'Quảng Ninh',
      type: '3',
      value: 10000,
    },
    {
      city: 'Quảng Ninh',
      type: '4',
      value: 9000,
    },

    {
      city: 'Hà Nội',
      type: '5',
      value: 14500,
    },
    {
      city: 'Hà Nội',
      type: '6',
      value: 8500,
    },
    {
      city: 'Hà Nội',
      type: '7',
      value: 10000,
    },
    {
      city: 'Hà Nội',
      type: '8',
      value: 7000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '5',
      value: 9000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '6',
      value: 8500,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '7',
      value: 11000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '8',
      value: 6000,
    },
    {
      city: 'Đà Nẵng',
      type: '5',
      value: 16000,
    },
    {
      city: 'Đà Nẵng',
      type: '6',
      value: 5000,
    },
    {
      city: 'Đà Nẵng',
      type: '7',
      value: 6000,
    },
    {
      city: 'Đà Nẵng',
      type: '8',
      value: 10000,
    },
    {
      city: 'Quảng Ninh',
      type: '5',
      value: 14000,
    },
    {
      city: 'Quảng Ninh',
      type: '6',
      value: 9000,
    },
    {
      city: 'Quảng Ninh',
      type: '7',
      value: 10000,
    },
    {
      city: 'Quảng Ninh',
      type: '8',
      value: 9000,
    },

    {
      city: 'Hà Nội',
      type: '9',
      value: 14500,
    },
    {
      city: 'Hà Nội',
      type: '10',
      value: 8500,
    },
    {
      city: 'Hà Nội',
      type: '11',
      value: 10000,
    },
    {
      city: 'Hà Nội',
      type: '12',
      value: 7000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '9',
      value: 9000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '10',
      value: 8500,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '11',
      value: 11000,
    },
    {
      city: 'TP Hồ Chí Minh',
      type: '12',
      value: 6000,
    },
    {
      city: 'Đà Nẵng',
      type: '9',
      value: 16000,
    },
    {
      city: 'Đà Nẵng',
      type: '10',
      value: 5000,
    },
    {
      city: 'Đà Nẵng',
      type: '11',
      value: 6000,
    },
    {
      city: 'Đà Nẵng',
      type: '12',
      value: 10000,
    },
    {
      city: 'Quảng Ninh',
      type: '9',
      value: 14000,
    },
    {
      city: 'Quảng Ninh',
      type: '10',
      value: 9000,
    },
    {
      city: 'Quảng Ninh',
      type: '11',
      value: 10000,
    },
    {
      city: 'Quảng Ninh',
      type: '12',
      value: 9000,
    },
  ];
  const config: ColumnConfig = {
    data,
    xField: 'type',
    yField: 'value',
    seriesField: 'city',
    isGroup: true,
    columnStyle: {
      radius: [5, 5, 5, 5],
    },

    legend: {
      position: 'top-right',
    },

    color: ({ city }) => {
      switch (city) {
        case 'Hà Nội':
          return '#00b4d8';
        case 'TP Hồ Chí Minh':
          return '#165baa';
        case 'Đà Nẵng':
          return '#f7bb48';
        case 'Quảng Ninh':
          return '#e76c91';
        default:
          return '';
      }
    },
    tooltip: {
      title(title, datum) {
        return `Tháng ${title}`;
      },
    },
    yAxis: {
      label: {
        style: {
          fill: 'var(--2-b-2-b-2-b, #2B2B2B)',
        },
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
      line: {
        style: {
          opacity: 0,
        },
      },
      tickLine: {
        length: 0,
      },
      title: {
        text: 'Tháng',
        position: 'left',
        offset: 14,
        style: {
          x: 20,
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
    },
  };
  return <Column style={{ marginTop: 10, height: '30.5vh' }} {...config} />;
};

export default ChartLesson;
