import vn from 'antd/locale/vi_VN';
import en from 'antd/locale/en_US';
import { ConfigProvider } from 'antd';
import AppRouter from 'router';
import { useSelector } from 'react-redux';
import { languageSelector } from 'languages/selectors';
import { ThemeProvider } from 'styled-components';
import { themes } from 'styles';
import moment from 'moment';
import 'moment/min/locales';
const App = () => {
  const lang = useSelector(languageSelector);
  moment.locale(lang === 'vi' ? 'vi' : 'en');
  return (
    <ThemeProvider theme={themes}>
      <ConfigProvider
        locale={lang === 'vi' ? vn : en}
        theme={{
          token: {
            fontFamily: 'Inter',
            colorPrimary: themes.primary,
          },
        }}
      >
        <AppRouter />
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
