import { Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { WrapperActionTable } from 'modules/role/page/style';
import CTable from 'components/commons/Table';
import CButton, { TypeCustom } from 'components/commons/Button';
import { Text } from 'components/commons/Template/style';
import { vehicleReportSelector } from '../selector';
import ViewDetailModal from './ViewDetailModal';
import { getDataDetail, setIdDetail, setParams } from '../redux';
import { VehicleReport } from '../type';

const Grid = () => {
  const {showModal, dataGrid, params, loadingTable, startDateDetail, endDateDetail} = useSelector(vehicleReportSelector)

  const dispatch = useDispatch();
  const intl = useIntl();

  const columns: ColumnsType<VehicleReport> = [
    {
      title: 'STT',
      align: 'left',
      width: 80,
      fixed: 'left',
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.licensePlate' }),
      dataIndex: 'licensePlate',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.vehicleClass' }),
      dataIndex: 'classification',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'vehicleReport.owner' }),
      dataIndex: 'ownerName',
      width: 150,
      align: 'left',
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      fixed: 'right',
      width: 150,
      render(value, record, index) {
        return (
          <WrapperActionTable>
              <CButton typeCustom={TypeCustom.Action} onClick={() => handleViewDetail(record)}>
                {<FormattedMessage id={'common.detail'} />}
              </CButton>
          </WrapperActionTable>
        );
      },
    },
  ];


  const handleViewDetail = (itemEdit: any) => {
    dispatch(setIdDetail(itemEdit.id))
    dispatch(getDataDetail({id: itemEdit.id, startDate: startDateDetail, endDate: endDateDetail}))
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterStudentsManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperStudentsManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 163 - 188;
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  return (
    <div>
      <Row>
        <CTable
          rowKey="id"
          columns={columns}
          style={{width: '100%'}}
          dataSource={dataGrid.content}
          loading={loadingTable}
          scroll={{ y: changeHeightTable() }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30],
            current: params.page + 1,
            pageSize: params.size,
            size: 'small',
            total: dataGrid?.totalElements,
            showQuickJumper: true,
            showTotal: TotalMessage,
            locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
          }}
          onChange={handleChangeTable}
        />
      </Row>
      {showModal && <ViewDetailModal />}
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
