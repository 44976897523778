import React, { FC } from 'react';
import { TextAreaProps } from 'antd/es/input';
import { StyledTextArea } from './style';
import { Form } from 'antd';

const CTextArea: FC<TextAreaProps> = React.memo(({ id, children, onBlur, ...rest }) => {
  const form = Form.useFormInstance();
  const field = id as string;
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    } else {
      form.setFieldValue(field, e.target.value.trim());
      form.validateFields([field]);
    }
  };
  return (
    <StyledTextArea onBlur={handleBlur} {...rest} autoSize={{ minRows: 2, maxRows: 5 }}>
      {children}
    </StyledTextArea>
  );
});

export default CTextArea;
