import { Form, TabsProps, Row, Col } from 'antd';
import { ActionType } from 'helper/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { courseManagerSelector, itemDataSelector } from '../selector';
import { createCourse, setDataItem, setShowModal, setTypeModal, updateCourse, deleteCourse, setInstructorsWorking0 } from '../redux';
import { StyledModal, StyledTitle } from '../page/styles';
import { useEffectOnce } from 'react-use';
import { isEmpty } from 'helper/utils';
import ModalConfirm from 'components/commons/modalConfirm';
import { StyledTitleModal } from 'modules/role/page/style';
import { useRolesByRouter } from 'helper/cusHooks';
import CButton, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { includes } from 'lodash';
import { ReactComponent as CloseIcon } from 'assets/imgs/CloseIcon.svg';
import { RowButton } from 'components/commons/Template/style';
import CTabs from 'components/commons/Tabs';
import FormCourse from './FormCourse';
import DetailListGV from './DetailListGV';
import DetailListHV from './DetailListHV';
import DetailSessionTraning from './DetailSessionTraining';
import { TabCourse } from '../constant';
import moment from 'moment';
interface Iprops {
  type: string;
}

const AddEditViewCourse = (props: Iprops) => {
  const { loadingBtnModal, showModal, typeModal } = useSelector(courseManagerSelector);
  const { type } = props;
  const dispatch = useDispatch();
  const itemData = useSelector(itemDataSelector);
  const intl = useIntl();
  const listRoles = useRolesByRouter();

  const [form] = Form.useForm();

  const renderTitle = () => {
    switch (type) {
      case ActionType.ADD:
        return intl.formatMessage({ id: 'common.add' });
      case ActionType.EDIT:
        return intl.formatMessage({ id: 'common.edit' });
      case ActionType.VIEW:
        return intl.formatMessage({ id: 'common.viewDetail' });
      default:
        return '';
    }
  };

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };

  const setFields = (field: string, message: string) => {
    form.setFields([
      {
        name: field,
        errors: [message],
      },
    ]);
  };

  const onFinish = (values: any) => {
    const data = {
      ...values,
      instructors: values.instructors?.map((item: string) => ({
        id: item,
      })),
      students: values.students?.map((item: string) => ({
        id: item,
      })),
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : values.startDate,
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : values.endDate,
      expectedExaminationDate: values.expectedExaminationDate
        ? moment(values.expectedExaminationDate).format('YYYY-MM-DD')
        : values.expectedExaminationDate,
    };

    if (type === ActionType.ADD) {
      dispatch(
        createCourse({
          ...data,
          setFields: setFields,
        })
      );
      return;
    }

    if (type === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmUpdate',
        handleConfirm: () => {
          dispatch(
            updateCourse({
              ...data,
              id: itemData?.id,
              setFields: setFields,
            })
          );
        },
      });

      return;
    }
  };

  const handleBtnClose = () => {
    if (typeModal === ActionType.EDIT) {
      ModalConfirm({
        title: 'common.confirmCancelAction',

        handleConfirm: () => {
          dispatch(setShowModal(false));
        },
      });
      return;
    }
    if (typeModal === ActionType.ADD) {
      const values = form.getFieldsValue();

      const isValuesEmpty = isEmpty({
        ...values,
        status: 0,
      });
      if (!isValuesEmpty) {
        ModalConfirm({
          title: 'common.confirmCancelAction',
          handleConfirm: () => {
            dispatch(setShowModal(false));
          },
        });
      } else {
        dispatch(setShowModal(false));
      }
      return;
    }
    dispatch(setShowModal(false));
  };
  const handleChangeEdit = (e: any) => {
    e.preventDefault();
    dispatch(setTypeModal(ActionType.EDIT));
  };

  const handleDeleteRole = () => {
    if (itemData?.id) {
      ModalConfirm({
        title: 'common.confirmDelete',
        message: 'common.relatedDataDeleted',
        handleConfirm: () => {
          dispatch(deleteCourse([itemData?.id]));
          handleCancel();
        },
      });
    }
  };

  useEffectOnce(() => {
    return () => {
      dispatch(setDataItem({}));
      dispatch(setInstructorsWorking0([]));
      form.resetFields();
    };
  });

  const itemsTab: TabsProps['items'] = [
    {
      key: TabCourse.courseInfo,
      label: intl.formatMessage({ id: 'course.courseInfo' }),
      children: <FormCourse form={form} type={type} />,
    },
    {
      key: TabCourse.detailGV_HV,
      label: intl.formatMessage({ id: 'course.detailGV_HV' }),
      children: (
        <Row style={{ marginBottom: '24px' }}>
          <Col span={24}>
            <StyledTitle style={{ marginTop: '-12px' }}>{intl.formatMessage({ id: 'course.listTeacher' })}</StyledTitle>
          </Col>
          <DetailListGV />
          <Col span={24}>
            <StyledTitle style={{ marginTop: '16px' }}>{intl.formatMessage({ id: 'course.litStudent' })}</StyledTitle>
          </Col>
          <DetailListHV />
        </Row>
      ),
    },
    {
      key: TabCourse.detailLesson,
      label: intl.formatMessage({ id: 'course.detailTrainingSession' }),
      children: <DetailSessionTraning />,
    },
  ];

  return (
    <StyledModal
      closable
      closeIcon={<CloseIcon />}
      open={showModal}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
      width={'1000px'}
    >
      <Form form={form} layout="vertical" labelAlign="left" onFinish={onFinish}>
        <StyledTitleModal>{renderTitle()}</StyledTitleModal>
        {typeModal === ActionType.VIEW ? (
          <CTabs defaultActiveKey={TabCourse.courseInfo} items={itemsTab} />
        ) : (
          <FormCourse form={form} type={type} />
        )}
        <RowButton>
          {typeModal === ActionType.VIEW ? (
            <>
              {moment(itemData?.startDate).isBefore(moment()) ? (
                <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                  {intl.formatMessage({ id: 'common.close' })}
                </CButton>
              ) : (
                <>
                  {includes(listRoles, actionsType.DELETE) && (
                    <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleDeleteRole}>
                      <FormattedMessage id="common.delete" />
                    </CButton>
                  )}
                  {includes(listRoles, actionsType.UPDATE) && (
                    <CButton sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} onClick={handleChangeEdit}>
                      <FormattedMessage id="common.edit" />
                    </CButton>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <CButton sizeCustom={TypeSizeCustom.Medium} onClick={handleBtnClose}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </CButton>

              <CButton loading={loadingBtnModal} sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} htmlType="submit">
                <FormattedMessage id="common.save" />
              </CButton>
            </>
          )}
        </RowButton>
      </Form>
    </StyledModal>
  );
};
export default AddEditViewCourse;
