import paths from 'helper/pathRoutes';
import { SERVER_API_URL } from '../../AppConfig';
import Storage from '../../helper/storage';
// import { LanguageType } from "../../languages/redux";
// import Storage from 'helper/storage';
// import { store } from "redux/store";
// import { v4 as uuidv4 } from 'uuid';
// import { EventSourcePolyfill } from "event-source-polyfill";

export function connectSse(uri: string, history?: any) {
  // const typeLanguage = store.getState().language.type;
  const token = Storage.getAccessToken() || '';
  if (!token && history) {
    history.push(paths.login);
  }
  // const headers: any = {};
  // if (token) {
  //   headers['Authorization'] = `Bearer ${token}`;
  // }
  // headers['x-client-request-id'] = uuidv4();
  // headers['Accept-Language'] = typeLanguage === LanguageType.VI ? 'vi-VN' : 'en-US ';
  // headers['Accept'] = '*';
  // return new EventSourcePolyfill(`${SERVER_API_URL}${uri}`, {
  //   headers: headers
  // });
  return new EventSource(`${SERVER_API_URL}${uri}?token=${token}`);
}
