export enum BtnTabType {
  event = 'event',
  trip = 'trip',
  prev = 'prev',
}
export const EventCategory: { [key: string]: number } = {
  DRIVING_AND_PARKING_KEY: 1,
  DMS_KEY: 2,
  COLLISIONS_KEY: 3,
  HARD_ACCELERATION_KEY: 4,
  HARD_BRAKE_KEY: 5,
  SHARP_TURN_KEY: 6,
};

export const eventIcon = 'https://data.waylens.com/0c5321a10142e8bbc5d81d6154c9cd39.svg';
export const videoIcon = 'https://data.waylens.com/a50ea735abd819a6d229b55431be7da0.svg';
