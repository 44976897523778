import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  deleteListInstructor,
  getListInstructorFull,
  setSelectedRowKeys,
  setTypeModal,
  setValueSearch,
  startGetListLicense,
  updateSearch,
} from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'helper/constants';
import { paramsSelector, selectedRowKeysSelector, valueSearchSelector } from '../selector';
import { useRolesByRouter } from 'helper/cusHooks';
import includes from 'lodash/includes';
import { actionsType } from 'components/layouts/redux/leftMenu';
import { RowHeader, TitleHeader, WrapperButton } from 'components/commons/Template/style';
import Button, { TypeCustom, TypeSizeCustom } from 'components/commons/Button';
import ModalConfirm from 'components/commons/modalConfirm';
import { useEffectOnce } from 'react-use';
import { CReloadButton } from 'components/commons/ReloadButton';
import CustomSearch from 'components/commons/Search';
import { StyledCSelect } from '../page/style';

function Header() {
  const dispatch = useDispatch();
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const valueSearch = useSelector(valueSearchSelector);
  const params = useSelector(paramsSelector);
  const listRole = useRolesByRouter();
  const intl = useIntl();

  const optionCustomerType = [
    { label: intl.formatMessage({ id: 'instructorManagement.all' }), value: 2 },
    { label: intl.formatMessage({ id: 'instructorManagement.working' }), value: 1 },
    { label: intl.formatMessage({ id: 'instructorManagement.quitted' }), value: 0 },
  ];

  const handleAddRole = () => {
    dispatch(setTypeModal(ActionType.ADD));
  };

  useEffectOnce(() => {
    dispatch(startGetListLicense());
    dispatch(getListInstructorFull());
  });

  const handleDeleteMuti = () => {
    ModalConfirm({
      title: 'common.confirmDelete',
      message: 'common.relatedDataDeleted',
      handleConfirm: () => {
        dispatch(deleteListInstructor(selectedRowKeys as string[]));
        dispatch(setSelectedRowKeys([]));
      },
    });
  };

  const handleSearch = (values: string) => {
    dispatch(
      updateSearch({
        q: values,
        page: 0,
      })
    );
  };

  const handleRefresh = () => {
    dispatch(
      updateSearch({
        page: 0,
        size: 10,
        q: '',
        status: 2,
      })
    );
    dispatch(setValueSearch(''));
  };

  const setValueQS = (value: string) => {
    dispatch(setValueSearch(value));
  };

  const handleChangeStatus = (value: any) => {
    dispatch(
      updateSearch({
        ...params,
        status: value,
      })
    );
  };

  return (
    <>
      <TitleHeader>
        <FormattedMessage id="layout.leftMenu.teacherManager" />
      </TitleHeader>
      <RowHeader>
        <WrapperButton>
          <CustomSearch
            tooltip={intl.formatMessage({ id: 'instructorManagement.tooltipQS' })}
            onSearch={handleSearch}
            value={valueSearch}
            setValue={setValueQS}
          />
          <CReloadButton onClick={handleRefresh} />
          <StyledCSelect
            options={optionCustomerType}
            value={params.status}
            placeholder={intl.formatMessage({ id: 'instructorManagement.all' })}
            onChange={handleChangeStatus}
          />
        </WrapperButton>
        <WrapperButton>
          {includes(listRole, actionsType.DELETE) && (
            <Button
              sizeCustom={TypeSizeCustom.Medium}
              typeCustom={TypeCustom.Secondary}
              activeCustom={selectedRowKeys.length ? true : false}
              disabledCustom={selectedRowKeys.length ? false : true}
              onClick={handleDeleteMuti}
            >
              <FormattedMessage id="common.delete" />
            </Button>
          )}
          {includes(listRole, actionsType.CREATE) && (
            <Button sizeCustom={TypeSizeCustom.Medium} typeCustom={TypeCustom.Primary} icon={<PlusOutlined />} onClick={handleAddRole}>
              <FormattedMessage id="common.add" />
            </Button>
          )}
        </WrapperButton>
      </RowHeader>
    </>
  );
}

export default Header;
