import { NotificationSuccess } from 'components/notifications/index';
import { NotificationError } from 'components/notifications';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getListManagement,
  createManagementStart,
  updateManagement,
  deleteManagement,
  deleteMutiManagement,
  getDetailManagement,
  getListManagementSuccess,
  getListManagementFail,
  getListManagementFailDefault,
  getDetailManagementSuccess,
  createManagementSuccess,
  createManagementFail,
  updateManagementSuccess,
  updateManagementFail, deleteManagementSuccess, deleteManagementFail, deleteMutiManagementFail,
} from '../redux/index';
import {
  createManagement, deleteManagementById, deleteMutiManagementById,
  getDetail,
  getListManagementByQS, updateManagementById
} from "../services";
import {ManagementObjectType, ParamsType} from "../type";
import {paramsSelector} from "../selector";

function* getListManagementByQuickSearch(action: ReturnType<typeof getListManagement>): any {
  try {
    const res = yield getListManagementByQS(action?.payload);
    yield put(getListManagementSuccess(res));
  } catch (e: any) {
    yield put(getListManagementFail());
    NotificationError(e);
  }
}

function* handleCreateManagement(action: ReturnType<typeof createManagementStart>) {
  try {
    yield createManagement({ ...action.payload.data });
    const params: ParamsType = yield select(paramsSelector);
    yield put(createManagementSuccess());
    yield put(getListManagement(params));
    NotificationSuccess('common.addSuccess',  { value: 'managementAgency.title' });
  } catch (e: any) {
    if (e?.fieldErrors) {
      action.payload.setFieldError(e?.fieldErrors);
    } else {
      NotificationError(e);
    }
    yield put(createManagementFail())
  }
}
function* handleViewManagement(action: ReturnType<typeof getDetailManagement>): any {
  try {
    const res: ManagementObjectType = yield getDetail(action.payload);
    yield put(getDetailManagementSuccess(res));
  } catch (e: any) {
    yield put(getListManagementFailDefault());
    NotificationError(e);
  }
}

function* handleUpdateManagement(action: ReturnType<typeof updateManagement>) {
  try {
    yield updateManagementById({ ...action?.payload });
    yield put(updateManagementSuccess());
    NotificationSuccess('common.updateSuccess',  { value: 'managementAgency.title' })
    const params: ParamsType = yield select(paramsSelector);
    yield put(getListManagement(params));
  } catch (e: any) {
    if (e?.fieldErrors) {
      action.payload.setFieldError(e?.fieldErrors);
    } else {
      NotificationError(e);
    }
    yield put(updateManagementFail())
  }
}

function* handleDeleteManagement(action: ReturnType<typeof deleteManagement>): any {
  try {
    yield deleteManagementById(action.payload);
    yield put(deleteManagementSuccess());
    NotificationSuccess('common.deleteSuccess',  { value: 'managementAgency.title' });
    const params: ParamsType = yield select(paramsSelector);
    yield put(getListManagement(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteManagementFail());
  }
}

function* handleDeleteMutiManagement(action: ReturnType<typeof deleteMutiManagement>) {
  try {
    yield deleteMutiManagementById(action?.payload);
    const params: ParamsType = yield select(paramsSelector);
    yield put(deleteManagementSuccess());
    yield put(getListManagement(params));
    NotificationSuccess('common.deleteSuccess', { value: 'managementAgency.title' });
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteMutiManagementFail());
  }
}

export default function* authSaga(): any {
  return all([
    yield takeLatest(getListManagement, getListManagementByQuickSearch),
    yield takeLatest(createManagementStart, handleCreateManagement),
    yield takeLatest(getDetailManagement, handleViewManagement),
    yield takeLatest(updateManagement, handleUpdateManagement),
    yield takeLatest(deleteManagement, handleDeleteManagement),
    yield takeLatest(deleteMutiManagement, handleDeleteMutiManagement),
  ]);
}
