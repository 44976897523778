import { Text } from 'components/commons/Template/style';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import CTable from 'components/commons/Table';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { courseManagerStateSelector } from '../selector';
import { setParamsLessons, getDetailLessons } from '../redux';
import { useEffect } from 'react';

const Grid = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { lessons, paramsLesson, loadingLessons } = useSelector(courseManagerStateSelector);

  useEffect(() => {
    dispatch(getDetailLessons())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsLesson])

  useEffect(() => {
    return () => {
      dispatch(setParamsLessons({ page: 0, size: 10, id: '' }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      align: 'left',
      width: 60,
      fixed: 'left',
      render(value, _, index) {
        return <Text>{index + 1 + paramsLesson.page * paramsLesson.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'course.sessionTraining' }),
      dataIndex: 'code',
      width: 130,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.student' }),
      dataIndex: 'student',
      width: 155,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.teacher' }),
      dataIndex: 'instructor',
      width: 155,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'course.carPratice' }),
      dataIndex: 'vehicle',
      width: 120,
      align: 'left',
      render(value, record) {
        return (
          <Text>{value}</Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.startTime' }),
      dataIndex: 'startTime',
      align: 'left',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.endTime' }),
      dataIndex: 'endTime',
      width: 160,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={moment(value).format('DD/MM/YYYY HH:mm:ss')} placement="topLeft">
            <Text>{moment(value).format('DD/MM/YYYY')}</Text>
          </Tooltip>
        );
      },
    },
  ];

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParamsLessons({
        ...paramsLesson,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  return (
    <CTable
      columns={columns}
      loading={loadingLessons}
      rowKey={'code'}
      dataSource={lessons?.content}
      scroll={{ y: 323 }}
      style={{height: '440px'}}
      onChange={handleChangeTable}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30],
        current: paramsLesson.page + 1,
        pageSize: paramsLesson.size,
        total: lessons?.totalElements,
        size: 'small',
        showTotal: TotalMessage,
        showQuickJumper: true,
      }}
    />
  );
};

export const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;

