import { all, put, select, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  createDevice,
  createDeviceFail,
  handleCreateWareHouseFail,
  createDeviceSuccess,
  getListDevice,
  getListDeviceFail,
  getListDeviceSuccess,
  getListWareHouse,
  createWareHouse,
  getDetailDevice,
  getDetailDeviceFail,
  getDetailDeviceSuccess,
  getListWareHouseSuccess,
  updateDevice,
  updateDeviceSuccess,
  updateDeviceFail,
  deleteDevice,
  deleteDeviceFail,
  deleteDeviceSuccess,
  getListDeviceParam,
  getListDeviceParamSuccess,
  getListDeviceParamFail,
  setShowModal,
  resetOtp,
  setShowOtpSection,
  deleteDeviceWareHouse,
  setTimeCountDownOtp,
  createWareHouseSuccess,
} from '../redux';
import {
  createDeviceService,
  deleteDeviceService,
  getDetailDeviceService,
  getListDeviceByParams,
  updateDeviceService,
  getListDeviceParamService,
  deleteAddDeviceService,
  createWareHouseService,
  getListWareHouseByParams,
} from '../services';
import { NotificationError, NotificationSuccess, NotificationErrorCustom } from 'components/notifications';
import { paramsSelector } from '../selector';
import { DataPayloadCreateDevice, ParamsPayloadType } from '../type';
import { END, eventChannel } from 'redux-saga';
import { connectSse } from 'core/sse';
import moment from 'moment';

function waitForConfirmation(payload: DataPayloadCreateDevice) {
  const { serialNumber, timeCountDown } = payload;
  const timeOtp = Number(timeCountDown);
  return eventChannel(emitter => {
    const es = connectSse(`/api/driving-devices/notification/${serialNumber}`);
    es.onopen = () => {
      // eslint-disable-next-line no-console
      console.info('Open SSE connection');
    };
    es.onmessage = me => {
      const data = JSON.parse(me.data);
      // eslint-disable-next-line no-console
      console.log('Message from SSE: ', data);
      if (data.isSuccess === true) {
        NotificationSuccess('device.addSuccess');
        es.close();
        emitter('DONE');
      } else if (data.isSuccess === false) {
        if (data.code === 'error.device.serial.registered.in.cloud') {
          emitter('DEVICE_REGISTERED');
        } else if (data.code === 'error.otp.invalid') {
          NotificationErrorCustom('device.otpIncorrect');
        } else NotificationErrorCustom('device.registerFailed');
      } else {
        // eslint-disable-next-line no-console
        console.log('Unknown message');
      }
      return null;
    };
    es.onerror = evt => {
      console.error('Error while connect to SSE', evt);
    };
    const timeout = setTimeout(() => {
      es.close();
      emitter(END);
    }, timeOtp * 1000); // end after 60s
    // The subscriber must return an unsubscribe function
    return () => {
      clearTimeout(timeout);
    };
  });
}

function* handleCreateDeviceSuccess(action: ReturnType<typeof createDeviceSuccess>): any {
  yield put(setTimeCountDownOtp(moment().add(action.payload.timeCountDown, 'seconds')));
  const channelNotification = yield call(waitForConfirmation, action.payload);
  yield takeEvery(channelNotification, function* (event: string) {
    if (event === 'DEVICE_REGISTERED') {
      yield put(setShowOtpSection(false));
      yield put(resetOtp());
    }
    if (event === 'DONE') {
      yield put(setShowModal(false));
      yield put(resetOtp());
      yield put(setShowOtpSection(false));
      const params: ParamsPayloadType = yield select(paramsSelector);
      yield put(getListDevice(params));
    }
  });
}

function* handleCreateDevice(action: ReturnType<typeof createDevice>): any {
  try {
    const res = yield createDeviceService(action?.payload);
    yield put(createDeviceSuccess({ ...res, timeCountDown: action.payload.timeCountDown }));
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(createDeviceFail());
  }
}

function* handleCreateWareHouse(action: ReturnType<typeof createWareHouse>): any {
  try {
    const res = yield createWareHouseService(action?.payload);
    yield put(createWareHouseSuccess(res));
    NotificationSuccess('device.updateSuccess');
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(handleCreateWareHouseFail(e?.fieldErrors));
  }
}

function* handleUpdateDevice(action: ReturnType<typeof updateDevice>): any {
  try {
    yield updateDeviceService(action?.payload);
    yield put(updateDeviceSuccess());
    const params = yield select(paramsSelector);
    yield put(getListDevice(params));
    NotificationSuccess('device.updateSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(updateDeviceFail());
  }
}

function* handleGetListDevice(action: ReturnType<typeof getListDevice>): any {
  try {
    const res = yield getListDeviceByParams(action?.payload);
    yield put(getListDeviceSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListDeviceFail());
  }
}

function* handleGetListWareHouse(action: ReturnType<typeof getListWareHouse>): any {
  try {
    const res = yield getListWareHouseByParams(action?.payload);
    yield put(getListWareHouseSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListDeviceFail());
  }
}

function* handleGetDetailDevice(action: ReturnType<typeof getDetailDevice>): any {
  try {
    const res = yield getDetailDeviceService(action.payload);
    yield put(getDetailDeviceSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getDetailDeviceFail());
  }
}

function* handleDeleteDevice(action: ReturnType<typeof deleteDevice>): any {
  try {
    yield deleteDeviceService(action.payload);
    yield put(deleteDeviceSuccess());
    NotificationSuccess('device.deleteSuccess');
    const params = yield select(paramsSelector);
    yield put(getListDevice(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteDeviceFail());
  }
}

function* handleDeleteWareHouse(action: ReturnType<typeof deleteDeviceWareHouse>): any {
  try {
    yield deleteAddDeviceService(action.payload);
    yield put(deleteDeviceSuccess());
    NotificationSuccess('device.deleteSuccess');
    const params = yield select(paramsSelector);
    yield put(getListWareHouse(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteDeviceFail());
  }
}

function* handleGetListDeviceParam(action: ReturnType<typeof getListDeviceParam>): any {
  try {
    const res = yield getListDeviceParamService();
    yield put(getListDeviceParamSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListDeviceParamFail());
  }
}


export default function* deviceManagerSaga(): any {
  return all([
    yield takeLatest(createDevice, handleCreateDevice),
    yield takeLatest(createWareHouse, handleCreateWareHouse),
    yield takeLatest(getListDevice, handleGetListDevice),
    yield takeLatest(getListWareHouse, handleGetListWareHouse),
    yield takeLatest(getDetailDevice, handleGetDetailDevice),
    yield takeLatest(updateDevice, handleUpdateDevice),
    yield takeLatest(deleteDevice, handleDeleteDevice),
    yield takeLatest(deleteDeviceWareHouse, handleDeleteWareHouse),
    yield takeLatest(getListDeviceParam, handleGetListDeviceParam),
    yield takeLatest(createDeviceSuccess, handleCreateDeviceSuccess),
  ]);
}


