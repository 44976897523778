import { all, put, takeLatest } from 'redux-saga/effects';
import { getListReportByPr, getListArea, exportListReportByPr } from '../service';
import {
  getListReportItem,
  getListReportItemFail,
  getListReportItemSuccess,
  getListAreaAction,
  getListAreaActionFail,
  getListAreaActionSuccess,
  exportReport,
  exportReportFail,
  exportReportSuccess,
  setTotal,
} from '../redux';
import { NotificationError } from 'components/notifications';
import moment from 'moment';

function* getListReportItemByParams(action: ReturnType<typeof getListReportItem>): any {
  try {
    const res = yield getListReportByPr(action.payload);
    yield put(getListReportItemSuccess(res.content));
    yield put(setTotal(res.totalElements));
  } catch (e: any) {
    yield put(getListReportItemFail());
    NotificationError(e);
  }
}

function* exportListReportItemByParams(action: ReturnType<typeof exportReport>): any {
  try {
    const res = yield exportListReportByPr(action.payload);
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = `Report_${moment().format('DD/MM/YYYY')}`;
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
    yield put(exportReportSuccess());
  } catch (e: any) {
    yield put(exportReportFail());
    NotificationError(e);
  }
}

function* getListAreaByName(): any {
  try {
    const res = yield getListArea();
    yield put(getListAreaActionSuccess(res.content));
  } catch (e: any) {
    yield put(getListAreaActionFail());
    NotificationError(e);
  }
}

export default function* reportSaga(): any {
  return all([
    yield takeLatest(getListReportItem, getListReportItemByParams),
    yield takeLatest(getListAreaAction, getListAreaByName),
    yield takeLatest(exportReport, exportListReportItemByParams),
  ]);
}
