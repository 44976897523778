import { menuItemSelect } from 'components/layouts/selector/leftMenuSelector';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import concat from 'lodash/concat';

export const useRolesByRouter = () => {
  const menuItem = useSelector(menuItemSelect);
  const arrMenuItem = flatMap(menuItem, ({ items }) => map(items, items => items));
  const location = useLocation();
  const pathname = location.pathname;
  const actions = concat(menuItem, arrMenuItem)?.filter(item => item.uri === pathname)?.[0]?.actions;
  return actions || [];
};
