import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .spin {
    margin-bottom: 20px;
  }
`;
