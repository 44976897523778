import HttpRequest from 'core/http/singleton/auth';
import { ParamsPayloadType } from '../type';

export const createStudentApi = async (data: any): Promise<any> => {
  const res = await HttpRequest.post<any>('api/driving-student', data);
  return res.data;
};

export const getListStudentByParams = async (params: ParamsPayloadType): Promise<any> => {
  const res = await HttpRequest.get<any>('api/search/drivingStudent', params);
  return res.data;
};

export const getDetailStudentApi = async (studentId: string): Promise<any> => {
  const res = await HttpRequest.get<any>(`api/driving-student/${studentId}`);
  return res.data;
};

export const updateStudentApi = async (data: any): Promise<any> => {
  const res = await HttpRequest.put<any>('api/driving-student', data);
  return res.data;
};

export const deleteStudentApi = async (data: string[]): Promise<any> => {
  const res = await HttpRequest.delete<any>('api/driving-student', {
    data: data,
  });
  return res.data;
};

export const getListCoursesApi = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/driving-student/course');
  return res.data;
};

export const getListLicenseApi = async (): Promise<any> => {
  const res = await HttpRequest.get<any>('api/driving-student/license');
  return res.data;
};

export const postUploadFile = async (file: string[]): Promise<any> => {
  const res = await HttpRequest.post<any>('api/file/upload/student-avatar', file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  if (!res || !res.data) throw new Error('Opps');
  return res.data;
};
