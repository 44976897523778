import styled from 'styled-components/macro';

export const StyledWrapSelect = styled.div`
  min-height: 36px;
  .ant-select {
    box-shadow: none !important;
  }
  .ant-select-selector {
    padding: 0 24px 0 4px;
    min-height: 36px;
    border: 1px solid ${({ theme }) => theme.strokeLineLight};

    input {
      height: 32px !important;
    }
  }

  .ant-select-selection-item {
    color: #2d394b !important;
    font-weight: 500;
    font-size: 13px;
  }

  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 13px;
    line-height: 32px !important;
    color: ${({ theme }) => theme.contentPlaceholder};
    opacity: 0.6;
  }

  .ant-select-selector > .ant-select-selection-item {
    line-height: 34px !important;
  }
`;
