import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  createAccount,
  createAccountFail,
  createAccountSuccess,
  getListAccount,
  getListAccountFail,
  getListAccountSuccess,
  getDetailAccount,
  getDetailAccountFail,
  getDetailAccountSuccess,
  updateAccount,
  updateAccountSuccess,
  updateAccountFail,
  deleteAccount,
  deleteAccountFail,
  deleteAccountSuccess,
  getListGroupUser,
  getListGroupUserSuccess,
  getListGroupUserFail,
  getListRoles,
  getListRolesSuccess,
  getListRolesFail,
  pushListRoleStatus0,
  pushListGroupStatus0,
} from '../redux';
import { createUser, deleteUser, getDetailUser, getListUserByParams, getListUserGroupByPr, updateUser } from '../services';
import { NotificationError, NotificationSuccess } from 'components/notifications';
import { paramsSelector } from '../selector';
import { getListRoleByPr } from 'modules/role/services';
import { roleItem, groupItem } from '../type';

function* handleCreateAccount(action: ReturnType<typeof createAccount>): any {
  try {
    yield createUser(action?.payload);
    yield put(createAccountSuccess());
    const params = yield select(paramsSelector);
    yield put(getListAccount(params));
    NotificationSuccess('accountManager.addSuccess');
  } catch (e: any) {
    if (e?.fieldErrors?.length) {
      e?.fieldErrors?.forEach((item: any) => {
        action.payload.setFields(item?.field, item?.message);
      });
    } else {
      NotificationError(e);
    }
    yield put(createAccountFail());
  }
}

function* handleUpdateAccount(action: ReturnType<typeof updateAccount>): any {
  try {
    yield updateUser(action?.payload);
    yield put(updateAccountSuccess());
    const params = yield select(paramsSelector);
    yield put(getListAccount(params));
    NotificationSuccess('accountManager.updateSuccess');
  } catch (e: any) {
    NotificationError(e);
    yield put(updateAccountFail());
  }
}

function* handleGetListUser(action: ReturnType<typeof getListAccount>): any {
  try {
    const res = yield getListUserByParams(action?.payload);
    yield put(getListAccountSuccess(res));
  } catch (e: any) {
    NotificationError(e);
    yield put(getListAccountFail());
  }
}

function* handleGetDetailUser(action: ReturnType<typeof getDetailAccount>): any {
  try {
    const res = yield getDetailUser(action.payload);
    yield put(getDetailAccountSuccess(res));
    let listOptionRole: roleItem[] = [];
    let listOptionGroup: groupItem[] = [];

    res.roles?.forEach((item: roleItem) => {
      if (item.roleStatus === 0) {
        listOptionRole.push(item);
      }
    })

    res.groups?.forEach((item: groupItem) => {
      if (item.groupStatus === 0) {
        listOptionGroup.push(item);
      }
    })

    yield put(pushListRoleStatus0(listOptionRole));
    yield put(pushListGroupStatus0(listOptionGroup));
  } catch (e: any) {
    NotificationError(e);
    yield put(getDetailAccountFail());
  }
}

function* handleDeleteUser(action: ReturnType<typeof deleteAccount>): any {
  try {
    yield deleteUser(action.payload);
    yield put(deleteAccountSuccess());
    NotificationSuccess('accountManager.deleteSuccess');
    const params = yield select(paramsSelector);
    yield put(getListAccount(params));
  } catch (e: any) {
    NotificationError(e);
    yield put(deleteAccountFail());
  }
}

function* getListGroupByParams(action: ReturnType<typeof getListGroupUser>): any {
  try {
    const response = yield getListUserGroupByPr(action.payload);
    yield put(getListGroupUserSuccess(response?.content));
  } catch (err: any) {
    NotificationError(err);
    yield put(getListGroupUserFail());
  }
}

function* getListRoleByParams(action: ReturnType<typeof getListRoles>): any {
  try {
    const res = yield getListRoleByPr(action?.payload);
    yield put(getListRolesSuccess(res?.content));
  } catch (e: any) {
    yield put(getListRolesFail());
    NotificationError(e);
  }
}

export default function* accountManagerSaga(): any {
  return all([
    yield takeLatest(createAccount, handleCreateAccount),
    yield takeLatest(getListAccount, handleGetListUser),
    yield takeLatest(getDetailAccount, handleGetDetailUser),
    yield takeLatest(updateAccount, handleUpdateAccount),
    yield takeLatest(deleteAccount, handleDeleteUser),
    yield takeLatest(getListGroupUser, getListGroupByParams),
    yield takeLatest(getListRoles, getListRoleByParams),
  ]);
}
