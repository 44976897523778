import { Form } from 'antd';
import { loginStart, resetErrDetail } from 'modules/auth/redux';
import { errDetailSelector, loadingButtonLoginSelector } from 'modules/auth/selectors';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalClient from './ModalClient';
import Title from 'antd/es/typography/Title';
import { ErrorDetail, ForgotPassword } from '../ModalLogin/styles';
import { TypeCustom } from 'components/commons/Button';
import ModalInputEmail from 'modules/forgotPass/page/components/ModalInputEmail';
import { setShowModalInputEmail } from 'modules/forgotPass/redux';
import FormVerify from 'modules/forgotPass/page/components/FormVerify';
import CInput from 'components/commons/Input';
import CButton from 'components/commons/Button';
import CInputPassword from 'components/commons/InputPass';
import { RegValidEmail } from 'helper/constants';

const FormLogin = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const loadingButtonLogin = useSelector(loadingButtonLoginSelector);
  const errDetail = useSelector(errDetailSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const onFinish = (values: any) => {
    dispatch(
      loginStart({
        username: values.username.trim(),
        password: values.password.trim(),
        history: history,
      })
    );
  };
  const handleBlur = (e: any, field: string) => {
    form.setFieldValue(field, e.target.value.trim());
    form.validateFields([field]);
  };

  const handlePates = async (e: any, field: string) => {
    setTimeout(() => {
      const value = e.target.value;
      if (value) {
        form.setFieldValue(field, value.trim());
        form.validateFields([field]);
      }
    }, 100);
  };

  return (
    <>
      <Title level={4}>
        <FormattedMessage id={'auth.login'} />
      </Title>
      <Form form={form} layout="vertical" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
        <Form.Item
          label={intl.formatMessage({ id: 'auth.labelUser' })}
          name="username"
          rules={[
            { required: true, message: intl.formatMessage({ id: 'auth.labelUser' }) + intl.formatMessage({ id: 'validator.require' }) },
            {
              validator: (_, value) =>
                !value || RegValidEmail.test(value)
                  ? Promise.resolve()
                  : Promise.reject(intl.formatMessage({ id: 'auth.labelUser' }) + intl.formatMessage({ id: 'validator.errFormat' })),
            },
          ]}
        >
          <CInput
            onBlur={(e: any) => {
              handleBlur(e, 'username');
            }}
            onPaste={(e: any) => {
              handlePates(e, 'username');
            }}
            className="login-form__input"
            placeholder={intl.formatMessage({ id: 'auth.placeholderEmail' })}
            maxLength={255}
          />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({ id: 'auth.pass' })}
          name="password"
          rules={[{ required: true, message: <FormattedMessage id={'validator.requirePass'} /> }]}
        >
          <CInputPassword
            onPaste={(e: any) => {
              handlePates(e, 'password');
            }}
            className="login-form__input"
            placeholder={intl.formatMessage({ id: 'auth.placeholderPass' })}
            maxLength={30}
          />
        </Form.Item>

        <ForgotPassword>
          <div
            onClick={() => {
              dispatch(setShowModalInputEmail(true));
            }}
          >
            <FormattedMessage id={'auth.forgotPassword'} />
          </div>
        </ForgotPassword>

        <Form.Item>
          <CButton
            typeCustom={TypeCustom.Primary}
            size="large"
            block
            onClick={() => {
              dispatch(resetErrDetail());
            }}
            loading={loadingButtonLogin}
            htmlType="submit"
            className="btn-submit"
          >
            <FormattedMessage id={'auth.login'} />
          </CButton>
        </Form.Item>
        {errDetail && <ErrorDetail>{errDetail}</ErrorDetail>}
      </Form>

      <ModalClient />
      <ModalInputEmail />
      <FormVerify />
    </>
  );
};

export default FormLogin;
