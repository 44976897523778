import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetListVehicleRequest,
  ContentItem,
  roleItem,
  GetListVehicleResponse,
  VehicleItem,
  ItemEdit,
  DeviceType,
} from 'modules/vehicleManagement/api/types';
import { ActionType } from 'helper/constants';
import { fieldErrorsItem } from 'modules/auth/redux/type';

export interface VehicleManagementState {
  params: GetListVehicleRequest;
  detailVehicle: any;
  loadingTable: boolean;
  data: ContentItem[];
  totalElements: number;
  listRole: roleItem[];
  listRoleStatus0: roleItem[];
  typeModal: ActionType | null;
  itemEdit: ItemEdit;
  listDevice: DeviceType;
  listVehicleStatus0: VehicleItem[];
  selectedRowKeys: React.Key[];
  fieldErrors: fieldErrorsItem[];
  valueSearch: string;
  valueDetailId: string;
  loadingBtnModal: boolean;
  loadingModal: boolean;
}

const initialState: VehicleManagementState = {
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  detailVehicle: {},
  loadingTable: false,
  data: [],
  totalElements: 0,
  listRole: [],
  listRoleStatus0: [],
  typeModal: null,
  itemEdit: {
    id: null,
    licensePlate: '',
    vehicleLicense: '',
    ownerName: '',
    ownerPhoneNumber: '',
    note: '',
    classification: '',
    brand: '',
    device: {
      id: '',
      code: '',
    },
    user: [],
  },
  listDevice: {
    devices: []
  },
  listVehicleStatus0: [],
  selectedRowKeys: [],
  fieldErrors: [],
  valueSearch: '',
  valueDetailId: '',
  loadingBtnModal: false,
  loadingModal: false,
};

const vehicleManagementSlice = createSlice({
  name: 'vehicleManagementSlice',
  initialState,
  reducers: {
    resetSearch: state => {
      state.params = initialState.params;
      state.selectedRowKeys = initialState.selectedRowKeys;
      state.valueSearch = initialState.valueSearch;
    },
    updateSearch: (state, action: PayloadAction<object>) => {
      state.params = { ...state.params, ...action.payload } || {};
      state.loadingTable = true;
    },
    getDetailVehicle: (state, action: PayloadAction<string>) => {
      state.loadingModal = true;
    },

    getDetailVehicleSuccess: (state, action: PayloadAction<any>) => {
      state.itemEdit = action.payload;
      state.loadingModal = false;
    },

    getDetailVehicleFail: state => {
      state.loadingModal = false;
    },

    getListVehicleSuccess: (state, action: PayloadAction<GetListVehicleResponse>) => {
      state.data = action.payload.content;
      state.loadingTable = initialState.loadingTable;
      state.totalElements = action.payload.totalElements;
    },

    getListUserFail: state => {
      state.data = initialState.data;
      state.loadingTable = initialState.loadingTable;
    },
    getListRoleSuccess: (state, action: PayloadAction<roleItem[]>) => {
      state.listRole = action.payload;
    },

    getListVehicleFull: state => {
      state.loadingTable = true;
    },
    setTypeModal: (state, action: PayloadAction<ActionType | null>) => {
      if (action.payload === null) {
        state.itemEdit = initialState.itemEdit;
      }
      state.typeModal = action.payload;
      state.fieldErrors = initialState.fieldErrors;
    },
    updateItemEdit: (state, action: PayloadAction<object>) => {
      state.itemEdit = { ...state.itemEdit, ...action.payload } || {};
    },
    startGetListDevice: () => {},
    getListDeviceSuccess: (state, action: PayloadAction<DeviceType>) => {
      state.listDevice = action.payload;
    },
    pushListVehicleNameStatus0: (state, action: PayloadAction<VehicleItem[]>) => {
      state.listVehicleStatus0 = action.payload;
    },
    createVehicle: (state, action: PayloadAction<ItemEdit>) => {
      state.loadingBtnModal = true;
    },
    createVehicleOk: state => {
      state.loadingBtnModal = false;
    },
    createVehicleFail: state => {
      state.loadingBtnModal = false;
    },
    editVehicle: (state, action: PayloadAction<ItemEdit>) => {
      state.loadingBtnModal = true;
    },
    editVehicleOk: state => {
      state.loadingBtnModal = false;
    },
    editVehicleFail: state => {
      state.loadingBtnModal = false;
    },
    deleteListVehicle: (state, action: PayloadAction<string[]>) => {},
    setSelectedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
      state.selectedRowKeys = action.payload;
    },
    setFieldErrors: (state, action: PayloadAction<fieldErrorsItem[]>) => {
      state.fieldErrors = action.payload;
    },
    setValueSearch: (state, action: PayloadAction<string>) => {
      state.valueSearch = action.payload;
    },
  },
});

export const {
  resetSearch,
  updateSearch,
  getListVehicleSuccess,
  getListUserFail,
  getListRoleSuccess,
  getListVehicleFull,
  setTypeModal,
  updateItemEdit,
  startGetListDevice,
  getListDeviceSuccess,
  pushListVehicleNameStatus0,
  createVehicle,
  editVehicle,
  deleteListVehicle,
  setSelectedRowKeys,
  setFieldErrors,
  setValueSearch,
  getDetailVehicle,
  getDetailVehicleSuccess,
  getDetailVehicleFail,
  createVehicleFail,
  createVehicleOk,
  editVehicleFail,
  editVehicleOk,
} = vehicleManagementSlice.actions;

export default vehicleManagementSlice.reducer;
