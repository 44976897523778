import React from 'react';
import { Bar, ColumnConfig } from '@ant-design/plots';
import { StyledTooltip } from '../page/styles';
import { orderBy } from 'lodash';

interface Props {
  sort: 'asc' | 'desc';
}
const ChartEvent = ({ sort }: Props) => {
  const data = [
    {
      type: 'Nghe điện thoại',
      sales: 233,

      bgColor: '#03045E',
    },
    {
      type: 'Không thắt dây an toàn',
      sales: 190,

      bgColor: '#023E8A',
    },
    {
      type: 'Không có tài xế',
      sales: 160,

      bgColor: '#0077B6',
    },
    {
      type: 'Mất tập trung',
      sales: 145,

      bgColor: '#0096C7',
    },
    {
      type: 'Thay đổi tài xế',
      sales: 130,

      bgColor: '#00B4D8',
    },
    {
      type: 'Tăng tốc đột ngột',
      sales: 120,

      bgColor: '#48CAE4',
    },
    {
      type: 'Phanh gấp',
      sales: 70,

      bgColor: '#7CE2F8',
    },
    {
      type: 'Đâm đụng',
      sales: 68,

      bgColor: '#BBF1FC',
    },
  ];
  const config: ColumnConfig = {
    data: orderBy(data, ['sales'], [sort]),
    xField: 'sales',
    yField: 'type',
    seriesField: 'bgColor',
    legend: false,

    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
    color: data => {
      return data.bgColor;
    },
    yAxis: {
      label: {
        style: { fill: 'black' },
      },
      line: {
        style: {
          opacity: 0,
        },
      },
      tickLine: {
        length: 0,
      },
    },

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
      line: {
        style: {
          opacity: 0,
        },
      },
      grid: {
        line: { style: { fill: '#f7f7f7', opacity: 0.3 } },
      },
      tickLine: {
        length: 0,
      },
      title: {
        text: 'Số lượng',
        position: 'left',
        offset: 13,
        style: {
          x: 98,
          fill: '#004193',
          fontWeight: 'bold',
        },
      },
    },
    tooltip: {
      customContent(title, data) {
        return (
          <StyledTooltip>
            <p>{data[0]?.data?.sales}</p> <span>Sự kiện</span>
          </StyledTooltip>
        );
      },
    },
  };
  return <Bar {...config} style={{ height: '30.5vh', marginTop: 10 }} />;
};

export default ChartEvent;
