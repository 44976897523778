import { Text } from 'components/commons/Template/style';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import CTable from 'components/commons/Table';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { itemDataSelector } from '../selector';
import { allParamSelector } from '../../auth/selectors';

const Grid = () => {
  const intl = useIntl();
  const { students } = useSelector(itemDataSelector);
  const { PERSON_SEX, TRAINING_TYPE } = useSelector(allParamSelector);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      align: 'left',
      width: 60,
      fixed: 'left',
      render(value, _, index) {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.name' }),
      dataIndex: 'fullName',
      width: 140,
      align: 'left',
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'course.cccd' }),
      dataIndex: 'citizenIdCardNo',
      width: 140,
      align: 'left',
      render(value, record) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'course.typeTraining' }),
      dataIndex: 'trainingType',
      width: 150,
      align: 'left',
      render(value, record) {
        return <Text>{TRAINING_TYPE.find((item: any) => item.value === String(value))?.label}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.gender' }),
      dataIndex: 'gender',
      width: 80,
      align: 'left',
      render(value, record) {
        return <Text>{PERSON_SEX.find((item: any) => item.value === String(value))?.label}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.dateBirth' }),
      dataIndex: 'birthday',
      align: 'left',
      width: 100,
      render(value, record, index) {
        return <Text>{value ? moment(value).format('DD/MM/YYYY') : ''} </Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.phoneNumber' }),
      dataIndex: 'phoneNo',
      width: 120,
      align: 'left',
      render(value, record) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'profile.email' }),
      dataIndex: 'email',
      width: 140,
      align: 'left',
      render(value, record) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <CTable
      columns={columns}
      rowKey={'id'}
      dataSource={students}
      scroll={{ y: 176 }}
      style={{ height: '216px' }}
      pagination={false}
      size="small"
    />
  );
};

export const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
