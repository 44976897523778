import { PasswordProps } from 'antd/es/input';
import React, { FC } from 'react';
import { StyledInputPass } from './style';
import { Form } from 'antd';

interface Props extends PasswordProps {
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
}

const CInputPassword: FC<Props> = React.memo(({ id, onBlur, ...rest }) => {
  const form = Form.useFormInstance();
  const field = id as string;
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    } else {
      form.validateFields([field]);
    }
  };
  return <StyledInputPass {...rest} autoComplete="new-password" onBlur={handleBlur}></StyledInputPass>;
});

export default CInputPassword;
