import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
`;

export const Body = styled.div`
  padding: 0 12px;
`;

export const Title = styled.div`
  font-size: 1.14285em;
  font-weight: 500;
  color: #344254;
`;
export const Time = styled.div`
  font-size: 0.85714em;
`;

export const TimeDuration = styled.div`
  color: #9b9b9b;
`;

export const Content = styled.div`
  font-size: 1em;
  color: ${({ theme }) => theme.titleDefault} !important;
  margin-bottom: 3px;
`;

export const CardMapWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 0px 12px;
  background-color: white;
  height: 68px;
  z-index: 99;
  gap: 12px;
  justify-content: space-between;
`;
