import { timeCountDownOtpSelector } from '../selector';
import moment from 'moment';
import numeral from 'numeral';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

const TimeCountDown = () => {
  const timeCount = useSelector(timeCountDownOtpSelector);
  const [time, setTime] = useState(moment());
  useEffectOnce(() => {
    const time = setInterval(() => {
      setTime(moment());
    }, 1000);
    return () => clearTimeout(time);
  });
  const text = numeral(moment(timeCount).format('x')).subtract(moment(time).format('x')).divide(1000).format('0,0');
  return <>{Number(text) > 0 ? text : 0}s</>;
};

export default TimeCountDown;
