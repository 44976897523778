import { TitleHeader } from 'components/commons/Template/style';
import { FormattedMessage } from 'react-intl';
import { Wrapper } from './styles';
import Grid from '../components/Grid';


const StudentPage = () => {
  return (
    <Wrapper id="wrapperStudentsManager">
      <TitleHeader id="filterStudentsManager">
        <FormattedMessage id="layout.leftMenu.student" />
      </TitleHeader>
      <Grid />
    </Wrapper>
  );
};

export default StudentPage;
