import styled from 'styled-components/macro';

export const Wrapper = styled('div')<{ $isDetail: boolean }>`
  width: 100%;
  height: ${({ $isDetail }) => (!$isDetail ? '100%' : 'calc(100% - 90px)')};
  display: flex;
  background: ${({ theme }) => theme.tableSelectedActive};
  padding: 10px;
  border-radius: 12px;
  margin-top: ${({ $isDetail }) => (!$isDetail ? 'initial' : '12px')};
`;

export const LaneColor = styled.div`
  width: 14px;
  background-color: #fff;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  flex-direction: column;
`;
