import Typography from 'antd/es/typography';
import styled from 'styled-components/macro';

export const Text = styled(Typography.Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: ${({ theme }) => theme.contentTable};
  cursor: revert !important;
  white-space: pre;
`;

export const TextLink = styled(Typography.Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: ${({ theme }) => theme.primary};
  cursor: pointer !important;
  white-space: pre;
`;

export const StyledTextDesc = styled(Text)`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const RowButton = styled.div`
  display: flex;
  gap: 12px;
  justify-content: end;

  button {
    min-width: 120px;
  }
`;

export const TitleHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: ${({ theme }) => theme.titleDefault};
  margin-bottom: 30px;
`;

export const RowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const WrapperButton = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
