import { RouterConfig } from 'router/AppRoute';
import paths from 'helper/pathRoutes';
import PracticeReportPage from './page';

const routes: RouterConfig[] = [
  {
    path: paths.practiceReport,
    page: PracticeReportPage,
  },
];

export default routes;
