import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataCreateUpdateRoleType, ParamsType, ResListRole, RoleDefaultType, RoleObjectType } from '../type';

export interface RoleState {
  loadingTable: boolean;
  loadingModal: boolean;
  loadingButtonSave: boolean;
  listRole: any;
  listRoleDefault: any[];
  showModalAddEdit: boolean;
  params: ParamsType;
  itemEdit: any;
  typeModal: string;
  openFilter: boolean;
  qsValue: string;
}

const initialState: RoleState = {
  loadingTable: false,
  loadingModal: false,
  loadingButtonSave: false,
  listRole: [],
  listRoleDefault: [],
  showModalAddEdit: false,
  params: {
    page: 0,
    size: 10,
    q: '',
  },
  itemEdit: {},
  typeModal: '',
  openFilter: false,
  qsValue: '',
};

const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {
    //Action lấy danh sách role
    getListRole: (state, action: PayloadAction<ParamsType>) => {
      state.loadingTable = true;
    },

    getListRoleSuccess: (state, action: PayloadAction<ResListRole>) => {
      state.loadingTable = false;
      state.listRole = action.payload;
    },
    getListRoleFail: state => {
      state.loadingTable = false;
    },

    //Action setParams
    setParams: (state, action: PayloadAction<ParamsType>) => {
      state.params = action.payload;
    },

    //Action show Modal
    setOpenModalAddEdit: (state, action: PayloadAction<boolean>) => {
      state.showModalAddEdit = action.payload;
    },

    //Action lấy danh sách role Defautlt
    getListRoleDefault: state => {
      state.loadingModal = true;
    },
    getListRoleSuccessDefault: (state, action: PayloadAction<RoleDefaultType[]>) => {
      state.loadingModal = false;
      state.listRoleDefault = action.payload;
    },
    getListRoleFailDefault: state => {
      state.loadingModal = false;
    },

    //Action tạo role
    createRoleStart: (state, action: PayloadAction<DataCreateUpdateRoleType>) => {
      state.loadingButtonSave = true;
    },
    createRoleSuccess: state => {
      state.loadingButtonSave = false;
      state.showModalAddEdit = false;
    },
    createRoleFail: state => {
      state.loadingButtonSave = false;
    },

    //setItem cho ModalAdd và ModalUpdate
    setItemEdit: (state, action: PayloadAction<RoleObjectType | {}>) => {
      state.itemEdit = action.payload;
    },

    //Get Detail một Role
    getDetailRole: (state, action: PayloadAction<string>) => {},
    getDetailRoleSuccess: (state, action: PayloadAction<any>) => {
      state.itemEdit = action.payload;
      state.showModalAddEdit = true;
    },

    //Update role
    updateRole: (state, action: PayloadAction<RoleObjectType>) => {
      state.loadingButtonSave = true;
    },
    updateRoleSuccess: state => {
      state.loadingButtonSave = false;
      state.showModalAddEdit = false;
    },
    updateRoleFail: state => {
      state.loadingButtonSave = false;
    },

    //DeleteRole
    deleteRole: (state, action: PayloadAction<string>) => {
      state.loadingTable = true;
    },
    deleteRoleSuccess: state => {
      state.loadingTable = false;
    },
    deleteRoleFail: state => {
      state.loadingTable = false;
    },

    //Delete MutiRole
    deleteMutiRole: (state, action: PayloadAction<string[]>) => {
      state.loadingTable = true;
    },
    deleteMutiRoleSuccess: state => {
      state.loadingTable = false;
    },
    deleteMutiRoleFail: state => {
      state.loadingTable = false;
    },
    setTypeModal: (state, action: PayloadAction<string>) => {
      state.typeModal = action.payload;
    },
    setOpenFilter: (state, action: PayloadAction<boolean>) => {
      state.openFilter = action.payload;
    },
    setQsValue: (state, action: PayloadAction<string>) => {
      state.qsValue = action.payload;
    },
  },
});

export const {
  getListRole,
  getListRoleFail,
  getListRoleSuccess,
  setParams,
  setOpenModalAddEdit,
  getListRoleDefault,
  getListRoleFailDefault,
  getListRoleSuccessDefault,
  createRoleStart,
  createRoleFail,
  createRoleSuccess,
  setItemEdit,
  getDetailRole,
  getDetailRoleSuccess,
  updateRole,
  updateRoleSuccess,
  updateRoleFail,
  deleteRole,
  deleteRoleFail,
  deleteRoleSuccess,
  deleteMutiRole,
  deleteMutiRoleFail,
  deleteMutiRoleSuccess,
  setTypeModal,
  setOpenFilter,
  setQsValue,
} = roleSlice.actions;

export default roleSlice.reducer;
