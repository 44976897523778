import { ColumnsType } from 'antd/es/table';
import { Text } from 'components/commons/Template/style';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { reportStudentSelector } from '../selector';
import { StudentReportItem } from '../type';
import { getDetail, setParams, setShowModal } from '../redux';
import Table from 'components/commons/Table';
import moment from 'moment';
import { WrapperActionTable } from 'modules/role/page/style';
import CButton, { TypeCustom } from 'components/commons/Button';
import { Tooltip } from 'antd';
import ModalDetail from './ModalDetail';
const Grid = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loading, data, params, showModal } = useSelector(reportStudentSelector);

  const columns: ColumnsType<StudentReportItem> = [
    {
      title: 'STT',
      align: 'left',
      fixed: 'left',
      width: 80,
      render(value, record, index) {
        return <Text>{index + 1 + params.page * params.size}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: 'studentReport.studentName' }),
      align: 'left',
      dataIndex: 'fullName',
      width: 200,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'instructorManagement.cccd' }),
      align: 'left',
      dataIndex: 'citizenIdCardNo',
      width: 150,
      render(value) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },

    {
      title: intl.formatMessage({ id: 'common.dateBirth' }),
      align: 'left',
      dataIndex: 'birthday',
      width: 200,
      render(value, record, index) {
        return (
          <Tooltip title={value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''} placement="topLeft">
            <Text>{value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'studentReport.lessonCode' }),
      align: 'left',
      dataIndex: 'code',
      width: 200,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'course.tranningNetwork' }),
      align: 'center',
      dataIndex: 'licenseClass',
      width: 150,
      render(value, record, index) {
        return (
          <Tooltip title={value} placement="topLeft">
            <Text>{value}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'common.action' }),
      align: 'center',
      width: 150,
      fixed: 'right',
      render(value, record, index) {
        return (
          <WrapperActionTable>
            <CButton onClick={() => handleViewItem(record)} typeCustom={TypeCustom.Action}>
              {<FormattedMessage id={'common.detail'} />}
            </CButton>
          </WrapperActionTable>
        );
      },
    },
  ];

  const handleViewItem = (record: StudentReportItem) => {
    dispatch(setShowModal(true));
    dispatch(
      getDetail({
        idCourse: record.courseId,
        idStudent: record.studentId,
      })
    );
  };

  const changeHeightTable = () => {
    const heightFilter = document.getElementById('filterReportManager')?.offsetHeight;
    const heightWrapper = document.getElementById('wrapperReportManager')?.offsetHeight;

    return (heightWrapper ?? 0) - (heightFilter ? heightFilter + 18 : 0) - 155;
  };

  const handleChangeTable = (pagination: any) => {
    dispatch(
      setParams({
        ...params,
        page: pagination.current - 1,
        size: pagination.pageSize,
      })
    );
  };

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data.content}
        style={{ width: '100%' }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30],
          current: params.page + 1,
          pageSize: params.size,
          size: 'small',
          showQuickJumper: true,
          total: data.totalElements,
          showTotal: TotalMessage,
          locale: { jump_to: intl.formatMessage({ id: 'common.jump_to' }), page: '' },
        }}
        scroll={{ y: changeHeightTable() }}
        onChange={handleChangeTable}
      />
      {showModal && <ModalDetail />}
    </div>
  );
};

const TotalMessage = (total: number) => {
  return (
    <>
      <FormattedMessage id="common.total" />
      {' ' + total + ' '}
      <FormattedMessage id="common.item" />
    </>
  );
};

export default Grid;
