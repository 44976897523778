import { persistReducer, persistStore } from "redux-persist";
import sagaMiddleware, { setupMiddleware } from "./middleware";

import {
  CombinedState,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import { persistConfig } from "./options";
import rootReducer from "./reducers";

export type RootState = ReturnType<typeof rootReducer>;
const persistedReducer = persistReducer(persistConfig, rootReducer) as Reducer<
  CombinedState<RootState>>;

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(sagaMiddleware),
});

setupMiddleware();

let persistor = persistStore(store);

export { store, persistor };
